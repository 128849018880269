import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import {
  STATUS_PROCESSING, VIEW_TEXT, VIEW_AUDIO, ROLE_ASSISTANT,
  ROLE_USER, ROLE_DEBUG, ROLE_AGENT,
} from 'constants/messages';
import { downloadDocument } from 'modules/documents/actions';
import { getIconByMimetype } from 'utils/files';
import { prepareHtmlFromMessage } from 'utils/text';
import LoaderIcon from 'assets/icons/loader_icon.svg';
import Avatar from 'components/Avatar';
import ApiLoginBasicAuth from './ApiLoginBasicAuth';
import ApiLoginOtpAuth from './ApiLoginOtpAuth';
import ApiConfirmCall from './ApiConfirmCall';
import Form from './Form';
import DebugMessage from './DebugMessage';
import TextToSpeech from './TextToSpeech';

function Message({ message, chatbot, messages }) {
  const [actualPage, setActualPage] = useState(0);
  const [actualView, setActualView] = useState(message.type === 'audio' ? VIEW_AUDIO : VIEW_TEXT);
  const literals = useLiterals('chat');

  const handleChangeAudioView = () => {
    if (actualView === VIEW_TEXT) {
      setActualView(VIEW_AUDIO);
    } else {
      setActualView(VIEW_TEXT);
    }
  };

  const downloadDocumentInConversation = async (event) => {
    const documentId = event.currentTarget.getAttribute('data-id');
    const documentName = event.currentTarget.getAttribute('data-name');
    try {
      const blob = await downloadDocument(documentId);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = documentName;
      link.click();
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
    }
  };

  const printAvatar = () => {
    const agentsWriteAsBot = chatbot.extra_info?.agents_write_as_bot;
    if ((message.role === ROLE_ASSISTANT || (message.role === ROLE_AGENT && agentsWriteAsBot)) && chatbot.avatar) {
      const url = chatbot.avatar.indexOf('http') >= 0 ? chatbot.avatar : `${window.LLM_CHATBOT_API_URL}/chatbots/${chatbot.id}/avatar?t=${chatbot.avatar}`;
      return (
        <Avatar src={url} />
      );
    }

    if (message.role === ROLE_AGENT && !agentsWriteAsBot) {
      const photo = message.agent_info?.photo;

      if (photo) {
        return (
          <Avatar src={photo} />
        );
      }
    }

    return null;
  };

  const printAgentName = () => {
    const agentsWriteAsBot = chatbot.extra_info?.agents_write_as_bot;
    if ((message.role === ROLE_ASSISTANT || (message.role === ROLE_AGENT && agentsWriteAsBot)) && chatbot.llm_agent_name) {
      return (
        <div className='agent-name'>{chatbot.llm_agent_name}</div>
      );
    }

    if (message.role === ROLE_AGENT && !agentsWriteAsBot) {
      const name = message.agent_info?.name;

      if (name) {
        return (
          <div className='agent-name'>{name}</div>
        );
      }
    }

    return null;
  };

  const printDebugMessages = () => {
    // Debug messages triggered by this message
    const debugMessages = messages.filter((msg) => msg.role === ROLE_DEBUG && msg.extra_info && msg.extra_info.message_trigger_id === message.id);
    // Messages with no trigger message but its next to it (only for assistant messages)
    if (message.role === ROLE_ASSISTANT) {
      const index = messages.findIndex((msg) => msg.id === message.id);
      if (index < messages.length - 1) {
        // Get next message that role is not from assistant
        const nextMessage = messages[index + 1];

        if (nextMessage.role === ROLE_DEBUG) {
          const messageTriggerId = nextMessage.extra_info?.message_trigger_id;
          if (!messageTriggerId) {
            debugMessages.push(nextMessage);
          }
        }
      }
    }

    return debugMessages.map((msg) => <DebugMessage message={msg} key={`debug-message-${msg.id}`} />);
  };

  const printAttachments = (attachments) => {
    const attachmentsLayer = attachments.map((attachment) => {
      if (typeof attachment.url !== 'undefined') {
        return (
          <a className='attachment' href={attachment.url} target='_blank' rel='noreferrer' key={`attachment-${attachment.id}`} title={attachment.title}>
            {attachment.title}
          </a>
        );
      }

      return (
        <div className='attachment' key={`attachment-${attachment.id}`} title={attachment.title}>
          {attachment.title}
        </div>
      );
    });

    return (
      <div className='attachments-layer'>
        <div className='title'>{`${literals.attachments}: `}</div>
        {attachmentsLayer}
      </div>
    );
  };

  const decreaseActualPage = () => {
    setActualPage(actualPage - 1);
  };

  const increaseActualPage = () => {
    setActualPage(actualPage + 1);
  };

  const printAnswersPagination = () => {
    const totalPages = message.alternative_answers.length + 1;

    return (
      <div className='pagination'>
        {actualPage > 0 && (
          <div className='arrow' onClick={decreaseActualPage}>
            <i className='fa-solid fa-chevron-left' />
          </div>
        )}
        <div>{`${literals.answer} ${actualPage + 1} ${literals.of} ${totalPages}`}</div>
        {actualPage < (totalPages - 1) && (
          <div className='arrow' onClick={increaseActualPage}>
            <i className='fa-solid fa-chevron-right' />
          </div>
        )}
      </div>
    );
  };

  const printFileMessage = () => {
    const attachmentsLayer = message.attachments.map((attachment) => {
      const { size, title, id } = attachment;
      const sizeKb = size / 1024;
      let strSize = `${sizeKb.toFixed(2)} KB`;
      if (sizeKb > 1024) {
        const sizeMb = sizeKb / 1024;
        strSize = `${sizeMb.toFixed(2)} MB`;

        if (sizeMb > 1024) {
          const sizeGb = sizeMb / 1024;
          strSize = `${sizeGb.toFixed(2)} GB`;
        }
      }

      return (
        <button type='button' data-id={id} data-name={title} className='llm-chatbot-btn-download-attachment' key={`attachment-${id}`} title={title} onClick={downloadDocumentInConversation}>
          <div>
            <i className={`${getIconByMimetype(attachment.mimetype)} icon-mimetype`} />
          </div>
          <div>
            <div className='title'>{title}</div>
            <div className='size'>{strSize}</div>
          </div>
        </button>
      );
    });
    return (
      <div className={`llm-chatbot-message speaker_${message.role}`} data-id={message.id}>
        {printAvatar()}
        <div>
          {printAgentName()}
          <div className='content file'>
            {attachmentsLayer}
          </div>
        </div>
      </div>
    );
  };

  const printImageMessage = () => {
    return (
      <div className={`llm-chatbot-message speaker_${message.role}`} data-id={message.id}>
        {printAvatar()}
        <div>
          {printAgentName()}
          <div className='content file'>
            {
              message.attachments.map((attachment) => {
                return (
                  <img alt='' src={`${window.LLM_CHATBOT_API_URL}${attachment.url}`} key={`image-${attachment.id}`} style={{ width: `${attachment.width}px` }} />
                );
              })
            }
          </div>
        </div>
      </div>
    );
  };

  const getAudioUrl = () => {
    if (typeof message.audioURL !== 'undefined') {
      return message.audioURL;
    }

    return `${window.window.MAIN_API_URL}/chatbots/${chatbot.id}/conversations/${message.conversation_id}/messages/${message.id}/audio`;
  };

  const printTextMessage = (content) => {
    if (!content && message.role === ROLE_ASSISTANT && !chatbot.enable_debug) {
      return null;
    }

    if (message.extra_info?.html) {
      return (<div key={`text-message-${message.id}`} dangerouslySetInnerHTML={{ __html: content }} />);
    }
    return (
      <div key={`text-message-${message.id}`} className='d-flex'>
        {chatbot.extra_info?.is_dictation_enabled && (
          <TextToSpeech text={content} />
        )}
        <div>
          {prepareHtmlFromMessage(content, literals)}
        </div>
      </div>
    );
  };

  const printSignInApiForm = () => {
    const { extra_info: { api: dataAuth } } = message;

    let content = '';
    switch (dataAuth.type_auth) {
      case 'basic':
        content = (
          <ApiLoginBasicAuth
            chatbotId={chatbot.id}
            conversationId={message.conversation_id}
            messageId={message.id}
            dataAuth={dataAuth}
          />
        );
        break;
      case 'otp':
        content = (
          <ApiLoginOtpAuth
            chatbotId={chatbot.id}
            conversationId={message.conversation_id}
            messageId={message.id}
            extraInfo={message.extra_info}
            dataAuth={dataAuth}
          />
        );
        break;
      default:
        break;
    }
    return (
      <div className={`llm-chatbot-message speaker_${message.role}`} key={`signinapi-message-${message.id}`} data-id={message.id}>
        {printAvatar()}
        <div>
          {printAgentName()}
          <div className='content'>
            {content}
          </div>
        </div>
      </div>
    );
  };

  const printApiCallConfirmation = () => {
    return (
      <div className={`llm-chatbot-message speaker_${message.role}`} key={`signinapi-message-${message.id}`} data-id={message.id}>
        {printAvatar()}
        <div>
          {printAgentName()}
          <div className='content'>
            <ApiConfirmCall
              chatbotId={chatbot.id}
              conversationId={message.conversation_id}
              messageId={message.id}
              data={message.extra_info.request_info}
            />
          </div>
        </div>
      </div>
    );
  };

  const printMessageDate = () => {
    if (typeof message.created_at === 'undefined') {
      return null;
    }

    // **Parse the date in UTC format:**
    const date = new Date(`${message.created_at}Z`); // Add 'Z' to indicate UTC

    // **Get the client's local time zone:**
    const localTimeZoneOffset = new Date().getTimezoneOffset();

    // **Adjust the date to the client's time zone:**
    const localDate = new Date(date.getTime() - (localTimeZoneOffset * 60 * 1000));

    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);

    const isToday = localDate.toDateString() === now.toDateString();
    const isYesterday = localDate.toDateString() === yesterday.toDateString();

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    let dateStr = '';
    if (isToday) {
      dateStr = `${hours}:${minutes}`;
    } else if (isYesterday) {
      dateStr = `Ayer ${hours}:${minutes}`;
    } else {
      dateStr = `${day}/${month}/${year} ${hours}:${minutes}`;
    }

    return (<div className='date'>{dateStr}</div>);
  };

  const printForm = () => {
    return (
      <div className={`llm-chatbot-message speaker_${message.role} form`} key={`signinapi-message-${message.id}`} data-id={message.id}>
        {printAvatar()}
        <div>
          {printAgentName()}
          <div className='content'>
            <Form chatbotId={chatbot.id} conversationId={message.conversation_id} message={message} />
          </div>
        </div>
      </div>
    );
  };

  const printActualMessage = () => {
    switch (message.type) {
      case 'file':
        return printFileMessage();
      case 'image':
        return printImageMessage();
      case 'authentication_api':
        return printSignInApiForm();
      case 'api_call_confirmation':
        return printApiCallConfirmation();
      case 'form':
        return printForm();
      case 'hidden':
        return null;
      default:
        break;
    }

    if (message.role === ROLE_DEBUG) {
      return null;
    }

    let content = message.content;
    let attachments = message.attachments;

    if (actualPage > 0) {
      content = message.alternative_answers[actualPage - 1].content;
      attachments = message.alternative_answers[actualPage - 1].attachments;
    }

    return (
      <div className={`llm-chatbot-message speaker_${message.role}`} data-id={message.id}>
        {printAvatar()}
        <div>
          {printAgentName()}
          {typeof message.status !== 'undefined' && message.status === STATUS_PROCESSING ? (
            <div className='content'>
              <img src={LoaderIcon} alt='' className='loader' style={{ margin: '0 10px' }} />
            </div>
          ) : (content || (message.role === ROLE_ASSISTANT && !chatbot.enable_debug)) && (
            <>
              <div className='content'>
                {message.type === 'audio' && actualView === VIEW_AUDIO ? (
                  /* eslint-disable-next-line jsx-a11y/media-has-caption */
                  <audio id={`audio-message-${message.id}`} preload='metadata' controls src={getAudioUrl()} key={`audio-message-${message.id}`} />
                ) : printTextMessage(content)}
                {typeof attachments !== 'undefined' && attachments.length > 0 && (
                  printAttachments(attachments)
                )}
                {printMessageDate()}
              </div>
              {message.type === 'audio' && (
                <button key={`button-audio-${message.id}`} type='button' className='btn-link' onClick={handleChangeAudioView}>
                  {actualView === VIEW_AUDIO ? literals.viewAsText : literals.viewAsAudio}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      {printActualMessage()}
      {typeof message.alternative_answers !== 'undefined' && message.alternative_answers.length > 0 && (
        printAnswersPagination()
      )}
      {chatbot.enable_debug && (
        printDebugMessages()
      )}
    </>
  );
}

Message.propTypes = {
  chatbot: PropTypes.shape({
    id: PropTypes.string.isRequired,
    enable_debug: PropTypes.bool,
    avatar: PropTypes.string,
    llm_agent_name: PropTypes.string,
    extra_info: PropTypes.shape({
      agents_write_as_bot: PropTypes.bool,
      is_dictation_enabled: PropTypes.bool,
    }),
  }).isRequired,
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    conversation_id: PropTypes.string,
    role: PropTypes.string.isRequired,
    content: PropTypes.string,
    steps: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      extra_info: PropTypes.object,
      icon: PropTypes.string,
    })),
    agent_info: PropTypes.object,
    attachments: PropTypes.arrayOf(PropTypes.object),
    alternative_answers: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.string.isRequired,
      attachments: PropTypes.arrayOf(PropTypes.object),
    })),
    type: PropTypes.string,
    audioURL: PropTypes.string,
    status: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    extra_info: PropTypes.object,
    form: PropTypes.string,
    orchestrator_variables: PropTypes.object,
    created_at: PropTypes.string,
  }).isRequired,
  messages: PropTypes.array,
};

Message.defaultProps = {
  messages: [],
};

export default Message;
