// Importa los componentes necesarios de React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLiterals from 'hooks/useLiterals';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import isEmail from 'validator/lib/isEmail';
import { loadingActions } from 'modules/loading';
import {
  getMyOrganizations,
  getMyInvitations,
  createOrganization,
  deleteOrganization,
  createInvitation,
  acceptInvitation,
  rejectInvitation,
  updateOrganization,
  getOrganizationMembers,
} from 'modules/organizations/actions';
import { changeActualOrganization } from 'modules/app/actions';
import INVITATION_STATUS from 'constants/invitations';
import ConfirmModal from 'components/ConfirmModal';
import Layout from 'components/Layout';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownMenuToggle from 'components/DropdownMenuToggle';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import Form from './components/form';
import InviteUserForm from './components/inviteUser';
import MembersTable from './components/membersTable';

function Organizations() {
  const literals = useLiterals('organizations');
  const dispatch = useDispatch();
  const { organizations, invitations, members } = useSelector((state) => state.organizations);
  const { actualOrganization } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.session);
  const { showErrorModal, printErrorModal } = useErrorHandling();
  // Form creation/edition
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showInviteUserForm, setShowInviteUserForm] = useState(false);
  const [inviteUserData, setInviteUserData] = useState({ email: '' });
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [logo, setLogo] = useState('');
  const [primaryColor, setPrimaryColor] = useState('#68c3c6');
  const [secondaryColor, setSecondaryColor] = useState('#f4aadf');
  const [isSaving, setIsSaving] = useState(false);
  const [errorForm, setErrorForm] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [organizationToDelete, setOrganizationToDelete] = useState(null);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [membersData, setMembersData] = useState([]);

  useEffect(() => {
    dispatch(loadingActions.show());
    dispatch(getMyOrganizations());
    dispatch(getMyInvitations());
    dispatch(loadingActions.hide());
  }, []);

  const fetchOrganizationMembers = async (organizationId) => {
    return getOrganizationMembers(organizationId);
  };

  const handleViewMembers = async (organizationId) => {
    try {
      dispatch(loadingActions.show());
      const response = await fetchOrganizationMembers(organizationId);
      const data = response;
      setMembersData(data.members);
      setShowMembersModal(true);
    } catch (error) {
      console.error('Error fetching members data:', error);
      const errorMessage = getErrorMessage(error);
      showErrorModal(errorMessage);
    } finally {
      dispatch(loadingActions.hide());
    }
  };

  const createNewOrganization = () => {
    setSelectedOrganization(null);
    setName('');
    setDescription('');
    setLogo('');
    setPrimaryColor('#68c3c6');
    setSecondaryColor('#f4aadf');
    setShowCreateForm(true);
    setErrorForm(null);
  };

  const handleEditOrganization = (event) => {
    const organizationId = event.currentTarget.getAttribute('data-id');
    const organization = organizations.find((org) => org.id === organizationId);
    if (organization) {
      setSelectedOrganization(organization.id);
      setName(organization.name);
      setDescription(organization.description);
      setLogo('');
      setPrimaryColor(organization.primary_color || '#68c3c6');
      setSecondaryColor(organization.secondary_color || '#f4aadf');
      setShowCreateForm(true);
      setErrorForm(null);
    }
  };

  const createNewInvitation = () => {
    createInvitation(inviteUserData.organizationId, { invitee: inviteUserData.email });
    setShowInviteUserForm(false);
    setErrorForm(null);
  };

  const closeCreateForm = () => {
    setShowCreateForm(false);
  };

  const canSave = () => {
    return name !== '' && !isSaving && name.length <= 120;
  };

  const handleCloseDeleteOrganizationModal = () => {
    setOrganizationToDelete(null);
  };

  const handleSubmitDeleteOrganization = async () => {
    dispatch(loadingActions.show());
    handleCloseDeleteOrganizationModal();
    await dispatch(deleteOrganization(organizationToDelete.id));
    dispatch(loadingActions.hide());
  };

  const handleDeleteOrganization = async (event) => {
    const organizationId = event.currentTarget.getAttribute('data-id');
    const organization = organizations.find((org) => org.id === organizationId);
    if (organization) {
      setOrganizationToDelete(organization);
    }
  };

  const saveOrganization = async () => {
    setIsSaving(true);
    setErrorForm(null);
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('description', description);
      formData.append('logo', logo);
      formData.append('primary_color', primaryColor);
      formData.append('secondary_color', secondaryColor);
      if (selectedOrganization) {
        await dispatch(updateOrganization(selectedOrganization, formData));
        if (actualOrganization && actualOrganization.id === selectedOrganization) {
          const organization = {
            ...actualOrganization,
            name,
            description,
            primary_color: primaryColor,
            secondary_color: secondaryColor,
            logo: logo ? 'new_logo' : actualOrganization.logo,
          };
          dispatch(changeActualOrganization(organization));
        }
      } else {
        await dispatch(createOrganization(formData));
      }
      closeCreateForm();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      setErrorForm(literals.errors[errorMessage]);
    } finally {
      setIsSaving(false);
    }
  };

  const printConfirmDeleteOrganizationModal = () => {
    if (!organizationToDelete) {
      return null;
    }

    const message = `${literals.deleteOrganizationDescription} ${organizationToDelete.name}?`;

    return (
      <ConfirmModal
        open
        onClose={handleCloseDeleteOrganizationModal}
        onSubmit={handleSubmitDeleteOrganization}
        title={literals.deleteOrganizationTitle}
        message={message}
      />
    );
  };

  const printInvitationsTable = () => {
    const rows = invitations?.invitee.map((invitation) => {
      if (invitation.status !== INVITATION_STATUS.PENDING) {
        return null;
      }
      return (
        <tr key={invitation.id} data-id={invitation.id}>
          <td>{invitation?.organization?.name}</td>
          <td className='right'>
            <button
              className='btn btn-secondary btn-sm'
              type='button'
              onClick={() => {
                dispatch(loadingActions.show());
                dispatch(rejectInvitation(invitation.id));
                dispatch(loadingActions.hide());
              }}
            >
              {literals.reject}
            </button>
            <button
              className='btn btn-primary btn-sm'
              type='button'
              onClick={() => {
                dispatch(loadingActions.show());
                dispatch(acceptInvitation(invitation.id));
                dispatch(loadingActions.hide());
              }}
            >
              {literals.common.accept}
            </button>
          </td>
        </tr>
      );
    });

    return (
      <div>
        <table className='table_stl1'>
          <thead>
            <tr>
              <th>{literals.common.name}</th>
              <th className='right'>{literals.common.options}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const printTable = () => {
    const rows = organizations.map((organization) => {
      const isAdmin = organization.admin_users.includes(user.id);
      return (
        <tr key={organization.id} data-id={organization.id}>
          <td>{organization.name}</td>
          <td>{organization.description}</td>
          <td className='right'>
            <button
              className='btn btn-primary btn-sm'
              type='button'
              data-id={organization.id}
              onClick={handleEditOrganization}
            >
              {literals.common.edit}
            </button>
            <Dropdown className='dropdown-menu-expand'>
              <Dropdown.Toggle as={DropdownMenuToggle} id={`dropdown-custom-components-${organization.id}`} />
              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey='1'
                  onClick={() => {
                    setShowInviteUserForm(true);
                    setInviteUserData({ ...inviteUserData, organizationId: organization.id });
                  }}
                >
                  {literals.inviteUser}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey='2'
                  onClick={() => {
                    handleViewMembers(organization.id);
                  }}
                >
                  {literals.viewMembers}
                </Dropdown.Item>
                {isAdmin && (
                  <Dropdown.Item
                    eventKey='3'
                    className='text-danger'
                    onClick={handleDeleteOrganization}
                    data-id={organization.id}
                  >
                    {literals.common.delete}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });

    return (
      <div>
        <table className='table_stl1'>
          <thead>
            <tr>
              <th>{literals.common.name}</th>
              <th>{literals.description}</th>
              <th className='right'>{literals.common.options}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const printNoElements = () => {
    return (
      <div className='page_content'>
        <div className='no_elements'>{literals.thereIsNoOrganizations}</div>
      </div>
    );
  };

  const printInviteUserModalForm = () => {
    if (!showInviteUserForm) {
      return null;
    }

    return (
      <Modal show onHide={() => setShowInviteUserForm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{literals.inviteUser}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InviteUserForm
            email={inviteUserData.email}
            error={errorForm}
            onChangeEmail={(email) => setInviteUserData({ ...inviteUserData, email })}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowInviteUserForm(false)}>
            {literals.common.close}
          </Button>
          <Button
            variant='primary'
            onClick={createNewInvitation}
            disabled={!isEmail(inviteUserData.email)}
          >
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {literals.common.accept}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const printCreateOrganizationModalForm = () => {
    if (!showCreateForm) {
      return null;
    }

    return (
      <Modal show onHide={closeCreateForm}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedOrganization ? literals.editOrganization : literals.createOrganization}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            name={name}
            description={description}
            logo={logo}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            error={errorForm}
            onChangeName={setName}
            onChangeDescription={setDescription}
            onChangeLogo={setLogo}
            onChangePrimaryColor={setPrimaryColor}
            onChangeSecondaryColor={setSecondaryColor}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeCreateForm}>
            {literals.common.close}
          </Button>
          <Button
            variant='primary'
            onClick={saveOrganization}
            disabled={!canSave()}
          >
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {literals.common.accept}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const printMembersModal = () => {
    return (
      <Modal show={showMembersModal} onHide={() => setShowMembersModal(false)} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>{literals.members}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MembersTable members={membersData} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowMembersModal(false)}>
            {literals.common.close}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const pendingInvitations = invitations?.invitee?.filter((invitation) => invitation.status === INVITATION_STATUS.PENDING);

  return (
    <>
      {printCreateOrganizationModalForm()}
      {printInviteUserModalForm()}
      {printConfirmDeleteOrganizationModal()}
      {printMembersModal()}
      {printErrorModal()?.modal}
      <Layout selectedSection='organizations'>
        <div className='section'>
          <div className='section_header'>
            <h1>{literals.organizations}</h1>
            <div className='btn btn-primary' onClick={createNewOrganization}>{literals.createOrganization}</div>
          </div>
          {pendingInvitations?.length > 0 && (
            <div className='mt-lg'>
              {printInvitationsTable()}
            </div>
          )}
          <div className='mt-lg'>
            {organizations.length ? printTable() : printNoElements()}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Organizations;
