import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Chat from 'components/Chat';
import {
  TAB_CHATBOT, TAB_SOURCES, TAB_PROCESS, TAB_DATA,
} from 'constants/project';
import './styles.scss';

function Project() {
  // const { companyId, projectId } = useParams();

  const [selectedTab, setSelectedTab] = useState(TAB_CHATBOT);

  const selectTabChatbot = () => {
    setSelectedTab(TAB_CHATBOT);
  };

  const selectTabProcess = () => {
    setSelectedTab(TAB_PROCESS);
  };

  const selectTabSources = () => {
    setSelectedTab(TAB_SOURCES);
  };

  const selectTabData = () => {
    setSelectedTab(TAB_DATA);
  };

  const printChatbot = () => {
    return (
      <div className='wrapper-tab-chatbot'>
        <Chat />
      </div>
    );
  };

  return (
    <div className='section'>
      <div className='section_header'>
        <h1>Due Diligence Data Room</h1>
      </div>
      <div className='page_content'>
        <div className='tabs'>
          <div className={`tab ${selectedTab === TAB_CHATBOT ? 'selected' : ''}`} onClick={selectTabChatbot}>
            Conversations
          </div>
          <div className={`tab ${selectedTab === TAB_PROCESS ? 'selected' : ''}`} onClick={selectTabProcess}>
            Process
          </div>
          <div className={`tab ${selectedTab === TAB_SOURCES ? 'selected' : ''}`} onClick={selectTabSources}>
            Files
          </div>
          <div className={`tab ${selectedTab === TAB_DATA ? 'selected' : ''}`} onClick={selectTabData}>
            Data
          </div>
        </div>
        <div className={`tab_content ${selectedTab !== TAB_CHATBOT ? 'hidden' : ''}`}>
          {printChatbot()}
        </div>
        <div className={`tab_content ${selectedTab !== TAB_PROCESS ? 'hidden' : ''}`}>
          Process
        </div>
        <div className={`tab_content ${selectedTab !== TAB_SOURCES ? 'hidden' : ''}`}>
          Sources
        </div>
        <div className={`tab_content ${selectedTab !== TAB_DATA ? 'hidden' : ''}`}>
          Data
        </div>
      </div>
    </div>
  );
}

export default Project;
