export const SET_ACTUAL_ORGANIZATION = 'app/set-actual-organization';

export const initialState = {
  actualOrganization: null,
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_ACTUAL_ORGANIZATION: {
      const actualOrganization = payload;
      if (!actualOrganization) {
        return {
          ...state,
          actualOrganization: null,
        };
      }
      return {
        ...state,
        actualOrganization: {
          logoContent: null,
          ...actualOrganization,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
