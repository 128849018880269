import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const FORM_CATEGORY = 'Form';

export default {
  PRINT_FORM: {
    categories: [FORM_CATEGORY],
    tag: 'printForm',
    name: 'Print form',
    tags: ['form'],
    icon: 'fa-brands fa-wpforms',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.FORM,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'form',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.CONVERSATION,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'conversation',
        custom: false,
        from: [],
      },
    ],
    outputs: [
    ],
  },
};
