import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const DOCUMENT_CATEGORY = 'Document';

export default {
  EXTRACT_DOCUMENT_CONTENT: {
    categories: [DOCUMENT_CATEGORY],
    tag: 'extractDocumentContent',
    name: 'Extract content from document',
    tags: ['document', 'content', 'extract', 'get content', 'get document content'],
    icon: 'fa-solid fa-file-lines',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.DOCUMENT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'document',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'content',
        custom: false,
        to: [],
      },
    ],
  },
  SEARCH_DOCUMENTS: {
    categories: [DOCUMENT_CATEGORY],
    tag: 'searchDocuments',
    name: 'Search documents',
    tags: ['document', 'search', 'find', 'query', 'documents search'],
    icon: 'fa-solid fa-search',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.CHATBOT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'chatbot',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'search_query',
        custom: false,
        is_required: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'type',
        custom: false,
        is_required: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'minimum_score',
        custom: false,
        is_required: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.CONVERSATION,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'conversation',
        custom: false,
        is_required: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'results',
        custom: false,
        to: [],
      },
    ],
  },
};
