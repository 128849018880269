import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const colors = [
  '#E63946',
  '#457B9D',
  '#1D3557',
  '#F4A261',
  '#2A9D8F',
  '#E76F51',
  '#264653',
  '#E9C46A',
];

function TestResumeChart({
  chatbotTests,
}) {
  const getResultsFormatted = () => {
    const result = [];
    Object.keys(chatbotTests).forEach((chatbotId) => {
      chatbotTests[chatbotId].forEach((test) => {
        const testResult = result.find((r) => r.id === test.test_question_id);
        if (!testResult) {
          result.push({
            id: test.test_question_id,
            name: result.length + 1,
            [test.chatbot_id]: test.score,
          });
        } else {
          testResult[test.chatbot_id] = test.score;
        }
      });
    });
    return result;
  };

  const getChatbotName = (chatbotId) => {
    const chatbot = chatbotTests[chatbotId][0].chatbot_data;
    return chatbot.name;
  };

  const dataFormatted = getResultsFormatted();
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart
        width={500}
        height={300}
        data={dataFormatted}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip formatter={(value, name) => [value, getChatbotName(name)]} />
        <Legend formatter={(name) => getChatbotName(name)} />
        {Object.keys(chatbotTests).map((chatbotId, index) => (
          <Bar dataKey={chatbotId} maxBarSize={10} fill={colors[index]} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}

TestResumeChart.propTypes = {
  chatbotTests: PropTypes.object.isRequired,
};

TestResumeChart.defaultProps = {

};

export default TestResumeChart;
