import {
  SET_MESSAGES, ADD_MESSAGE_CONTENT, ADD_MESSAGE, DELETE_MESSAGE,
} from 'modules/chats/index';
import { SET_STATUS, SET_CONNECTED_TIME, SET_DISCONNECTED_TIME } from 'modules/socket';
import { STATUS_CONNECTED, STATUS_DISCONNECTED } from 'constants/status';
import { addUpdatedConversation, addDeletedConversation, addTransferedConversation } from 'modules/conversations/actions';
import { insertNodeInExecution, insertNodeResult } from 'modules/debug/actions';

let socket = null;

const sendBySocket = (data) => {
  try {
    socket.send(JSON.stringify(data));
  } catch (err) {
    console.error('error', err);
  }
};

export const initSocket = () => (dispatch) => {
  console.log('initSocket');
  socket = new WebSocket(window.LLM_CHATBOT_WEBSOCKET_URL);

  socket.onopen = () => {
    console.log('on open');
    dispatch({ type: SET_STATUS, payload: STATUS_CONNECTED });
    dispatch({ type: SET_CONNECTED_TIME, payload: Date.now() });

    // Hacemos ping cada 10 segundos para que no se corte por inactividad
    setInterval(() => {
      sendBySocket({ type: 'get_rooms', data: Date.now() });
    }, 20000);
  };

  socket.onclose = () => {
    dispatch({ type: SET_STATUS, payload: STATUS_DISCONNECTED });
    dispatch({ type: SET_DISCONNECTED_TIME, payload: Date.now() });
    setTimeout(() => dispatch(initSocket()), 1000);
  };

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    switch (data.type) {
      case 'conversation_updated':
        dispatch(addUpdatedConversation(data.data));
        break;
      case 'chat_message_streaming':
        dispatch({ type: ADD_MESSAGE_CONTENT, payload: data.data });
        break;
      case 'chat_message':
        dispatch({
          type: ADD_MESSAGE,
          payload: {
            ...data.data,
          },
        });
        break;
      case 'conversation_is_deleted':
        dispatch(addDeletedConversation(data.data.conversation_id));
        break;
      case 'transfer_conversation':
        dispatch(addTransferedConversation(data.data));
        break;
      case 'chat_message_deleted':
        dispatch({
          type: DELETE_MESSAGE,
          payload: {
            conversationId: data.data.conversation_id,
            messageId: data.data.message_id,
          },
        });
        break;
      case 'node_executed':
        dispatch(insertNodeInExecution(data.data.process_id, data.data.node_id));
        break;
      case 'node_result':
        dispatch(insertNodeResult(data.data.process_id, data.data.node_id, data.data.result));
        break;
      default:
        break;
    }
  };

  socket.onerror = (event) => {
    console.error(`WebSocket error:  ${event}`);
  };
};

export const setMessages = (data) => (dispatch) => {
  dispatch({ type: SET_MESSAGES, payload: data });
};

export const sendMessage = (type, data, dontSet = false) => (dispatch, getState) => {
  if (!dontSet) {
    dispatch(setMessages(data));
  }

  // Get user id
  const { session } = getState();

  const userId = session?.user?.id ?? null;
  sendBySocket({ type, data: { ...data, user_id: userId } });
};

export const addMessage = (conversationId, message) => (dispatch) => {
  dispatch({
    type: ADD_MESSAGE,
    payload: {
      ...message,
      conversation_id: conversationId,
    },
  });
};

export const sendNewConversation = (conversationId) => {
  try {
    sendBySocket({ type: 'new_conversation', data: { conversation_id: conversationId } });
  } catch (err) {
    console.error('error', err);
  }
};

export const joinConversation = (conversationId) => {
  try {
    sendBySocket({ type: 'join_conversation', data: { conversation_id: conversationId } });
  } catch (err) {
    console.error('error', err);
  }
};

export const retryMessage = (conversationId, messageId, userId) => {
  const data = {
    conversation_id: conversationId,
    message_id: messageId,
    user_id: userId,
  };
  setTimeout(() => {
    sendBySocket({ type: 'retry_message', data: { ...data, user_id: userId } });
  }, 100);
};
