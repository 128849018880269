import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const UTILITIES_CATEGORY = 'Utils';

export default {
  CODE: {
    categories: [UTILITIES_CATEGORY],
    tag: 'code',
    name: 'Execute Code',
    tags: ['code', 'execute', 'python'],
    icon: 'fa-solid fa-code',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'response',
        custom: false,
        to: [],
      },
    ],
  },
  IS_EMPTY: {
    categories: [UTILITIES_CATEGORY],
    tag: 'isEmpty',
    name: 'Is Empty',
    tags: ['variable', 'is', 'empty', 'null', 'undefined', 'check', 'none'],
    icon: 'fa-solid fa-question',
    inputs: [
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'variable',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'is_empty',
        custom: false,
        to: [],
      },
    ],
  },
  SLEEP: {
    categories: [UTILITIES_CATEGORY],
    tag: 'sleep',
    name: 'Sleep',
    tags: ['sleep', 'wait', 'pause', 'delay', 'timeout', 'settimeout'],
    icon: 'fa-solid fa-clock',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'seconds',
        custom: false,
        default_value: 1,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
    ],
  },
  SEND_HTML: {
    categories: [UTILITIES_CATEGORY],
    tag: 'sendHTML',
    name: 'Send HTML',
    tags: ['send', 'html', 'markup'],
    icon: 'fa-brands fa-html5',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.CONVERSATION,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'conversation',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'html',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'html',
        custom: false,
        to: [],
      },
    ],
  },
};
