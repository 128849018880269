export const VIEW_DATA = 'view_data';
export const VIEW_ENDPOINTS = 'view_endpoints';

export const TYPE_NO_AUTH = 'no_auth';
export const TYPE_OAUTH = 'oauth';
export const TYPE_BASIC = 'basic';
export const TYPE_OTP = 'otp';

export const TYPE_PARAM_QUERY = 'query';
export const TYPE_PARAM_BODY = 'body';
export const TYPE_PARAM_FORM_DATA = 'form-data';

export const TYPES_PARAMS = [TYPE_PARAM_QUERY, TYPE_PARAM_BODY, TYPE_PARAM_FORM_DATA];

export const TYPES_AUTH = [
  {
    value: TYPE_NO_AUTH,
    label: 'No Auth',
  },
  // {
  //   value: TYPE_OAUTH,
  //   label: 'OAuth',
  // },
  {
    value: TYPE_BASIC,
    label: 'Basic',
  },
  {
    value: TYPE_OTP,
    label: 'OTP',
  },
];
