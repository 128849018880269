import React from 'react';
import propTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import useLiterals from 'hooks/useLiterals';

function ConfirmModal({
  open, onClose, onSubmit, message,
  title, confirmText, cancelText,
}) {
  const literals = useLiterals('confirmModal');
  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title || literals.areYourSure}</Modal.Title>
      </Modal.Header>
      {message && (
        <Modal.Body>{message}</Modal.Body>
      )}
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {cancelText || literals.cancel}
        </Button>
        <Button
          className='btn-danger'
          onClick={onSubmit}
        >
          {confirmText || literals.confirm}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
  title: propTypes.string,
  cancelText: propTypes.string,
  confirmText: propTypes.string,
  message: propTypes.string,
};

ConfirmModal.defaultProps = {
  title: null,
  cancelText: null,
  confirmText: null,
  message: null,
};

export default ConfirmModal;
