import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import { updateForm } from 'modules/forms/actions';

function Questions(props) {
  const {
    form, setForm, onBack, onEdit, handleRemoveQuestion,
  } = props;
  const { data } = form;
  const [isSaving, setIsSaving] = useState(false);

  const { showErrorModal, printErrorModal } = useErrorHandling();
  const literals = useLiterals('forms');

  const editQuestion = (e) => {
    const questions = data?.questions || [];
    const id = e.currentTarget.getAttribute('data-id');
    const question = questions.find((q) => q.id === id);
    if (question) {
      onEdit(question);
    }
  };

  const removeQuestion = (questionId) => {
    const updatedQuestions = data.questions.filter((question) => question.id !== questionId);
    const updatedFormData = { ...data, questions: updatedQuestions };
    const updatedForm = { ...form, data: updatedFormData };
    updateForm(form.id, updatedForm)
      .then(() => {
        // Actualización exitosa, llamamos a la función de actualización del estado
        handleRemoveQuestion(updatedForm);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        showErrorModal(errorMessage);
      });
  };

  const printNoElements = () => {
    return (
      <div className='page_content'>
        <div className='no_elements'>{literals.thereIsNoQuestions}</div>
      </div>
    );
  };

  const saveForm = async () => {
    setIsSaving(true);
    try {
      await updateForm(form.id, {
        ...form,
      });
      if (onBack) {
        onBack();
      }
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  const printTable = () => {
    const questions = data?.questions || [];

    if (!questions.length) {
      return printNoElements();
    }

    const rows = questions.map((question) => {
      if (!question) {
        return null;
      }
      return (
        <tr key={question.id} data-id={question.id}>
          <td>{question.title}</td>
          <td>{literals[question.type]}</td>
          <td>{question.variable_name}</td>
          <td className='right'>
            <div className='buttons-container' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                data-id={question.id}
                className='btn btn-primary btn-sm'
                type='button'
                onClick={editQuestion}
              >
                {literals.common.edit}
              </button>
              <button
                data-id={question.id}
                className='btn btn-danger btn-sm ml-2'
                type='button'
                onClick={() => removeQuestion(question.id)}
              >
                {literals.common.delete}
              </button>
            </div>
          </td>
        </tr>
      );
    });
    return (
      <div>
        <table className='table_stl1'>
          <thead>
            <tr>
              <th>{literals.common.name}</th>
              <th>{literals.common.type}</th>
              <th>{literals.variableName}</th>
              <th className='right'>{literals.common.options}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      {printErrorModal()?.modal}
      {printTable()}
      <div className='form_buttons'>
        <button type='button' className='btn btn-secondary' onClick={onBack}>
          {literals.common.cancel}
        </button>
        <button type='button' className='btn btn-primary' onClick={saveForm}>
          {isSaving && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
          {literals.common.save}
        </button>
      </div>
    </div>
  );
}

Questions.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.shape({
      questions: PropTypes.arrayOf(PropTypes.shape({
        variable_name: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        required: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string,
          value: PropTypes.string,
          text: PropTypes.string,
        })),
      })),
    }),
  }).isRequired,
  setForm: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  handleRemoveQuestion: PropTypes.func.isRequired,
};

export default Questions;
