import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import useLiterals from 'hooks/useLiterals';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { loadingActions } from 'modules/loading';
import { getOrchestratorNode, moveConversations } from 'modules/orchestrators/actions';
import { getConversationsStats, downloadCSV } from 'modules/conversations/actions';
import Layout from 'components/Layout';
import { ROUTE_PATH } from 'routes';
import ConversationsTable from './components/ConversationsTable';
import Conversation from './components/Conversation';
import './styles.scss';
import MoveConversationsModal from './components/MoveConversations';

const CONVERSATIONS_PER_PAGE = 50;
let TO_GET_CONVERSATIONS = null;

function OrchestratorConversations() {
  const navigate = useNavigate();
  const literals = useLiterals('conversations');
  const dispatch = useDispatch();
  const [conversations, setConversations] = useState([]);
  const [error, setError] = useState(null);
  const { orchestratorId } = useParams();
  const [selectedChatbotId, setSelectedChatbotId] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [showMoveConversationsModal, setShowMoveConversationsModal] = useState(false);
  const [filters, setFilters] = useState({
    page: 0,
    limit: CONVERSATIONS_PER_PAGE,
    sortType: 'desc',
    sortField: 'created_at',
    search: '',
    startDate: moment().subtract(1, 'months').startOf('day').format('YYYY-MM-DD'),
    endDate: moment().endOf('day').format('YYYY-MM-DD'),
  });

  const { orchestrators } = useSelector((state) => state.orchestrators);

  const getOrchestratorConversations = () => {
    dispatch(loadingActions.show());
    const startDate = moment(filters.startDate).startOf('day').format('YYYY-MM-DD');
    const endDate = moment(filters.endDate).endOf('day').format('YYYY-MM-DD');
    getConversationsStats(orchestratorId, filters.page, filters.limit, filters.sortType, filters.sortField, startDate, endDate, filters.search)
      .then((response) => {
        setConversations(response);
      })
      .catch((e) => {
        setError(e?.response?.data?.message);
      })
      .finally(() => {
        dispatch(loadingActions.hide());
      });
  };

  useEffect(() => {
    if (TO_GET_CONVERSATIONS) {
      clearTimeout(TO_GET_CONVERSATIONS);
    }

    TO_GET_CONVERSATIONS = setTimeout(getOrchestratorConversations, 1500);
  }, [filters]);

  const handleViewConversation = (chatbotId, newConversationId) => {
    setSelectedChatbotId(chatbotId);
    setConversationId(newConversationId);
  };

  const handleshowMoveConversationsModal = () => {
    setShowMoveConversationsModal(true);
  };

  const hideMoveConversationsModal = () => {
    setShowMoveConversationsModal(false);
  };

  const handleSearchChange = (event) => {
    setFilters({
      ...filters,
      search: event.target.value,
    });
  };

  const handleStartDateChange = (event) => {
    setFilters({
      ...filters,
      startDate: event.target.value,
    });
  };

  const handleEndDateChange = (event) => {
    setFilters({
      ...filters,
      endDate: event.target.value,
    });
  };

  const handleMoveConversations = (selectedOrchestrator) => {
    moveConversations(orchestratorId, selectedOrchestrator)
      .then(() => {
        hideMoveConversationsModal();
        navigate(ROUTE_PATH.ORCHESTRATORS);
      })
      .catch((e) => {
        setError(e?.response?.data?.message);
      });
  };

  const handleDownloadCSV = () => {
    downloadCSV(orchestratorId, filters.startDate, filters.endDate)
      .then((csvData) => {
        const blob = new Blob([csvData], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'data.csv';
        link.click();
        window.URL.revokeObjectURL(link);
      })
      .catch((e) => {
        setError(e?.response?.data?.message);
      })
      .finally(() => {
        dispatch(loadingActions.hide());
      });
  };

  const handleCloseChat = () => {
    setSelectedChatbotId(null);
    setConversationId(null);
  };

  return (
    <Layout selectedSection='orchestrators'>
      <MoveConversationsModal
        open={showMoveConversationsModal}
        onClose={hideMoveConversationsModal}
        onSubmit={handleMoveConversations}
        orchestrator={orchestrators.find((orchestrator) => orchestrator.id === orchestratorId)}
        orchestrators={orchestrators}
      />
      <div className='section'>
        <div className='section_header'>
          <h1>
            {orchestrators.find((orchestrator) => orchestrator.id === orchestratorId)?.name || literals.title}
          </h1>
          <div>
            <Button variant='secondary' onClick={handleshowMoveConversationsModal}>{literals.moveConversations}</Button>
            <Button variant='primary' onClick={handleDownloadCSV}>{literals.downloadCSV}</Button>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-md-4'>
            <div className='input-text'>
              {literals.common.search}
            </div>
            <div className='input-value'>
              <input type='text' className='form_input_stl' value={filters.search} onChange={handleSearchChange} />
            </div>
          </div>
          <div className='col-xs-12 col-md-4'>
            <div className='input-text'>
              {literals.common.startDate}
            </div>
            <div className='input-value'>
              <input type='date' className='form_input_stl' name={literals.startDate} value={filters.startDate} onChange={handleStartDateChange} />
            </div>
          </div>
          <div className='col-xs-12 col-md-4'>
            <div className='input-text'>
              {literals.common.endDate}
            </div>
            <div className='input-value'>
              <input type='date' className='form_input_stl' name={literals.endDate} value={filters.endDate} onChange={handleEndDateChange} />
            </div>
          </div>
        </div>
        <div className='mt-lg orchestrator_conversations_container'>
          {error && <div>{error}</div>}
          {!error && (
            <>
              <ConversationsTable conversations={conversations} filters={filters} setFilters={setFilters} handleViewConversation={handleViewConversation} conversationId={conversationId} />
              {conversationId && selectedChatbotId && (
                <Conversation chatbotId={selectedChatbotId} conversationId={conversationId} onClose={handleCloseChat} />
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default OrchestratorConversations;
