import React, { useState } from 'react';
import FeedbackModal from 'components/FeedbackModal';

const useErrorHandling = () => {
  const [titleError, setTitleError] = useState(null);
  const [error, setError] = useState(null);

  const clearError = () => {
    setError(null);
  };

  const showErrorModal = (title, errorMessage) => {
    if (!errorMessage) {
      if (title) {
        setTitleError('Error');
      } else {
        setTitleError(null);
      }
      setError(title);
    } else {
      setError(errorMessage);
      setTitleError(title);
    }
  };

  const printErrorModal = () => {
    if (!error) {
      return null;
    }

    return {
      modal: (
        <FeedbackModal
          open
          onClose={clearError}
          title={titleError}
          success={false}
          message={error}
        />
      ),
      clearError,
    };
  };

  return { showErrorModal, printErrorModal };
};

export default useErrorHandling;
