import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const DATATABLE_CATEGORY = 'Datatable';

export default {
  SEARCH_DATATABLE_ROWS: {
    categories: [DATATABLE_CATEGORY],
    tag: 'searchDatatableRows',
    name: 'Search datatable rows',
    tags: ['search', 'datatable', 'rows'],
    icon: 'fa-solid fa-table',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.DATATABLE,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'datatable',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
    ],
  },
  ADD_DATATABLE_ROW: {
    categories: [DATATABLE_CATEGORY],
    tag: 'addDatatableRow',
    name: 'Add datatable row',
    tags: ['add', 'datatable', 'row'],
    icon: 'fa-solid fa-table',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.DATATABLE,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'datatable',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
    ],
  },
  UPDATE_DATATABLE_ROW: {
    categories: [DATATABLE_CATEGORY],
    tag: 'updateDatatableRow',
    name: 'Update datatable row',
    tags: ['update datatable row', 'edit datatable row', 'row', 'modify datatable row', 'set datatable row', 'change datatable row'],
    icon: 'fa-solid fa-table',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.DATATABLE,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'datatable',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
    ],
  },
};
