import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from 'constants/orchestrator';
import useLiterals from 'hooks/useLiterals';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import { formatSize } from 'utils/utils';
import { uploadDocument } from 'modules/documents/actions';
import FeedbackModal from 'components/FeedbackModal';
import DatastructrureInstanceForm from 'routes/DatatableEntries/components/form';

function VariableRow({
  input, inputs, onChange, hasError,
}) {
  const [defaultValue, setDefaultValue] = useState(input.default_value);

  // Datastructures
  const [showDataStructureModal, setShowDataStructureModal] = useState(false);

  useEffect(() => {
    const typeSet = input.type_set || TYPE_SET_CONNECTIONS.SINGLE;
    if (input.type === TYPE_CONNECTIONS.OBJECT && typeof defaultValue === 'object' && defaultValue !== null && typeSet === TYPE_SET_CONNECTIONS.SINGLE) {
      setDefaultValue(JSON.stringify(defaultValue, null, 2));
    } else if (input.type === TYPE_CONNECTIONS.OBJECT && typeSet === TYPE_SET_CONNECTIONS.ARRAY) {
      let isChanged = false;
      const newArray = [];
      if (Array.isArray(defaultValue)) {
        defaultValue.forEach((value) => {
          if (typeof value === 'object' && value !== null) {
            isChanged = true;
            console.log('lo cambio porque es un object', value);
            newArray.push(JSON.stringify(value, null, 2));
          } else {
            newArray.push(value);
          }
        });
      }

      if (isChanged) {
        setDefaultValue(newArray);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (input.default_value !== defaultValue) {
      setDefaultValue(input.default_value);
    }
  }, [input]);

  // Document upload
  const [isUploading, setIsUploading] = useState(false);

  // Data for endpoint connections
  const [availableEndpoints, setAvailableEndpoints] = useState([]);

  const { chatbots } = useSelector((state) => state.chatbots);
  const { forms } = useSelector((state) => state.forms);
  const { apis } = useSelector((state) => state.apis);
  const { models } = useSelector((state) => state.models);
  const { orchestrators } = useSelector((state) => state.orchestrators);
  const { datastructures } = useSelector((state) => state.datastructures);
  const { datatables } = useSelector((state) => state.datatables);
  const { organizations } = useSelector((state) => state.organizations);

  const documentInputRef = useRef(null);
  const { showErrorModal, printErrorModal } = useErrorHandling();
  const literals = useLiterals('orchestrators');

  const handleOnChangeDefaultValue = (event) => {
    setDefaultValue(event.target.value);
  };

  const handleOnChangeDefaultCheckbox = (event) => {
    setDefaultValue(event.target.checked);
  };

  const handleOnChangeChatbot = (event) => {
    const chatbot = chatbots.find((c) => c.id === event.target.value);
    if (chatbot) {
      setDefaultValue({ id: chatbot.id, name: chatbot.name });
    } else {
      setDefaultValue(null);
    }
  };

  const handleOnChangeOrchestrator = (event) => {
    const orchestrator = orchestrators.find((o) => o.id === event.target.value);
    if (orchestrator) {
      setDefaultValue({ id: orchestrator.id, name: orchestrator.name });
    } else {
      setDefaultValue(null);
    }
  };

  const handleOnChangeLlmModel = (event) => {
    const model = models.find((m) => m.id === event.target.value);
    if (model) {
      setDefaultValue({ id: model.id, name: model.name });
    } else {
      setDefaultValue(null);
    }
  };

  const handleOnChangeForm = (event) => {
    const form = forms.find((c) => c.id === event.target.value);
    if (form) {
      setDefaultValue({ id: form.id, name: form.name });
    } else {
      setDefaultValue(null);
    }
  };

  const handleOnChangeApi = (event) => {
    const api = apis.find((a) => a.id === event.target.value);
    if (api) {
      setDefaultValue({ id: api.id, name: api.name });
    } else {
      setDefaultValue(null);
    }
  };

  const handleOnChangeEndpoint = (event) => {
    const endpoint = availableEndpoints.find((e) => e.id === event.target.value);
    if (endpoint) {
      setDefaultValue({ id: endpoint.id, method: endpoint.method, endpoint: endpoint.endpoint });
    } else {
      setDefaultValue(null);
    }
  };

  const handleOnChangeDatastructure = (event) => {
    const datastructure = datastructures.find((d) => d.id === event.target.value);
    if (datastructure) {
      setDefaultValue({ id: datastructure.id, name: datastructure.name });
    } else {
      setDefaultValue(null);
    }
  };

  const handleOnChangeDatatable = (event) => {
    const datatable = datatables.find((d) => d.id === event.target.value);
    if (datatable) {
      setDefaultValue({ id: datatable.id, name: datatable.name });
    } else {
      setDefaultValue(null);
    }
  };

  const handleOnChangeOrganization = (event) => {
    const organization = organizations.find((d) => d.id === event.target.value);
    if (organization) {
      setDefaultValue({ id: organization.id, name: organization.name });
    } else {
      setDefaultValue(null);
    }
  };

  const handleClickUploadDocument = () => {
    documentInputRef.current.click();
  };

  const handleOnChangeDocument = async (e) => {
    if (!isUploading) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      try {
        setIsUploading(true);
        const response = await uploadDocument(formData);
        setDefaultValue(response);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        showErrorModal(errorMessage);
      } finally {
        setIsUploading(false);
      }
    }
  };

  useEffect(() => {
    if (defaultValue !== input.default_value) {
      onChange({ ...input, default_value: defaultValue });
    }
  }, [defaultValue]);

  useEffect(() => {
    switch (input.type) {
      case TYPE_CONNECTIONS.ENDPOINT: {
        const apiId = inputs.find((i) => i.type === TYPE_CONNECTIONS.API)?.default_value?.id;
        if (apiId) {
          const api = apis.find((a) => a.id === apiId);
          if (api) {
            setAvailableEndpoints(api.endpoints);
            break;
          }
        }
        setAvailableEndpoints([]);
      }
        break;
      default:
        break;
    }
  }, [inputs]);

  const printArrayInput = () => {
    const arrayValues = defaultValue || [];
    const arrayInputs = arrayValues.map((value, index) => {
      let iterationValue = value;
      if (input.type === TYPE_CONNECTIONS.OBJECT && typeof value === 'object') {
        iterationValue = JSON.stringify(iterationValue, null, 2);
      }
      const arrayInput = {
        ...input, name: '', default_value: iterationValue, type_set: TYPE_SET_CONNECTIONS.SINGLE,
      };
      return (
        <div key={index} className='flex gap-1'>
          <div className='flex-grow'>
            <VariableRow
              key={index}
              input={arrayInput}
              inputs={inputs}
              onChange={(change) => {
                const newValues = [...arrayValues];
                newValues[index] = change.default_value;
                setDefaultValue(newValues);
              }}
            />
          </div>
          <div>
            <button
              type='button'
              className='btn btn-sm btn-danger'
              onClick={() => {
                const newValues = [...arrayValues];
                newValues.splice(index, 1);
                setDefaultValue(newValues);
              }}
            >
              <i className='fa-solid fa-trash mr-0' />
            </button>
          </div>
        </div>
      );
    });

    return (
      <>
        {arrayInputs}
        <button
          type='button'
          className='btn btn-sm btn-secondary'
          onClick={() => {
            setDefaultValue([...arrayValues, '']);
          }}
        >
          <i className='fa-solid fa-plus mr-0' />
        </button>
      </>
    );
  };

  const printVariableFieldInput = () => {
    const typeSet = input.type_set || TYPE_SET_CONNECTIONS.SINGLE;
    if (typeSet === TYPE_SET_CONNECTIONS.ARRAY) {
      return printArrayInput();
    }

    if (input.type.indexOf('DATASTRUCTURE') !== -1) {
      return (
        <button
          type='button'
          className='btn btn-sm btn-secondary'
          onClick={() => {
            setShowDataStructureModal(true);
          }}
        >
          {literals.defineDatastructure}
        </button>
      );
    }

    const errorClass = hasError ? 'input_error' : '';
    switch (input.type) {
      case TYPE_CONNECTIONS.BOOLEAN:
        return (
          <input type='checkbox' checked={defaultValue} onChange={handleOnChangeDefaultCheckbox} />
        );
      case TYPE_CONNECTIONS.ANY:
        return (
          <input type='text' value={defaultValue || ''} className={`form_input_stl ${errorClass}`} onChange={handleOnChangeDefaultValue} />
        );
      case TYPE_CONNECTIONS.STRING:
        if (typeof input.options !== 'undefined' && input.options.length > 0) {
          return (
            <select value={defaultValue || ''} className={`form_select_stl ${errorClass}`} onChange={handleOnChangeDefaultValue}>
              <option value=''>{literals.selectAnOption}</option>
              {input.options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          );
        }
        return (
          <textarea value={defaultValue || ''} className={`form_input_stl ${errorClass}`} onChange={handleOnChangeDefaultValue} />
        );
      case TYPE_CONNECTIONS.OBJECT:
        return (
          <textarea value={defaultValue || ''} className={`form_input_stl ${errorClass}`} onChange={handleOnChangeDefaultValue} placeholder={literals.writeJsonData} />
        );
      case TYPE_CONNECTIONS.NUMBER:
        return (
          <input type='number' value={defaultValue || ''} className={`form_input_stl ${errorClass}`} onChange={handleOnChangeDefaultValue} />
        );
      case TYPE_CONNECTIONS.LLM_MODEL:
        return (
          <select value={defaultValue?.id || ''} className={`form_select_stl ${errorClass}`} onChange={handleOnChangeLlmModel}>
            <option value=''>{literals.selectAModel}</option>
            {models.map((model) => (
              <option key={model.id} value={model.id}>
                {model.name}
              </option>
            ))}
          </select>
        );
      case TYPE_CONNECTIONS.ORCHESTRATOR: {
        const filteredOrchestrators = orchestrators.filter((orchestrator) => typeof input.orchestrator_type === 'undefined' || !input.orchestrator_type || orchestrator.type === input.orchestrator_type);
        return (
          <select value={defaultValue?.id || ''} className={`form_select_stl ${errorClass}`} onChange={handleOnChangeOrchestrator}>
            <option value=''>{literals.selectAnOrchestrator}</option>
            {filteredOrchestrators.map((orchestrator) => (
              <option key={orchestrator.id} value={orchestrator.id}>
                {orchestrator.name}
              </option>
            ))}
          </select>
        );
      }
      case TYPE_CONNECTIONS.CHATBOT:
        return (
          <select value={defaultValue?.id || ''} className={`form_select_stl ${errorClass}`} onChange={handleOnChangeChatbot}>
            <option value=''>{literals.selectAChatbot}</option>
            {chatbots.map((chatbot) => (
              <option key={chatbot.id} value={chatbot.id}>
                {chatbot.name}
              </option>
            ))}
          </select>
        );
      case TYPE_CONNECTIONS.FORM:
        return (
          <select value={defaultValue?.id || ''} className={`form_select_stl ${errorClass}`} onChange={handleOnChangeForm}>
            <option value=''>{literals.selectAForm}</option>
            {forms.map((form) => (
              <option key={form.id} value={form.id}>
                {form.name}
              </option>
            ))}
          </select>
        );
      case TYPE_CONNECTIONS.API:
        return (
          <select value={defaultValue?.id || ''} className={`form_select_stl ${errorClass}`} onChange={handleOnChangeApi}>
            <option value=''>{literals.selectAnApi}</option>
            {apis.map((api) => (
              <option key={api.id} value={api.id}>
                {api.name}
              </option>
            ))}
          </select>
        );
      case TYPE_CONNECTIONS.ENDPOINT:
        return (
          <select value={defaultValue?.id || ''} className={`form_select_stl ${errorClass}`} onChange={handleOnChangeEndpoint}>
            <option value=''>{literals.selectAnEndpoint}</option>
            {availableEndpoints.map((endpoint) => (
              <option key={endpoint.id} value={endpoint.id}>
                {`${endpoint.method.toUpperCase()} ${endpoint.endpoint}`}
              </option>
            ))}
          </select>
        );
      case TYPE_CONNECTIONS.DATASTRUCTURE:
        return (
          <select value={defaultValue?.id || ''} className={`form_select_stl ${errorClass}`} onChange={handleOnChangeDatastructure}>
            <option value=''>{literals.selectADatastructure}</option>
            {datastructures.map((datastructure) => (
              <option key={datastructure.id} value={datastructure.id}>
                {datastructure.name}
              </option>
            ))}
          </select>
        );
      case TYPE_CONNECTIONS.DATATABLE:
        return (
          <select value={defaultValue?.id || ''} className={`form_select_stl ${errorClass}`} onChange={handleOnChangeDatatable}>
            <option value=''>{literals.selectADatatable}</option>
            {datatables.map((datatable) => (
              <option key={datatable.id} value={datatable.id}>
                {datatable.name}
              </option>
            ))}
          </select>
        );
      case TYPE_CONNECTIONS.DOCUMENT:
        return (
          <>
            <input type='file' onChange={handleOnChangeDocument} style={{ display: 'none' }} ref={documentInputRef} />
            {defaultValue ? (`${defaultValue.name} (${formatSize(defaultValue.size)})`) : null}
            <button className='btn btn-sm btn-secondary' type='button' onClick={handleClickUploadDocument}>
              {isUploading ? (<i className='fa-solid fa-spinner fa-spin' />) : null}
              {defaultValue ? literals.change : literals.uploadDocument}
            </button>
          </>
        );
      case TYPE_CONNECTIONS.ORGANIZATION:
        return (
          <select value={defaultValue?.id || ''} className={`form_select_stl ${errorClass}`} onChange={handleOnChangeOrganization}>
            <option value=''>{literals.selectAnOrganization}</option>
            {organizations.map((organization) => (
              <option key={organization.id} value={organization.id}>
                {organization.name}
              </option>
            ))}
          </select>
        );
      default:
        return null;
    }
  };

  const printDatastructureModal = () => {
    if (!showDataStructureModal) {
      return null;
    }

    const datastructureId = input.type.replace('DATASTRUCTURE-', '');
    const datastructure = datastructures.find((d) => d.id === datastructureId);
    console.log('datastructure', datastructure);
    if (!datastructure) {
      return (
        <FeedbackModal
          open
          onClose={() => setShowDataStructureModal(false)}
          title={literals.datastructureNotFound}
          success={false}
          message={literals.datastructureNotFoundMessage}
        />
      );
    }

    const row = defaultValue ? {
      id: null,
      values: defaultValue,
    } : null;
    return (
      <DatastructrureInstanceForm
        row={row}
        datastructure={datastructure}
        onClose={() => {
          setShowDataStructureModal(false);
        }}
        onSave={({ values }) => {
          setDefaultValue(values);
          setShowDataStructureModal(false);
        }}
      />
    );
  };

  return (
    <>
      {printErrorModal()?.modal}
      {printDatastructureModal()}
      <div key={input.name}>
        <div className='input_title'>
          {input.name}
        </div>
        <div className='input_value'>
          {printVariableFieldInput()}
        </div>
      </div>
    </>
  );
}

VariableRow.propTypes = {
  input: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  inputs: PropTypes.array.isRequired,
  hasError: PropTypes.bool,
};

VariableRow.defaultProps = {
  hasError: false,
};

export default VariableRow;
