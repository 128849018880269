import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useLiterals from 'hooks/useLiterals';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { loadingActions } from 'modules/loading';
import Dropdown from 'react-bootstrap/Dropdown';
import ConfirmModal from 'components/ConfirmModal';
import DropdownMenuToggle from 'components/DropdownMenuToggle';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import {
  getChatbotTexts, createText, updateText, deleteText,
} from 'modules/texts/actions';

function Texts({ chatbotId }) {
  const [text, setText] = useState('');
  const [selectedText, setSelectedText] = useState([]);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [allTexts, setAllTexts] = useState([]);
  const [textsBeingDeleted, setTextsBeingDeleted] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [textToBeDeleted, setTextToBeDeleted] = useState(null);

  const dispatch = useDispatch();
  const literals = useLiterals('texts');

  const { showErrorModal, printErrorModal } = useErrorHandling();

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    handleGetChatbotTexts();
  }, []);

  const handleChangeText = (e) => {
    setText(e.target.value);
  };

  const handleGetChatbotTexts = async () => {
    try {
      dispatch(loadingActions.show());
      const response = await getChatbotTexts(chatbotId);
      setAllTexts(response.texts);
      if (response?.texts?.length) {
        setText(response.texts[0]?.text);
      }
      dispatch(loadingActions.hide());
    } catch (err) {
      console.error(err);
      dispatch(loadingActions.hide());
    }
  };

  const handleSaveText = async () => {
    try {
      setIsSaving(true);
      dispatch(loadingActions.show());
      if (selectedText) {
        await updateText(chatbotId, selectedText, text);
      } else {
        await createText(chatbotId, text);
      }
      setShowCreationModal(false);
      handleGetChatbotTexts();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    } finally {
      setIsSaving(false);
      dispatch(loadingActions.hide());
    }
  };

  const handleCreateText = () => {
    setSelectedText(null);
    setText('');
    setShowCreationModal(true);
  };

  const handleEditText = (e) => {
    const textId = e.target.getAttribute('data-id');
    const foundText = allTexts.find((t) => t.id === textId);
    if (foundText) {
      setSelectedText(foundText.id);
      setText(foundText.text);
      setShowCreationModal(true);
    }
  };

  const closeCreationModal = () => {
    setShowCreationModal(false);
  };

  const handleDeleteText = (e) => {
    const textId = e.target.getAttribute('data-id');
    setTextToBeDeleted(textId);
  };

  const cancelDeleteText = () => {
    setTextToBeDeleted(null);
  };

  const submitDeleteText = async () => {
    try {
      setTextsBeingDeleted([...textsBeingDeleted, textToBeDeleted]);
      await deleteText(chatbotId, textToBeDeleted);
      setAllTexts(allTexts.filter((t) => t.id !== textToBeDeleted));
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    } finally {
      setTextsBeingDeleted(textsBeingDeleted.filter((id) => id !== textToBeDeleted));
      setTextToBeDeleted(null);
    }
  };

  const printTable = () => {
    const rows = allTexts.map((actualText) => {
      const sampleText = actualText.text.length > 50 ? `${actualText.text.substring(0, 50)}...` : actualText.text;
      return (
        <tr key={actualText.id} data-id={actualText.id} className={`${textsBeingDeleted.includes(actualText.id) ? 'disabled_row' : ''}`}>
          <td>{sampleText}</td>
          <td className='right'>
            <button type='button' className='btn btn-primary btn-sm' onClick={handleEditText} data-id={actualText.id}>{literals.common.edit}</button>
            <Dropdown className='dropdown-menu-expand'>
              <Dropdown.Toggle as={DropdownMenuToggle} id={`dropdown-custom-components-${actualText.id}`} />
              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey='1'
                  className='text-danger'
                  data-id={actualText.id}
                  onClick={handleDeleteText}
                >
                  {literals.common.delete}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
    return (
      <div>
        <div className='text-align-right subsection_buttons'>
          <button type='button' className='btn btn-primary btn-sm' onClick={handleCreateText}>
            {literals.createText}
          </button>
        </div>
        <table className='table_stl1'>
          <thead>
            <tr>
              <th>{literals.common.name}</th>
              <th className='right'>{literals.common.options}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const printNoElements = () => {
    return (
      <div className='no-elements-container'>
        <p>{literals.thereIsNoTexts}</p>
        <button type='button' className='btn btn-primary' onClick={handleCreateText}>
          {literals.createText}
        </button>
      </div>
    );
  };

  const printCreationModal = () => {
    if (!showCreationModal) {
      return null;
    }

    return (
      <Modal show size='lg' onHide={closeCreationModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedText ? literals.editText : literals.createText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className='input_title'>
              {literals.title}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <textarea type='text' className='form_input_stl' value={text} onChange={handleChangeText} style={{ height: '60vh' }} />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeCreationModal}>
            {literals.common.cancel}
          </Button>
          <Button variant='primary' disabled={!text} onClick={handleSaveText}>
            {isSaving && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
            {literals.common.accept}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const printDeleteModal = () => {
    if (!textToBeDeleted) {
      return null;
    }

    return (
      <ConfirmModal
        open
        title={literals.confirmDeleteText}
        onClose={cancelDeleteText}
        onSubmit={submitDeleteText}
      />
    );
  };

  return (
    <>
      {printErrorModal()?.modal}
      <div className='p-md texts__container'>
        {printCreationModal()}
        {printDeleteModal()}
        {allTexts.length ? printTable() : printNoElements()}
      </div>
    </>
  );
}

Texts.propTypes = {
  chatbotId: PropTypes.string.isRequired,
};

export default Texts;
