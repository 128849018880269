import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import useLiterals from 'hooks/useLiterals';

function CreateQuestionForm(props) {
  const {
    title,
    answer,
    onChangeTitle,
    onChangeAnswer,
    allOrganizations,
    selectedOrganizations,
    onChangeOrganizations,
  } = props;

  const literals = useLiterals('tests');

  const handleOnChangeTitle = (event) => {
    onChangeTitle(event.target.value);
  };

  const handleOnChangeAnswer = (event) => {
    onChangeAnswer(event.target.value);
  };

  const handleSelectOrganization = (selectedValues) => {
    onChangeOrganizations(selectedValues);
  };

  return (
    <form>
      <div className='input_title'>
        {literals.title}
      </div>
      <div className='input_value'>
        <input type='text' className='form_input_stl' onChange={handleOnChangeTitle} value={title} />
      </div>
      <div className='input_title'>
        {literals.answer}
      </div>
      <div className='input_value'>
        <textarea type='text' className='form_input_stl' onChange={handleOnChangeAnswer} value={answer} />
      </div>
      <div className='panel-header'>{literals.organizations}</div>
      <div className='panel-body'>
        <div className='input_title'>
          <Select
            isMulti
            options={allOrganizations.map((organization) => ({ value: organization.id, label: organization.name }))}
            onChange={handleSelectOrganization}
            value={selectedOrganizations}
          />
        </div>
      </div>
    </form>
  );
}

CreateQuestionForm.propTypes = {
  title: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  allOrganizations: PropTypes.array.isRequired,
  selectedOrganizations: PropTypes.array.isRequired,
  onChangeOrganizations: PropTypes.func.isRequired,
  onChangeTitle: PropTypes.func.isRequired,
  onChangeAnswer: PropTypes.func.isRequired,
};

export default CreateQuestionForm;
