import { getOrganizationLogo } from 'modules/organizations/actions';
import { SET_ACTUAL_ORGANIZATION } from './index';
import { saveExtraField } from '../session/actions';

export const changeActualOrganization = (organization, saveDefault = false) => async (dispatch) => {
  dispatch({ type: SET_ACTUAL_ORGANIZATION, payload: organization });

  if (organization && organization.id !== 'personal') {
    document.title = organization.name;
    document.documentElement.style.setProperty('--primary', organization.primary_color || '#68c3c6');
    document.documentElement.style.setProperty('--secondary', organization.secondary_color || '#f4aadf');
  } else {
    document.title = 'LLMs';
    document.documentElement.style.setProperty('--primary', '#68c3c6');
    document.documentElement.style.setProperty('--secondary', '#f4aadf');
  }

  if (saveDefault) {
    dispatch(saveExtraField('default_organization', organization ? organization.id : ''));
  }

  // Load logo
  if (organization && organization.logo) {
    const logoContent = await getOrganizationLogo(organization.id);
    dispatch({ type: SET_ACTUAL_ORGANIZATION, payload: { ...organization, logoContent: URL.createObjectURL(logoContent) } });
  }
};
