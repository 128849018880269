import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import useLiterals from 'hooks/useLiterals';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import { getMyOrganizations } from 'modules/organizations/actions';
import { createForm, updateForm } from 'modules/forms/actions';

const MAX_LENGTH_DESCRIPTION = 1000;

function Data(props) {
  const { form, onBack } = props;

  const [name, setName] = useState(form?.name || '');
  const [description, setDescription] = useState(form?.description || '');
  const [initMessage, setInitMessage] = useState(form?.init_message || '');
  const [fillMessage, setFillMessage] = useState(form?.fill_message || '');
  const [formOrganizations, setFormOrganizations] = useState(form?.organizations || []);
  const [isSaving, setIsSaving] = useState(false);

  const { showErrorModal, printErrorModal } = useErrorHandling();
  const literals = useLiterals('forms');
  const { organizations } = useSelector((state) => state.organizations);
  const { actualOrganization } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyOrganizations());
    const formId = form?.id;
    if (!formId && actualOrganization) {
      setFormOrganizations(actualOrganization.id === 'personal' ? [] : [actualOrganization.id]);
    }
  }, []);

  const handleOnChangeName = (event) => {
    setName(event.target.value);
  };

  const handleOnChangeDescription = (event) => {
    if (event.target.value.length <= MAX_LENGTH_DESCRIPTION) {
      setDescription(event.target.value);
    }
  };

  const handleOnChangeInitMessage = (event) => {
    setInitMessage(event.target.value);
  };

  const handleOnChangeFillMessage = (event) => {
    setFillMessage(event.target.value);
  };

  const handleSelectOrganization = (selectedValues) => {
    setFormOrganizations(selectedValues.map((selectedValue) => (selectedValue.value)));
  };

  const canContinue = () => {
    return name && description && name.length <= 120;
  };

  const saveForm = async () => {
    setIsSaving(true);
    try {
      if (form && form.id) {
        await updateForm(form.id, {
          name, description, organizations: formOrganizations, data: form.data, init_message: initMessage, fill_message: fillMessage,
        });
      } else {
        await createForm({
          name, description, organizations: formOrganizations, init_message: initMessage, fill_message: fillMessage,
        });
      }
      onBack();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  const organizationsValues = [];
  formOrganizations.forEach((organizationId) => {
    const organization = organizations.find((org) => org.id === organizationId);
    if (organization) {
      organizationsValues.push({ value: organization.id, label: organization.name });
    }
  });

  return (
    <>
      {printErrorModal()?.modal}
      <Form>
        <div className='panel'>
          <div className='panel-header'>{literals.basicInfo}</div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.common.name}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeName} value={name} />
            </div>
            <div className='input_title'>
              {literals.common.description}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <textarea className='form_input_stl' onChange={handleOnChangeDescription} value={description} />
              <div className='max_length_textarea'>
                {`${description.length}/${MAX_LENGTH_DESCRIPTION}`}
              </div>
            </div>
          </div>
        </div>
        <div className='panel'>
          <div className='panel-header'>{literals.messages}</div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.initMessage}
            </div>
            <div className='input_value'>
              <textarea className='form_input_stl' onChange={handleOnChangeInitMessage} value={initMessage} />
            </div>
            <div className='input_title'>
              {literals.fillMessage}
            </div>
            <div className='input_value'>
              <textarea className='form_input_stl' onChange={handleOnChangeFillMessage} value={fillMessage} />
            </div>
          </div>
        </div>
        <div className='panel'>
          <div className='panel-header'>{literals.sharedWith}</div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.selectOrganization}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <Select
                isMulti
                options={organizations.map((organization) => ({ value: organization.id, label: organization.name }))}
                onChange={handleSelectOrganization}
                value={organizationsValues}
              />
            </div>
          </div>
        </div>
        <div className='form_buttons'>
          <button type='button' className='btn btn-secondary' onClick={onBack}>
            {literals.common.cancel}
          </button>
          <button type='button' className='btn btn-primary' disabled={!canContinue()} onClick={saveForm}>
            {isSaving && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
            {literals.common.save}
          </button>
        </div>
      </Form>
    </>
  );
}

Data.propTypes = {
  onBack: PropTypes.func.isRequired,
  form: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    init_message: PropTypes.string,
    fill_message: PropTypes.string,
    organizations: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.object,
  }),
};

Data.defaultProps = {
  form: null,
};

export default Data;
