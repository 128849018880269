import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  getFaqs, createFaq, deleteFaq, importFaqs, updateFaq,
} from 'modules/faqs/actions';
import useLiterals from 'hooks/useLiterals';
import { getErrorMessage } from 'utils/errors';
import useErrorHandling from 'hooks/useErrorHandling';
import ConfirmModal from 'components/ConfirmModal';

function Faq() {
  const [showCreateFaq, setShowCreateFaq] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [similarQuestions, setSimilarQuestions] = useState(['']);
  const [selectedFaqItem, setSelectedFaqItem] = useState(null);
  const [title, setTitle] = useState('');
  const [answer, setAnswer] = useState('');
  const [showDeleteFaqModal, setShowDeleteFaqModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const literals = useLiterals('faq');
  const { id } = useParams();
  const fileInputRef = useRef(null);

  const { showErrorModal, printErrorModal } = useErrorHandling();

  const handleNewSimilarQuestion = () => {
    setSimilarQuestions([...similarQuestions, '']);
  };
  const loadFaqs = async () => {
    const response = await getFaqs(id);
    if (response?.faqs?.length) {
      setSelectedFaqItem(response.faqs[0]);
    }
    setFaqs(response.faqs);
  };

  useEffect(async () => {
    await loadFaqs();
  }, []);

  const handleEditFaq = () => {
    if (selectedFaqItem) {
      setTitle(selectedFaqItem.title);
      setAnswer(selectedFaqItem.answer);
      setSimilarQuestions(selectedFaqItem.similar_questions || ['']);
      setShowCreateFaq(true);
    }
  };

  const handleSaveFaq = async () => {
    const similarQuestionsFiltered = similarQuestions.filter((question) => question !== '');
    let res = null;
    try {
      if (selectedFaqItem) {
        res = await updateFaq(id, selectedFaqItem.id, {
          title,
          answer,
          similarQuestions: similarQuestionsFiltered,
        });

        setFaqs(faqs.map((faq) => (faq.id === res.id ? res : faq)).sort((a, b) => (a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1)));
      } else {
        res = await createFaq(id, {
          title,
          answer,
          similarQuestions: similarQuestionsFiltered,
        });
        setFaqs([...faqs, res].sort((a, b) => (a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1)));
      }
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    }
    setSelectedFaqItem(null);
    setShowCreateFaq(false);
    setAnswer('');
    setTitle('');
    setSimilarQuestions(['']);
  };

  const submitDeleteFaq = () => {
    if (selectedFaqItem) {
      deleteFaq(id, selectedFaqItem.id).then(() => {
        setFaqs(faqs.filter((faq) => faq.id !== selectedFaqItem.id));
        setSelectedFaqItem(null);
      });
    }
  };

  const handleDeleteFaq = () => {
    setShowDeleteFaqModal(true);
  };

  const handleHideDeleteFaqModal = () => {
    setShowDeleteFaqModal(false);
  };

  const handleClickshowCreateFaq = () => {
    setSelectedFaqItem(null);
    setTitle('');
    setAnswer('');
    setSimilarQuestions(['']);
    setShowCreateFaq(true);
  };

  const handleHideCreateFaq = () => {
    setSelectedFaqItem(null);
    setTitle('');
    setAnswer('');
    setSimilarQuestions(['']);
    setShowCreateFaq(false);
  };

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeSimilarQuestion = (e, index) => {
    const newSimilarQuestions = [...similarQuestions];
    newSimilarQuestions[index] = e.target.value;
    setSimilarQuestions(newSimilarQuestions);
  };

  const renderFaqList = () => {
    return (
      <div className='faq-list'>
        {faqs.map((faqItem) => (
          <div
            key={faqItem.id}
            onClick={() => setSelectedFaqItem(faqItem)}
            className={`faq-item ${selectedFaqItem?.id === faqItem.id ? 'selected' : ''}`}
          >
            <h1>{faqItem.title}</h1>
          </div>
        ))}
      </div>
    );
  };
  const renderFaq = () => {
    return (
      <div className='faq'>
        {selectedFaqItem?.similar_questions?.length > 0 && (
          <>
            <p><strong>{literals.otherWaysOfExpressingTheQuestion}</strong></p>
            <ul>
              {selectedFaqItem?.similar_questions?.map((question) => (
                <li key={`faq-${question.id}`}>{question}</li>
              ))}
            </ul>
          </>
        )}
        <div dangerouslySetInnerHTML={{ __html: selectedFaqItem?.answer }} />
        <div onClick={handleDeleteFaq} className='delete-faq btn btn-secondary'>{literals.deleteFaq}</div>
        <div onClick={handleEditFaq} className='edit-faq btn btn-primary'>{literals.common.edit}</div>
      </div>
    );
  };

  const renderSimilarQuestions = () => {
    return (
      <div className='similar-questions'>
        {similarQuestions.map((question, index) => (
          <div className='similar-question' key={`similar-question-${question.id}-${index}`}>
            <textarea key={index} onChange={(e) => handleChangeSimilarQuestion(e, index)} value={question} className='question-input' />
          </div>
        ))}
        <div className='btn btn-primary' onClick={handleNewSimilarQuestion}>+</div>
      </div>
    );
  };

  const handleImportFileChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    try {
      setIsUploading(true);
      await importFaqs(id, formData);
      loadFaqs();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    } finally {
      setIsUploading(false);
    }
  };

  const handleImportFaqs = () => {
    if (!isUploading) {
      fileInputRef.current.click();
    }
  };

  const handleExportFaqs = () => {
    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(faqs)], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'faqs.json';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const rendershowCreateFaq = () => {
    return (
      <div className='create-faq-wrapper'>
        <p>{literals.question}</p>
        <textarea onChange={handleChangeTitle} value={title} className='question-input' />
        <p>{literals.otherWaysOfExpressingTheQuestion}</p>
        {renderSimilarQuestions()}
        <p>{literals.answer}</p>
        <ReactQuill
          theme='snow'
          value={answer}
          onChange={setAnswer}
          modules={{
            toolbar: [
              [{ header: '1' }, { header: '2' }, { font: [] }],
              [{ size: [] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [
                { list: 'ordered' }, { list: 'bullet' },
                { indent: '-1' }, { indent: '+1' },
              ],
              ['link', 'image', 'video'],
              ['clean'],
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
          }}
          formats={[
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video',
          ]}
        />
        {/* <textarea onChange={handleChangeAnswer} value={answer} className='answer-input' /> */}
        <div className='create-faq-actions'>
          <div onClick={handleHideCreateFaq} className='btn btn-secondary'>{literals.cancel}</div>
          <div onClick={handleSaveFaq} className='btn btn-primary'>{selectedFaqItem ? literals.common.save : literals.createFaq}</div>
        </div>
      </div>
    );
  };

  const printDeleteFaqModal = () => {
    if (!showDeleteFaqModal || !selectedFaqItem) {
      return null;
    }

    return (
      <ConfirmModal
        open
        onClose={handleHideDeleteFaqModal}
        onSubmit={submitDeleteFaq}
        title={literals.confirmDeleteFaqTitle}
        message={literals.confirmDeleteFaqDescription}
      />
    );
  };

  if (!faqs.length && !showCreateFaq) {
    return (
      <div className='faq-wrapper'>
        <div className='no-elements-container'>
          <p>{literals.noFaq}</p>
          <input type='file' ref={fileInputRef} onChange={handleImportFileChange} style={{ display: 'none' }} accept='application/json' />
          <button type='button' onClick={handleImportFaqs} className='btn btn-secondary' disabled={isUploading}>
            {isUploading && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
            {literals.common.import}
          </button>
          <button type='button' onClick={handleClickshowCreateFaq} className='btn btn-primary'>{literals.createFaq}</button>
        </div>
      </div>
    );
  }

  let content = null;
  if (showCreateFaq) {
    content = rendershowCreateFaq();
  } else if (selectedFaqItem) {
    content = (
      <>
        {renderFaqList()}
        {renderFaq()}
      </>
    );
  } else {
    content = renderFaqList();
  }

  return (
    <>
      {printErrorModal()?.modal}
      {printDeleteFaqModal()}
      <div className='faq-wrapper'>
        {!showCreateFaq && (
          <div className='section_header mt-md'>
            <div />
            <div>
              <input type='file' ref={fileInputRef} onChange={handleImportFileChange} style={{ display: 'none' }} accept='application/json' />
              <button type='button' onClick={handleImportFaqs} className='btn btn-secondary' disabled={isUploading}>
                {isUploading && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
                {literals.common.import}
              </button>
              <button type='button' onClick={handleExportFaqs} className='btn btn-secondary'>{literals.common.export}</button>
              <button type='button' onClick={handleClickshowCreateFaq} className='btn btn-primary'>{literals.createFaq}</button>
            </div>
          </div>
        )}
        <div className='faq-container'>
          {content}
        </div>
      </div>
    </>
  );
}

export default Faq;
