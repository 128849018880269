import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import './styles.scss';

function DatePicker({
  name,
  value,
  onChange,
  type,
}) {
  return (
    <div className='orchestrator-stats-date-input'>
      <label htmlFor='datePicker'>{name}</label>
      <input
        type={type}
        id='datePicker'
        name='datePicker'
        value={moment(value).format(type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm')}
        onChange={onChange}
      />
    </div>
  );
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

DatePicker.defaultProps = {
  type: 'date',
};

export default DatePicker;
