export const SET_MESSAGES = 'chats/set-messages';
export const ADD_MESSAGE = 'chats/add-message';
export const ADD_MESSAGE_CONTENT = 'chats/add-message-content';
export const DELETE_MESSAGE = 'chats/delete-message';

export const initialState = {
  chats: {},
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_MESSAGES: {
      const { chats } = state;
      const newChats = structuredClone(chats);

      if (!payload.conversation_id) {
        return state;
      }

      if (typeof newChats[payload.conversation_id] === 'undefined') {
        newChats[payload.conversation_id] = payload;
      }

      newChats[payload.conversation_id].messages = payload.messages;
      return {
        ...state,
        chats: newChats,
      };
    }
    case ADD_MESSAGE: {
      const { chats } = state;
      const newChats = structuredClone(chats);
      const conversationId = payload.conversation_id;
      if (typeof newChats[conversationId] === 'undefined') {
        return state;
      }

      const messageIndex = newChats[conversationId].messages.findIndex((message) => message.id === payload.id);
      if (messageIndex !== -1) {
        newChats[conversationId].messages[messageIndex] = {
          ...payload,
          content: newChats[conversationId].messages[messageIndex].content,
        };
      } else {
        newChats[conversationId].messages.push(payload);
      }

      return {
        ...state,
        chats: newChats,
      };
    }
    case ADD_MESSAGE_CONTENT: {
      const { chats } = state;
      const newChats = structuredClone(chats);
      const conversationId = payload.conversation_id;
      if (typeof newChats[conversationId] === 'undefined') {
        return state;
      }

      const messageIndex = newChats[conversationId].messages.findIndex((message) => message.id === payload.id);
      if (messageIndex !== -1) {
        newChats[conversationId].messages[messageIndex].steps = payload.steps;
        newChats[conversationId].messages[messageIndex].attachments = payload.attachments;
        newChats[conversationId].messages[messageIndex].content += payload.content;
      } else {
        const newPayload = {
          ...payload,
          content: payload.all_content || payload.content,
        };
        newChats[conversationId].messages.push(newPayload);
      }

      return {
        ...state,
        chats: newChats,
      };
    }
    case DELETE_MESSAGE: {
      const { chats } = state;
      const newChats = structuredClone(chats);
      const conversationId = payload.conversationId;
      if (typeof newChats[conversationId] === 'undefined' || typeof newChats[conversationId].messages === 'undefined') {
        return state;
      }

      const messageIndex = newChats[conversationId].messages.findIndex((message) => message.id === payload.messageId);
      if (messageIndex !== -1) {
        newChats[conversationId].messages.splice(messageIndex, 1);
      }
      return {
        ...state,
        chats: newChats,
      };
    }
    default:
      return state;
  }
};

export default reducer;
