import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

const URLS = {
  getChatbotDocuments: (chatbotId) => `chatbots/${chatbotId}/documents`,
  uploadDocumentToChatbot: (chatbotId) => `chatbots/${chatbotId}/documents`,
  uploadDocumentToConversation: (chatbotId, conversationId) => `chatbots/${chatbotId}/conversations/${conversationId}/documents`,
  deleteDocumentFromChatbot: (chatbotId, documentId) => `chatbots/${chatbotId}/documents/${documentId}`,
  reprocessDocument: (chatbotId, documentId) => `chatbots/${chatbotId}/documents/${documentId}/reprocess`,
  downloadDocument: (documentId) => `documents/${documentId}/download`,
  uploadDocument: 'documents',
};

export default {
  async getChatbotDocuments(chatbotId) {
    return ApiFetchInstance.get(URLS.getChatbotDocuments(chatbotId));
  },
  async uploadDocumentToChatbot(chatbotId, formData) {
    return ApiFetchInstance.post(URLS.uploadDocumentToChatbot(chatbotId), formData);
  },
  async uploadDocumentToConversation(chatbotId, conversationId, formData) {
    return ApiFetchInstance.post(URLS.uploadDocumentToConversation(chatbotId, conversationId), formData);
  },
  async deleteDocumentFromChatbot(chatbotId, documentId) {
    return ApiFetchInstance.delete(URLS.deleteDocumentFromChatbot(chatbotId, documentId));
  },
  async reprocessDocument(chatbotId, documentId) {
    return ApiFetchInstance.post(URLS.reprocessDocument(chatbotId, documentId));
  },
  async downloadDocument(documentId) {
    return ApiFetchInstance.get(URLS.downloadDocument(documentId), {
      responseType: 'blob', // Añadir responseType: "blob" aquí
    });
  },
  async uploadDocument(formData) {
    return ApiFetchInstance.post(URLS.uploadDocument, formData);
  },
};
