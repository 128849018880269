import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from 'components/Layout';
import useLiterals from 'hooks/useLiterals';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import DropdownMenuToggle from 'components/DropdownMenuToggle';
import ConfirmModal from 'components/ConfirmModal';
import { deleteDatatable } from 'modules/datatables/actions';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import DatatableForm from './components/form';

function Datatables() {
  const [showForm, setShowForm] = useState(false);
  const [selectedDatatable, setSelectedDatatable] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const literals = useLiterals('datatables');
  const { datatables } = useSelector((state) => state.datatables);
  const { actualOrganization } = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showErrorModal, printErrorModal } = useErrorHandling();

  const getFilteredDatatables = () => {
    if (!datatables) {
      return [];
    }

    if (!actualOrganization) {
      return datatables;
    }

    if (actualOrganization.id === 'personal') {
      return datatables.filter((datatable) => !datatable.organizations || !datatable.organizations.length);
    }

    return datatables.filter((datatable) => datatable.organizations && datatable.organizations.includes(actualOrganization.id));
  };

  const handleCreateDataStructure = () => {
    setSelectedDatatable(null);
    setShowForm(true);
  };

  const handleEditDatatable = (e) => {
    setSelectedDatatable(e.currentTarget.getAttribute('data-id'));
    setShowForm(true);
  };

  const handleDeleteDatatable = (e) => {
    setSelectedDatatable(e.currentTarget.getAttribute('data-id'));
    setShowDeleteModal(true);
  };

  const handleViewEntries = (e) => {
    const id = e.currentTarget.getAttribute('data-id');
    navigate(`/datatables/${id}/entries`);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDeleteDatatable = async () => {
    if (selectedDatatable) {
      try {
        await dispatch(deleteDatatable(selectedDatatable));
        setShowDeleteModal(false);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        showErrorModal(errorMessage);
      }
    }
  };

  const printDeleteModal = () => {
    const datatable = datatables.find((d) => d.id === selectedDatatable);
    const title = `${literals.confirmDeleteDatatable} '${datatable?.name}'?`;
    return (
      <ConfirmModal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        onSubmit={confirmDeleteDatatable}
        title={title}
      />
    );
  };

  const printNoElements = () => {
    return (
      <div className='page_content'>
        <div className='no_elements'>{literals.thereIsNoDatatables}</div>
      </div>
    );
  };

  const printTable = () => {
    const rows = getFilteredDatatables().map((datatable) => {
      return (
        <tr key={datatable.id} data-id={datatable.id}>
          <td>{datatable.name}</td>
          <td>{datatable.description}</td>
          <td className='right'>
            <button
              className='btn btn-primary btn-sm'
              type='button'
              data-id={datatable.id}
              onClick={handleViewEntries}
            >
              {literals.viewEntries}
            </button>
            <Dropdown className='dropdown-menu-expand'>
              <Dropdown.Toggle as={DropdownMenuToggle} id={`dropdown-custom-components-${datatable.id}`} />
              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey='1'
                  data-id={datatable.id}
                  onClick={handleEditDatatable}
                >
                  {literals.common.edit}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey='2'
                  className='text-danger'
                  data-id={datatable.id}
                  onClick={handleDeleteDatatable}
                >
                  {literals.common.delete}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
    return (
      <div>
        <table className='table_stl1'>
          <thead>
            <tr>
              <th>{literals.common.name}</th>
              <th>{literals.common.description}</th>
              <th className='right'>{literals.common.options}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const printForm = () => {
    if (!showForm) {
      return null;
    }

    if (selectedDatatable) {
      const datatable = datatables.find((d) => d.id === selectedDatatable);
      return (
        <DatatableForm
          id={datatable.id}
          name={datatable.name}
          description={datatable.description}
          datastructureId={datatable.datastructure_id}
          organizations={datatable.organizations}
          onSave={closeForm}
          onClose={closeForm}
        />
      );
    }
    return (
      <DatatableForm
        onSave={closeForm}
        onClose={closeForm}
      />
    );
  };

  return (
    <>
      {printErrorModal()?.modal}
      {printDeleteModal()}
      {printForm()}
      <Layout selectedSection='datatables'>
        <div className='section'>
          <div className='section_header'>
            <h1>{literals.datatables}</h1>
            <div className='btn btn-primary' onClick={handleCreateDataStructure}>{literals.addDatatable}</div>
          </div>
          <div className='mt-lg'>
            {datatables && getFilteredDatatables().length ? printTable() : printNoElements()}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Datatables;
