import {
  SET_CHATBOTS, CREATE_CHATBOT, UPDATE_CHATBOT, DELETE_CHATBOTS, DELETE_CHATBOT,
} from './index';
import Api from './repository';

export const getChatbots = (elementId, offset = 0, limit = 1000) => async (dispatch) => {
  const response = await Api.getChatbots(elementId, offset, limit);
  dispatch({ type: SET_CHATBOTS, payload: response.chatbots });
};

export const createChatbot = (name, organizationsIds, orchestratorId) => async (dispatch) => {
  const response = await Api.createChatbot(name, organizationsIds, orchestratorId);
  dispatch({ type: CREATE_CHATBOT, payload: response });
  return response;
};

export const updateChatbot = (chatbotId, name, tools, model, initialMessage, systemMessage, canEmbed, enableDebug, inputPlaceholder, organizations, enableImages, temperature, avatar, removeAvatar, llmAgentName, showDisclaimer, disclaimer, extraInfo, iconBubbleToUpload, hasRemovedIconBubble, openaiKey, conversationOrchestrator, documentsOrchestrator, emailsOrchestrator, searchInDocuments, learningContext) => async (dispatch) => {
  const response = await Api.updateChatbot(chatbotId, name, tools, model, initialMessage, systemMessage, canEmbed, enableDebug, inputPlaceholder, organizations, enableImages, temperature, avatar, removeAvatar, llmAgentName, showDisclaimer, disclaimer, extraInfo, iconBubbleToUpload, hasRemovedIconBubble, openaiKey, conversationOrchestrator, documentsOrchestrator, emailsOrchestrator, searchInDocuments, learningContext);
  dispatch({ type: UPDATE_CHATBOT, payload: response });
  return response;
};

export const deleteChatbots = () => async (dispatch) => {
  const response = await Api.deleteChatbots();
  dispatch({ type: DELETE_CHATBOTS, payload: response });
  return response;
};

export const deleteChatbot = (chatbotId) => async (dispatch) => {
  const response = await Api.deleteChatbot(chatbotId);
  dispatch({ type: DELETE_CHATBOT, payload: response });
  return response;
};

export const updateIntegrations = (chatbotId, zendeskMail, zendeskToken, zendeskHost, zendeskDomain, zendeskSupport, zendeskSupportGroup, zendeskSchedule, crispEmail, crispToken, crispURL, whatsappNumber, whatsappNumberID, whatsappToken, whatsappVerifyToken, twilioID, twilioToken, twilioPhone, isChangedZendesk, isChangedCrisp, isChangedWhatsapp, isChangedTwilio, emailIntegrations) => async (dispatch) => {
  const response = await Api.updateIntegrations(chatbotId, zendeskMail, zendeskToken, zendeskHost, zendeskDomain, zendeskSupport, zendeskSupportGroup, zendeskSchedule, crispEmail, crispToken, crispURL, whatsappNumber, whatsappNumberID, whatsappToken, whatsappVerifyToken, twilioID, twilioToken, twilioPhone, isChangedZendesk, isChangedCrisp, isChangedWhatsapp, isChangedTwilio, emailIntegrations);
  dispatch({ type: UPDATE_CHATBOT, payload: response });
  return response;
};

export const getIntegrations = async (chatbotId) => {
  const response = await Api.getIntegrations(chatbotId);
  return response;
};

export const getTickets = async (zendeskMail, zendeskToken, zendeskHost, zendeskDomain) => {
  const response = await Api.getTickets(zendeskMail, zendeskToken, zendeskHost, zendeskDomain);
  return response;
};

export const responseTicket = async (zendeskMail, zendeskToken, zendeskHost, zendeskDomain, ticketID, ResponseTextTicket, subject) => {
  const response = await Api.responseTicket(zendeskMail, zendeskToken, zendeskHost, zendeskDomain, ticketID, ResponseTextTicket, subject);
  return response;
};

export const getPublicChatbot = async (chatbotId) => {
  const response = await Api.getPublicChatbot(chatbotId);
  return response;
};

export const testEmailIntegration = async (chatbotId, type, username, password, host, port, useSSL) => {
  const response = await Api.testEmailIntegration(chatbotId, type, username, password, host, port, useSSL);
  return response;
};
