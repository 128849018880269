export const CLEAR_APIS = 'chatbotApi/clear-apis';
export const SET_APIS = 'chatbotApi/set-apis';
export const ADD_API = 'chatbotApi/add-api';
export const UPDATE_API = 'chatbotApi/update-api';
export const DELETE_API = 'chatbotApi/delete-api';
export const RESET_ENDPOINTS = 'chatbotApi/reset-endpoints';
export const SET_ENDPOINTS = 'chatbotApi/set-endpoints';

export const initialState = {
  apis: [],
  endpoints: [],
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case CLEAR_APIS:
      return {
        ...state,
        apis: [],
      };
    case SET_APIS: {
      const { apis } = payload;
      return {
        ...state,
        apis,
      };
    }
    case ADD_API: {
      const { api } = payload;
      return {
        ...state,
        apis: [...state.apis, api],
      };
    }
    case UPDATE_API: {
      const { api } = payload;
      const apis = [...state.apis];
      const index = apis.findIndex((a) => a.id === api.id);
      if (index >= 0) {
        apis[index] = api;
      }
      return {
        ...state,
        apis,
      };
    }
    case DELETE_API: {
      const { apiId } = payload;
      const apis = [...state.apis];
      const index = apis.findIndex((a) => a.id === apiId);
      if (index >= 0) {
        apis.splice(index, 1);
      }
      return {
        ...state,
        apis,
      };
    }
    case RESET_ENDPOINTS:
      return {
        ...state,
        endpoints: [],
      };
    case SET_ENDPOINTS: {
      const { apiId, endpoints } = payload;
      return {
        ...state,
        endpoints: {
          ...state.endpoints,
          [apiId]: endpoints,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
