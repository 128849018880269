export const STATUS_PROCESSING = 'processing';
export const STATUS_FINISHED = 'finished';
export const VIEW_TEXT = 'view_text';
export const VIEW_AUDIO = 'view_audio';
export const ROLE_AGENT = 'agent';
export const ROLE_ASSISTANT = 'assistant';
export const ROLE_USER = 'user';
export const ROLE_SYSTEM = 'system';
export const ROLE_DEBUG = 'debug';
export const ORCHESTRATOR_STEPS = 'orchestrator_steps';
