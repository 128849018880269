import { DELETE_API } from 'modules/apis/index';

export const SET_CHATBOTS = 'chatbots/set-chatbots';
export const CREATE_CHATBOT = 'chatbots/create-chatbot';
export const UPDATE_CHATBOT = 'chatbots/update-chatbot';
export const DELETE_CHATBOTS = 'chatbots/delete-chatbots';
export const DELETE_CHATBOT = 'chatbots/delete-chatbot';

export const initialState = {
  chatbots: null,
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_CHATBOTS: {
      return {
        ...state,
        chatbots: payload,
      };
    }
    case CREATE_CHATBOT: {
      const { chatbots } = state;
      const newChatbots = chatbots ? [...chatbots] : [];
      newChatbots.push(payload);
      return {
        ...state,
        chatbots: newChatbots,
      };
    }
    case UPDATE_CHATBOT: {
      const chatbots = [...state.chatbots];

      const index = chatbots.findIndex((c) => c.id === payload.id);
      if (index !== -1) {
        chatbots[index] = payload;
      }

      return {
        ...state,
        chatbots,
      };
    }
    case DELETE_CHATBOT: {
      const chatbots = [...state.chatbots];
      const filteredChatbots = chatbots.filter((chatbot) => chatbot.id !== payload.id);
      return {
        ...state,
        chatbots: filteredChatbots,
      };
    }
    case DELETE_CHATBOTS: {
      return {
        ...state,
        chatbots: [],
      };
    }
    case DELETE_API: {
      const { chatbotId, apiId } = payload;
      const chatbots = state.chatbots.map((chatbot) => ({
        ...chatbot,
        tools: [...chatbot.tools],
      }));

      const index = chatbots.findIndex((c) => c.id === chatbotId);
      if (index !== -1) {
        const chatbot = chatbots[index];
        const apis = [...chatbot.tools];
        const apiIndex = apis.findIndex((a) => a === apiId);
        if (apiIndex !== -1) {
          apis.splice(apiIndex, 1);
          chatbots[index].tools = apis;
        }
      }

      return {
        ...state,
        chatbots,
      };
    }
    default:
      return state;
  }
};

export default reducer;
