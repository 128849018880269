import React from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import { useSelector } from 'react-redux';
import Layout from 'components/Layout';

function List(props) {
  const { forms, onCreate, onEdit } = props;

  const literals = useLiterals('forms');
  const { actualOrganization } = useSelector((state) => state.app);

  const editForm = (e) => {
    const id = e.currentTarget.getAttribute('data-id');
    onEdit(id);
  };

  const getFilteredForms = () => {
    if (!forms) {
      return [];
    }

    if (!actualOrganization) {
      return forms;
    }

    if (actualOrganization.id === 'personal') {
      return forms.filter((form) => !form.organizations || !form.organizations.length);
    }

    return forms.filter((form) => form.organizations && form.organizations.includes(actualOrganization.id));
  };

  const printTable = () => {
    const filteredForms = getFilteredForms();
    const rows = filteredForms.map((form) => {
      if (!form) {
        return null;
      }
      return (
        <tr key={form.id} data-id={form.id}>
          <td>{form.name}</td>
          <td>{form.description}</td>
          <td className='right'>
            <div className='buttons-container' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                data-id={form.id}
                className='btn btn-primary btn-sm'
                type='button'
                onClick={editForm}
              >
                {literals.common.edit}
              </button>
            </div>
          </td>
        </tr>
      );
    });
    return (
      <div>
        <table className='table_stl1'>
          <thead>
            <tr>
              <th>{literals.common.name}</th>
              <th>{literals.common.description}</th>
              <th className='right'>{literals.common.options}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const printNoElements = () => {
    return (
      <div className='page_content'>
        <div className='no_elements'>{literals.thereIsNoForms}</div>
      </div>
    );
  };

  return (
    <Layout selectedSection='forms'>
      <div className='section'>
        <div className='section_header'>
          <h1>{literals.forms}</h1>
          <div>
            <button type='button' className='btn btn-primary' onClick={onCreate}>
              {literals.createForm}
            </button>
          </div>
        </div>
        <div className='mt-lg'>
          {getFilteredForms().length ? printTable() : printNoElements()}
        </div>
      </div>
    </Layout>
  );
}

List.propTypes = {
  forms: PropTypes.array.isRequired,
  onCreate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default List;
