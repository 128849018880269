export const SET_DATATABLES = 'datatables/set-datatables';
export const CREATE_DATATABLE = 'datatables/create-datatable';
export const UPDATE_DATATABLE = 'datatables/update-datatable';
export const DELETE_DATATABLE = 'datatables/delete-datatable';

export const initialState = {
  datatables: null,
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_DATATABLES:
      return {
        ...state,
        datatables: payload,
      };
    case CREATE_DATATABLE: {
      const { datatables } = state;
      const newDatatables = datatables ? [...datatables] : [];
      newDatatables.push(payload);
      return {
        ...state,
        datatables: newDatatables,
      };
    }
    case UPDATE_DATATABLE: {
      const datatables = [...state.datatables];

      const index = datatables.findIndex((c) => c.id === payload.id);
      if (index !== -1) {
        datatables[index] = payload;
      }

      return {
        ...state,
        datatables,
      };
    }
    case DELETE_DATATABLE: {
      const datatables = [...state.datatables];
      const filteredDatatables = datatables.filter((datatable) => datatable.id !== payload);
      return {
        ...state,
        datatables: filteredDatatables,
      };
    }
    default:
      return state;
  }
};

export default reducer;
