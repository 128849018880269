import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PlayIcon from 'assets/icons/play.svg';
import { ReactComponent as StopIcon } from 'assets/icons/stop_icon.svg';
import LanguageDetect from 'languagedetect';

function TextToSpeech({ text }) {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [allStoredVoices, setAllStoredVoices] = useState([]);
  const [voice, setVoice] = useState(null);
  const synth = window.speechSynthesis;

  useEffect(() => {
    if (window.speechSynthesis && typeof SpeechSynthesisUtterance === 'function') {
      const loadVoices = () => {
        let allVoices = window.speechSynthesis.getVoices();
        if (allVoices.length !== 0) {
          setAllStoredVoices(allVoices);
          setVoice(allVoices[0]); // Puedes elegir una voz específica aquí si quieres
          setLoaded(true);
        } else {
          window.speechSynthesis.addEventListener('voiceschanged', () => {
            allVoices = window.speechSynthesis.getVoices();
            setAllStoredVoices(allVoices);
            setVoice(allVoices[0]);
            setLoaded(true);
          });
        }
      };
      loadVoices();
    }
  }, []);

  const handleGetLanguage = () => {
    const lngDetector = new LanguageDetect();
    const detectedLanguage = lngDetector.detect(text);
    if (detectedLanguage.length === 0 || detectedLanguage[0].length === 0) {
      return 'es';
    }
    const lang = detectedLanguage[0][0];
    switch (lang) {
      case 'spanish':
        return 'es-ES';
      case 'english':
        return 'en-US';
      case 'french':
        return 'fr-FR';
      case 'german':
        return 'de-DE';
      case 'italian':
        return 'it-IT';
      default:
        return 'es-ES';
    }
  };

  const startReading = () => {
    if (!loaded) {
      console.error('SpeechSynthesis voices not loaded yet');
      return;
    }
    if (synth?.speaking) {
      console.error('SpeechSynthesis is already speaking');
      return;
    }
    if (text !== '') {
      const initialUtterance = new SpeechSynthesisUtterance('first');
      const language = handleGetLanguage();
      initialUtterance.lang = language;
      initialUtterance.voice = voice;
      initialUtterance.volume = 0;

      initialUtterance.onend = () => {
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.volume = 1;

        const voiceLang = allStoredVoices.find((v) => v.lang === language);
        if (!voiceLang) {
          utterance.voice = voice;
        } else {
          utterance.voice = voiceLang;
        }

        utterance.onend = () => {
          setIsSpeaking(false);
        };

        utterance.onerror = () => {
          setIsSpeaking(false);
        };

        synth.speak(utterance);
      };

      initialUtterance.onerror = () => {
        setIsSpeaking(false);
      };

      synth.speak(initialUtterance);
      setIsSpeaking(true);
    }
  };

  const stopReading = () => {
    if (synth.speaking) {
      synth.cancel();
      setIsSpeaking(false);
    }
  };

  return (
    <div>
      {!isSpeaking && (
        <div className='dictation-container' onClick={startReading}>
          <img src={PlayIcon} alt='Play message' />
        </div>
      )}
      {isSpeaking && (
        <div className='dictation-container' onClick={stopReading}>
          <StopIcon fill='#ffffff' />
        </div>
      )}
    </div>
  );
}

TextToSpeech.propTypes = {
  text: PropTypes.string,
};

TextToSpeech.defaultProps = {
  text: '',
};

export default TextToSpeech;
