/* eslint-disable no-param-reassign */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import session, { SESSION_LOGOUT } from 'modules/session';
import i18n from 'modules/i18n';
import loading from 'modules/loading';
import chats from 'modules/chats';
import socket from 'modules/socket';
import elements from 'modules/elements';
import tools from 'modules/tools';
import models from 'modules/models';
import chatbots from 'modules/chatbots';
import apis from 'modules/apis';
import organizations from 'modules/organizations';
import forms from 'modules/forms';
import tests from 'modules/tests';
import app from 'modules/app';
import conversations from 'modules/conversations';
import debug from 'modules/debug';
import orchestrators from 'modules/orchestrators';
import datastructures from 'modules/datastructures';
import datatables from 'modules/datatables';
import initialState from './initialState';

const combinedReducer = combineReducers({
  session,
  i18n,
  loading,
  chats,
  socket,
  elements,
  tools,
  chatbots,
  models,
  apis,
  organizations,
  forms,
  tests,
  app,
  conversations,
  debug,
  orchestrators,
  datastructures,
  datatables,
});

const rootReducer = (state, action) => {
  if (action.type === SESSION_LOGOUT) {
    state = {
      ...initialState,
      session: {
        ...initialState.session,
        checked: true,
      },
    };
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});
