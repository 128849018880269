import React, { useEffect, useState } from 'react';
import { read } from 'xlsx';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import {
  createChatbotQuestion,
} from 'modules/tests/actions';

function ExcelUpload(props) {
  const {
    literals,
    allOrganizations,
    loadQuestions,
  } = props;

  const [showFileColumnsModal, setShowFileColumnsModal] = useState(false);
  const [titleColumn, setTitleColumn] = useState('');
  const [answerColumn, setAnswerColumn] = useState('');
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [fileContent, setFileContent] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const fileInputRef = React.createRef();

  const handleCreateExcelQuestions = async () => {
    const questions = fileContent[titleColumn.value].map((title, index) => ({
      title,
      answer: fileContent[answerColumn.value][index],
      organizations: selectedOrganizations.map((organization) => organization.value),
    }));
    const promiseRequests = questions.map((question) => createChatbotQuestion(question));
    setIsSaving(true);
    await Promise.all(promiseRequests);
    setIsSaving(true);
    setShowFileColumnsModal(false);
    loadQuestions();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Obtenemos los nombres de las columnas que tienen contenido
        const columnsWithContent = Object.keys(sheet)
          .filter((cell) => !cell.includes('!'))
          .map((cell) => cell.replace(/[0-9]/g, '')) // Removemos los números de las celdas
          .filter((value, index, self) => self.indexOf(value) === index); // Filtramos valores únicos

        const columnValues = {};

        columnsWithContent.forEach((column) => {
          columnValues[column] = [];
          // eslint-disable-next-line no-restricted-syntax
          for (const cell in sheet) {
            if (cell.includes(column)) {
              columnValues[column].push(sheet[cell].v);
            }
          }
        });
        setFileContent(columnValues);
        setShowFileColumnsModal(true);
      };

      reader.readAsBinaryString(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const renderFileColumnsModal = () => {
    if (!showFileColumnsModal) return null;
    return (
      <Modal show onHide={() => setShowFileColumnsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{literals.selectData}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className='my-2'>{literals.selectDataInfo}</div>
            <div className='panel-header'>{literals.title}</div>
            <div className='input_value'>
              <Select
                options={Object.keys(fileContent).map((column) => ({ value: column, label: column, isDisabled: column === answerColumn?.value }))}
                onChange={(selected) => setTitleColumn(selected)}
                value={titleColumn}
              />
            </div>
            <div className='panel-header'>{literals.answer}</div>
            <div className='input_value'>
              <Select
                options={Object.keys(fileContent).map((column) => ({ value: column, label: column, isDisabled: column === titleColumn?.value }))}
                onChange={(selected) => setAnswerColumn(selected)}
                value={answerColumn}
              />
            </div>
            <div className='panel-header'>{literals.organizations}</div>
            <div className='input_value'>
              <Select
                isMulti
                options={allOrganizations.map((organization) => ({ value: organization.id, label: organization.name }))}
                onChange={(selected) => setSelectedOrganizations(selected)}
                value={selectedOrganizations}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowFileColumnsModal(false)}>
            {literals.common.close}
          </Button>
          <Button
            variant='primary'
            onClick={handleCreateExcelQuestions}
          >
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {literals.common.accept}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {renderFileColumnsModal()}
      <input
        type='file'
        style={{ display: 'none' }}
        className='btn btn-primary btn-sm'
        onChange={handleFileUpload}
        ref={fileInputRef}
      />
      <Button
        variant='primary'
        onClick={handleButtonClick}
      >
        {literals.uploadExcel}
      </Button>
    </>
  );
}

ExcelUpload.propTypes = {
  literals: PropTypes.object.isRequired,
  allOrganizations: PropTypes.array.isRequired,
  loadQuestions: PropTypes.func.isRequired,
};

export default ExcelUpload;
