import Api from './repository';
import {
  SET_DATASTRUCTURES, CREATE_DATASTRUCTURE, UPDATE_DATASTRUCTURE, DELETE_DATASTRUCTURE,
} from '.';

/* Funciones con dispatch */
export const getDatastructures = () => async (dispatch) => {
  const response = await Api.getDatastructures();
  dispatch({
    type: SET_DATASTRUCTURES,
    payload: response.datastructures,
  });
};

export const createDatastructure = (data) => async (dispatch) => {
  const response = await Api.createDatastructure(data);
  dispatch({ type: CREATE_DATASTRUCTURE, payload: response });
  return response;
};

export const updateDatastructure = (datastructureId, data) => async (dispatch) => {
  const response = await Api.updateDatastructure(datastructureId, data);
  dispatch({ type: UPDATE_DATASTRUCTURE, payload: response });
  return response;
};

export const deleteDatastructure = (datastructureId) => async (dispatch) => {
  const response = await Api.deleteDatastructure(datastructureId);
  dispatch({ type: DELETE_DATASTRUCTURE, payload: datastructureId });
  return response;
};
