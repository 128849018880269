import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

const URLS = {
  getTools: 'tools',
};

export default {
  async getTools(offset = 0, limit = 1000) {
    const url = `${URLS.getTools}?offset=${offset}&limit=${limit}`;
    return ApiFetchInstance.get(url);
  },
};
