import Api from './repository';

export const getChatbotTexts = async (chatbot) => {
  const response = await Api.getChatbotTexts(chatbot);
  return response;
};

export const createText = async (chatbot, text) => {
  const response = await Api.createText(chatbot, text);
  return response;
};

export const updateText = async (chatbot, textId, text) => {
  const response = await Api.updateText(chatbot, textId, text);
  return response;
};

export const deleteText = async (chatbot, textId) => {
  const response = await Api.deleteText(chatbot, textId);
  return response;
};
