import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { VIEW_DATA, VIEW_ENDPOINTS } from 'constants/chatbotsApis';
import useLiterals from 'hooks/useLiterals';
import DataView from './components/data';
import EndpointsView from './components/endpoints';
import './styles.scss';

function AddApi(props) {
  const {
    api, defaultView, onBack,
  } = props;

  const [actualApi, setActualApi] = useState(api);
  const [actualView, setActualView] = useState(defaultView || VIEW_DATA);

  const literals = useLiterals('addApi');

  const handleCreate = (response) => {
    setActualApi(response);
    setActualView(VIEW_ENDPOINTS);
  };

  const handleUpdate = () => {
    onBack();
  };

  const setDataView = () => {
    setActualView(VIEW_DATA);
  };

  const setEndpointsView = () => {
    setActualView(VIEW_ENDPOINTS);
  };

  const printTabs = () => {
    if (!actualApi || !actualApi.id) {
      return null;
    }

    return (
      <div className='secondary_tabs'>
        <div className={`tab ${actualView === VIEW_DATA ? 'selected' : ''}`} onClick={setDataView}>
          {literals.data}
        </div>
        <div className={`tab ${actualView === VIEW_ENDPOINTS ? 'selected' : ''}`} onClick={setEndpointsView}>
          {literals.endpoints}
        </div>
      </div>
    );
  };

  const printActualView = () => {
    switch (actualView) {
      case VIEW_DATA:
        return (
          <DataView
            api={actualApi}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            onBack={onBack}
          />
        );
      case VIEW_ENDPOINTS:
        return (
          <EndpointsView
            apiId={actualApi.id}
            onBack={onBack}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className='p-md'>
      <div className='subsection_title'>{literals.connectWithYourApi}</div>
      {printTabs()}
      {printActualView()}
    </div>
  );
}

AddApi.propTypes = {
  api: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    swagger_url: PropTypes.string,
  }),
  onBack: PropTypes.func.isRequired,
  defaultView: PropTypes.string,
};

AddApi.defaultProps = {
  api: null,
  defaultView: null,
};

export default AddApi;
