/* eslint-disable no-plusplus */
import React, { useEffect, useMemo, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import { transformDate } from 'utils/dates';
import Paginator from 'components/Paginator';

function ConversationsTable({
  conversations, filters, setFilters, handleViewConversation, conversationId,
}) {
  const literals = useLiterals('conversations');
  const headers = useMemo(() => [
    { name: 'id', label: literals.id, sortable: true },
    { name: 'agent_life', label: literals.type, sortable: true },
    { name: 'ticket_id', label: literals.ticket, sortable: true },
    { name: 'total_messages', label: literals.totalMessages, sortable: false },
    { name: 'price', label: literals.price, sortable: false },
    { name: 'whatsapp', label: literals.whatsapp, sortable: false },
    { name: 'name', label: literals.common.name, sortable: true },
    { name: 'created_at', label: literals.date, sortable: true },
  ], [literals]);

  const handleChangeFilter = (header) => {
    if (header.sortable) {
      const sortType = filters.sortField === header.name && filters.sortType === 'asc' ? 'desc' : 'asc';
      setFilters({ ...filters, sortField: header.name, sortType });
    }
  };

  const renderWhatsApp = (conversation) => {
    let whatsapp = '';
    if (conversation?.contact
      && conversation?.contact?.twilio
      && conversation?.contact?.twilio?.length > 1
      && conversation?.contact?.twilio[0] === 'whatsapp') {
      whatsapp = conversation?.contact?.twilio[1];
    }
    return whatsapp;
  };

  const printConversationsTable = () => {
    const rows = conversations?.conversations?.map((c) => {
      return (
        <tr className={conversationId === c.conversation.id && 'selected'} key={c.conversation.id} data-id={c.conversation.id} onClick={() => handleViewConversation(c.conversation.chatbot_id, c.conversation.id)}>
          <td>{c.conversation.id.slice(-5)}</td>
          <td>{c.conversation.agent_life}</td>
          <td>
            <a href={`https://tuio-help.zendesk.com/agent/tickets/${c.conversation.ticket_id}`}>
              {c.conversation.ticket_id}
            </a>
          </td>
          <td>{c.total_messages}</td>
          <td>{c.total_price}</td>
          <td>{renderWhatsApp(c.conversation)}</td>
          <td>{c.conversation?.name}</td>
          <td>{transformDate(c.conversation?.created_at)}</td>
        </tr>
      );
    });

    return (
      <div>
        <table className='table_stl1'>
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header.name} onClick={() => handleChangeFilter(header)}>
                  {header.label}
                  {' '}
                  {header.sortable && (
                    <>
                      <i className={`fa-solid fa-arrow-up ${filters.sortField === header.name && filters.sortType === 'asc' ? 'enabled' : 'disabled'}`} />
                      {' '}
                      <i className={`fa-solid fa-arrow-down ${filters.sortField === header.name && filters.sortType === 'desc' ? 'enabled' : 'disabled'}`} />
                    </>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const printPaginator = () => {
    return <Paginator filters={filters} total={conversations?.total} onPageChange={(page) => setFilters({ ...filters, page })} />;
  };

  return (
    <>
      {printConversationsTable()}
      {printPaginator()}
    </>
  );
}

ConversationsTable.propTypes = {
  conversations: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  handleViewConversation: PropTypes.func.isRequired,
  conversationId: PropTypes.string,
};

ConversationsTable.defaultProps = {
  conversationId: null,
};

export default ConversationsTable;
