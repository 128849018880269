import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import useLiterals from 'hooks/useLiterals';
import { v4 as uuidv4 } from 'uuid';
import {
  TYPE_TEXT, TYPE_NUMBER, TYPE_SELECT, TYPE_PHONE, TYPE_EMAIL, TYPE_DATE,
} from 'constants/forms';

const TYPES = [TYPE_TEXT, TYPE_NUMBER, TYPE_SELECT, TYPE_PHONE, TYPE_EMAIL, TYPE_DATE];

const TYPES_WITH_OPTIONS = [TYPE_SELECT];

function QuestionForm(props) {
  const { question, onClose, onSubmit } = props;
  const [id] = useState(question?.id || uuidv4());
  const [title, setTitle] = useState(question?.title || '');
  const [variableName, setVariableName] = useState(question?.variable_name || '');
  const [type, setType] = useState(question?.type || TYPE_TEXT);
  const [required, setRequired] = useState(question?.required || false);
  const [options, setOptions] = useState(question?.options || []);
  const [hasSetVariableName, setHasSetVariableName] = useState(!!question?.variable_name || false);

  const literals = useLiterals('forms');

  const handleOnChangeVariableName = (event) => {
    let result = event.target.value.replace(/\s+/g, '_');

    // Luego, eliminamos las tildes y las "ñ"
    result = result.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ñ/gi, '');

    // Finalmente, eliminamos cualquier carácter que no sea letra o número
    result = result.replace(/[^a-zA-Z0-9_]/g, '');
    setVariableName(result);
  };

  const handleKeyUpVariableName = (event) => {
    if (event.target.value) {
      if (!hasSetVariableName) {
        setHasSetVariableName(true);
      }
    } else {
      setHasSetVariableName(false);
    }
  };

  const handleOnChangeTitle = (event) => {
    setTitle(event.target.value);

    if (!hasSetVariableName) {
      handleOnChangeVariableName(event);
    }
  };

  const handleOnChangeType = (event) => {
    setType(event.target.value);
  };

  const handleOnChangeRequired = (event) => {
    setRequired(event.target.checked);
  };

  const handleSubmit = () => {
    onSubmit({
      id, title, variable_name: variableName, type, required, options,
    });
  };

  const canContinue = () => {
    let result = title.length && variableName.length && type.length;

    if (TYPES_WITH_OPTIONS.includes(type)) {
      result = result && options.length;

      if (result) {
        result = options.every((option) => option.value && option.text);
      }
    }

    return result;
  };

  const handleAddOption = () => {
    const newOptions = [...options];
    newOptions.push({
      id: uuidv4(),
      value: '',
      text: '',
    });
    setOptions(newOptions);
  };

  const handleDeleteOption = (event) => {
    const newOptions = [...options];
    const optionId = event.target.getAttribute('data-id');
    const optionIndex = newOptions.findIndex((option) => option.id === optionId);
    if (optionIndex >= 0) {
      newOptions.splice(optionIndex, 1);
    }
    setOptions(newOptions);
  };

  const handleChangeTextOption = (event) => {
    const newOptions = [...options];
    const optionId = event.target.getAttribute('data-id');
    const optionIndex = newOptions.findIndex((option) => option.id === optionId);
    if (optionIndex >= 0) {
      newOptions[optionIndex].text = event.target.value;
    }
    setOptions(newOptions);
  };

  const handleChangeValueOption = (event) => {
    const newOptions = [...options];
    const optionId = event.target.getAttribute('data-id');
    const optionIndex = newOptions.findIndex((option) => option.id === optionId);
    if (optionIndex >= 0) {
      newOptions[optionIndex].value = event.target.value;
    }
    setOptions(newOptions);
  };

  const printOptions = () => {
    if (!TYPES_WITH_OPTIONS.includes(type)) {
      return null;
    }

    const optionsRows = options.map((option) => {
      return (
        <div className='row' key={option.id}>
          <div className='col-5'>
            <div className='input_title'>
              {literals.text}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' data-id={option.id} onChange={handleChangeTextOption} value={option.text} />
            </div>
          </div>
          <div className='col-5'>
            <div className='input_title'>
              {literals.value}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' data-id={option.id} onChange={handleChangeValueOption} value={option.value} />
            </div>
          </div>
          <div className='col-2'>
            <button className='btn btn-danger btn-xs mt-4' type='button' data-id={option.id} onClick={handleDeleteOption}>
              {literals.common.delete}
            </button>
          </div>
        </div>
      );
    });

    return (
      <div className='panel'>
        <div className='panel-header'>{literals.options}</div>
        <div className='panel-body'>
          {optionsRows}
          <div className='input_value'>
            <button type='button' className='btn btn-secondary btn-xs' onClick={handleAddOption}>{literals.addOption}</button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal show onHide={onClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{literals.question}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='panel'>
          <div className='panel-header'>{literals.basicInfo}</div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.title}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeTitle} value={title} />
            </div>
            <div className='input_title'>
              {literals.common.type}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <select className='form_select_stl' onChange={handleOnChangeType} value={type}>
                {TYPES.map((typeAux) => (<option value={typeAux} key={typeAux}>{literals[typeAux]}</option>))}
              </select>
            </div>
            <div className='input_title'>
              {literals.variableName}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeVariableName} onKeyUp={handleKeyUpVariableName} value={variableName} />
            </div>
            <div className='input_title'>
              <div className='form-check form-switch'>
                <input type='checkbox' id='is_required-switch' className='form-check-input' onChange={handleOnChangeRequired} checked={required} />
                <label title='' htmlFor='is_required-switch' className='form-check-label'>{literals.isRequired}</label>
              </div>
            </div>
          </div>
        </div>
        {printOptions()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {literals.common.cancel}
        </Button>
        <Button
          variant='primary'
          onClick={handleSubmit}
          disabled={!canContinue()}
        >
          {literals.common.accept}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

QuestionForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    variable_name: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      text: PropTypes.string,
    })),
  }),
};

QuestionForm.defaultProps = {
  question: null,
};

export default QuestionForm;
