import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const ORCHESTRATOR_CATEGORY = 'Orchestrator';

export default {
  EXECUTE_ORCHESTRATOR: {
    categories: [ORCHESTRATOR_CATEGORY],
    tag: 'executeOrchestrator',
    name: 'Execute Orchestrator',
    tags: ['execute', 'orchestrator', 'executeOrchestrator'],
    icon: 'fa-solid fa-sitemap',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ORCHESTRATOR,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'orchestrator',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
    ],
  },
};
