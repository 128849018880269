import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const GITLAB_CATEGORY = 'Gitlab';

export default {
  GITLAB_REPOSITORY_TREE: {
    categories: [GITLAB_CATEGORY],
    tag: 'getGitlabRepositoryTree',
    name: 'Get Gitlab Repository Tree',
    tags: ['Gitlab', 'repository', 'tree'],
    icon: 'fa-brands fa-gitlab',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'gitlab_url',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'private_token',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'project_id',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'branch_name',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'only_files',
        default_value: false,
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'repository_tree',
        custom: false,
        to: [],
      },
    ],
  },
  GITLAB_GET_FILE_CONTENT: {
    categories: [GITLAB_CATEGORY],
    tag: 'getGitlabFileContent',
    name: 'Get Gitlab File Content',
    tags: ['Gitlab', 'file', 'content'],
    icon: 'fa-brands fa-gitlab',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'gitlab_url',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'private_token',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'project_id',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'branch_name',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'file_path',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'content',
        custom: false,
        to: [],
      },
    ],
  },
};
