export default [
  {
    name: 'orchestrators',
    value: 'orchestrators',
    path: '/orchestrators',
    icon: 'fa-solid fa-sitemap',
  },
  {
    name: 'myChatbots',
    value: 'chatbots',
    path: '/chatbots',
    icon: 'fa-solid fa-headset',
  },
  // {
  //   name: 'myElements',
  //   value: 'elements',
  //   path: '/elements',
  //   icon: 'fa-solid fa-cubes',
  // },
  {
    name: 'myOrganizations',
    value: 'organizations',
    path: '/organizations',
    icon: 'fa-solid fa-building',
  },
  {
    name: 'myApis',
    value: 'apis',
    path: '/my-apis',
    icon: 'fa-solid fa-shapes',
  },
  {
    name: 'myForms',
    value: 'forms',
    path: '/forms',
    icon: 'fa-brands fa-wpforms',
  },
  {
    name: 'myDatastructures',
    value: 'datastructures',
    path: '/datastructures',
    icon: 'fa-solid fa-database',
  },
  {
    name: 'myDatatables',
    value: 'datatables',
    path: '/datatables',
    icon: 'fa-solid fa-table',
  },
  {
    name: 'myApiKeys',
    value: 'apiKeys',
    path: '/api-keys',
    icon: 'fa-solid fa-key',
  },
  {
    name: 'tests',
    value: 'tests',
    path: '/tests',
    icon: 'fa-solid fa-line-chart',
  },
];
