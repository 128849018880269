import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

const URLS = {
  getElements: 'elements',
  createElement: 'elements',
};

export default {
  async getAllElements(offset = 0, limit = 1000) {
    return ApiFetchInstance.get(`${URLS.getElements}?offset=${offset}&limit=${limit}`);
  },
  async getElements(elementType) {
    return ApiFetchInstance.get(`${URLS.getElements}?element_type=${elementType}`);
  },
  async createElement(name, elementType, elementId) {
    const data = {
      name,
      element_type: elementType,
      element_id: elementId,
    };
    return ApiFetchInstance.post(URLS.createElement, data);
  },
};
