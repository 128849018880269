export const SET_FORMS = 'SET_FORMS';

export const initialState = {
  forms: null,
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_FORMS: {
      return {
        ...state,
        forms: payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
