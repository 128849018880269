import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const MESSAGE_CATEGORY = 'Message';

export default {
  EXTRACT_MESSAGE_DATA: {
    categories: [MESSAGE_CATEGORY],
    tag: 'getMessageData',
    name: 'Get message data',
    tags: ['message', 'data'],
    icon: 'fa-solid fa-file-lines',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.MESSAGE,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'message',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {

        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'id',
        custom: false,
        to: [],
      },
      {

        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'conversation_id',
        custom: false,
        to: [],
      },
      {

        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'chatbot_id',
        custom: false,
        to: [],
      },
      {

        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'role',
        custom: false,
        to: [],
      },
      {

        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'type',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'content',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'user_id',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'input_tokens',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'output_tokens',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'price',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'extra_info',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'created_at',
        custom: false,
        to: [],
      },
    ],
  },
  CREATE_MESSAGE: {
    categories: [MESSAGE_CATEGORY],
    tag: 'createMessage',
    name: 'Create message',
    tags: ['create message', 'new message', 'message'],
    icon: 'fa-solid fa-file-plus',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.CONVERSATION,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'conversation',
        custom: false,
        from: [],
      },
      {

        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'role',
        custom: false,
        options: ['assistant', 'user'],
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'content',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'extra_info',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {

        type: TYPE_CONNECTIONS.MESSAGE,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'message',
        custom: false,
        to: [],
      },
    ],
  },
};
