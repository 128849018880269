import React, { useState, useEffect } from 'react';
import { doForgotPassword } from 'modules/session/actions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from 'utils/errors';
import ForgotPasswordForm from './components/form';
import { ROUTE_PATH } from '..';
import './styles.scss';

function Login() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const { user } = useSelector((state) => state.session);

  const navigate = useNavigate();

  useEffect(() => {
    if (user.id) {
      navigate(ROUTE_PATH.CHATBOTS);
    }
  }, [user, navigate]);

  const handleOnSubmit = async () => {
    setError('');
    setIsLoading(true);
    doForgotPassword(email)
      .then(() => {
        setIsLoading(false);
        setShowModalSuccess(true);
      })
      .catch((e) => {
        const messageError = getErrorMessage(e);
        setError(messageError);
        setShowModalError(true);
        setIsLoading(false);
      });
  };

  return (
    <ForgotPasswordForm
      email={email}
      error={error}
      isLoading={isLoading}
      onChangeEmail={setEmail}
      onSubmit={handleOnSubmit}
      showModalSuccess={showModalSuccess}
      showModalError={showModalError}
      navigate={navigate}
    />
  );
}

export default Login;
