import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import ReactFlow, {
  Background, useNodesState, useEdgesState, useReactFlow, useStoreApi,
} from 'reactflow';
import PropTypes from 'prop-types';
import { updateOrchestratorNode } from 'modules/orchestrators/actions';
import 'reactflow/dist/style.css';
import { NODE_TYPES, TYPE_CONNECTIONS } from 'constants/orchestrator';
import { copyTextInClipboard } from 'utils/text';
import CustomNode from './CustomNode';
import CustomEdge from './CustomEdge';
import OverviewPanel from './OverviewPanel';
import ContextualMenu from './ContextualMenu';

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

function OrchestratorDiagram({
  nodes,
  edges,
  nodesSelected,
  edgesSelected,
  setNodesSelected,
  setEdgesSelected,
  orchestrator,
  version,
  forceShowContextualMenu,
  onChangeNodes,
  onChangePosition,
  onDeleteNodes,
  onDeleteEdges,
  onChangeOrchestrator,
  onCreateNodeType,
  onChangeVersion,
  debugNodes,
  onCopyPasteNodes,
  onManageOrchestratorVersions,
  onChangeOrchestratorVersion,
  selectedVersion,
  onGoToFunction,
  selectedFunction,
  selectedVersionFunction,
  onRefreshOrchestrator,
}) {
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0 });
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [selection, setSelection] = useState({ nodes: [], edges: [] });

  const reactFlowContainerRef = useRef(null);
  const [nodesP, setNodes, onNodesChange] = useNodesState(nodes.map((node) => ({
    ...node,
    data: {
      ...node.data,
      nodes,
    },
  })));
  const [edgesP, setEdges, onEdgesChange] = useEdgesState(edges);
  const reactFlow = useReactFlow();
  const store = useStoreApi();

  useEffect(() => {
    const element = document.getElementById('orchestrator_top_menu');
    if (element) {
      element.click();
    }
  }, []);

  useEffect(() => {
    // Reposiciono el diagrama
    let minX = null;
    let minY = null;
    let maxX = null;
    let maxY = null;
    nodes.forEach((node) => {
      if (minX === null || node.position.x < minX) {
        minX = node.position.x;
      }
      if (minY === null || node.position.y < minY) {
        minY = node.position.y;
      }
      if (maxX === null || node.position.x > maxX) {
        maxX = node.position.x;
      }
      if (maxY === null || node.position.y > maxY) {
        maxY = node.position.y;
      }
    });
    const panelElement = document.querySelector('.orchestrator__node-settings-panel');
    const widthPanel = panelElement ? panelElement.offsetWidth : 0;
    const centerX = ((minX + maxX) / 2) + (widthPanel / 2);
    const centerY = (minY + maxY) / 2;
    reactFlow.fitView();
    reactFlow.setCenter(centerX, centerY, { zoom: 1 });
  }, [selectedFunction, selectedVersionFunction]);

  const simulateClickOnBody = () => {
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    document.body.dispatchEvent(clickEvent);
  };

  useEffect(() => {
    setNodes(nodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        nodes,
      },
    })));
    setEdges(edges);
    simulateClickOnBody();
  }, [version]);

  useEffect(() => {
    setNodes(nodes.map((node) => {
      const isSelected = nodesSelected.includes(node.id);
      const isExecuted = debugNodes.executed_nodes.includes(node.id);
      const result = debugNodes.results[node.id] || null;
      const auxNode = nodesP.find((nodeP) => nodeP.id === node.id);
      return {
        ...node,
        data: {
          ...node.data,
          nodes,
          isSelected,
          isExecuted,
          result,
        },
        position: auxNode?.position || node.position,
      };
    }));
    simulateClickOnBody();
  }, [nodes]);

  useEffect(() => {
    if (forceShowContextualMenu) {
      const x = (window.innerWidth - reactFlowContainerRef.current.getBoundingClientRect().left) / 2 - 300;
      const y = (window.innerHeight - reactFlowContainerRef.current.getBoundingClientRect().top) / 2 - 175;
      setContextMenu({ visible: true, x, y });
      setMousePosition({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
    }
  }, [forceShowContextualMenu]);

  const handleOnNodesChange = useCallback((selectedNodes) => {
    onNodesChange(selectedNodes);
  }, []);

  const handleChangePositionNode = (elementId, x, y) => {
    const newOrchestrator = { ...orchestrator };
    updateOrchestratorNode(selectedFunction || orchestrator.id, elementId, { x, y });

    if (!selectedFunction) {
      const childrenIndex = newOrchestrator.children.findIndex((child) => child.id === elementId);
      if (childrenIndex !== -1) {
        newOrchestrator.children[childrenIndex].x = x;
        newOrchestrator.children[childrenIndex].y = y;
      }
    } else {
      const functionIndex = newOrchestrator.functions.findIndex((f) => f.id === selectedFunction);
      const childrenIndex = newOrchestrator.functions[functionIndex].children.findIndex((child) => child.id === elementId);

      if (childrenIndex !== -1) {
        newOrchestrator.functions[functionIndex].children[childrenIndex].x = x;
        newOrchestrator.functions[functionIndex].children[childrenIndex].y = y;
      }
    }

    onRefreshOrchestrator(newOrchestrator);
  };

  const handleOnNodeDragStop = useCallback((event, selectedNode) => {
    handleChangePositionNode(selectedNode.id, selectedNode.position.x, selectedNode.position.y);
    simulateClickOnBody();
  }, [selectedFunction, orchestrator]);

  const handleSelectionDragStop = useCallback((event, selectedElements) => {
    selectedElements.forEach((element) => {
      handleChangePositionNode(element.id, element.position.x, element.position.y);
    });
    simulateClickOnBody();
  }, [selectedFunction, orchestrator]);

  const handleOnNodeClick = useCallback((event, node) => {
    setNodesSelected([node.id]);
    setEdgesSelected([]);
    setSelection({ nodes: [node], edges: [] });
    simulateClickOnBody();
  }, []);

  const handleSelectionChange = useCallback((elements) => {
    setSelection(elements);
  }, []);

  useEffect(() => {
    simulateClickOnBody();
  }, [nodesSelected, edgesSelected]);

  const handleOnEdgesChange = useCallback((selectedEdges) => {
    onEdgesChange(selectedEdges);
  }, []);

  const handleOnPaneClick = useCallback(() => {
    setNodesSelected([]);
    setSelection({ nodes: [], edges: [] });
    console.log('focus');
    simulateClickOnBody();
  }, []);

  const handleOnEdgeClick = useCallback((event, edge) => {
    setNodesSelected([]);
    setEdgesSelected([edge]);
    setSelection({ nodes: [], edges: [edge] });
  });

  const handleOnPaneMove = useCallback((event, data) => {
    const {
      height,
      width,
      transform: [transformX, transformY, zoomLevel],
    } = store.getState();

    const zoomMultiplier = 1 / zoomLevel;
    // Figure out the center of the current viewport
    const centerX = -transformX * zoomMultiplier + (width * zoomMultiplier) / 2;
    const centerY = -transformY * zoomMultiplier + (height * zoomMultiplier) / 2;

    onChangePosition({ x: centerX, y: centerY });
  }, []);

  const handleOnConnect = (params) => {
    const sourceNode = nodes.find((node) => node.id === params.source);
    const targetNode = nodes.find((node) => node.id === params.target);

    if (!sourceNode || !targetNode) {
      return;
    }

    const sourceHandle = sourceNode?.data?.outputs?.find((output) => output.id === params.sourceHandle);
    const targetHandle = targetNode?.data?.inputs?.find((input) => input.id === params.targetHandle);

    if (!sourceHandle || !targetHandle || (sourceHandle.type !== targetHandle.type && targetHandle.type !== TYPE_CONNECTIONS.ANY && sourceHandle.type !== TYPE_CONNECTIONS.ANY) || sourceHandle.type_set !== targetHandle.type_set || (sourceHandle.type === TYPE_CONNECTIONS.ANY && targetHandle.type === TYPE_CONNECTIONS.EXEC) || (sourceHandle.type === TYPE_CONNECTIONS.EXEC && targetHandle.type === TYPE_CONNECTIONS.ANY)) {
      return;
    }

    // Si está intentando conectar un nodo consigo mismo
    if (sourceNode.id === targetNode.id) {
      return;
    }

    // Compruebo que esa conexión no exista ya
    const existingEdge = edges.find((edge) => edge.source === params.source && edge.sourceHandle === params.sourceHandle && edge.target === params.target && edge.targetHandle === params.targetHandle);
    if (existingEdge) {
      return;
    }

    const nodesToUpdate = [];

    // Le asigno el destinto al output
    // Si es un output de tipo EXEC, solo puede tener un destino
    if (sourceHandle.type === TYPE_CONNECTIONS.EXEC && sourceHandle.type_set === 'SINGLE') {
      // Si ya tenia un destino, lo elimino
      if (sourceHandle.to.length > 0) {
        const oldTargetHandle = sourceHandle.to[0];
        const oldTargetNode = nodes.find((node) => node.id === oldTargetHandle.node_id);
        const oldTargetInput = oldTargetNode?.data?.inputs?.find((input) => input.id === oldTargetHandle.input_id);
        if (oldTargetInput) {
          oldTargetNode.data.inputs = oldTargetNode.data.inputs.map((input) => {
            if (input.id === oldTargetInput.id) {
              return {
                ...input,
                from: input.from.filter((from) => from.node_id !== sourceNode.id || from.output_id !== sourceHandle.id),
              };
            }
            return input;
          });
          nodesToUpdate.push(oldTargetNode);
        }
      }
      sourceHandle.to = [{
        node_id: targetNode.id,
        input_id: targetHandle.id,
      }];
    } else {
      sourceHandle.to = [...sourceHandle.to, {
        node_id: targetNode.id,
        input_id: targetHandle.id,
      }];
    }

    nodesToUpdate.push(sourceNode);

    // Le asigno el origen al input
    // Si es un input de tipo EXEC, puede tener varios origenes
    if (targetHandle.type === TYPE_CONNECTIONS.EXEC) {
      targetHandle.from = [...targetHandle.from, {
        node_id: sourceNode.id,
        output_id: sourceHandle.id,
      }];
    } else {
      // Si ya tenia un origen, lo elimino
      if (targetHandle.from.length > 0) {
        const oldSourceHandle = targetHandle.from[0];
        const oldSourceNode = nodes.find((node) => node.id === oldSourceHandle.node_id);
        const oldSourceOutput = oldSourceNode?.data?.outputs?.find((output) => output.id === oldSourceHandle.output_id);
        if (oldSourceOutput) {
          oldSourceNode.data.outputs = oldSourceNode.data.outputs.map((output) => {
            if (output.id === oldSourceOutput.id) {
              return {
                ...output,
                to: output.to.filter((to) => to.node_id !== targetNode.id || to.input_id !== targetHandle.id),
              };
            }
            return output;
          });
          nodesToUpdate.push(oldSourceNode);
        }
      }
      targetHandle.from = [{
        node_id: sourceNode.id,
        output_id: sourceHandle.id,
      }];
    }

    nodesToUpdate.push(targetNode);

    // Actualizo el nodo origen
    const sourceNodeIndex = nodes.findIndex((node) => node.id === sourceNode.id);
    const newNodes = [...nodes];
    newNodes[sourceNodeIndex] = sourceNode;

    // Actualizo el nodo destino
    const targetNodeIndex = nodes.findIndex((node) => node.id === targetNode.id);
    newNodes[targetNodeIndex] = targetNode;
    onChangeNodes(newNodes);

    // Persisto los nodos
    const updatedNodes = [];
    nodesToUpdate.forEach((node) => {
      if (updatedNodes.includes(node.id)) {
        return;
      }

      updatedNodes.push(node.id);

      updateOrchestratorNode(selectedFunction || orchestrator.id, node.id, {
        inputs: node.data.inputs,
        outputs: node.data.outputs,
      });
    });
  };

  const handleUpdateNode = (nodeId, data) => {
    const orchestratorAux = selectedFunction ? orchestrator.functions.find((f) => f.id === selectedFunction) : orchestrator;
    const oldData = nodes.find((node) => node.id === nodeId)?.data ?? {};
    const inputsDeleted = oldData.inputs.filter((input) => !data.inputs.find((newInput) => newInput.id === input.id));
    const outputsDeleted = oldData.outputs.filter((output) => !data.outputs.find((newOutput) => newOutput.id === output.id));

    updateOrchestratorNode(orchestratorAux.id, nodeId, data);

    // Actualizo el nodo destino
    const newNodes = [...nodes];
    const targetNodeIndex = newNodes.findIndex((node) => node.id === nodeId);
    newNodes[targetNodeIndex] = {
      ...nodes[targetNodeIndex],
      data: {
        ...nodes[targetNodeIndex].data,
        label: data.name,
        inputs: data.inputs,
        outputs: data.outputs,
        extra_info: data.extra_info,
      },
    };

    // Elimino las conexiones que se hayan eliminado
    const nodesToSave = [];
    inputsDeleted.forEach((input) => {
      input.from.forEach((from) => {
        const sourceNode = nodes.find((node) => node.id === from.node_id);
        if (sourceNode) {
          sourceNode.data.outputs = sourceNode.data.outputs.map((output) => {
            if (output.id === from.output_id) {
              return {
                ...output,
                to: output.to.filter((to) => to.node_id !== nodeId || to.input_id !== input.id),
              };
            }
            return output;
          });

          if (!nodesToSave.includes(sourceNode.id)) {
            nodesToSave.push(sourceNode.id);
          }
        }
      });
    });

    outputsDeleted.forEach((output) => {
      output.to.forEach((to) => {
        const targetNode = nodes.find((node) => node.id === to.node_id);
        if (targetNode) {
          targetNode.data.inputs = targetNode.data.inputs.map((input) => {
            if (input.id === to.input_id) {
              return {
                ...input,
                from: input.from.filter((from) => from.node_id !== nodeId || from.output_id !== output.id),
              };
            }
            return input;
          });

          if (!nodesToSave.includes(targetNode.id)) {
            nodesToSave.push(targetNode.id);
          }
        }
      });
    });

    // SI es un nodo inicial y ha cambiado los custom outputs actualizo sus nodos GET y SET
    if (oldData.type === NODE_TYPES.INITIAL) {
      // Compruebo si algunos de los outputs ha cambiado de nombre
      data.outputs.forEach((newOutput) => {
        const oldOutput = oldData.outputs.find((o) => o.id === newOutput.id);
        if (oldOutput && oldOutput.name !== newOutput.name) {
          const nodesToChange = nodes.filter((node) => (node.data.type === 'GET' || node.data.type === 'SET') && node.data.extra_info.variable === oldOutput.name);

          nodesToChange.forEach((n) => {
            const node = nodes.find((tmpNode) => tmpNode.id === n.id);
            node.data.inputs = node.data.inputs.map((input) => {
              if (input.name === oldOutput.name) {
                return {
                  ...input,
                  name: newOutput.name,
                };
              }
              return input;
            });

            node.data.outputs = node.data.outputs.map((output) => {
              if (output.name === oldOutput.name) {
                return {
                  ...output,
                  name: newOutput.name,
                };
              }
              return output;
            });

            const newExtraInfo = { ...node.data.extra_info };
            newExtraInfo.variable = newOutput.name;
            node.data.extra_info = newExtraInfo;

            if (!nodesToSave.includes(node.id)) {
              nodesToSave.push(node.id);
            }
          });
        }
      });
    }

    nodesToSave.forEach((nodeToSaveId) => {
      const node = newNodes.find((n) => n.id === nodeToSaveId);
      if (node) {
        updateOrchestratorNode(orchestratorAux.id, nodeToSaveId, {
          inputs: node.data.inputs,
          outputs: node.data.outputs,
          extra_info: node.data.extra_info,
        });
      }
    });

    onChangeNodes(newNodes);
    onChangeVersion();
  };

  const handleKeyDown = (event) => {
    const target = event.target;

    // Verificar si el target tiene una clase que empieza con "react-flow__"
    const hasReactFlowClass = Array.from(target.classList).some((className) => className.startsWith('react-flow__'));
    if (event.key === 'Delete') {
      if (selection.nodes.length) {
        onDeleteNodes(selection.nodes.map((node) => node.id));
      } else if (nodesSelected.length) {
        onDeleteNodes();
      }

      if (selection.edges.length) {
        onDeleteEdges(selection.edges);
      } else if (edgesSelected.length) {
        onDeleteEdges();
      }
    } else if (event.ctrlKey && hasReactFlowClass) {
      switch (event.key) {
        case 'c':
        case 'C': {
          const nodesIds = [];
          if (selection.nodes.length) {
            selection.nodes.forEach((node) => {
              nodesIds.push(node.id);
            });
          } else if (nodesSelected.length) {
            nodesIds.push(...nodesSelected);
          }
          const auxOrchestrator = selectedFunction ? orchestrator.functions.find((f) => f.id === selectedFunction) : orchestrator;
          const selectedNodes = auxOrchestrator.children.filter((node) => nodesIds.includes(node.id));
          const auxSelectedVersion = selectedFunction ? selectedVersionFunction : selectedVersion;
          const versionData = auxOrchestrator.versions.find((v) => v.id === auxSelectedVersion);
          const variables = [];

          selectedNodes.forEach((node) => {
            if (node.type === 'GET' || node.type === 'SET') {
              const variableName = node.extra_info.variable;
              const variable = versionData.variables.find((v) => v.name === variableName);
              if (variable && !variables.find((v) => v.name === variable.name)) {
                variables.push(variable);
              }
            }
          });

          copyTextInClipboard(JSON.stringify({ nodes: selectedNodes, variables }));
        }
          break;
        default:
          break;
      }
    }
  };

  const handleClickWrapper = () => {
    if (contextMenu.visible) {
      setContextMenu({ ...contextMenu, visible: false });
    }
  };

  const handleContextMenuWrapper = (event) => {
    event.preventDefault();

    let x = event.pageX - 250;
    let y = event.pageY;

    if (event.pageY > window.innerHeight / 2) {
      y -= 360;
    }

    if (event.pageX > window.innerWidth / 2) {
      x -= 410;
    }

    setMousePosition({ x: event.clientX, y: event.clientY });
    setContextMenu({
      visible: true,
      x,
      y,
    });
  };

  const handleCreateNodeType = (nodeType, extraInfo = {}) => {
    const containerPosition = {
      x: reactFlowContainerRef.current.getBoundingClientRect().left,
      y: reactFlowContainerRef.current.getBoundingClientRect().top,
    };

    const adjustedMousePosition = {
      x: mousePosition.x - containerPosition.x,
      y: mousePosition.y - containerPosition.y,
    };

    // Usa la función project para convertir las coordenadas ajustadas
    const position = reactFlow.project(adjustedMousePosition);

    onCreateNodeType(nodeType, { ...extraInfo, x: position.x, y: position.y });
    setContextMenu({ ...contextMenu, visible: false });
  };

  const printContextualMenu = () => {
    if (contextMenu.visible) {
      return (
        <ContextualMenu
          onSelectNodeType={handleCreateNodeType}
          x={contextMenu.x}
          y={contextMenu.y}
          orchestrator={orchestrator}
          selectedVersion={selectedVersion}
          selectedFunction={selectedFunction}
          selectedVersionFunction={selectedVersionFunction}
        />
      );
    }
    return null;
  };

  const handleSelectionEnd = useCallback(() => {
    simulateClickOnBody();
  }, []);

  const handleKeyUp = (event) => {
    simulateClickOnBody();
  };

  const handlePaste = useCallback((event) => {
    // Accede al contenido del portapapeles
    const pastedData = event.clipboardData.getData('Text');
    try {
      const data = JSON.parse(pastedData);
      if (data.nodes) {
        onCopyPasteNodes(data.nodes, data.variables || []);
        event.preventDefault();
      }
    } catch (error) {
      console.error('Error parsing pasted text', error);
    }
  }, [onCopyPasteNodes, selectedVersion, selectedFunction, selectedVersionFunction, orchestrator]);

  useEffect(() => {
    // Añadir el event listener para el evento 'paste'
    document.addEventListener('paste', handlePaste);

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [onCopyPasteNodes, selectedVersion, selectedFunction, selectedVersionFunction, orchestrator]);

  return (
    <>
      {printContextualMenu()}
      <div style={{ width: '100%', height: 'calc(100vh - 54px)' }}>
        <div
          onContextMenu={handleContextMenuWrapper}
          onClick={handleClickWrapper}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          style={{ width: '100%', height: 'calc(100vh - 54px)' }}
          ref={reactFlowContainerRef}
          tabIndex={0}
          role='button'
          aria-label='Orchestrator diagram'
        >
          <ReactFlow
            nodes={nodesP}
            edges={edgesP}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onNodesChange={handleOnNodesChange}
            onEdgesChange={handleOnEdgesChange}
            onConnect={handleOnConnect}
            onNodeClick={handleOnNodeClick}
            onNodeDragStop={handleOnNodeDragStop}
            onSelectionEnd={handleSelectionEnd}
            onSelectionChange={handleSelectionChange}
            onSelectionDragStop={handleSelectionDragStop}
            onEdgeClick={handleOnEdgeClick}
            onPaneClick={handleOnPaneClick}
            onMove={handleOnPaneMove}
            fitView
            nodeDragThreshold={3}
            minZoom={0.2}
          >
            <Background
              color='#ccc'
              variant='dots'
              style={{
                backgroundColor: '#2f3131',
              }}
            />
          </ReactFlow>
        </div>
        <OverviewPanel
          orchestrator={orchestrator}
          node={nodesSelected.length ? nodes.find((n) => n.id === nodesSelected[0]) : null}
          onUpdateNode={handleUpdateNode}
          onUpdateOrchestrator={onChangeOrchestrator}
          onGoToFunction={onGoToFunction}
          onManageOrchestratorVersions={onManageOrchestratorVersions}
          onChangeOrchestratorVersion={onChangeOrchestratorVersion}
          selectedVersion={selectedVersion}
          selectedFunction={selectedFunction}
          selectedVersionFunction={selectedVersionFunction}
        />
      </div>
    </>
  );
}

OrchestratorDiagram.propTypes = {
  nodes: PropTypes.array.isRequired,
  edges: PropTypes.array.isRequired,
  setNodesSelected: PropTypes.func.isRequired,
  orchestrator: PropTypes.object.isRequired,
  version: PropTypes.number.isRequired,
  onChangeNodes: PropTypes.func.isRequired,
  onChangePosition: PropTypes.func.isRequired,
  nodesSelected: PropTypes.arrayOf(PropTypes.string),
  edgesSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
  setEdgesSelected: PropTypes.func.isRequired,
  onDeleteNodes: PropTypes.func.isRequired,
  onDeleteEdges: PropTypes.func.isRequired,
  onChangeOrchestrator: PropTypes.func.isRequired,
  onCreateNodeType: PropTypes.func.isRequired,
  forceShowContextualMenu: PropTypes.bool.isRequired,
  onChangeVersion: PropTypes.func.isRequired,
  debugNodes: PropTypes.object.isRequired,
  onCopyPasteNodes: PropTypes.func.isRequired,
  onManageOrchestratorVersions: PropTypes.func.isRequired,
  onChangeOrchestratorVersion: PropTypes.func.isRequired,
  selectedVersion: PropTypes.string.isRequired,
  onGoToFunction: PropTypes.func.isRequired,
  selectedFunction: PropTypes.string,
  selectedVersionFunction: PropTypes.string,
  onRefreshOrchestrator: PropTypes.func.isRequired,
};

OrchestratorDiagram.defaultProps = {
  nodesSelected: [],
  selectedFunction: null,
  selectedVersionFunction: null,
};

export default OrchestratorDiagram;
