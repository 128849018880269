/* eslint-disable no-param-reassign */
import axios from 'axios';
import { getErrorMessage } from 'utils/errors';

const ApiFetch = (baseURL) => {
  const instance = axios.create({
    withCredentials: true,
  });

  // Modifica los headers en todas las peticiones
  instance.interceptors.request.use((config) => {
    // Set base url
    if (!baseURL) {
      // eslint-disable-next-line no-param-reassign
      baseURL = window.LLM_CHATBOT_API_URL;
    }
    config.url = `${baseURL}/${config.url}`;
    if (typeof process !== 'undefined' && typeof process.env !== 'undefined' && process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET && process.env.REACT_APP_CF_ACCESS_CLIENT_ID) {
      config.headers['CF-Access-Client-Secret'] = process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET;
      config.headers['CF-Access-Client-Id'] = process.env.REACT_APP_CF_ACCESS_CLIENT_ID;
    }
    // config.headers['Access-Control-Allow-Origin'] = '*';
    return config;
  });

  // Recupera la respuesta de cualquier petición
  // Más información: https://github.com/axios/axios#interceptors
  instance.interceptors.response.use(
    (res) => {
      return res.data;
    },
    (err) => {
      const errorMessage = getErrorMessage(err);
      console.error('ENtro', errorMessage);
      console.error('Ha habido un error', err);
      return Promise.reject(err);
    },
  );

  return instance;
};

export default ApiFetch;
