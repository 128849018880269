import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ModalSelectChatbotForDebug(props) {
  const { chatbots, onClose, onSelect } = props;

  const [selectedChatbot, setSelectedChatbot] = useState(null);

  const literals = useLiterals('orchestrators');

  const handleChangeChatbot = (event) => {
    setSelectedChatbot(event.target.value);
  };

  const handleSelectChatbot = () => {
    onSelect(selectedChatbot);
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{literals.selectAChatbotForDebug}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className='input_title'>
            {literals.selectAChatbotForDebug}
            <span className='required_field'>*</span>
          </div>
          <div className='input_value'>
            <select className='form_select_stl' onChange={handleChangeChatbot}>
              <option value=''>{literals.selectAChatbot}</option>
              {chatbots.map((chatbot) => (
                <option value={chatbot.id} key={`chatbot-debug-${chatbot.id}`}>{chatbot.name}</option>
              ))}
            </select>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {literals.common.close}
        </Button>
        <Button
          variant='primary'
          onClick={handleSelectChatbot}
          disabled={!selectedChatbot}
        >
          {literals.common.accept}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalSelectChatbotForDebug.propTypes = {
  chatbots: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ModalSelectChatbotForDebug;
