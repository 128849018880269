import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import useLiterals from 'hooks/useLiterals';
import { createDatastructure, updateDatastructure } from 'modules/datastructures/actions';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import { v4 as uuidv4 } from 'uuid';
import { BASIC_VARIABLES, TYPE_SET_CONNECTIONS } from 'constants/orchestrator';

function DatastructureForm(props) {
  const {
    id: idProp, name: nameProp, description: descriptionProp, fields: fieldsProp,
    organizations: organizationsProp,
    onSave, onClose,
  } = props;

  const [id, setId] = useState(idProp || null);
  const [name, setName] = useState(nameProp || '');
  const [description, setDescription] = useState(descriptionProp || '');
  const [datastructureOrganizations, setDatastructureOrganizations] = useState(organizationsProp || []);
  const [fields, setFields] = useState(fieldsProp || []);
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();
  const literals = useLiterals('datastructures');

  const { organizations } = useSelector((state) => state.organizations);
  const { actualOrganization } = useSelector((state) => state.app);
  const { datastructures } = useSelector((state) => state.datastructures);

  const { showErrorModal, printErrorModal } = useErrorHandling();

  useEffect(() => {
    if (!id && actualOrganization) {
      setDatastructureOrganizations(actualOrganization.id === 'personal' ? [] : [{ value: actualOrganization.id, label: actualOrganization.name }]);
    }
  }, [actualOrganization]);

  useEffect(() => {
    if (id && organizations && organizations.length > 0 && organizationsProp.length > 0) {
      const selectedOrganizations = organizations.filter((organization) => organizationsProp.includes(organization.id));
      setDatastructureOrganizations(selectedOrganizations.map((organization) => ({ value: organization.id, label: organization.name })));
    }
  }, [id, organizations, organizationsProp]);

  const handleSaveDatastructure = async () => {
    try {
      setIsSaving(true);
      const organizationsIds = datastructureOrganizations.map((organization) => organization.value);
      const data = {
        name,
        description,
        fields,
        organizations: organizationsIds,
      };
      await dispatch(id ? updateDatastructure(id, data) : createDatastructure(data));
      onSave();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  const canSave = () => {
    let fieldsAreValid = true;
    fields.forEach((field) => {
      if (!field.name) {
        fieldsAreValid = false;
      }
    });
    return fieldsAreValid && name.length > 0 && !isSaving;
  };

  const handleOnChangeName = (event) => {
    setName(event.target.value);
  };

  const handleOnChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleSelectOrganization = (selectedValues) => {
    setDatastructureOrganizations(selectedValues);
  };

  const handleAddField = () => {
    setFields([...fields, { id: uuidv4(), name: '', type: BASIC_VARIABLES[0] }]);
  };

  const handleOnChangeFieldName = (event) => {
    const fieldId = event.currentTarget.getAttribute('data-id');
    const newFields = [...fields];
    const index = newFields.findIndex((field) => field.id === fieldId);
    newFields[index].name = event.target.value;
    setFields(newFields);
  };

  const handleOnChangeFieldType = (event) => {
    const fieldId = event.currentTarget.getAttribute('data-id');
    const newFields = [...fields];
    const index = newFields.findIndex((field) => field.id === fieldId);
    newFields[index].type = event.target.value;
    setFields(newFields);
  };

  const handleOnChangeFieldTypeSet = (event) => {
    const fieldId = event.currentTarget.getAttribute('data-id');
    const newFields = [...fields];
    const index = newFields.findIndex((field) => field.id === fieldId);
    newFields[index].type_set = event.target.value;
    setFields(newFields);
  };

  const handleRemoveField = (event) => {
    const fieldId = event.currentTarget.getAttribute('data-id');
    const newFields = fields.filter((field) => field.id !== fieldId);
    setFields(newFields);
  };

  const printFields = () => {
    if (!fields.length) {
      return null;
    }

    const rows = fields.map((field) => {
      return (
        <tr key={field.id}>
          <td aria-label={literals.common.name}>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeFieldName} value={field.name} data-id={field.id} />
            </div>
          </td>
          <td aria-label={literals.common.type}>
            <div className='input_value'>
              <select className='form_select_stl' onChange={handleOnChangeFieldType} value={field.type} data-id={field.id}>
                {BASIC_VARIABLES.map((variable) => (
                  <option key={variable} value={variable}>{variable}</option>
                ))}
                {datastructures.map((datastructure) => (
                  <option key={datastructure.id} value={`DATASTRUCTURE-${datastructure.id}`}>{datastructure.name}</option>
                ))}
              </select>
            </div>
          </td>
          <td aria-label={literals.common.type}>
            <div className='input_value'>
              <select className='form_select_stl' onChange={handleOnChangeFieldTypeSet} value={field.type_set || TYPE_SET_CONNECTIONS.SINGLE} data-id={field.id}>
                <option value={TYPE_SET_CONNECTIONS.SINGLE}>{TYPE_SET_CONNECTIONS.SINGLE}</option>
                <option value={TYPE_SET_CONNECTIONS.ARRAY}>{TYPE_SET_CONNECTIONS.ARRAY}</option>
              </select>
            </div>
          </td>
          <td className='right' aria-label={literals.common.options}>
            <Button
              variant='danger'
              size='sm'
              data-id={field.id}
              onClick={handleRemoveField}
            >
              <i className='fa-solid fa-trash-can' />
            </Button>
          </td>
        </tr>
      );
    });
    return (
      <table className='table_stl1 mb-10'>
        <thead>
          <tr>
            <th>
              {literals.common.name}
              <span className='required_field'>*</span>
            </th>
            <th>{literals.common.type}</th>
            <th />
            <th className='right'>{literals.common.options}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  };

  return (
    <>
      {printErrorModal()?.modal}
      <Modal show size='lg' onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{id ? literals.editDatastructure : literals.addDatastructure}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className='panel'>
              <div className='panel-header'>{literals.datastructureData}</div>
              <div className='panel-body'>
                <div className='input_title'>
                  {literals.common.name}
                  <span className='required_field'>*</span>
                </div>
                <div className='input_value'>
                  <input type='text' className='form_input_stl' onChange={handleOnChangeName} value={name} />
                </div>
                <div className='input_title'>
                  {literals.common.description}
                </div>
                <div className='input_value'>
                  <textarea className='form_input_stl' onChange={handleOnChangeDescription} value={description} />
                </div>
                <div className='input_title'>{literals.common.organizations}</div>
                <div className='input_value'>
                  <Select
                    isMulti
                    options={organizations.map((organization) => ({ value: organization.id, label: organization.name }))}
                    onChange={handleSelectOrganization}
                    value={datastructureOrganizations}
                  />
                </div>
              </div>
            </div>
            <div className='panel'>
              <div className='panel-header'>{literals.fields}</div>
              <div className='panel-body'>
                {printFields()}
                <div className='right'>
                  <Button variant='primary' onClick={handleAddField}>{literals.addField}</Button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClose}>
            {literals.common.close}
          </Button>
          <Button
            variant='primary'
            onClick={handleSaveDatastructure}
            disabled={!canSave()}
          >
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {literals.common.accept}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

DatastructureForm.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  organizations: PropTypes.array,
  fields: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

DatastructureForm.defaultProps = {
  id: null,
  name: '',
  description: '',
  organizations: [],
  fields: [],
};

export default DatastructureForm;
