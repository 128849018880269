import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import useLiterals from 'hooks/useLiterals';
import { createApi, updateApi } from 'modules/apis/actions';
import { getMyOrganizations } from 'modules/organizations/actions';
import FeedbackModal from 'components/FeedbackModal';
import Select from 'react-select';
import { getErrorMessage } from 'utils/errors';
import {
  TYPES_AUTH, TYPE_OAUTH, TYPE_BASIC, TYPE_OTP, TYPES_PARAMS,
} from 'constants/chatbotsApis';

const MAX_LENGTH_DESCRIPTION = 200;

function DataView(props) {
  const {
    api, onBack, onCreate, onUpdate,
  } = props;

  const literals = useLiterals('addApi');
  const [id] = useState(api?.id ?? null);
  const [name, setName] = useState(api?.name ?? '');
  const [description, setDescription] = useState(api?.description ?? '');
  const [urlBase, setUrlBase] = useState(api?.url_base ?? '');
  const [typeAuth, setTypeAuth] = useState(api?.type_auth ?? TYPES_AUTH[0].value);
  const [authParams, setAuthParams] = useState(api?.auth_params ?? {
    oauth_client_id: '', oauth_client_secret: '', oauth_authorization_url: '', oauth_token_url: '', oauth_redirect_url: '', basic_login_endpoint: '', basic_method: 'POST', basic_username_fieldname: '', basic_password_fieldname: '', basic_params_type: TYPES_PARAMS[0], otp_send_endpoint_method: 'POST', otp_send_endpoint: '', otp_user_fieldname: '', otp_user_placeholder: '', otp_verify_endpoint_method: 'POST', otp_verify_endpoint: '', otp_value_fieldname: '', otp_value_placeholder: '', otp_send_message: literals.otpSendMessage, otp_verify_message: literals.otpVerifyMessage,
  });
  const [headers, setHeaders] = useState(api?.headers ?? []);
  const [swaggerUrl, setSwaggerUrl] = useState(api?.swagger_url ?? '');
  const [apiOrganizations, setApiOrganizations] = useState(api?.organizations ?? []);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const { organizations } = useSelector((state) => state.organizations);
  const { actualOrganization } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyOrganizations());
    if (!id && actualOrganization) {
      setApiOrganizations(actualOrganization.id === 'personal' ? [] : [actualOrganization.id]);
    }
  }, []);

  const handleOnChangeName = (e) => {
    setName(e.target.value);
  };

  const handleOnChangeDescription = (e) => {
    if (e.target.value.length <= MAX_LENGTH_DESCRIPTION) {
      setDescription(e.target.value);
    }
  };

  const handleOnChangeUrlBase = (e) => {
    setUrlBase(e.target.value);
  };

  const handleOnChangeSwaggerUrl = (e) => {
    setSwaggerUrl(e.target.value);
  };

  const handleOnChangeAuthType = (e) => {
    setTypeAuth(e.target.value);
  };

  const canContinue = () => {
    switch (typeAuth) {
      case TYPE_OAUTH:
        if (!authParams?.oauth_client_id || !authParams?.oauth_client_secret || !authParams?.oauth_authorization_url || !authParams?.oauth_token_url || !authParams?.oauth_redirect_url) {
          return false;
        }
        break;
      case TYPE_BASIC:
        if (!authParams?.basic_login_endpoint || !authParams?.basic_method || !authParams?.basic_username_fieldname || !authParams?.basic_password_fieldname) {
          return false;
        }
        break;
      case TYPE_OTP:
        if (!authParams?.otp_send_endpoint_method || !authParams?.otp_send_endpoint || !authParams?.otp_user_fieldname || !authParams?.otp_user_placeholder || !authParams?.otp_verify_endpoint_method || !authParams?.otp_verify_endpoint || !authParams?.otp_value_fieldname || !authParams?.otp_value_placeholder) {
          return false;
        }
        break;
      default:
        break;
    }

    return name.trim().length > 0 && description.trim().length > 0 && urlBase.trim().length > 0;
  };

  const saveApi = async () => {
    try {
      setIsSaving(true);
      const params = {
        name, description, swagger_url: swaggerUrl, url_base: urlBase, type_auth: typeAuth, auth_params: authParams, organizations: apiOrganizations, headers,
      };
      let apiResponse = null;
      if (id) {
        apiResponse = await dispatch(updateApi(id, params));
        onUpdate(apiResponse);
      } else {
        apiResponse = await dispatch(createApi(params));
        setIsSaving(false);
        onCreate(apiResponse);
      }
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      setError(errorMessage);
      setIsSaving(false);
    }
  };

  const clearError = () => {
    setError(null);
  };

  const printErrorModal = () => {
    if (!error) {
      return null;
    }

    return (
      <FeedbackModal
        open
        onClose={clearError}
        title={literals.common.error}
        success={false}
        message={error}
      />
    );
  };

  const handleOnChangeOauthClientId = (e) => {
    setAuthParams({ ...authParams, oauth_client_id: e.target.value });
  };

  const handleOnChangeOauthClientSecret = (e) => {
    setAuthParams({ ...authParams, oauth_client_secret: e.target.value });
  };

  const handleOnChangeOauthAuthorizationUrl = (e) => {
    setAuthParams({ ...authParams, oauth_authorization_url: e.target.value });
  };

  const handleOnChangeOauthTokenUrl = (e) => {
    setAuthParams({ ...authParams, oauth_token_url: e.target.value });
  };

  const handleOnChangeOauthRedirectUrl = (e) => {
    setAuthParams({ ...authParams, oauth_redirect_url: e.target.value });
  };

  const handleOnChangeBasicLoginEndpoint = (e) => {
    setAuthParams({ ...authParams, basic_login_endpoint: e.target.value });
  };

  const handleOnChangeBasicMethod = (e) => {
    setAuthParams({ ...authParams, basic_method: e.target.value });
  };

  const handleOnChangeBasicParamsType = (e) => {
    setAuthParams({ ...authParams, basic_params_type: e.target.value });
  };

  const handleOnChangeBasicUsernameFieldName = (e) => {
    setAuthParams({ ...authParams, basic_username_fieldname: e.target.value });
  };

  const handleOnChangeBasicPasswordFieldName = (e) => {
    setAuthParams({ ...authParams, basic_password_fieldname: e.target.value });
  };

  const handleOnChangeSendOTPEndpoint = (e) => {
    setAuthParams({ ...authParams, otp_send_endpoint: e.target.value });
  };

  const handleOnChangeSendOTPEndpointMethod = (e) => {
    setAuthParams({ ...authParams, otp_send_endpoint_method: e.target.value });
  };

  const handleOnChangeOTPUserFieldName = (e) => {
    setAuthParams({ ...authParams, otp_user_fieldname: e.target.value });
  };

  const handleOnChangeOTPUserPlaceholder = (e) => {
    setAuthParams({ ...authParams, otp_user_placeholder: e.target.value });
  };

  const handleOnChangeVerifyOTPEndpointMethod = (e) => {
    setAuthParams({ ...authParams, otp_verify_endpoint_method: e.target.value });
  };

  const handleOnChangeVerifyOTPEndpoint = (e) => {
    setAuthParams({ ...authParams, otp_verify_endpoint: e.target.value });
  };

  const handleOnChangeOTPValueFieldName = (e) => {
    setAuthParams({ ...authParams, otp_value_fieldname: e.target.value });
  };

  const handleOnChangeOTPValuePlaceholder = (e) => {
    setAuthParams({ ...authParams, otp_value_placeholder: e.target.value });
  };

  const handleOnChangeOTPSendMessage = (e) => {
    setAuthParams({ ...authParams, otp_send_message: e.target.value });
  };

  const handleOnChangeOTPVerifyMessage = (e) => {
    setAuthParams({ ...authParams, otp_verify_message: e.target.value });
  };

  const handleSelectOrganization = (selectedValues) => {
    setApiOrganizations(selectedValues.map((selectedValue) => (selectedValue.value)));
  };

  const handleAddHeader = () => {
    setHeaders([...headers, { key: '', value: '' }]);
  };

  const handleOnChangeHeaderKey = (e, index) => {
    const newHeaders = [...headers];
    newHeaders[index].key = e.target.value;
    setHeaders(newHeaders);
  };

  const handleOnChangeHeaderValue = (e, index) => {
    const newHeaders = [...headers];
    newHeaders[index].value = e.target.value;
    setHeaders(newHeaders);
  };

  const handleRemoveHeader = (index) => {
    const newHeaders = [...headers];
    newHeaders.splice(index, 1);
    setHeaders(newHeaders);
  };

  const printHeaders = () => {
    const rows = headers.map((header, index) => (
      <tr key={index}>
        <td>
          <div className='input_value'>
            <input type='text' className='form_input_stl' onChange={(e) => handleOnChangeHeaderKey(e, index)} value={header.key} />
          </div>
        </td>
        <td>
          <div className='input_value'>
            <input type='text' className='form_input_stl' onChange={(e) => handleOnChangeHeaderValue(e, index)} value={header.value} />
          </div>
        </td>
        <td className='right'>
          <div className='buttons-container flex justify-end'>
            <button type='button' className='btn btn-danger btn-sm' onClick={() => handleRemoveHeader(index)}>
              {literals.remove}
            </button>
          </div>
        </td>
      </tr>
    ));

    return (
      <table className='table_stl1'>
        <thead>
          <tr>
            <th>{literals.key}</th>
            <th>{literals.value}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  };

  const printAuthParams = () => {
    switch (typeAuth) {
      case TYPE_OAUTH:
        return (
          <>
            <div className='input_title'>
              {literals.clientId}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeOauthClientId} value={authParams?.oauth_client_id ?? ''} />
            </div>
            <div className='input_title'>
              {literals.clientSecret}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeOauthClientSecret} value={authParams?.oauth_client_secret ?? ''} />
            </div>
            <div className='input_title'>
              {literals.authorizationUrl}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeOauthAuthorizationUrl} value={authParams?.oauth_authorization_url ?? ''} />
            </div>
            <div className='input_title'>
              {literals.tokenUrl}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeOauthTokenUrl} value={authParams?.oauth_token_url ?? ''} />
            </div>
            <div className='input_title'>
              {literals.redirectUri}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeOauthRedirectUrl} value={authParams?.oauth_redirect_url ?? ''} />
            </div>
          </>
        );
      case TYPE_BASIC:
        return (
          <>
            <div className='input_title'>
              {literals.loginEndpoint}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeBasicLoginEndpoint} value={authParams?.basic_login_endpoint ?? ''} placeholder='/users/login' />
            </div>
            <div className='input_title'>
              {literals.method}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <select className='form_select_stl' onChange={handleOnChangeBasicMethod} value={authParams?.basic_method ?? 'POST'}>
                <option value='GET'>GET</option>
                <option value='POST'>POST</option>
                <option value='PUT'>PUT</option>
              </select>
            </div>
            <div className='input_title'>
              {literals.paramsType}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <select className='form_select_stl' onChange={handleOnChangeBasicParamsType} value={authParams?.basic_params_type ?? TYPES_PARAMS[0]}>
                {TYPES_PARAMS.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
            <div className='input_title'>
              {literals.usernameFieldName}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeBasicUsernameFieldName} value={authParams?.basic_username_fieldname ?? ''} placeholder='email' />
            </div>
            <div className='input_title'>
              {literals.passwordFieldName}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeBasicPasswordFieldName} value={authParams?.basic_password_fieldname ?? ''} placeholder='password' />
            </div>
          </>
        );
      case TYPE_OTP:
        return (
          <>
            <div className='input_title'>
              {literals.sendOTPEndpoint}
              <span className='required_field'>*</span>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-3'>
                <div className='input_value'>
                  <select className='form_select_stl' onChange={handleOnChangeSendOTPEndpointMethod} value={authParams?.otp_send_endpoint_method ?? 'POST'}>
                    <option value='GET'>GET</option>
                    <option value='POST'>POST</option>
                    <option value='PUT'>PUT</option>
                  </select>
                </div>
              </div>
              <div className='col-xs-12 col-sm-9'>
                <div className='input_value'>
                  <input type='text' className='form_input_stl' onChange={handleOnChangeSendOTPEndpoint} value={authParams?.otp_send_endpoint ?? ''} placeholder='/otp/send' />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-6'>
                <div className='input_title'>
                  {literals.userFieldName}
                  <span className='required_field'>*</span>
                </div>
                <div className='input_value'>
                  <input type='text' className='form_input_stl' onChange={handleOnChangeOTPUserFieldName} value={authParams?.otp_user_fieldname ?? ''} placeholder='phone' />
                </div>
              </div>
              <div className='col-xs-12 col-sm-6'>
                <div className='input_title'>
                  {literals.userFieldPlaceholder}
                  <span className='required_field'>*</span>
                </div>
                <div className='input_value'>
                  <input type='text' className='form_input_stl' onChange={handleOnChangeOTPUserPlaceholder} value={authParams?.otp_user_placeholder ?? ''} placeholder={literals.phone} />
                </div>
              </div>
            </div>
            <div className='input_title'>
              {literals.sendOTPMessage}
            </div>
            <div className='input_value'>
              <textarea className='form_input_stl' onChange={handleOnChangeOTPSendMessage} value={authParams?.otp_send_message ?? ''} />
            </div>
            <div className='input_title'>
              {literals.verifyOTPEndpoint}
              <span className='required_field'>*</span>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-3'>
                <div className='input_value'>
                  <select className='form_select_stl' onChange={handleOnChangeVerifyOTPEndpointMethod} value={authParams?.otp_verify_endpoint_method ?? 'POST'}>
                    <option value='GET'>GET</option>
                    <option value='POST'>POST</option>
                    <option value='PUT'>PUT</option>
                  </select>
                </div>
              </div>
              <div className='col-xs-12 col-sm-9'>
                <div className='input_value'>
                  <input type='text' className='form_input_stl' onChange={handleOnChangeVerifyOTPEndpoint} value={authParams?.otp_verify_endpoint ?? ''} placeholder='/otp/verify' />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-sm-6'>
                <div className='input_title'>
                  {literals.otpValueFieldName}
                  <span className='required_field'>*</span>
                </div>
                <div className='input_value'>
                  <input type='text' className='form_input_stl' onChange={handleOnChangeOTPValueFieldName} value={authParams?.otp_value_fieldname ?? ''} placeholder='code' />
                </div>
              </div>
              <div className='col-xs-12 col-sm-6'>
                <div className='input_title'>
                  {literals.otpValuePlaceholder}
                  <span className='required_field'>*</span>
                </div>
                <div className='input_value'>
                  <input type='text' className='form_input_stl' onChange={handleOnChangeOTPValuePlaceholder} value={authParams?.otp_value_placeholder ?? ''} placeholder={literals.code} />
                </div>
              </div>
            </div>
            <div className='input_title'>
              {literals.verifyOTPMessage}
            </div>
            <div className='input_value'>
              <textarea className='form_input_stl' onChange={handleOnChangeOTPVerifyMessage} value={authParams?.otp_verify_message ?? ''} />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const organizationsValues = [];
  apiOrganizations.forEach((organizationId) => {
    const organization = organizations.find((org) => org.id === organizationId);
    if (organization) {
      organizationsValues.push({ value: organization.id, label: organization.name });
    }
  });

  return (
    <>
      {printErrorModal()}
      <Form>
        <div className='panel'>
          <div className='panel-header'>{literals.addApi}</div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.common.name}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeName} value={name} />
            </div>
            <div className='input_title'>
              {literals.common.description}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <textarea className='form_input_stl' onChange={handleOnChangeDescription} value={description} />
              <div className='max_length_textarea'>
                {`${description.length}/${MAX_LENGTH_DESCRIPTION}`}
              </div>
            </div>
            <div className='input_title'>
              {literals.urlBase}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeUrlBase} value={urlBase} placeholder='https://yourdomain.com/api' />
            </div>
            <div className='input_title'>
              {literals.swaggerUrl}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeSwaggerUrl} value={swaggerUrl} placeholder='https://yourdomain.com/api/api-docs' />
            </div>
          </div>
        </div>
        <div className='panel'>
          <div className='panel-header'>{literals.userAuthentication}</div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.authType}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <select className='form_select_stl' onChange={handleOnChangeAuthType} value={typeAuth}>
                {TYPES_AUTH.map((type) => (
                  <option key={type.value} value={type.value}>{type.label}</option>
                ))}
              </select>
            </div>
            {printAuthParams()}
          </div>
        </div>
        <div className='panel'>
          <div className='panel-header'>{literals.headers}</div>
          <div className='panel-body'>
            {printHeaders()}
            <div className='form_buttons'>
              <button type='button' className='btn btn-secondary btn-sm' onClick={handleAddHeader}>
                {literals.addHeader}
              </button>
            </div>
          </div>
        </div>
        <div className='panel'>
          <div className='panel-header'>{literals.sharedWith}</div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.selectOrganization}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <Select
                isMulti
                options={organizations.map((organization) => ({ value: organization.id, label: organization.name }))}
                onChange={handleSelectOrganization}
                value={organizationsValues}
              />
            </div>
          </div>
        </div>
        <div className='form_buttons'>
          <button type='button' className='btn btn-secondary' onClick={onBack}>
            {literals.common.cancel}
          </button>
          <button type='button' className='btn btn-primary' disabled={!canContinue()} onClick={saveApi}>
            {isSaving && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
            {literals.common.save}
          </button>
        </div>
      </Form>
    </>
  );
}

DataView.propTypes = {
  api: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    swagger_url: PropTypes.string,
    type_auth: PropTypes.string,
    auth_params: PropTypes.object,
    headers: PropTypes.array,
    url_base: PropTypes.string,
    organizations: PropTypes.array,
  }),
  onBack: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

DataView.defaultProps = {
  api: null,
};

export default DataView;
