import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import { executeRequest } from 'modules/apis/actions';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';

function ApiConfirmCall(props) {
  const {
    chatbotId, conversationId, messageId, data,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [hasDoneRequest, setHasDoneRequest] = useState(false);
  const { showErrorModal, printErrorModal } = useErrorHandling();

  const literals = useLiterals('chat');

  const acceptRequest = async () => {
    setIsLoading(true);
    try {
      await executeRequest(chatbotId, conversationId, messageId);
      setHasDoneRequest(true);
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const printParams = () => {
    const params = [];

    if (data.parameters) {
      data.parameters.forEach((param) => {
        if (param.name !== 'cookie') {
          params.push({
            name: param.name,
            value: param.value,
          });
        }
      });
    }

    if (params.length > 0) {
      return (
        <>
          <br />
          <br />
          <b>{`${literals.params}: `}</b>
          {params.map((param) => (
            <>
              <br />
              {`${param.name} -> ${param.value}`}
            </>
          ))}
        </>
      );
    }

    return null;
  };

  const printRequestBody = () => {
    if (typeof data.request_body !== 'undefined' && Object.keys(data.request_body).length > 0) {
      return (
        <>
          <br />
          <br />
          <b>{`${literals.requestBody}: `}</b>
          <br />
          {Object.keys(data.request_body).map((key) => {
            return (
              <>
                {`${key} -> ${data.request_body[key]}`}
                <br />
              </>
            );
          })}
        </>
      );
    }

    return null;
  };

  return (
    <>
      {printErrorModal()?.modal}
      <div className='message speaker_assistant' key={`signinapi-message-${messageId}`}>
        <div className='content'>
          {literals.apiConfirmCall}
          <br />
          <br />
          <b>{`${literals.action}: `}</b>
          {data.summary || `${data.method} ${data.endpoint}`}
          {printParams()}
          {printRequestBody()}
          {data.status.toLowerCase() === 'pending' && !hasDoneRequest ? (
            <div className='form_buttons'>
              <button type='button' className='btn btn-message-form' onClick={acceptRequest}>
                {isLoading && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
                {literals.common.accept}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

ApiConfirmCall.propTypes = {
  data: PropTypes.shape({
    summary: PropTypes.string,
    method: PropTypes.string,
    endpoint: PropTypes.string,
    parameters: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })),
    request_body: PropTypes.shape({}),
    status: PropTypes.string.isRequired,
  }).isRequired,
  chatbotId: PropTypes.string.isRequired,
  conversationId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
};

export default ApiConfirmCall;
