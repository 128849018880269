import { retryMessage } from 'modules/socket/actions';
import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

const URLS = {
  audioTranscription: 'whisper/transcription',
  sendFileToChatbot: 'chatbot/file',
  generateNameConversation: (chatbotId) => `chatbots/${chatbotId}/generateNameConversation`,
  getMessages: (chatbotId, conversationId) => `chatbots/${chatbotId}/conversations/${conversationId}/messages`,
  sendMessage: (chatbotId) => `chatbots/${chatbotId}/prompt`,
  getAgents: (chatbotId) => `${chatbotId}/zendesk/agents`,
  retryMessage: (chatbotId, conversationId, messageId) => `chatbots/${chatbotId}/conversations/${conversationId}/messages/${messageId}/retry`,
};

export default {
  async getAudioTranscription(formData) {
    return ApiFetchInstance.post(URLS.audioTranscription, formData);
  },
  async uploadFileToChatbot(formData) {
    return ApiFetchInstance.post(URLS.sendFileToChatbot, formData);
  },
  async generateNameConversation(chatbotId, message) {
    const params = { message };
    return ApiFetchInstance.post(URLS.generateNameConversation(chatbotId), params);
  },
  async getMessages(chatbotId, conversationId, offset = 0, limit = 100) {
    const url = `${URLS.getMessages(chatbotId, conversationId)}?offset=${offset}&limit=${limit}`;
    return ApiFetchInstance.get(url);
  },
  async sendMessage(chatbotId, messages, conversationId) {
    const params = { messages, conversation_id: conversationId };
    return ApiFetchInstance.post(URLS.sendMessage(chatbotId), params);
  },
  async getAgents(chatbotId) {
    return ApiFetchInstance.get(URLS.getAgents(chatbotId));
  },
  async retryMessage(chatbotId, conversationId, messageId) {
    return ApiFetchInstance.post(URLS.retryMessage(chatbotId, conversationId, messageId));
  },
};
