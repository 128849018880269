import Api from './repository';
import {
  SET_DATATABLES, CREATE_DATATABLE, UPDATE_DATATABLE, DELETE_DATATABLE,
} from '.';

/* Funciones con dispatch */
export const getDatatables = () => async (dispatch) => {
  const response = await Api.getDatatables();
  dispatch({
    type: SET_DATATABLES,
    payload: response.datatables,
  });
};

export const createDatatable = (data) => async (dispatch) => {
  const response = await Api.createDatatable(data);
  dispatch({ type: CREATE_DATATABLE, payload: response });
  return response;
};

export const updateDatatable = (datatableId, data) => async (dispatch) => {
  const response = await Api.updateDatatable(datatableId, data);
  dispatch({ type: UPDATE_DATATABLE, payload: response });
  return response;
};

export const deleteDatatable = (datatableId) => async (dispatch) => {
  const response = await Api.deleteDatatable(datatableId);
  dispatch({ type: DELETE_DATATABLE, payload: datatableId });
  return response;
};

export const getDatatableRows = async (datatableId, from, size) => {
  const response = await Api.getDatatableRows(datatableId, from, size);
  return response;
};

export const createDatatableRow = async (datatableId, data) => {
  const response = await Api.createDatatableRow(datatableId, data);
  return response;
};

export const updateDatatableRow = async (datatableId, rowId, data) => {
  const response = await Api.updateDatatableRow(datatableId, rowId, data);
  return response;
};

export const deleteDatatableRow = async (datatableId, rowId) => {
  const response = await Api.deleteDatatableRow(datatableId, rowId);
  return response;
};

export const importDatatableRows = async (datatableId, data) => {
  const response = await Api.importDatatableRows(datatableId, data);
  return response;
};
