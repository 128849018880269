import React, { useState, useEffect } from 'react';
import { doSetPassword } from 'modules/session/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getErrorMessage } from 'utils/errors';
import SetTokenForm from './components/form';
import { ROUTE_PATH } from '..';
import './styles.scss';

function Login() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const { token } = useParams();

  const { user } = useSelector((state) => state.session);

  const navigate = useNavigate();
  // const dispatch = useDispatch();

  useEffect(() => {
    if (user.id) {
      console.log('me piro', user);
      // navigate(ROUTE_PATH.CHATBOTS);
    }
  }, [user, navigate]);

  const handleOnSubmit = async () => {
    setError('');
    setIsLoading(true);
    doSetPassword(password, token)
      .then(() => {
        setIsLoading(false);
        setShowModalSuccess(true);
      })
      .catch((e) => {
        const messageError = getErrorMessage(e);
        setError(messageError);
        setShowModalError(true);
        setIsLoading(false);
      });
  };

  const clearError = () => {
    setShowModalError(false);
  };

  return (
    <SetTokenForm
      password={password}
      confirmPassword={confirmPassword}
      error={error}
      isLoading={isLoading}
      onChangePassword={setPassword}
      onChangeConfirmPassword={setConfirmPassword}
      onSubmit={handleOnSubmit}
      showModalSuccess={showModalSuccess}
      showModalError={showModalError}
      clearError={clearError}
      navigate={navigate}
    />
  );
}

export default Login;
