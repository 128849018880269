import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import useMessages from 'hooks/useMessages';
import { getErrorMessage } from 'utils/errors';
import { sendOtpInChatbotApi, verifyOtpInChatbotApi } from 'modules/apis/actions';
import { deleteMessage } from 'modules/chats/actions';

function ApiLoginOtpAuth(props) {
  const [field, setField] = useState('');
  const [code, setCode] = useState('');
  const [isValidatedField, setIsValidatedField] = useState(false);
  const [isLoading, setIsLoading] = useState('');
  const [error, setError] = useState('');

  const {
    dataAuth, chatbotId, conversationId, messageId, extraInfo,
  } = props;

  const literals = useLiterals('chat');
  const { getPreviousMessage, retryMessage } = useMessages(conversationId);

  const handleChangeField = (e) => {
    setField(e.target.value);
  };

  const handleChangeCode = (e) => {
    setCode(e.target.value);
  };

  const backToField = () => {
    if (!isLoading) {
      setError('');
      setIsValidatedField(false);
    }
  };

  const sendOtp = async () => {
    if (!isLoading) {
      setIsLoading(true);
      setError('');
      try {
        const data = { field, message_id: messageId };
        if (extraInfo?.api_chatbot) {
          data.orchestrator = extraInfo.orchestrator;
          data.api_chatbot = extraInfo.api_chatbot;
        }
        await sendOtpInChatbotApi(chatbotId, conversationId, dataAuth.id, data);
        setIsValidatedField(true);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        setError(errorMessage);
        setIsValidatedField(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const verifyOtp = async () => {
    if (!isLoading) {
      setIsLoading(true);
      setError('');
      try {
        const prevMessage = getPreviousMessage(messageId);
        const data = { field, code, message_id: messageId };
        if (extraInfo?.orchestrator && extraInfo?.api_chatbot) {
          data.orchestrator = extraInfo.orchestrator;
          data.api_chatbot = extraInfo.api_chatbot;
        }
        await verifyOtpInChatbotApi(chatbotId, conversationId, dataAuth.id, data);
        deleteMessage(conversationId, messageId);
        retryMessage(chatbotId, prevMessage.id);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        setError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const checkKeyPressSend = (e) => {
    if (field && e.key === 'Enter') {
      sendOtp();
    }
  };

  const checkKeyPressVerify = (e) => {
    if (code && e.key === 'Enter') {
      verifyOtp();
    }
  };

  return (
    <div className='login-api-form'>
      {isValidatedField ? (
        <>
          {dataAuth.auth_params.otp_verify_message || literals.signinApiOtpAuth2}
          <div className='input_value'>
            <input type='text' className='form_input_stl' onChange={handleChangeCode} value={code} placeholder={dataAuth.auth_params.otp_value_placeholder} onKeyDown={checkKeyPressVerify} />
          </div>
          <div className='form_buttons'>
            <button type='button' className='btn btn-secondary btn-xs' onClick={backToField}>
              {literals.back}
            </button>
            <button type='button' className='btn btn-message-form btn-xs' disabled={!code} onClick={verifyOtp}>
              {isLoading && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
              {literals.verifyCode}
            </button>
          </div>
        </>
      ) : (
        <>
          {dataAuth.auth_params.otp_send_message || literals.signinApiOtpAuth1}
          <div className='input_value'>
            <input type='text' className='form_input_stl' onChange={handleChangeField} value={field} placeholder={dataAuth.auth_params.otp_user_placeholder} onKeyDown={checkKeyPressSend} />
          </div>
          <div className='form_buttons'>
            <button type='button' className='btn btn-message-form btn-xs' disabled={!field} onClick={sendOtp}>
              {isLoading && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
              {literals.sendCode}
            </button>
          </div>
        </>
      )}
      {error && <div className='error-in-message'>{error}</div>}
    </div>
  );
}

ApiLoginOtpAuth.propTypes = {
  dataAuth: PropTypes.object.isRequired,
  chatbotId: PropTypes.string.isRequired,
  conversationId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
  extraInfo: PropTypes.object,
};

ApiLoginOtpAuth.defaultProps = {
  extraInfo: {},
};

export default ApiLoginOtpAuth;
