import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import Dropdown from 'react-bootstrap/Dropdown';
import Layout from 'components/Layout';
import useLiterals from 'hooks/useLiterals';
import { getDatatableRows, deleteDatatableRow, importDatatableRows } from 'modules/datatables/actions';
import ConfirmModal from 'components/ConfirmModal';
import { loadingActions } from 'modules/loading';
import useErrorHandling from 'hooks/useErrorHandling';
import DropdownMenuToggle from 'components/DropdownMenuToggle';
import { getErrorMessage } from 'utils/errors';
import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from 'constants/orchestrator';
import Paginator from 'components/Paginator';
import RowForm from './components/form';

function DatatableEntries() {
  const { id } = useParams();

  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [size, setSize] = useState(50);
  const [from, setFrom] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const { datatables } = useSelector((state) => state.datatables);
  const { datastructures } = useSelector((state) => state.datastructures);

  const fileInputRef = useRef(null);

  const datatable = datatables.find((d) => d.id === id);
  const datastructure = datatable ? datastructures.find((d) => d.id === datatable.datastructure_id) : null;

  const literals = useLiterals('datatableEntries');
  const dispatch = useDispatch();

  const { showErrorModal, printErrorModal } = useErrorHandling();

  const loadRows = async () => {
    dispatch(loadingActions.show());
    try {
      const { rows, total } = await getDatatableRows(id, from, size);
      setRecords(rows);
      setTotalRecords(total);
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    } finally {
      dispatch(loadingActions.hide());
    }
  };

  useEffect(() => {
    if (datatable) {
      loadRows();
    }
  }, [datatable, from, size]);

  const handleAddEntry = () => {
    setSelectedRow(null);
    setShowForm(true);
  };

  const handleSaveRow = () => {
    loadRows();
    setShowForm(false);
  };

  const handleCloseRowForm = () => {
    setShowForm(false);
  };

  const handleEditRow = (event) => {
    const rowId = event.target.getAttribute('data-id');
    const row = records.find((r) => r.id === rowId);
    setSelectedRow(row);
    setShowForm(true);
  };

  const handleDeleteRow = (event) => {
    const rowId = event.target.getAttribute('data-id');
    const row = records.find((r) => r.id === rowId);
    setSelectedRow(row);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleExport = async () => {
    if (isExporting) {
      return;
    }
    setIsExporting(true);
    try {
      const { rows } = await getDatatableRows(id, 0, totalRecords);

      // Extraer los valores de "values" de cada fila
      const data = rows.map((row) => row.values);

      // Obtener todas las claves únicas de los objetos "values"
      const headers = Array.from(new Set(data.flatMap(Object.keys)));

      // Formatear los datos para que sean compatibles con el formato Excel
      const formattedData = data.map((row) => {
        return headers.reduce((acc, key) => {
          if (typeof row[key] === 'object') {
            acc[key] = JSON.stringify(row[key]); // Convertir a JSON si es un objeto
          } else if (typeof row[key] === 'boolean') {
            acc[key] = row[key] ? 'true' : 'false'; // Convertir a 'true' o 'false' si es un booleano
          } else {
            acc[key] = row[key] || ''; // Rellenar con un string vacío si la clave no existe
          }
          return acc;
        }, {});
      });

      // Crear una hoja de trabajo y un libro de trabajo
      const ws = XLSX.utils.json_to_sheet(formattedData, { header: headers });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Data');

      // Generar el archivo Excel y desencadenar la descarga
      XLSX.writeFile(wb, `${datatable.name}.xlsx`);
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    } finally {
      setIsExporting(false);
    }
  };

  const handleImportEntries = () => {
    if (!isUploading) {
      fileInputRef.current.click();
    }
  };

  const handleImportFileChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    try {
      setIsUploading(true);
      await importDatatableRows(datatable.id, formData);
      await loadRows();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    } finally {
      setIsUploading(false);
    }
  };

  const confirmDeleteDatatableRow = async () => {
    if (selectedRow) {
      try {
        dispatch(loadingActions.show());
        await deleteDatatableRow(id, selectedRow.id);
        setShowDeleteModal(false);
        await loadRows();
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        showErrorModal(errorMessage);
      } finally {
        dispatch(loadingActions.hide());
      }
    }
  };

  const getFieldValue = (type, originalValue) => {
    let value = '';
    if (type.indexOf('DATASTRUCTURE') !== -1) {
      value = JSON.stringify(originalValue || {});
    } else {
      switch (type) {
        case TYPE_CONNECTIONS.BOOLEAN:
          value = originalValue ? 'true' : 'false';
          break;
        case TYPE_CONNECTIONS.NUMBER:
          value = originalValue || 0;
          break;
        case TYPE_CONNECTIONS.OBJECT:
          value = JSON.stringify(originalValue || {});
          break;
        default:
          value = originalValue || '';
          break;
      }
    }
    return value;
  };

  const printTable = () => {
    if (!datastructure) {
      return (<div className='alert alert-danger'>{literals.defineADatastructureForThisDatatable}</div>);
    }

    const rows = [];

    records.forEach((row) => {
      rows.push(
        <tr key={`row-${row.id}`}>
          {datastructure.fields.map((field) => {
            const fieldType = field.type_set || TYPE_SET_CONNECTIONS.SINGLE;
            let value = '';
            if (fieldType === TYPE_SET_CONNECTIONS.ARRAY) {
              if (field.type === TYPE_CONNECTIONS.OBJECT || field.type.indexOf('DATASTRUCTURE') !== -1) {
                value = JSON.stringify(row.values[field.name]);
              } else {
                value = [];
                if (row.values[field.name]) {
                  row.values[field.name].forEach((v) => {
                    value.push(getFieldValue(field.type, v));
                  });
                }
                value = JSON.stringify(value);
              }
            } else {
              value = getFieldValue(field.type, row.values[field.name]);
            }

            return (<td key={`field-${field.id}`}>{value}</td>);
          })}
          <td className='right'>
            <button
              type='button'
              className='btn btn-primary'
              data-id={row.id}
              onClick={handleEditRow}
            >
              {literals.common.edit}
            </button>
            <Dropdown className='dropdown-menu-expand'>
              <Dropdown.Toggle as={DropdownMenuToggle} id={`dropdown-custom-components-${datatable.id}`} />
              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey='1'
                  className='text-danger'
                  data-id={row.id}
                  onClick={handleDeleteRow}
                >
                  {literals.common.delete}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>,
      );
    });

    return (
      <div>
        <table className='table_stl1'>
          <thead>
            <tr>
              {datastructure.fields.map((field) => (
                <th key={`header-${field.id}`}>{field.name}</th>
              ))}
              <th className='right'>{literals.common.options}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const handleChangePage = (page) => {
    setFrom(page * size);
  };

  const printPaginator = () => {
    const actualPage = Math.floor(from / size);
    const filters = {
      page: actualPage,
      limit: size,
    };

    if (totalRecords <= size) {
      return null;
    }
    return <Paginator filters={filters} total={totalRecords} onPageChange={handleChangePage} />;
  };

  const printRowForm = () => {
    if (!showForm) {
      return null;
    }

    console.log('selectedRow', selectedRow);

    return (
      <RowForm
        datatableId={id}
        datastructure={datastructure}
        row={selectedRow}
        onClose={handleCloseRowForm}
        onSave={handleSaveRow}
      />
    );
  };

  const printDeleteModal = () => {
    return (
      <ConfirmModal
        open={showDeleteModal && selectedRow}
        onClose={closeDeleteModal}
        onSubmit={confirmDeleteDatatableRow}
        title={literals.confirmDeleteDatatableRow}
      />
    );
  };

  if (!datatable) {
    return (
      <Layout>
        <div className='section'>
          <div className='section_header'>
            <h1>{literals.datatableEntries}</h1>
          </div>
          <div className='mt-lg'>
            <div className='alert alert-danger'>{literals.datatableNotFound}</div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <>
      {printErrorModal()?.modal}
      {printRowForm()}
      {printDeleteModal()}
      <Layout selectedSection='datatables'>
        <div className='section'>
          <div className='section_header'>
            <h1>{datatable.name}</h1>
            <div>
              <input type='file' ref={fileInputRef} onChange={handleImportFileChange} style={{ display: 'none' }} accept='.xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel' />
              <button type='button' onClick={handleImportEntries} className='btn btn-secondary' disabled={isUploading}>
                {isUploading && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
                {literals.common.import}
              </button>
              <button type='button' className='btn btn-secondary' onClick={handleExport}>
                {isExporting && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
                {literals.common.export}
              </button>
              <button type='button' className='btn btn-primary' onClick={handleAddEntry}>{literals.addEntry}</button>
            </div>
          </div>
          <div className='mt-lg'>
            {printTable()}
            {printPaginator()}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default DatatableEntries;
