import Api from './repository';

export const getFaqs = async (chatbotId, offset = 0, limit = 100) => {
  const response = await Api.getFaqs(chatbotId, offset, limit);
  return response;
};

export const createFaq = async (chatbotId, data) => {
  const response = await Api.createFaq(chatbotId, data);
  return response;
};

export const deleteFaq = async (chatbotId, faqId) => {
  const response = await Api.deleteFaq(chatbotId, faqId);
  return response;
};

export const getFaq = async (chatbotId, faqId) => {
  const response = await Api.getFaq(chatbotId, faqId);
  return response;
};
export const updateFaq = async (chatbotId, faqId, data) => {
  const response = await Api.updateFaq(chatbotId, faqId, data);
  return response;
};

export const importFaqs = async (chatbotId, formData) => {
  const response = await Api.importFaqsInChatbot(chatbotId, formData);
  return response;
};
