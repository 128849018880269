import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const DATASTRUCTURE_CATEGORY = 'Datastructure';

export default {
  CREATE_DATASTRUCTURE_INSTANCE: {
    categories: [DATASTRUCTURE_CATEGORY],
    tag: 'createDatastructureInstance',
    name: 'Create datastructure instance',
    tags: ['create', 'datastructure', 'instance', 'add'],
    icon: 'fa-solid fa-database',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.DATASTRUCTURE,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'datastructure',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
    ],
  },
  BREAK_DATASTRUCTURE_INSTANCE: {
    categories: [DATASTRUCTURE_CATEGORY],
    tag: 'breakDatastructureInstance',
    name: 'Break datastructure instance',
    tags: ['break', 'datastructure', 'instance', 'remove'],
    icon: 'fa-solid fa-database',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.DATASTRUCTURE,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'datastructure',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
    ],
  },
};
