import { CREATE_CHATBOT } from 'modules/chatbots';
import {
  SET_ORCHESTRATORS, CREATE_ORCHESTRATOR, UPDATE_ORCHESTRATOR, DELETE_ORCHESTRATOR,
} from './index';
import Api from './repository';

export const createOrchestrator = (orchestrator) => async (dispatch) => {
  const response = await Api.createOrchestrator(orchestrator);
  dispatch({ type: CREATE_ORCHESTRATOR, payload: response });
  return response;
};

export const updateOrchestrator = (orchestratorId, orchestrator) => async (dispatch) => {
  const response = await Api.updateOrchestrator(orchestratorId, orchestrator);
  dispatch({ type: UPDATE_ORCHESTRATOR, payload: response });
  return response;
};

export const deleteOrchestrator = (orchestratorId) => async (dispatch) => {
  const response = await Api.deleteOrchestrator(orchestratorId);
  dispatch({ type: DELETE_ORCHESTRATOR, payload: orchestratorId });
  return response;
};

export const getOrchestrators = () => async (dispatch) => {
  const { orchestrators } = await Api.getOrchestrators();
  dispatch({ type: SET_ORCHESTRATORS, payload: orchestrators });
  return orchestrators;
};

export const duplicateOrchestrator = (orchestratorId) => async (dispatch) => {
  const response = await Api.duplicateOrchestrator(orchestratorId);
  dispatch({ type: CREATE_CHATBOT, payload: response.chatbot });
  dispatch({ type: CREATE_ORCHESTRATOR, payload: response.orchestrator });
  return response;
};

export const createEdge = async (orchestratorId, parentId, childId) => {
  const response = await Api.createEdge(orchestratorId, parentId, childId);
  return response;
};

export const deleteEdge = async (orchestratorId, parentId, childId, actionId) => {
  const response = await Api.deleteEdge(orchestratorId, parentId, childId, actionId);
  return response;
};

export const getOrchestratorsStatistics = async (orchestratorId, start, end) => {
  const response = await Api.getOrchestratorsStatistics(orchestratorId, start, end);
  return response;
};

export const getOrchestrator = async (orchestratorId) => {
  const response = await Api.getOrchestrator(orchestratorId);
  return response;
};

export const getOrchestratorNode = async (orchestratorId, nodeId) => {
  const response = await Api.getOrchestratorNode(orchestratorId, nodeId);
  return response;
};

export const moveConversations = async (orchestratorId, selectedOrchestratorId) => {
  const response = await Api.moveConversations(orchestratorId, selectedOrchestratorId);
  return response;
};

export const createOrchestratorNode = async (orchestratorId, node) => {
  const response = await Api.createOrchestratorNode(orchestratorId, node);
  return response;
};

export const updateOrchestratorNode = async (orchestratorId, nodeId, node) => {
  const response = await Api.updateOrchestratorNode(orchestratorId, nodeId, node);
  return response;
};

export const deleteOrchestratorNode = async (orchestratorId, nodeId) => {
  const response = await Api.deleteOrchestratorNode(orchestratorId, nodeId);
  return response;
};

export const createOrchestratorNodeAction = async (orchestratorId, nodeId, action) => {
  const response = await Api.createOrchestratorNodeAction(orchestratorId, nodeId, action);
  return response;
};

export const updateOrchestratorNodeAction = async (orchestratorId, nodeId, actionId, action) => {
  const response = await Api.updateOrchestratorNodeAction(orchestratorId, nodeId, actionId, action);
  return response;
};

export const deleteOrchestratorNodeAction = async (orchestratorId, nodeId, actionId) => {
  const response = await Api.deleteOrchestratorNodeAction(orchestratorId, nodeId, actionId);
  return response;
};

export const getAllNodeActions = async (orchestratorId) => {
  const response = await Api.getAllNodeActions(orchestratorId);
  return response;
};

export const debugOrchestrator = async (orchestratorId, processId, inputs, versionId = null) => {
  const response = await Api.debugOrchestrator(orchestratorId, processId, inputs, versionId);
  return response;
};

export const exportOrchestrator = async (orchestratorId) => {
  const response = await Api.exportOrchestrator(orchestratorId);
  return response;
};

export const importOrchestrator = (formData) => async (dispatch) => {
  const response = await Api.importOrchestrator(formData);
  dispatch({ type: CREATE_CHATBOT, payload: response.chatbot });
  dispatch({ type: CREATE_ORCHESTRATOR, payload: response.orchestrator });
  return response;
};

export const createOrchestratorVersion = async (orchestratorId, data) => {
  const response = await Api.createOrchestratorVersion(orchestratorId, data);
  return response;
};

export const updateOrchestratorVersion = async (orchestratorId, versionId, data) => {
  const response = await Api.updateOrchestratorVersion(orchestratorId, versionId, data);
  return response;
};

export const deleteOrchestratorVersion = async (orchestratorId, versionId) => {
  const response = await Api.deleteOrchestratorVersion(orchestratorId, versionId);
  return response;
};

export const createOrchestratorFunction = async (orchestratorId, data) => {
  const response = await Api.createOrchestratorFunction(orchestratorId, data);
  return response;
};

export const updateOrchestratorFunction = async (orchestratorId, functionId, data) => {
  const response = await Api.updateOrchestratorFunction(orchestratorId, functionId, data);
  return response;
};

export const deleteOrchestratorFunction = async (orchestratorId, functionId) => {
  const response = await Api.deleteOrchestratorFunction(orchestratorId, functionId);
  return response;
};
