import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import useLiterals from 'hooks/useLiterals';
import ConfirmModal from 'components/ConfirmModal';
import Button from 'react-bootstrap/Button';
import Chat from 'components/Chat';
import { deleteConversation, deleteConversations } from 'modules/conversations/actions';
import RenameForm from './renameForm';

function Conversations(props) {
  const {
    conversations, chatbotId, onCreateConversation,
    onUpdateConversation, onDeleteConversation, onDeleteAllConversations,
  } = props;

  const [selectedConversation, setSelectedConversation] = useState(null);
  const [conversationToRename, setConversationToRename] = useState(null);
  const [conversationToDelete, setConversationToDelete] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Puedes ajustar esta cantidad según tus necesidades

  const chatbot = useSelector(({ chatbots }) => chatbots.chatbots).find((auxChatbot) => auxChatbot.id === chatbotId);

  const literals = useLiterals('chatbot');
  const commonliterals = useLiterals('common');

  const handleClickConversation = (event) => {
    if (event.target.tagName !== 'BUTTON' && event.target.tagName !== 'I' && event.target.tagName !== 'A') {
      const id = event.currentTarget.getAttribute('data-id');
      setSelectedConversation(id);
    }
  };

  const handleRenameConversation = (event) => {
    const id = event.currentTarget.getAttribute('data-id');
    setConversationToRename(id);
  };

  const handleDeleteAllConversations = () => {
    setDeleteAll(true);
    setShowConfirmation(true);
  };

  const handleDeleteConversation = (event) => {
    const id = event.currentTarget.getAttribute('data-id');
    setConversationToDelete(id);
    setShowConfirmation(true);
  };

  const handlePositiveConfirmation = async () => {
    if (deleteAll) {
      // Eliminar todas las conversaciones
      await deleteConversations(chatbotId);
      onDeleteAllConversations();
      setSelectedConversation(null);
    } else if (conversationToDelete) {
      // Eliminar una conversación específica
      await deleteConversation(chatbotId, conversationToDelete);
      onDeleteConversation(conversationToDelete);
      if (selectedConversation === conversationToDelete) {
        setSelectedConversation(null);
      }
    }
    setDeleteAll(false);
    setConversationToDelete(null);
    setShowConfirmation(false);
  };

  const handleNegativeConfirmation = () => {
    setDeleteAll(false);
    setConversationToDelete(null);
    setShowConfirmation(false);
  };

  const printConversations = () => {
    if (!conversations) {
      return null;
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentConversations = conversations.slice(indexOfFirstItem, indexOfLastItem);

    return currentConversations.map((conversation) => (
      <div
        key={conversation.id}
        data-id={conversation.id}
        className={`conversation ${conversation.id === selectedConversation ? 'selected' : ''}`}
        onClick={handleClickConversation}
        data-cy='chatbot-conversations-item'
      >
        <div className='conversation_name'>{conversation.name || literals.newChat}</div>
        <div className='menu-opener'>
          <DropdownButton
            align={{ lg: 'end' }}
            title={(<i className='fa-solid fa-ellipsis-vertical' />)}
          >
            <Dropdown.Item eventKey='1' data-id={conversation.id} onClick={handleRenameConversation}>{literals.editName}</Dropdown.Item>
            <Dropdown.Item eventKey='2' data-id={conversation.id} onClick={handleDeleteConversation}>{literals.delete}</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
    ));
  };

  const handleCreateConversation = (newConversation) => {
    onCreateConversation(newConversation);
    setSelectedConversation(newConversation.id);
  };

  const handleUpdateConversation = (updatedConversation) => {
    onUpdateConversation(updatedConversation);
  };

  const handleNewConversation = () => {
    setSelectedConversation(null);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPaginator = () => {
    const totalPages = Math.ceil(conversations.length / itemsPerPage);

    const getPageNumbers = () => {
      const maxPagesToShow = 3;
      const pageNumbers = [];

      if (totalPages <= maxPagesToShow) {
        for (let i = 1; i <= totalPages; i += 1) {
          pageNumbers.push(i);
        }
      } else {
        const currentPageValue = currentPage;
        const totalPagesToShow = maxPagesToShow;

        let startPage = Math.max(1, currentPageValue - Math.floor(totalPagesToShow / 2));
        const endPage = Math.min(totalPages, startPage + totalPagesToShow - 1);

        // Si la página actual está cerca del inicio, ajusta el rango final
        if (startPage <= 2) {
          startPage = 1;
        }

        // Si la página actual está cerca del final, ajusta el rango inicial
        if (endPage >= totalPages - 1) {
          startPage = Math.max(1, totalPages - totalPagesToShow + 1);
        }

        if (startPage > 1) {
          pageNumbers.push(1);
          if (startPage > 2) {
            pageNumbers.push('...');
          }
        }

        for (let i = startPage; i <= endPage; i += 1) {
          pageNumbers.push(i);
        }

        if (endPage < totalPages) {
          if (endPage < totalPages - 1) {
            pageNumbers.push('...');
          }
          pageNumbers.push(totalPages);
        }
      }

      return pageNumbers;
    };

    const pageNumbers = getPageNumbers();

    return (
      <div className='pagination'>
        {pageNumbers.map((pageNumber, index) => (
          <React.Fragment key={index}>
            {pageNumber === '...' ? (
              <span>...</span>
            ) : (
              <button
                key={pageNumber}
                onClick={() => setCurrentPage(pageNumber)}
                className={pageNumber === currentPage ? 'active' : ''}
                type='button'
              >
                {pageNumber}
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  const printRenameForm = () => {
    if (!conversationToRename) {
      return null;
    }

    const conversation = conversations.find((auxConversation) => auxConversation.id === conversationToRename);
    if (!conversation) {
      return null;
    }

    return (
      <RenameForm
        name={conversation.name}
        chatbotId={chatbotId}
        conversationId={conversation.id}
        onClose={() => setConversationToRename(null)}
        onSubmit={(name) => {
          setConversationToRename(null);
          const updatedConversation = { ...conversation, name };
          onUpdateConversation(updatedConversation);
        }}
      />
    );
  };

  const printDeleteForm = () => {
    if (!deleteAll && !conversationToDelete) {
      return null;
    }

    const conversation = conversations.find((auxConversation) => auxConversation.id === conversationToDelete);
    if (!conversation && !deleteAll) {
      return null;
    }

    return (
      <div>
        {showConfirmation && (
          <ConfirmModal
            open={showConfirmation}
            onClose={handleNegativeConfirmation}
            onSubmit={handlePositiveConfirmation}
            message={deleteAll ? literals.deleteAllConversationsConfirmation : literals.deleteConversationConfirmation}
            title={deleteAll ? literals.deleteAllConversations : literals.deleteConversation}
            confirmText={deleteAll ? literals.deleteAll : literals.delete}
            cancelText={commonliterals.cancel}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {printRenameForm()}
      {printDeleteForm()}
      <div className='conversations-wrapper'>
        <div className='conversations-chatbot'>
          <Chat
            chatbot={chatbot}
            conversationId={selectedConversation}
            onCreateConversation={handleCreateConversation}
            onUpdateConversation={handleUpdateConversation}
            onDeleteConversation={handleDeleteConversation}
          />
        </div>
        <div className='conversations-list'>
          <div className='buttons-container' style={{ display: 'flex', alignItems: 'center' }}>
            <Button variant='secondary' className='btn-sm' onClick={handleNewConversation}>
              {literals.newConversation}
            </Button>
            <Button variant='secondary' className='btn btn-danger btn-sm' onClick={handleDeleteAllConversations}>
              {literals.deleteAll}
            </Button>
          </div>
          {printConversations()}
          {renderPaginator()}
        </div>
      </div>
    </>
  );
}

Conversations.propTypes = {
  chatbotId: PropTypes.string.isRequired,
  conversations: PropTypes.array.isRequired,
  onCreateConversation: PropTypes.func.isRequired,
  onUpdateConversation: PropTypes.func.isRequired,
  onDeleteConversation: PropTypes.func.isRequired,
  onDeleteAllConversations: PropTypes.func.isRequired,
};

export default Conversations;
