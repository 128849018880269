import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout';
import useLiterals from 'hooks/useLiterals';
import { useDispatch, useSelector } from 'react-redux';
import { getAPIs, clearApiEndpoints } from 'modules/apis/actions';
import AddApi from 'components/AddApi';
import { VIEW_DATA, VIEW_ENDPOINTS } from 'constants/chatbotsApis';

function Apis() {
  const [showApiView, setShowApiView] = useState(false);
  const [selectedApi, setSelectedApi] = useState(null);
  const [actualViewAddApi, setActualViewAddApi] = useState(VIEW_DATA);

  const literals = useLiterals('addApi');
  const { apis } = useSelector((state) => state.apis);
  const { actualOrganization } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearApiEndpoints());
  }, []);

  const connectApi = () => {
    setShowApiView(true);
    setSelectedApi(null);
  };

  const backToList = () => {
    setShowApiView(false);
    setSelectedApi(null);
  };

  const editApi = (e) => {
    const id = e.currentTarget.getAttribute('data-id');
    const api = apis.find((a) => a.id === id);
    if (api) {
      setSelectedApi(api);
      setShowApiView(true);
    } else {
      console.error('API not found');
    }
  };

  useEffect(() => {
    dispatch(getAPIs());
  }, []);

  const getFilteredApis = () => {
    if (!apis) {
      return [];
    }

    if (!actualOrganization) {
      return apis;
    }

    if (actualOrganization.id === 'personal') {
      return apis.filter((api) => !api.organizations || !api.organizations.length);
    }

    return apis.filter((api) => api.organizations && api.organizations.includes(actualOrganization.id));
  };

  const printTable = () => {
    const filteredApis = getFilteredApis();
    const rows = filteredApis.map((api) => {
      if (!api) {
        return null;
      }
      return (
        <tr key={api.id} data-id={api.id}>
          <td>{api.name}</td>
          <td>{api.description}</td>
          <td className='right'>
            <div className='buttons-container' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                data-id={api.id}
                className='btn btn-primary btn-sm'
                type='button'
                onClick={editApi}
              >
                {literals.common.access}
              </button>
            </div>
          </td>
        </tr>
      );
    });
    return (
      <div>
        <table className='table_stl1'>
          <thead>
            <tr>
              <th>{literals.common.name}</th>
              <th>{literals.common.description}</th>
              <th className='right'>{literals.common.options}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const printNoElements = () => {
    return (
      <div className='page_content'>
        <div className='no_elements'>{literals.thereIsNoAPIs}</div>
      </div>
    );
  };

  const printActualView = () => {
    if (showApiView) {
      return (
        <AddApi
          api={selectedApi}
          onBack={backToList}
          defaultView={actualViewAddApi}
        />
      );
    }
    return getFilteredApis().length ? printTable() : printNoElements();
  };

  return (
    <Layout selectedSection='apis'>
      <div className='section'>
        <div className='section_header'>
          <h1>{literals.apis}</h1>
          {!showApiView && (
            <div>
              <div className='btn btn-primary' onClick={connectApi}>
                {literals.connectApi}
              </div>
            </div>
          )}
        </div>
        <div className='mt-lg'>
          {printActualView()}
        </div>
      </div>
    </Layout>
  );
}

export default Apis;
