import React from 'react';
import PropTypes from 'prop-types';
import { doLogout } from 'modules/session/actions';
import { ROUTE_PATH } from 'routes';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import useLiterals from 'hooks/useLiterals';
import { changeActualOrganization } from 'modules/app/actions';
import LateralMenu from './components/LateralMenu';
import './styles.scss';

function Layout(props) {
  const { children, selectedSection } = props;

  const dispatch = useDispatch();
  const literals = useLiterals('layout');

  const { user } = useSelector((state) => state.session);
  const { organizations } = useSelector((state) => state.organizations);
  const { actualOrganization } = useSelector((state) => state.app);

  const handleChangeOrganization = (e) => {
    const organizationId = e.currentTarget.getAttribute('data-id');
    if (!organizationId) {
      dispatch(changeActualOrganization(null, true));
    } else if (organizationId === 'personal') {
      dispatch(changeActualOrganization({ id: 'personal' }, true));
    } else {
      const organization = organizations.find((org) => org.id === organizationId);
      if (organization) {
        dispatch(changeActualOrganization(organization, true));
      }
    }
  };

  const logout = async () => {
    await dispatch(doLogout());
    window.location.href = ROUTE_PATH.LOGIN;
  };

  const printDropdownMenu = () => {
    const personalData = (
      <div>
        <div><b>{`${user.firstname} ${user.lastname}`}</b></div>
        <div>{user.email}</div>
      </div>
    );

    let organizationData = null;
    if (organizations && organizations.length) {
      organizationData = organizations.map((organization) => {
        return (
          <Dropdown.Item onClick={handleChangeOrganization} data-id={organization.id} key={organization.id} className={actualOrganization && actualOrganization.id === organization.id ? 'selected' : ''}>{organization.name}</Dropdown.Item>
        );
      });

      organizationData.unshift(<Dropdown.Item onClick={handleChangeOrganization} data-id='personal' key='personal' className={actualOrganization && actualOrganization.id === 'personal' ? 'selected' : ''}>{literals.personal}</Dropdown.Item>);
      organizationData.push(<Dropdown.Item onClick={handleChangeOrganization} data-id='' key='all' className={!actualOrganization ? 'selected' : ''}>{literals.allMyWorkspace}</Dropdown.Item>);
      organizationData.push(<Dropdown.Divider key='divider' />);
    }

    const logoutData = (
      <Dropdown.Item href='#/action-1' onClick={logout}>{literals.logout}</Dropdown.Item>
    );

    return (
      <>
        {personalData}
        <Dropdown.Divider />
        {organizationData}
        {logoutData}
      </>
    );
  };

  let displayName = literals.allMyWorkspace;
  if (actualOrganization) {
    displayName = actualOrganization.id === 'personal' ? literals.personal : actualOrganization.name;
  }

  return (
    <>
      <div className='layout'>
        <div className='blur-layer' />
        <LateralMenu selectedSection={selectedSection} />
        <div className='page_content'>{children}</div>
      </div>
      <div className='bottom_options_menu'>
        <Dropdown>
          <Dropdown.Toggle variant='success' id='dropdown-basic'>
            <i className='fa-solid fa-right-from-bracket' />
            {` ${displayName}`}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {printDropdownMenu()}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  selectedSection: PropTypes.string,
};

Layout.defaultProps = {
  selectedSection: '',
};

export default Layout;
