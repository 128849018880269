import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

const URLS = {
  getWebsiteLinks: 'websiteLinks',
  getTrainedWebsiteLinks: (chatbotId) => `chatbots/${chatbotId}/websites`,
  deleteWebsite: (chatbotId, websiteId) => `chatbots/${chatbotId}/websites/${websiteId}`,
};

export default {
  async getWebsiteLinks(website) {
    const url = `${URLS.getWebsiteLinks}?url=${website}`;
    return ApiFetchInstance.get(url);
  },
  async getTrainedWebsiteLinks(chatbotId) {
    const url = URLS.getTrainedWebsiteLinks(chatbotId);
    return ApiFetchInstance.get(url);
  },
  async trainWebsite(chatbotId, url) {
    return ApiFetchInstance.post(URLS.getTrainedWebsiteLinks(chatbotId), { url });
  },
  async deleteWebsite(chatbotId, websiteId) {
    const url = `${URLS.deleteWebsite(chatbotId, websiteId)}`;
    return ApiFetchInstance.delete(url);
  },
};
