import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

const URLS = {
  getChatbots: 'chatbots',
  createChatbot: 'chatbots',
  updateChatbot: (chatbotId) => `chatbots/${chatbotId}`,
  getIntegrations: (chatbotId) => `chatbots/${chatbotId}/integrations`,
  updateIntegrationsZendesk: (chatbotId) => `chatbots/${chatbotId}/integrations/zendesk`,
  updateIntegrationsCrisp: (chatbotId) => `chatbots/${chatbotId}/integrations/crisp`,
  updateIntegrationsWhatsapp: (chatbotId) => `chatbots/${chatbotId}/integrations/whatsapp`,
  updateIntegrationsTwilio: (chatbotId) => `chatbots/${chatbotId}/integrations/twilio`,
  updateEmailIntegrations: (chatbotId) => `chatbots/${chatbotId}/integrations/email`,
  testEmailIntegration: (chatbotId) => `chatbots/${chatbotId}/integrations/email/test`,
  getTickets: 'zendesk/tickets',
  responseTickets: 'zendesk/tickets/response',
  deleteChatbot: (chatbotId) => `chatbots/${chatbotId}`,
  deleteChatbots: 'chatbots',
  getPublicChatbot: (chatbotId) => `public/chatbots/${chatbotId}`,
};

export default {
  async getChatbots(elementId, offset = 0, limit = 100) {
    let url = `${URLS.getChatbots}?offset=${offset}&limit=${limit}`;
    if (elementId) {
      url += `&element_id=${elementId}`;
    }
    return ApiFetchInstance.get(url);
  },
  async createChatbot(name, organizationsIds = [], orchestratorId = null) {
    const body = {
      name,
      tools: [],
      organizations: organizationsIds,
      is_from_orchestrator: orchestratorId,
    };
    return ApiFetchInstance.post(URLS.createChatbot, body);
  },
  async updateChatbot(chatbotId, name, tools = [], model = null, initialMessage = null, systemMessage = null, canEmbed = null, enableDebug = null, inputPlaceholder = null, organizations = [], enableImages = null, temperature = null, avatar = null, removeAvatar = null, llmAgentName = null, showDisclaimer = null, disclaimer = null, extraInfo = null, iconBubble = false, removeIconBubble = false, openaiKey = null, conversationOrchestrator = null, documentsOrchestrator = null, emailsOrchestrator = null, searchInDocuments = null, learningContext = null) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('tools', JSON.stringify(tools));
    formData.append('model_id', model);

    if (initialMessage !== null) {
      formData.append('initial_message', initialMessage === '' ? '_empty_' : initialMessage);
    }

    if (systemMessage !== null) {
      formData.append('system_message', systemMessage === '' ? '_empty_' : systemMessage);
    }

    if (canEmbed !== null) {
      formData.append('can_embed', canEmbed);
    }

    if (enableDebug !== null) {
      formData.append('enable_debug', enableDebug);
    }

    if (inputPlaceholder !== null) {
      formData.append('input_placeholder', inputPlaceholder === '' ? '_empty_' : inputPlaceholder);
    }

    if (organizations !== null) {
      formData.append('organizations', JSON.stringify(organizations));
    }

    if (enableImages !== null) {
      formData.append('enable_images', enableImages);
    }

    if (temperature !== null) {
      formData.append('temperature', temperature);
    }

    if (avatar !== null) {
      formData.append('avatar', avatar);
    }

    if (removeAvatar !== null) {
      formData.append('remove_avatar', removeAvatar);
    }

    if (llmAgentName !== null) {
      formData.append('llm_agent_name', llmAgentName === '' ? '_empty_' : llmAgentName);
    }

    if (showDisclaimer !== null) {
      formData.append('show_disclaimer', showDisclaimer);
    }

    if (disclaimer !== null) {
      formData.append('disclaimer', disclaimer === '' ? '_empty_' : disclaimer);
    }

    if (extraInfo !== null) {
      formData.append('extra_info', JSON.stringify(extraInfo));
    }

    if (iconBubble) {
      formData.append('icon_bubble', iconBubble);
    }

    if (removeIconBubble) {
      formData.append('remove_icon_bubble', removeIconBubble);
    }

    if (openaiKey !== null) {
      formData.append('openai_key', openaiKey === '' ? '_empty_' : openaiKey);
    }

    if (conversationOrchestrator !== null) {
      formData.append('conversation_orchestrator', conversationOrchestrator);
    }

    if (documentsOrchestrator !== null) {
      formData.append('documents_orchestrator', documentsOrchestrator);
    }

    if (emailsOrchestrator !== null) {
      formData.append('emails_orchestrator', emailsOrchestrator);
    }

    if (searchInDocuments !== null) {
      formData.append('search_in_documents', searchInDocuments);
    }

    if (learningContext !== null) {
      formData.append('learning_context', learningContext === '' ? '_empty_' : learningContext);
    }

    return ApiFetchInstance.patch(URLS.updateChatbot(chatbotId), formData);
  },

  async deleteChatbots() {
    return ApiFetchInstance.delete(URLS.deleteChatbots);
  },

  async deleteChatbot(chatbotId) {
    return ApiFetchInstance.delete(URLS.deleteChatbot(chatbotId));
  },

  async getIntegrations(chatbotId) {
    return ApiFetchInstance.get(URLS.getIntegrations(chatbotId));
  },

  async updateIntegrations(chatbotId, zendeskMail, zendeskToken, zendeskHost, zendeskDomain, zendeskSupport, zendeskSupportGroup, zendeskSchedule, crispEmail, crispToken, crispURL, whatsappNumber, whatsappNumberId, whatsappToken, whatsappVerifyToken, twilioID, twilioToken, twilioPhone, isChangedZendesk, isChangedCrisp, isChangedWhatsapp, isChangedTwilio, emailIntegrations) {
    const promises = [];
    if (isChangedZendesk !== null && isChangedZendesk) {
      const bodyZendesk = {
        chatbot_id: chatbotId,
        zendesk_mail: zendeskMail,
        zendesk_token: zendeskToken,
        zendesk_host: zendeskHost,
        zendesk_domain: zendeskDomain,
        support: zendeskSupport,
        support_group: zendeskSupportGroup,
        zendesk_schedule: zendeskSchedule,
      };
      promises.push(ApiFetchInstance.patch(URLS.updateIntegrationsZendesk(chatbotId), bodyZendesk));
    }
    if (isChangedCrisp !== null && isChangedCrisp) {
      const bodyCrisp = {
        chatbot_id: chatbotId,
        crisp_mail: crispEmail,
        crisp_token: crispToken,
        crisp_URL: crispURL,
      };
      promises.push(ApiFetchInstance.patch(URLS.updateIntegrationsCrisp(chatbotId), bodyCrisp));
    }
    if (isChangedWhatsapp !== null && isChangedWhatsapp) {
      const bodyWhatsapp = {
        chatbot_id: chatbotId,
        whatsapp_number: whatsappNumber,
        whatsapp_number_id: whatsappNumberId,
        whatsapp_token: whatsappToken,
        whatsapp_verify_token: whatsappVerifyToken,
      };
      promises.push(ApiFetchInstance.patch(URLS.updateIntegrationsWhatsapp(chatbotId), bodyWhatsapp));
    }
    if (isChangedTwilio !== null && isChangedTwilio) {
      const bodyTwilio = {
        chatbot_id: chatbotId,
        twilio_sid: twilioID,
        twilio_token: twilioToken,
        twilio_phone: twilioPhone,
      };
      promises.push(ApiFetchInstance.patch(URLS.updateIntegrationsTwilio(chatbotId), bodyTwilio));
    }

    const bodyEmailIntegrations = {
      email_integrations: emailIntegrations,
    };
    promises.push(ApiFetchInstance.patch(URLS.updateEmailIntegrations(chatbotId), bodyEmailIntegrations));

    const responses = await Promise.all(promises);
    return { responses };
  },

  async getPublicChatbot(chatbotId) {
    return ApiFetchInstance.get(URLS.getPublicChatbot(chatbotId));
  },

  async getTickets(zendeskMail, zendeskToken, zendeskHost, zendeskDomain) {
    const body = {
      zendesk_mail: zendeskMail,
      zendesk_token: zendeskToken,
      zendesk_host: zendeskHost,
      zendesk_domain: zendeskDomain,
    };
    return ApiFetchInstance.patch(URLS.getTickets, body);
  },

  async responseTicket(zendeskMail, zendeskToken, zendeskHost, zendeskDomain, ticketId, zendeskComment, zendeskSubject) {
    const body = {
      zendesk_mail: zendeskMail,
      zendesk_token: zendeskToken,
      zendesk_host: zendeskHost,
      zendesk_domain: zendeskDomain,
      ticket_id: ticketId,
      comment: zendeskComment,
      subject: zendeskSubject,
    };
    return ApiFetchInstance.patch(URLS.responseTickets, body);
  },

  async testEmailIntegration(chatbotId, type, username, password, host, port, useSSL) {
    const body = {
      type,
      username,
      password,
      host,
      port,
      use_ssl: useSSL,
    };
    return ApiFetchInstance.post(URLS.testEmailIntegration(chatbotId), body);
  },
};
