import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

const URLS = {
  getForms: 'forms',
  createForm: 'form',
  updateForm: (formId) => `form/${formId}`,
  deleteForm: (formId) => `form/${formId}`,
  fillForm: (chatbotId, conversationId, messageId) => `chatbots/${chatbotId}/conversations/${conversationId}/messages/${messageId}/form`,
  getFormByMessageId: (messageId) => `forms/message/${messageId}`,
};

export default {
  async getForms(offset = 0, limit = 1000) {
    const url = `${URLS.getForms}?offset=${offset}&limit=${limit}`;
    return ApiFetchInstance.get(url);
  },
  async createForm(data = null) {
    return ApiFetchInstance.post(URLS.createForm, data);
  },
  async updateForm(formId, data = null) {
    return ApiFetchInstance.put(URLS.updateForm(formId), data);
  },
  async deleteForm(formId) {
    return ApiFetchInstance.delete(URLS.deleteForm(formId));
  },
  async fillForm(chatbotId, conversationId, messageId, values) {
    return ApiFetchInstance.post(URLS.fillForm(chatbotId, conversationId, messageId), { values });
  },
  async getFormByMessageId(messageId) {
    return ApiFetchInstance.get(URLS.getFormByMessageId(messageId));
  },
};
