export const NODE_TYPES = {
  INITIAL: 'INITIAL',
  EXECUTE_CHATBOT: 'EXECUTE_CHATBOT',
  SWITCH: 'SWITCH',
  SEND_MESSAGE: 'SEND_MESSAGE',
  SET: 'SET',
  CONDITION: 'CONDITION',
  PRINT_FORM: 'PRINT_FORM',
  CONNECT_API: 'CONNECT_API',
  PROCESS_JSON: 'PROCESS_JSON',
  CODE: 'CODE',
  CHANGE_CONVERSATION_AGENT: 'CHANGE_CONVERSATION_AGENT',
  ADD_ZENDESK_TAG: 'ADD_ZENDESK_TAG',
  REMOVE_ZENDESK_TAG: 'REMOVE_ZENDESK_TAG',
  SEND_ZENDESK_MESSAGE: 'SEND_ZENDESK_MESSAGE',
  MAKE_OBJECT: 'MAKE_OBJECT',
  IS_EMPTY: 'IS_EMPTY',
  EXTRACT_MESSAGE_DATA: 'EXTRACT_MESSAGE_DATA',
  EXTRACT_CONVERSATION_DATA: 'EXTRACT_CONVERSATION_DATA',
  BOOLEAN_OR: 'BOOLEAN_OR',
  BOOLEAN_AND: 'BOOLEAN_AND',
  STRING_EQUALS: 'STRING_EQUALS',
  STRING_CONCAT: 'STRING_CONCAT',
  STRING_INDEXOF: 'STRING_INDEXOF',
  SLEEP: 'SLEEP',
  FIND_CHATBOT: 'FIND_CHATBOT',
  SEND_PROMPT: 'SEND_PROMPT',
  TO_STRING: 'TO_STRING',
  NUMBER_EQUALS: 'NUMBER_EQUALS',
  NUMBER_LOWER_THAN: 'NUMBER_LOWER_THAN',
  NUMBER_LOWER_OR_EQUAL_THAN: 'NUMBER_LOWER_OR_EQUAL_THAN',
  NUMBER_GREATER_THAN: 'NUMBER_GREATER_THAN',
  NUMBER_GREATER_OR_EQUAL_THAN: 'NUMBER_GREATER_OR_EQUAL_THAN',
  SEQUENCE: 'SEQUENCE',
  TWILIO_COMES_FROM_TWILIO: 'TWILIO_COMES_FROM_TWILIO',
  TWILIO_EXTRACT_ORIGIN: 'TWILIO_EXTRACT_ORIGIN',
  TWILIO_EXTRACT_PHONE_NUMBER: 'TWILIO_EXTRACT_PHONE_NUMBER',
  TO_OBJECT: 'TO_OBJECT',
  RETURN_NODE: 'RETURN_NODE',
  MERGE_OBJECTS: 'MERGE_OBJECTS',
  PROCESS_MERGE_TAGS: 'PROCESS_MERGE_TAGS',
  EXTRACT_DOCUMENT_CONTENT: 'EXTRACT_DOCUMENT_CONTENT',
  OBTAIN_NUMBER_TOKENS: 'OBTAIN_NUMBER_TOKENS',
  SAVE_AS_EMBEDDING: 'SAVE_AS_EMBEDDING',
  ASYNC_SEQUENCE: 'ASYNC_SEQUENCE',
  NUMBER_ADD: 'NUMBER_ADD',
  NUMBER_SUBTRACT: 'NUMBER_SUBTRACT',
  NUMBER_MULTIPLY: 'NUMBER_MULTIPLY',
  NUMBER_DIVIDE: 'NUMBER_DIVIDE',
  NUMBER_MODULO: 'NUMBER_MODULO',
  EXECUTE_ORCHESTRATOR: 'EXECUTE_ORCHESTRATOR',
  ARRAY_CLEAR: 'ARRAY_CLEAR',
  ARRAY_APPEND: 'ARRAY_APPEND',
  ARRAY_TO_STRING: 'ARRAY_TO_STRING',
  ARRAY_LENGTH: 'ARRAY_LENGTH',
  ARRAY_MERGE: 'ARRAY_MERGE',
  ARRAY_INDEX_OF: 'ARRAY_INDEX_OF',
  ARRAY_INSERT: 'ARRAY_INSERT',
  ARRAY_POP: 'ARRAY_POP',
  ARRAY_REMOVE: 'ARRAY_REMOVE',
  ARRAY_REVERSE: 'ARRAY_REVERSE',
  ARRAY_SORT: 'ARRAY_SORT',
  SEARCH_DOCUMENTS: 'SEARCH_DOCUMENTS',
  SPLIT_TEXT_BY_CHUNK_SIZE: 'SPLIT_TEXT_BY_CHUNK_SIZE',
  FOREACH_LOOP: 'FOREACH_LOOP',
  CREATE_DATASTRUCTURE_INSTANCE: 'CREATE_DATASTRUCTURE_INSTANCE',
  BREAK_DATASTRUCTURE_INSTANCE: 'BREAK_DATASTRUCTURE_INSTANCE',
  STRING_TO_UPPER_CASE: 'STRING_TO_UPPER_CASE',
  STRING_TO_LOWER_CASE: 'STRING_TO_LOWER_CASE',
  EDIT_ZENDESK_REQUESTER_DATA: 'EDIT_ZENDESK_REQUESTER_DATA',
  SEARCH_DATATABLE_ROWS: 'SEARCH_DATATABLE_ROWS',
  SEND_HTML: 'SEND_HTML',
  ADD_DATATABLE_ROW: 'ADD_DATATABLE_ROW',
  EXTRACT_EMAIL_DATA: 'EXTRACT_EMAIL_DATA',
  CREATE_ZENDESK_TICKET: 'CREATE_ZENDESK_TICKET',
  TWILIO_SEND_TEMPLATE: 'TWILIO_SEND_TEMPLATE',
  UPDATE_CONVERSATION: 'UPDATE_CONVERSATION',
  HAS_ZENDESK_TAG: 'HAS_ZENDESK_TAG',
  FETCH_URL: 'FETCH_URL',
  ARRAY_GET_ELEMENT: 'ARRAY_GET_ELEMENT',
  STRING_REPLACE: 'STRING_REPLACE',
  STRING_SEARCH: 'STRING_SEARCH',
  SEARCH_CONVERSATIONS: 'SEARCH_CONVERSATIONS',
  CREATE_FAQ: 'CREATE_FAQ',
  UPDATE_FAQ: 'UPDATE_FAQ',
  RETRIEVE_CONVERSATION_MESSAGES: 'RETRIEVE_CONVERSATION_MESSAGES',
  UPDATE_DATATABLE_ROW: 'UPDATE_DATATABLE_ROW',
  FUNCTION: 'FUNCTION',
  SEARCH_IN_GOOGLE: 'SEARCH_IN_GOOGLE',
  SEARCH_IN_YOUTUBE: 'SEARCH_IN_YOUTUBE',
  GITLAB_REPOSITORY_TREE: 'GITLAB_REPOSITORY_TREE',
  GITLAB_GET_FILE_CONTENT: 'GITLAB_GET_FILE_CONTENT',
};

export const TYPE_CONNECTIONS = {
  EXEC: 'EXEC',
  ANY: 'ANY',
  BOOLEAN: 'BOOLEAN',
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  CHATBOT: 'CHATBOT',
  CONVERSATION: 'CONVERSATION',
  MESSAGE: 'MESSAGE',
  FORM: 'FORM',
  API: 'API',
  ENDPOINT: 'ENDPOINT',
  OBJECT: 'OBJECT',
  LLM_MODEL: 'LLM_MODEL',
  DOCUMENT: 'DOCUMENT',
  ORCHESTRATOR: 'ORCHESTRATOR',
  DATASTRUCTURE: 'DATASTRUCTURE',
  DATATABLE: 'DATATABLE',
  EMAIL: 'EMAIL',
  FAQ: 'FAQ',
  ORGANIZATION: 'ORGANIZATION',
};

export const BASIC_VARIABLES = [TYPE_CONNECTIONS.STRING, TYPE_CONNECTIONS.NUMBER, TYPE_CONNECTIONS.BOOLEAN, TYPE_CONNECTIONS.OBJECT];

export const TYPE_SET_CONNECTIONS = {
  SINGLE: 'SINGLE',
  ARRAY: 'ARRAY',
};

export const ORCHESTRATOR_TYPE_CONVERSATION = 'CONVERSATION';
export const ORCHESTRATOR_TYPE_DOCUMENT_PROCESSOR = 'DOCUMENT_PROCESSOR';
export const ORCHESTRATOR_TYPE_EMAIL_PROCESSOR = 'EMAIL_PROCESSOR';
export const ORCHESTRATOR_TYPE_AGENT = 'AGENT';

export const ORCHESTRATOR_TYPES = [
  {
    value: ORCHESTRATOR_TYPE_CONVERSATION,
    label: 'conversational',
  },
  {
    value: ORCHESTRATOR_TYPE_DOCUMENT_PROCESSOR,
    label: 'documentProcessor',
  },
  {
    value: ORCHESTRATOR_TYPE_EMAIL_PROCESSOR,
    label: 'emailProcessor',
  },
  {
    value: ORCHESTRATOR_TYPE_AGENT,
    label: 'agent',
  },
];
