import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const BOOLEANS_CATEGORY = 'Booleans';

export default {
  BOOLEAN_OR: {
    categories: [BOOLEANS_CATEGORY],
    tag: 'booleanOr',
    name: 'OR Boolean',
    tags: ['or', 'boolean', 'condition'],
    icon: 'fa-solid fa-code-branch',
    inputs: [
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'b',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'or',
        custom: false,
        to: [],
      },
    ],
  },
  BOOLEAN_AND: {
    categories: [BOOLEANS_CATEGORY],
    tag: 'booleanAnd',
    name: 'AND Boolean',
    tags: ['and', 'boolean', 'condition'],
    icon: 'fa-solid fa-code-branch',
    inputs: [
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'b',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'and',
        custom: false,
        to: [],
      },
    ],
  },
};
