import { debounce } from 'utils/utils';
import { RESET_EXECUTION, INSERT_NODE_IN_EXECUTION, INSERT_NODE_RESULT } from '.';

export const resetExecution = (processId) => (dispatch) => {
  dispatch({ type: RESET_EXECUTION, payload: { processId } });
};

let time = 100;

const resetTime = () => {
  time = 100;
};

let toResetTime = null;

export const insertNodeInExecution = (processId, nodeId) => {
  time += 20;

  if (toResetTime) {
    clearTimeout(toResetTime);
  }

  toResetTime = setTimeout(() => {
    resetTime();
  }, 2000);

  return debounce((dispatch) => {
    dispatch({ type: INSERT_NODE_IN_EXECUTION, payload: { processId, nodeId } });
  }, time); // Ajusta el tiempo según sea necesario
};

export const insertNodeResult = (processId, nodeId, result) => {
  time += 20;

  if (toResetTime) {
    clearTimeout(toResetTime);
  }

  toResetTime = setTimeout(() => {
    resetTime();
  }, 2000);

  return debounce((dispatch) => {
    dispatch({ type: INSERT_NODE_RESULT, payload: { processId, nodeId, result } });
  }, time); // Ajusta el tiempo según sea necesario
};
