import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

const URLS = {
  getMyOrganizations: 'organizations',
  createOrganization: 'organizations',
  getMyInvitations: 'invitations',
  createInvitation: (organizationId) => `organizations/${organizationId}/invite`,
  acceptInvitation: (invitationId) => `invitations/${invitationId}/accept`,
  rejectInvitation: (invitationId) => `invitations/${invitationId}/reject`,
  deleteOrganization: (organizationId) => `organizations/${organizationId}`,
  updateOrganization: (organizationId) => `organizations/${organizationId}`,
  getOrganizationMembers: (organizationId) => `organizations/${organizationId}/members`,
  getOrganizationLogo: (organizationId) => `organizations/${organizationId}/logo`,
};

export default {
  async getMyOrganizations() {
    return ApiFetchInstance.get(URLS.getMyOrganizations);
  },
  async createOrganization(organization) {
    return ApiFetchInstance.post(URLS.createOrganization, organization);
  },
  async getMyInvitations() {
    return ApiFetchInstance.get(URLS.getMyInvitations);
  },
  async createInvitation(organizationId, invitation) {
    return ApiFetchInstance.post(URLS.createInvitation(organizationId), invitation);
  },
  async acceptInvitation(invitationId) {
    return ApiFetchInstance.post(URLS.acceptInvitation(invitationId));
  },
  async rejectInvitation(invitationId) {
    return ApiFetchInstance.post(URLS.rejectInvitation(invitationId));
  },
  async deleteOrganization(organizationId) {
    return ApiFetchInstance.delete(URLS.deleteOrganization(organizationId));
  },
  async updateOrganization(organizationId, organization) {
    return ApiFetchInstance.put(URLS.updateOrganization(organizationId), organization);
  },
  async getOrganizationMembers(organizationId) {
    return ApiFetchInstance.get(URLS.getOrganizationMembers(organizationId));
  },
  async getOrganizationLogo(organizationId) {
    // return ApiFetchInstance.get(URLS.getOrganizationLogo(organizationId));
    return fetch(`${window.LLM_CHATBOT_API_URL}/organizations/${organizationId}/logo`);
  },

};
