import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useErrorHandling from 'hooks/useErrorHandling';
import useLiterals from 'hooks/useLiterals';
import {
  getApiEndpoints, patchApiEndpoint, deleteApiEndpoint, changeStatusApiEndpoints,
} from 'modules/apis/actions';
import { getErrorMessage } from 'utils/errors';
import ConfirmModal from 'components/ConfirmModal';
import FormEndpoint from './formEndpoint';
import Endpoint from './endpoint';

function EndpointsView(props) {
  const { apiId, onBack } = props;

  const [endpoints, setEndpoints] = useState([]);
  const [savingEndpoints, setSavingEndpoints] = useState({});

  const [showCreationForm, setShowCreationForm] = useState(false);
  const [endpointToEdit, setEndpointToEdit] = useState(null);

  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [endpointToDelete, setEndpointToDelete] = useState(null);

  const { showErrorModal, printErrorModal } = useErrorHandling();
  const literals = useLiterals('addApi');

  const loadEndpoints = async () => {
    try {
      const response = await getApiEndpoints(apiId);
      setEndpoints(response.endpoints);
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    }
  };

  useEffect(() => {
    loadEndpoints();
  }, []);

  const handleChangeStatus = async (endpointId, status) => {
    try {
      setSavingEndpoints((oldValue) => ({ ...oldValue, [endpointId]: true }));
      await patchApiEndpoint(apiId, endpointId, { active: status });
      setEndpoints((oldValue) => oldValue.map((endpoint) => {
        if (endpoint.id === endpointId) {
          return { ...endpoint, active: status };
        }
        return endpoint;
      }));
      setSavingEndpoints((oldValue) => ({ ...oldValue, [endpointId]: false }));
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
      setSavingEndpoints((oldValue) => ({ ...oldValue, [endpointId]: false }));
    }
  };

  const handleChangeRequiresConfirmation = async (endpoint, requiresConfirmation) => {
    try {
      setSavingEndpoints((oldValue) => ({ ...oldValue, [endpoint.id]: true }));
      await patchApiEndpoint(apiId, endpoint.id, { requires_confirmation: requiresConfirmation });
      setEndpoints((oldValue) => oldValue.map((auxEndpoint) => {
        if (auxEndpoint.id === endpoint.id) {
          return { ...auxEndpoint, requires_confirmation: requiresConfirmation };
        }
        return auxEndpoint;
      }));
      setSavingEndpoints((oldValue) => ({ ...oldValue, [endpoint.id]: false }));
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
      setSavingEndpoints((oldValue) => ({ ...oldValue, [endpoint.id]: false }));
    }
  };

  const changeAllStatus = async (status) => {
    try {
      await changeStatusApiEndpoints(apiId, status);
      setEndpoints(endpoints.map((endpoint) => ({
        ...endpoint,
        active: status,
      })));
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    }
  };

  const handleShowCreationForm = () => {
    setEndpointToEdit(null);
    setShowCreationForm(true);
  };

  const hideEndpointForm = () => {
    setShowCreationForm(false);
  };

  const enableAll = () => {
    changeAllStatus(true);
  };

  const disableAll = () => {
    changeAllStatus(false);
  };

  const handleSelectToEdit = (endpointId) => {
    const endpoint = endpoints.find((auxEndpoint) => auxEndpoint.id === endpointId);
    if (!endpoint) {
      return;
    }

    setEndpointToEdit(endpoint);
    setShowCreationForm(true);
  };

  const handleSelectToDelete = (endpointId) => {
    const endpoint = endpoints.find((auxEndpoint) => auxEndpoint.id === endpointId);
    if (!endpoint) {
      return;
    }

    setEndpointToDelete(endpoint);
    setShowDeleteForm(true);
  };

  const confirmDeleteEndpointApi = async () => {
    try {
      await deleteApiEndpoint(apiId, endpointToDelete.id);
      setEndpoints((oldValue) => oldValue.filter((endpoint) => endpoint.id !== endpointToDelete.id));
      setShowDeleteForm(null);
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    }
  };

  const printEndpoints = () => {
    return endpoints.map((endpoint) => (
      <Endpoint
        data={endpoint}
        key={endpoint.id}
        isSaving={savingEndpoints[endpoint.id]}
        onChangeStatus={handleChangeStatus}
        onChangeRequiresConfirmation={handleChangeRequiresConfirmation}
        onEdit={handleSelectToEdit}
        onDelete={handleSelectToDelete}
      />
    ));
  };

  const printNoElements = () => {
    return (
      <div className='page_content'>
        <div className='no_elements'>{literals.thereIsNoEndpoints}</div>
      </div>
    );
  };

  const handleCreateEndpoint = () => {
    loadEndpoints();
    hideEndpointForm();
  };

  const printEndpointForm = () => {
    if (!showCreationForm) {
      return null;
    }

    return (
      <FormEndpoint
        onClose={hideEndpointForm}
        onSubmit={handleCreateEndpoint}
        apiId={apiId}
        data={endpointToEdit}
      />
    );
  };

  const printDeleteModal = () => {
    if (!showDeleteForm) {
      return null;
    }

    return (
      <ConfirmModal
        open
        onClose={() => setShowDeleteForm(null)}
        onSubmit={confirmDeleteEndpointApi}
        title={literals.deleteApiEndpointConfirm}
      />
    );
  };

  return (
    <>
      {printEndpointForm()}
      {printDeleteModal()}
      {printErrorModal()?.modal}
      <div className='endpoints_list'>
        {endpoints.length ? (
          <div>
            <div className='right mb-3'>
              <button type='button' className='btn enable_all' onClick={enableAll}>
                {literals.enableAll}
              </button>
              <button type='button' className='btn disable_all' onClick={disableAll}>
                {literals.disableAll}
              </button>
            </div>
            {printEndpoints()}
          </div>
        ) : printNoElements()}
        <div className='form_buttons'>
          <button type='button' className='btn btn-secondary' onClick={onBack}>
            {literals.common.back}
          </button>
          <button type='button' className='btn btn-primary' onClick={handleShowCreationForm}>
            {literals.addNewEndpoint}
          </button>
        </div>
      </div>
    </>
  );
}

EndpointsView.propTypes = {
  apiId: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default EndpointsView;
