import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  PieChart, Pie, Tooltip, Cell, Label,
} from 'recharts';
import { CHART_COLORS } from 'constants/colors';

function TestChatbotChart({
  data,
}) {
  const [chartData, setChartData] = useState([]);
  const [average, setAverage] = useState(0);

  const getResultsFormatted = () => {
    const result = [
      { name: '0 - 50', value: 0 },
      { name: '50 - 75', value: 0 },
      { name: '75 - 90', value: 0 },
      { name: '90 - 100', value: 0 },
    ];
    data.forEach((test) => {
      const score = Number(test.score);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(score)) return;
      if (score >= 0 && score <= 50) {
        result[0].value += 1;
      } else if (score > 50 && score <= 75) {
        result[1].value += 1;
      } else if (score > 75 && score <= 90) {
        result[2].value += 1;
      } else {
        result[3].value += 1;
      }
    });
    return result;
  };

  const getAverage = () => {
    let total = 0;
    data.forEach((test) => {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(test.score)) return;
      total += Number(test.score);
    });
    return Math.trunc(total / data.length);
  };

  useEffect(() => {
    setChartData(getResultsFormatted());
    setAverage(getAverage());
  }, []);

  const getColor = (value) => {
    if (value >= 0 && value <= 50) {
      return CHART_COLORS[0];
    } if (value > 50 && value <= 75) {
      return CHART_COLORS[1];
    } if (value > 75 && value <= 90) {
      return CHART_COLORS[2];
    }
    return CHART_COLORS[3];
  };

  return (
    <div key={data[0]?.chatbot_id}>
      <PieChart width={150} height={150}>
        <Pie
          data={chartData}
          cx={70}
          cy={70}
          innerRadius={60}
          outerRadius={70}
          fill='#8884d8'
          paddingAngle={2}
        >
          <Label
            value={average}
            position='center'
            className='label-top'
            fontSize='27px'
            fill={getColor(average)}
          />
          {
            data.map((entry, index) => <Cell fill={CHART_COLORS[index % CHART_COLORS.length]} />)
          }
        </Pie>
        <Tooltip />
      </PieChart>
    </div>
  );
}

TestChatbotChart.propTypes = {
  data: PropTypes.object.isRequired,
};

TestChatbotChart.defaultProps = {

};

export default TestChatbotChart;
