import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const TWILIO_CATEGORY = 'Twilio';

export default {
  TWILIO_COMES_FROM_TWILIO: {
    categories: [TWILIO_CATEGORY],
    tag: 'comesFromTwilio',
    name: 'Comes from Twilio',
    tags: ['twilio', 'sms', 'message', 'receive', 'from'],
    icon: 'fa-solid fa-sms',
    inputs: [
      {
        type: TYPE_CONNECTIONS.CONVERSATION,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'conversation',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'comes_from_twilio',
        custom: false,
        to: [],
      },
    ],
  },
  TWILIO_EXTRACT_ORIGIN: {
    categories: [TWILIO_CATEGORY],
    tag: 'twilioExtractOrigin',
    name: 'Extract Origin from Twilio',
    tags: ['twilio', 'sms', 'message', 'receive', 'from', 'origin', 'sms', 'whatsapp'],
    icon: 'fa-solid fa-sms',
    inputs: [
      {
        type: TYPE_CONNECTIONS.CONVERSATION,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'conversation',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'origin',
        custom: false,
        to: [],
      },
    ],
  },
  TWILIO_EXTRACT_PHONE_NUMBER: {
    categories: [TWILIO_CATEGORY],
    tag: 'twilioExtractNumber',
    name: 'Extract Number from Twilio',
    tags: ['twilio', 'sms', 'message', 'receive', 'from', 'phone', 'number'],
    icon: 'fa-solid fa-sms',
    inputs: [
      {
        type: TYPE_CONNECTIONS.CONVERSATION,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'conversation',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'phone_number',
        custom: false,
        to: [],
      },
    ],
  },
  TWILIO_SEND_TEMPLATE: {
    categories: [TWILIO_CATEGORY],
    tag: 'sendTwilioTemplate',
    name: 'Send Twilio Template',
    tags: ['twilio', 'sms', 'message', 'send', 'template'],
    icon: 'fa-solid fa-sms',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.CHATBOT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'chatbot',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'phone_number',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'template_id',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'variables',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'messaging_service_sid',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
    ],
  },
  TWILIO_SEND_MESSAGE: {
    categories: [TWILIO_CATEGORY],
    tag: 'sendTwilioMessage',
    name: 'Send Twilio Message',
    tags: ['twilio', 'sms', 'message', 'send'],
    icon: 'fa-solid fa-sms',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.CHATBOT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'chatbot',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'type',
        options: ['sms', 'whatsapp'],
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'phone_number',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'message',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'media_url',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
    ],
  },
};
