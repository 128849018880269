import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ConfirmModal from 'components/ConfirmModal';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import { createOrchestratorVersion, updateOrchestratorVersion, deleteOrchestratorVersion } from 'modules/orchestrators/actions';

function ModalManageOrchestratorVersions({
  orchestratorId, defaultVersion, versions, onChange, onClose, onCreate, onChangeDefaultVersion, onEdit,
}) {
  const [createNewVersionFromThis, setCreateNewVersionFromThis] = useState(null);
  const [versionToDelete, setVersionToDelete] = useState(null);
  const [newVersionName, setNewVersionName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const [versionToEdit, setVersionToEdit] = useState(null);
  const [nameEdit, setNameEdit] = useState('');

  const literals = useLiterals('orchestrators');
  const { showErrorModal, printErrorModal } = useErrorHandling();

  const backToVersionsTable = () => {
    setCreateNewVersionFromThis(null);
    setVersionToEdit(null);
  };

  const handleChangeNewVersionName = (event) => {
    setNewVersionName(event.target.value);
  };

  const confirmDeleteOrchestratorVersion = async () => {
    try {
      await deleteOrchestratorVersion(orchestratorId, versionToDelete);
      setVersionToDelete(null);
      onChange();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    }
  };

  const submitNewOrchestratorVersion = async () => {
    if (newVersionName) {
      try {
        const data = {
          name: newVersionName,
          from_version: createNewVersionFromThis,
        };
        setIsSaving(true);
        const newVersion = await createOrchestratorVersion(orchestratorId, data);
        onCreate(newVersion);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        showErrorModal(errorMessage);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleEditVersion = (event) => {
    const versionId = event.currentTarget.getAttribute('data-id');
    const version = versions.find((v) => v.id === versionId);
    if (version) {
      setNameEdit(version.name);
      setVersionToEdit(version);
    }
  };

  const handleChangeEditName = (event) => {
    setNameEdit(event.target.value);
  };

  const handleSubmitEditVersion = async () => {
    if (nameEdit) {
      try {
        const data = {
          name: nameEdit,
        };
        setIsSaving(true);
        const editedVersion = await updateOrchestratorVersion(orchestratorId, versionToEdit.id, data);
        onEdit(editedVersion);
        backToVersionsTable();
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        showErrorModal(errorMessage);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const printVersionsTable = () => {
    return (
      <table className='table_stl1'>
        <thead>
          <tr>
            <th>{literals.version}</th>
            <th>{literals.isActive}</th>
            <th>{literals.options}</th>
          </tr>
        </thead>
        <tbody>
          {versions.map((version) => (
            <tr key={version.id}>
              <td>{version.name}</td>
              <td aria-controls=''>
                {defaultVersion === version.id ? literals.common.yes : literals.common.no}
              </td>
              <td>
                <Button
                  variant='secondary'
                  size='sm'
                  onClick={() => setCreateNewVersionFromThis(version.id)}
                >
                  {literals.createNewVersionFromThis}
                </Button>
                <Button
                  variant='secondary'
                  size='sm'
                  data-id={version.id}
                  onClick={handleEditVersion}
                >
                  {literals.common.edit}
                </Button>

                {defaultVersion !== version.id && (
                  <>
                    <Button
                      variant='success'
                      size='sm'
                      onClick={() => onChangeDefaultVersion(version.id)}
                    >
                      {literals.setAsActive}
                    </Button>
                    <Button
                      variant='danger'
                      size='sm'
                      onClick={() => setVersionToDelete(version.id)}
                    >
                      {literals.common.delete}
                    </Button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const printDeleteModal = () => {
    if (!versionToDelete) {
      return null;
    }

    return (
      <ConfirmModal
        open
        onClose={() => setVersionToDelete(null)}
        onSubmit={confirmDeleteOrchestratorVersion}
        title={literals.deleteOrchestratorVersionConfirm}
      />
    );
  };

  const printCreateModal = () => {
    if (!createNewVersionFromThis) {
      return null;
    }

    return (
      <>
        {printErrorModal()?.modal}
        <Modal show size='lg' onHide={backToVersionsTable}>
          <Modal.Header closeButton>
            <Modal.Title>{literals.createNewVersion}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='input_title'>
                {literals.common.name}
                <span className='required_field'>*</span>
              </div>
              <div className='input_value'>
                <input type='text' className='form_input_stl' value={newVersionName} onChange={handleChangeNewVersionName} />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={backToVersionsTable}>
              {literals.common.cancel}
            </Button>
            <Button variant='primary' onClick={submitNewOrchestratorVersion} disabled={!newVersionName}>
              {isSaving && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
              {literals.common.accept}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const printEditModal = () => {
    if (!versionToEdit) {
      return null;
    }

    return (
      <>
        {printErrorModal()?.modal}
        <Modal show size='lg' onHide={backToVersionsTable}>
          <Modal.Header closeButton>
            <Modal.Title>{`${literals.editVersion} ${versionToEdit.name}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='input_title'>
                {literals.common.name}
                <span className='required_field'>*</span>
              </div>
              <div className='input_value'>
                <input type='text' className='form_input_stl' value={nameEdit} onChange={handleChangeEditName} />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={backToVersionsTable}>
              {literals.common.cancel}
            </Button>
            <Button variant='primary' onClick={handleSubmitEditVersion} disabled={!nameEdit}>
              {isSaving && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
              {literals.common.accept}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  if (versionToDelete) {
    return printDeleteModal();
  }

  if (createNewVersionFromThis) {
    return printCreateModal();
  }

  if (versionToEdit) {
    return printEditModal();
  }

  return (
    <Modal show size='lg' onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{literals.manageOrchestratorVersions}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          {printVersionsTable()}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {literals.common.close}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalManageOrchestratorVersions.propTypes = {
  orchestratorId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  versions: PropTypes.array.isRequired,
  defaultVersion: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onChangeDefaultVersion: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default ModalManageOrchestratorVersions;
