export const SET_TESTS = 'tests/set-tests';
export const CREATE_TEST = 'tests/create-tests';
export const DELETE_TEST = 'tests/delete-test';

export const initialState = {
  tests: [],
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_TESTS: {
      return {
        ...state,
        tests: payload,
      };
    }
    case CREATE_TEST: {
      const { tests } = state;
      const newtests = tests ? [...tests] : [];
      newtests.push(payload);
      return {
        ...state,
        tests: newtests,
      };
    }
    case DELETE_TEST: {
      const tests = [...state.tests];
      const filteredtests = tests.filter((test) => test.test_id !== payload.test_id);
      return {
        ...state,
        tests: filteredtests,
      };
    }
    default:
      return state;
  }
};

export default reducer;
