import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import useLiterals from 'hooks/useLiterals';
import VariableRow from 'routes/Orchestrator/components/VariableRow';
import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from 'constants/orchestrator';
import { createDatatableRow, updateDatatableRow } from 'modules/datatables/actions';
import { loadingActions } from 'modules/loading';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';

function RowForm(props) {
  const {
    row, datatableId, datastructure, onClose, onSave,
  } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [values, setValues] = useState(row ? row.values : {});
  const [errors, setErrors] = useState({});

  const literals = useLiterals('datatableEntries');
  const dispatch = useDispatch();

  const { showErrorModal, printErrorModal } = useErrorHandling();

  const checkValues = () => {
    const fields = datastructure.fields;
    const newErrors = {};

    fields.forEach((field) => {
      switch (field.type) {
        case TYPE_CONNECTIONS.OBJECT:
          if (values[field.name]) {
            try {
              JSON.parse(values[field.name]);
            } catch (err) {
              newErrors[field.name] = literals.invalidJson;
            }
          }
          break;
        default:
          break;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSaveRow = async () => {
    if (!checkValues()) {
      return;
    }
    // Si no nos pasan el datatable id es porque lo estamos utilizando simplemente para definir una instancia de la estructura de datos sin querer guardarla, por ejemplo en una variable de un orquestador o en una variable de otro dataentry
    if (datatableId) {
      const data = {
        datastructure_id: datastructure.id,
        values,
      };

      dispatch(loadingActions.show());
      try {
        const response = await (row ? updateDatatableRow(datatableId, row.id, data) : createDatatableRow(datatableId, data));
        onSave(response);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        showErrorModal(errorMessage);
      } finally {
        dispatch(loadingActions.hide());
      }
    } else {
      onSave({ values });
    }
  };

  const printFields = () => {
    const ignoredTypes = [];

    if (!datastructure) {
      return null;
    }

    const inputs = datastructure.fields.map((f) => ({ ...f, default_value: values[f.name] || null }));

    return datastructure.fields.map((field) => {
      if (ignoredTypes.includes(field.type)) {
        return null;
      }

      let defaultValue = null;
      const typeSet = field.type_set || TYPE_SET_CONNECTIONS.SINGLE;
      if (typeSet === TYPE_SET_CONNECTIONS.ARRAY) {
        defaultValue = [];
      } else {
        switch (field.type) {
          case TYPE_CONNECTIONS.BOOLEAN:
            defaultValue = false;
            break;
          case TYPE_CONNECTIONS.NUMBER:
            defaultValue = 0;
            break;
          case TYPE_CONNECTIONS.OBJECT:
            defaultValue = '{}';
            break;
          default:
            defaultValue = '';
            break;
        }
      }
      if (values[field.name] !== undefined) {
        defaultValue = values[field.name];
      }
      const input = { ...field, default_value: defaultValue };

      let errorField = null;

      if (errors[field.name]) {
        errorField = (<div className='error'>{errors[field.name]}</div>);
      }

      return (
        <>
          <VariableRow
            key={field.id}
            input={input}
            inputs={inputs}
            onChange={(change) => {
              setValues({ ...values, [field.name]: change.default_value });
            }}
            hasError={!!errors[field.name]}
          />
          {errorField}
        </>
      );
    });
  };

  return (
    <>
      {printErrorModal()?.modal}
      <Modal show size='lg' onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{row ? literals.editEntry : literals.addEntry}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {printFields()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClose}>
            {literals.common.close}
          </Button>
          <Button
            variant='primary'
            onClick={handleSaveRow}
            disabled={isSaving}
          >
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {literals.common.accept}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

RowForm.propTypes = {
  datatableId: PropTypes.string,
  row: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  datastructure: PropTypes.object.isRequired,
};

RowForm.defaultProps = {
  row: null,
  datatableId: null,
};

export default RowForm;
