import { SET_MODELS } from './index';
import Api from './repository';

export const getModels = (offset = 0, limit = 1000) => async (dispatch, getState) => {
  const { models } = getState();
  if (models.models === null) {
    const response = await Api.getModels(offset, limit);
    dispatch({ type: SET_MODELS, payload: response.models });
  }
};
