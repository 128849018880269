import React, { useState } from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
} from 'reactflow';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import { deleteEdge } from 'modules/orchestrators/actions';
import ConfirmModal from 'components/ConfirmModal';

import '../styles.scss';

function CustomEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
  selected,
  source,
  target,
  data,
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const literals = useLiterals('orchestrators');
  const [showDeleteEdgeWarning, setShowDeleteEdgeWarning] = useState(false);

  const handleRemoveEdge = () => {
    if (data?.orchestratorId) {
      deleteEdge(data?.orchestratorId, source, target, data?.actionId)
        .finally(() => {
          data?.onRefresh();
        });
    }
  };

  const renderDeleteEdgeWarning = () => {
    return (
      <ConfirmModal
        open={showDeleteEdgeWarning}
        onClose={() => setShowDeleteEdgeWarning(false)}
        onSubmit={() => {
          setShowDeleteEdgeWarning(false);
          handleRemoveEdge();
        }}
        message={literals.deleteEdgeWarning}
      />
    );
  };

  const handleShowDeleteEdgeWarning = () => {
    setShowDeleteEdgeWarning(true);
  };

  return (
    <>
      {renderDeleteEdgeWarning()}
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      {selected && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: 'all',
            }}
            className='nodrag nopan'
          >
            <div className='custom-edge-remove' onClick={handleShowDeleteEdgeWarning}>
              <i className='fa-solid fa-xmark' />
            </div>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
}

CustomEdge.propTypes = {
  sourceX: PropTypes.number.isRequired,
  sourceY: PropTypes.number.isRequired,
  targetX: PropTypes.number.isRequired,
  targetY: PropTypes.number.isRequired,
  sourcePosition: PropTypes.number.isRequired,
  targetPosition: PropTypes.number.isRequired,
  style: PropTypes.object,
  markerEnd: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  source: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

CustomEdge.defaultProps = {
  style: {},
};

export default CustomEdge;
