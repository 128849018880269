export const TAB_CHATBOT = 'chatbot';
export const TAB_PROCESS = 'process';
export const TAB_SOURCES = 'sources';
export const TAB_DATA = 'data';

export const PROJECTS_TYPES = [
  'Financial Due Diligence',
  'Legal Due Diligence',
  'Operational Due Diligence',
  'IT Due Diligence',
  'Technical Due Diligence',
  'Human Resources Due Diligence',
  'Customer Due Diligence',
  'Vendor Due Diligence',
  'Cybersecurity Due Diligence',
  'IP Due Diligence',
  'Cultural Due Diligence',
  'Regulatory Due Diligence',
  'Tax Due Diligence',
  'Real Estate Due Diligence',
  'ESG Due Diligence',
  'Insurance Due Diligence',
  'Environmental Due Diligence',
  'Market Due Diligence',
  'Product Due Diligence',
  'Supplier vs. Supply Chain Due Diligence',
  'Data Due Diligence',
  'Manufacturing Due Diligence',
  'Logistics Due Diligence',
  'Franchise Due Diligence',
  'Export Compliance Due Diligence',
];
