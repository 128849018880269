import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { createTest, getTestDetails } from 'modules/tests/actions';
import TestResumeChart from './testResumeChart';
import TestChatbotChart from './testChatbotChart';
import TestQuestionResultModal from './testQuestionResultModal';
import RelaunchTestForm from './relaunchTestForm';

function testDetails({
  literals,
  tests,
  goBack,
}) {
  const [baseTestDetails, setBaseTestDetails] = useState([]);
  const [showChatbotTestQuestion, setShowChatbotTestQuestion] = useState(null);
  const [showRelaunchTestForm, setShowRelaunchTestForm] = useState(false);

  useEffect(() => {
    if (tests && tests.length && tests[0].base_test) {
      getTestDetails(tests[0].base_test)
        .then((response) => {
          setBaseTestDetails(response?.tests);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [tests]);

  const groupDataByChatbot = (data) => {
    const groupedData = data.reduce((result, currentObj) => {
      // Comprobamos si ya existe un array para el chatbot_id actual
      if (!result[currentObj.chatbot_id]) {
        // Si no existe, creamos un nuevo array con el objeto actual
        // eslint-disable-next-line no-param-reassign
        result[currentObj.chatbot_id] = [currentObj];
      } else {
        // Si ya existe, agregamos el objeto actual al array correspondiente
        result[currentObj.chatbot_id].push(currentObj);
      }
      return result;
    }, {});
    return groupedData;
  };

  const handleRelaunchTest = (testData) => {
    try {
      createTest(testData);
      setShowRelaunchTestForm(false);
    } catch (e) {
      console.error(e);
    }
  };

  const renderChatbotsPieCharts = (chatbotTests, baseChatbotTests) => {
    return Object.keys(chatbotTests).map((chatbotId, index) => {
      return (
        <div>
          <div className='d-flex align-items-center'>
            {baseChatbotTests[chatbotId] && (
              <>
                <div key={index} className='test-details__container__pie-chart-container__item'>
                  <TestChatbotChart
                    key={baseChatbotTests[chatbotId][0].test_id}
                    data={baseChatbotTests[chatbotId]}
                  />
                </div>
                <i className='fa fa-angle-right' aria-hidden='true' />
              </>
            )}

            <div key={index} className='test-details__container__pie-chart-container__item'>
              <TestChatbotChart
                key={chatbotId}
                data={chatbotTests[chatbotId]}
              />
            </div>
          </div>
          <p className='text-center'>{chatbotTests[chatbotId][0]?.chatbot_data?.name}</p>
        </div>
      );
    });
  };

  const printResume = () => {
    const chatbotTests = groupDataByChatbot(tests);
    const baseChatbotTests = groupDataByChatbot(baseTestDetails);
    return (
      <div className='panel'>
        <div className='panel-header'>{literals.testResume}</div>
        <div className='panel-body'>
          <p>
            <strong>{literals.chatbotsNum}</strong>
            {' '}
            {Object.keys(chatbotTests).length}
          </p>
          <p>
            <strong>{literals.testsNum}</strong>
            {' '}
            {chatbotTests[Object.keys(chatbotTests)[0]].length}
          </p>
          <div className='test-details__container__bar-chart'>
            <TestResumeChart
              chatbotTests={chatbotTests}
            />
          </div>
          <div className='test-details__container__pie-chart-container'>
            {renderChatbotsPieCharts(chatbotTests, baseChatbotTests)}
          </div>
        </div>
      </div>
    );
  };

  const getTestsFormatted = (data) => {
    const result = [];
    const chatbotTests = groupDataByChatbot(data);
    Object.keys(chatbotTests).forEach((chatbotId) => {
      chatbotTests[chatbotId].forEach((test) => {
        const testResult = result.find((r) => r.question.id === test.test_question_id);
        const question = test.test_question_data;
        if (!testResult) {
          result.push({
            question,
            [test.chatbot_id]: test,
          });
        } else {
          testResult[test.chatbot_id] = test;
        }
      });
    });
    return result;
  };

  const printRelaunchTestForm = () => {
    if (!showRelaunchTestForm) {
      return null;
    }

    const testsFormatted = getTestsFormatted(tests);
    const dataByChatbot = groupDataByChatbot(tests);
    return (
      <RelaunchTestForm
        onClose={() => setShowRelaunchTestForm(false)}
        literals={literals}
        initTest={handleRelaunchTest}
        questions={testsFormatted.map((test) => test.question)}
        allOrganizations={tests[0]?.organizations_data}
        allChatbots={Object.keys(dataByChatbot).map((chatbotId) => dataByChatbot[chatbotId][0]?.chatbot_data)}
        baseTest={tests[0].test_id}
      />
    );
  };

  const printQuestionResumeModal = () => {
    return (
      <TestQuestionResultModal
        literals={literals}
        question={showChatbotTestQuestion}
        onClose={() => setShowChatbotTestQuestion(null)}
      />
    );
  };

  const renderTests = () => {
    const testsFormatted = getTestsFormatted(tests);
    const baseTestsFormatted = getTestsFormatted(baseTestDetails);
    return testsFormatted.map((test, index) => {
      return (
        <div className='panel test-details__container__question'>
          <div className='panel-header'>{`Pregunta ${index + 1}: ${test.question.title}`}</div>
          <div className='panel-body'>
            {Object.keys(test).map((chatbotId) => {
              if (chatbotId === 'question') {
                return null;
              }
              const testResult = test[chatbotId];
              const questionBase = baseTestsFormatted.find((x) => x?.question?.id === test?.question?.id);
              let baseTestResult = null;
              if (questionBase) {
                baseTestResult = questionBase[chatbotId];
              }
              return (
                <div className='d-flex justify-content-between mt-2 test-details__container__question__item'>
                  <p>
                    <strong>{literals.chatbot}</strong>
                    {' '}
                    {testResult?.chatbot_data?.name}
                  </p>
                  <p>
                    <strong>{literals.model}</strong>
                    {' '}
                    {testResult?.model}
                  </p>
                  <div className='test-details__container__question__item__actions'>
                    <p>
                      <strong>{literals.score}</strong>
                      {' '}
                      {baseTestResult && (
                        <>
                          {baseTestResult.score}
                          {' '}
                          <i className='fa fa-angle-right fa-1' aria-hidden='true' />
                          {' '}
                        </>
                      )}
                      {testResult.score}
                    </p>
                    <Button
                      variant='primary'
                      onClick={() => setShowChatbotTestQuestion(testResult)}
                    >
                      {literals.viewDetail}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  if (!tests.length) {
    return null;
  }

  return (
    <>
      {printRelaunchTestForm()}
      <div className='test-details__container'>
        <Button
          className='mb-4'
          variant='secondary'
          onClick={goBack}
        >
          {literals.back}
        </Button>
        <h1>{tests[0]?.name}</h1>
        <Button
          className='mb-4'
          variant='primary'
          onClick={() => setShowRelaunchTestForm(true)}
        >
          Relanzar Test
        </Button>
      </div>

      {showChatbotTestQuestion && printQuestionResumeModal()}
      {printResume()}
      {renderTests()}
    </>
  );
}

testDetails.propTypes = {
  literals: PropTypes.object.isRequired,
  tests: PropTypes.array.isRequired,
  goBack: PropTypes.func.isRequired,
};

testDetails.defaultProps = {

};

export default testDetails;
