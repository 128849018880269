// En tu archivo Chat.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendMessage, setMessages, addMessage, sendNewConversation,
} from 'modules/socket/actions';
import { v4 as uuidv4 } from 'uuid';
import { STATUS_PROCESSING, STATUS_FINISHED } from 'constants/messages';
import {
  getAudioTranscription, getMessages, startConversation,
} from 'modules/chats/actions';
import { uploadDocumentToConversation } from 'modules/documents/actions';
import { createConversation, deleteTransferedConversation, getConversation } from 'modules/conversations/actions';
import { STATUS_CONNECTED } from 'constants/status';
import { sleep } from 'utils/utils';
import { createFormattedDate } from 'utils/dates';
import useLiterals from 'hooks/useLiterals';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import ChatForm from './components/ChatForm';
import './styles.scss';

function Chat(props) {
  const {
    conversationId: originalConversationId, onCreateConversation, chatbot, header,
    disabled, variables, onBeforeSendMessage, onClose, orchestratorVersionId, isDebug,
  } = props;

  const [isNewConversation, setIsNewConversation] = useState(!originalConversationId);
  const [conversationId, setConversationId] = useState(originalConversationId);
  const [conversation, setConversation] = useState(null);
  const [messagesChat, setMessagesChat] = useState([]);
  const [showDisclaimer, setShowDisclaimer] = useState(chatbot.show_disclaimer && !!chatbot.disclaimer);
  const [isConversationCreated, setIsConversationCreated] = useState(false);
  const [pendingTasks, setPendingTasks] = useState([]);
  const [pendingTasksSent, setPendingTasksSent] = useState([]);
  const [showTransferedMessage, setShowTransferedMessage] = useState(false);
  const dispatch = useDispatch();

  const { chats } = useSelector((state) => state.chats);
  const socketStatus = useSelector((state) => state.socket.status);
  const { transferedConversations } = useSelector((state) => state.conversations);
  const literals = useLiterals('chatbot');
  const { showErrorModal, printErrorModal } = useErrorHandling();

  const loadMessages = async () => {
    try {
      const response = await getMessages(chatbot.id, conversationId);
      setMessagesChat(response.messages.reverse());
      dispatch(setMessages({ conversation_id: conversationId, messages: response.messages }));
    } catch (error) {
      console.error('error', error);
    }
  };

  const loadConversation = async () => {
    try {
      const response = await getConversation(chatbot.id, conversationId);
      setConversation(response);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    const transfered = transferedConversations.findLast((conv) => conv.from === conversationId);
    if (transfered) {
      setConversationId(transfered.to);
      setShowTransferedMessage(true);
      setTimeout(() => {
        dispatch(deleteTransferedConversation(transfered));
      }, 1000);
    }
  }, [transferedConversations]);

  useEffect(() => {
    const bgColorUserMessage = chatbot?.extra_info?.bg_color_user_message ?? '#6eb3a1';
    const textColorUserMessage = chatbot?.extra_info?.text_color_user_message ?? '#ffffff';
    const bgColorBotMessage = chatbot?.extra_info?.bg_color_bot_message ?? '#eeeeee';
    const textColorBotMessage = chatbot?.extra_info?.text_color_bot_message ?? '#444444';
    const bgColorAgentMessage = chatbot?.extra_info?.bg_color_agent_message ?? '#a3dcff';
    const textColorAgentMessage = chatbot?.extra_info?.text_color_agent_message ?? '#444444';
    const bgColorRecordingAudioMessage = chatbot?.extra_info?.bg_color_recording_audio_message ?? '#E1BEE7';
    const textColorRecordingAudioMessage = chatbot?.extra_info?.text_color_recording_audio_message ?? '#ffffff';
    const bgColorHeader = chatbot?.extra_info?.bg_color_header ?? '#1cc6d0';
    const textColorHeader = chatbot?.extra_info?.text_color_header ?? '#ffffff';
    const colorButtonSend = chatbot?.extra_info?.color_button_send ?? '#1cc6d0';
    const colorButtonForms = chatbot?.extra_info?.color_button_forms ?? '#1cc6d0';
    const textColorButtonForms = chatbot?.extra_info?.text_color_button_forms ?? '#ffffff';
    const agentsWriteAsBot = chatbot?.extra_info?.agents_write_as_bot ?? false;
    const colorBubble = chatbot?.extra_info?.color_bubble ?? '#1cc6d0';
    const bubbleSize = chatbot?.extra_info?.bubble_size ?? 80;

    document.documentElement.style.setProperty('--bg-color-user-message', bgColorUserMessage);
    document.documentElement.style.setProperty('--text-color-user-message', textColorUserMessage);
    document.documentElement.style.setProperty('--bg-color-bot-message', bgColorBotMessage);
    document.documentElement.style.setProperty('--text-color-bot-message', textColorBotMessage);
    if (agentsWriteAsBot) {
      document.documentElement.style.setProperty('--bg-color-agent-message', bgColorBotMessage);
      document.documentElement.style.setProperty('--text-color-agent-message', textColorBotMessage);
    } else {
      document.documentElement.style.setProperty('--bg-color-agent-message', bgColorAgentMessage);
      document.documentElement.style.setProperty('--text-color-agent-message', textColorAgentMessage);
    }
    document.documentElement.style.setProperty('--bg-color-recording-audio-message', bgColorRecordingAudioMessage);
    document.documentElement.style.setProperty('--text-color-recording-audio-message', textColorRecordingAudioMessage);
    document.documentElement.style.setProperty('--bg-color-header', bgColorHeader);
    document.documentElement.style.setProperty('--text-color-header', textColorHeader);
    document.documentElement.style.setProperty('--color-button-send', colorButtonSend);
    document.documentElement.style.setProperty('--color-button-forms', colorButtonForms);
    document.documentElement.style.setProperty('--text-color-button-forms', textColorButtonForms);
    document.documentElement.style.setProperty('--color-bubble-minimized', colorBubble);
    document.documentElement.style.setProperty('--size-bubble-minimized', bubbleSize);
  }, [chatbot]);

  useEffect(() => {
    setIsNewConversation(false);
    setConversationId(originalConversationId);
  }, [originalConversationId]);

  useEffect(() => {
    if (conversationId && !isNewConversation) {
      loadConversation();
      loadMessages();
      setIsConversationCreated(true);
    } else if (!conversationId) {
      setMessagesChat([]);
      setIsConversationCreated(false);
    }
  }, [conversationId]);

  useEffect(() => {
    if (socketStatus === STATUS_CONNECTED && conversationId) {
      sendNewConversation(conversationId);
    }
  }, [socketStatus, conversationId]);

  useEffect(() => {
    if (typeof chats[conversationId] !== 'undefined') {
      setMessagesChat(chats[conversationId].messages);
    }
  }, [chats]);

  const doPendingTasks = async () => {
    if (pendingTasks.length && isConversationCreated) {
      const pendingTasksCopy = [...pendingTasks];
      let firstTask = pendingTasksCopy.shift();
      let isSent = pendingTasksSent.includes(firstTask.id);
      while (isSent && pendingTasksCopy.length) {
        firstTask = pendingTasksCopy.shift();
        isSent = pendingTasksSent.includes(firstTask.id);
      }

      if (isSent) {
        setPendingTasks([]);
        return;
      }
      setPendingTasksSent([...pendingTasksSent, firstTask.id]);

      switch (firstTask.type) {
        case 'sendMessage':
          if (onBeforeSendMessage) {
            onBeforeSendMessage();
          }
          dispatch(sendMessage('message', {
            ...firstTask.data,
            orchestrator_version_id: orchestratorVersionId,
            is_debug: isDebug,
          }, true));
          break;
        case 'uploadFile': {
          const { file, conversationId: convId } = firstTask.data;
          const formData = new FormData();
          formData.append('file', file, file.name);
          uploadDocumentToConversation(chatbot.id, convId, formData);
        }
          break;
        default:
          break;
      }
      await sleep(3000);
      setPendingTasks(pendingTasksCopy);
    }
  };

  useEffect(() => {
    doPendingTasks();
  }, [pendingTasks, isConversationCreated]);

  const handleNewConversation = async (id = null) => {
    let convId = id;
    if (convId) {
      setConversationId(convId);
      if (onCreateConversation) {
        onCreateConversation({
          id: convId,
          name: '',
          chatbot_id: chatbot.id,
        });
      }
    }

    setIsNewConversation(true);
    setIsConversationCreated(false);
    const conv = await createConversation(convId, chatbot.id, '', variables);
    setIsConversationCreated(true);
    setConversation(conv);
    if (!convId) {
      convId = conv.id;
      setConversationId(convId);
      if (onCreateConversation) {
        onCreateConversation(conv);
      }
    }
    return convId;
  };

  const handleSendMessage = async (message, type = 'text') => {
    let convId = conversationId;
    let hasToCreateConversation = false;
    if (!convId) {
      convId = uuidv4();
      dispatch(startConversation(convId));

      hasToCreateConversation = true;
    }

    const newMessages = [
      ...messagesChat,
      {
        id: uuidv4(),
        role: 'user',
        content: message,
        type,
        status: STATUS_FINISHED,
        conversation_id: convId,
        created_at: createFormattedDate(),
      },
    ];
    dispatch(addMessage(convId, newMessages[newMessages.length - 1]));
    if (isConversationCreated && !pendingTasks.length) {
      if (onBeforeSendMessage) {
        onBeforeSendMessage();
      }
      dispatch(sendMessage('message', {
        conversation_id: convId,
        messages: newMessages,
        orchestrator_version_id: orchestratorVersionId,
        is_debug: isDebug,
      }, false));
    } else {
      setPendingTasks([...pendingTasks, { type: 'sendMessage', id: uuidv4(), data: { conversation_id: convId, messages: newMessages } }]);
    }

    if (hasToCreateConversation) {
      await handleNewConversation(convId);
    }
  };

  const handleSendAudioMessage = async (blob, audioURL, extension) => {
    // Envío el archivo
    const formData = new FormData();
    formData.append('document_file', blob, `record_${Math.round(Math.random() * 999999)}_${Date.now()}.wav`);
    formData.append('conversation_id', conversationId);
    formData.append('extension', extension);

    const newMessage = {
      id: uuidv4(),
      role: 'user',
      type: 'audio',
      extra_info: {
        filename: audioURL,
      },
      audioURL,
      status: STATUS_PROCESSING,
    };

    dispatch(addMessage(conversationId, newMessage));

    try {
      const { text } = await getAudioTranscription(formData);
      handleSendMessage(text);
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    }
  };

  const handleUploadFile = async (file) => {
    let convId = conversationId;
    let hasToCreateConversation = false;
    if (!convId) {
      convId = uuidv4();
      setConversationId(convId);

      dispatch(startConversation(convId));

      hasToCreateConversation = true;
    }

    if (isConversationCreated && !pendingTasks.length) {
      const formData = new FormData();
      formData.append('file', file, file.name);
      await uploadDocumentToConversation(chatbot.id, convId, formData);
    } else {
      setPendingTasks([...pendingTasks, { type: 'uploadFile', id: uuidv4(), data: { file, conversationId: convId } }]);
    }

    if (hasToCreateConversation) {
      await handleNewConversation(convId);
    }
  };

  const getFilteredMessages = () => {
    const filteredMessages = messagesChat.filter((message, index) => {
      // Si el mensaje es de tipo formulario, solo lo muestro si no hay otro del mismo tipo antes y del mismo formulario si no ha sido contestado ya
      if (message.type === 'form') {
        if (index > 0) {
          for (let i = index - 1; i >= Math.max(0, index - 10); i -= 1) {
            if (messagesChat[i].type === 'form' && messagesChat[i].form_id === message.form_id && typeof message.extra_info.values === 'undefined') {
              return false;
            }
          }
        }
      }

      // Si el mensaje es el mensaje previo de un formulario solo lo muestro si no hay otro del mismo tipo antes y del mismo formulario y si no ha sido contestado ya
      const specialType = message.extra_info?.special_type;
      if (specialType === 'form_init_message') {
        let isFormAnswered = false;
        // Compruebo si el form ha sido contestado
        if (messagesChat.length > index + 1) {
          const nextMessage = messagesChat[index + 1];

          if (nextMessage.type === 'form' && nextMessage.extra_info.form.id === message.extra_info.form.id && typeof nextMessage.extra_info.values !== 'undefined') {
            isFormAnswered = true;
          }
        }
        // Si no ha sido contestado y hay otro mensaje inicial de este formulario después, no lo muestro
        if (!isFormAnswered) {
          if (index > 0) {
            for (let i = index - 1; i >= Math.max(0, index - 10); i -= 1) {
              const specialTypeAux = messagesChat[i].extra_info?.special_type;
              if (specialTypeAux === specialType && messagesChat[i].extra_info.form.id === message.extra_info.form.id) {
                return false;
              }
            }
          }
        }
      }

      return true;
    });
    return filteredMessages;
  };

  if (!chatbot) {
    return null;
  }

  return (
    <>
      {printErrorModal()?.modal}
      {showDisclaimer && !disabled && (
        <div className='popup-style'>
          <div className='popup-content-style'>
            <p>
              {chatbot.disclaimer}
            </p>
            <button type='button' className='btn btn-primary' onClick={() => setShowDisclaimer(false)}>
              {literals.disclaimerok}
            </button>
          </div>
        </div>
      )}
      <ChatForm
        conversationId={conversationId}
        conversation={conversation}
        messages={getFilteredMessages()}
        onSendMessage={handleSendMessage}
        onSendAudio={handleSendAudioMessage}
        onUploadFile={handleUploadFile}
        chatbot={chatbot}
        header={header}
        disabled={disabled}
        showTransferedMessage={showTransferedMessage}
        onClose={onClose}
      />
    </>
  );
}

Chat.propTypes = {
  chatbot: PropTypes.shape({
    id: PropTypes.string,
    initial_message: PropTypes.string,
    show_disclaimer: PropTypes.bool,
    disclaimer: PropTypes.string,
    extra_info: PropTypes.object,
  }).isRequired,
  onCreateConversation: PropTypes.func,
  onBeforeSendMessage: PropTypes.func,
  conversationId: PropTypes.string,
  header: PropTypes.bool,
  disabled: PropTypes.bool,
  variables: PropTypes.object,
  onClose: PropTypes.func,
  orchestratorVersionId: PropTypes.string,
  isDebug: PropTypes.bool,
};

Chat.defaultProps = {
  conversationId: null,
  onCreateConversation: null,
  header: false,
  disabled: false,
  variables: {},
  onBeforeSendMessage: null,
  onClose: null,
  orchestratorVersionId: null,
  isDebug: false,
};

export default Chat;
