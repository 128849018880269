import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from 'components/Layout';
import useLiterals from 'hooks/useLiterals';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownMenuToggle from 'components/DropdownMenuToggle';
import ConfirmModal from 'components/ConfirmModal';
import { deleteDatastructure } from 'modules/datastructures/actions';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import DatastructureForm from './components/form';

function Datastructures() {
  const [showForm, setShowForm] = useState(false);
  const [selectedDatastructure, setSelectedDatastructure] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const literals = useLiterals('datastructures');
  const { datastructures } = useSelector((state) => state.datastructures);
  const { actualOrganization } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { showErrorModal, printErrorModal } = useErrorHandling();

  const getFilteredDatastructures = () => {
    if (!datastructures) {
      return [];
    }

    if (!actualOrganization) {
      return datastructures;
    }

    if (actualOrganization.id === 'personal') {
      return datastructures.filter((datastructure) => !datastructure.organizations || !datastructure.organizations.length);
    }

    return datastructures.filter((datastructure) => datastructure.organizations && datastructure.organizations.includes(actualOrganization.id));
  };

  const handleCreateDataStructure = () => {
    setSelectedDatastructure(null);
    setShowForm(true);
  };

  const handleEditDatastructure = (e) => {
    setSelectedDatastructure(e.currentTarget.getAttribute('data-id'));
    setShowForm(true);
  };

  const handleDeleteDatastructure = (e) => {
    setSelectedDatastructure(e.currentTarget.getAttribute('data-id'));
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDeleteOrchestrator = async () => {
    if (selectedDatastructure) {
      try {
        await dispatch(deleteDatastructure(selectedDatastructure));
        setShowDeleteModal(false);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        showErrorModal(errorMessage);
      }
    }
  };

  const printDeleteModal = () => {
    const datastructure = datastructures.find((d) => d.id === selectedDatastructure);
    const title = `${literals.confirmDeletDataStructure} '${datastructure?.name}'?`;
    return (
      <ConfirmModal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        onSubmit={confirmDeleteOrchestrator}
        title={title}
      />
    );
  };

  const printNoElements = () => {
    return (
      <div className='page_content'>
        <div className='no_elements'>{literals.thereIsNoDatastructures}</div>
      </div>
    );
  };

  const printTable = () => {
    const rows = getFilteredDatastructures().map((datastructure) => {
      return (
        <tr key={datastructure.id} data-id={datastructure.id}>
          <td>{datastructure.name}</td>
          <td>{datastructure.description}</td>
          <td className='right'>
            <button
              className='btn btn-primary btn-sm'
              type='button'
              data-id={datastructure.id}
              onClick={handleEditDatastructure}
            >
              {literals.common.edit}
            </button>
            <Dropdown className='dropdown-menu-expand'>
              <Dropdown.Toggle as={DropdownMenuToggle} id={`dropdown-custom-components-${datastructure.id}`} />
              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey='2'
                  className='text-danger'
                  data-id={datastructure.id}
                  onClick={handleDeleteDatastructure}
                >
                  {literals.common.delete}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
    return (
      <div>
        <table className='table_stl1'>
          <thead>
            <tr>
              <th>{literals.common.name}</th>
              <th>{literals.common.description}</th>
              <th className='right'>{literals.common.options}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const printForm = () => {
    if (!showForm) {
      return null;
    }

    if (selectedDatastructure) {
      const datastructure = datastructures.find((d) => d.id === selectedDatastructure);
      return (
        <DatastructureForm
          id={datastructure.id}
          name={datastructure.name}
          description={datastructure.description}
          fields={datastructure.fields}
          organizations={datastructure.organizations}
          onSave={closeForm}
          onClose={closeForm}
        />
      );
    }
    return (
      <DatastructureForm
        onSave={closeForm}
        onClose={closeForm}
      />
    );
  };

  return (
    <>
      {printErrorModal()?.modal}
      {printDeleteModal()}
      {printForm()}
      <Layout selectedSection='datastructures'>
        <div className='section'>
          <div className='section_header'>
            <h1>{literals.datastructures}</h1>
            <div className='btn btn-primary' onClick={handleCreateDataStructure}>{literals.addDatastructure}</div>
          </div>
          <div className='mt-lg'>
            {datastructures && getFilteredDatastructures().length ? printTable() : printNoElements()}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Datastructures;
