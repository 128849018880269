import React from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
// import Logo from 'assets/img/logo.svg';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'components/Modal';
import { ROUTE_PATH } from 'routes';
import isEmail from 'validator/lib/isEmail';

function LoginForm(props) {
  const {
    error,
    onSubmit,
    isLoading,
    email,
    onChangeEmail,
    showModalSuccess,
    showModalError,
    navigate,
  } = props;

  const literals = useLiterals('login');

  const handleChangeEmail = (e) => {
    onChangeEmail(e.target.value);
  };

  const canContinue = () => {
    return isEmail(email);
  };

  return (
    <div className='login-form'>
      <Modal
        title={literals.forgotPasswordSuccessTitle}
        description={literals.forgotPasswordSuccessBody}
        confirmText={literals.accept}
        open={showModalSuccess}
        onClose={() => navigate(ROUTE_PATH.LOGIN)}
        onSubmit={() => navigate(ROUTE_PATH.LOGIN)}
      />
      <Modal
        title={literals.forgotPasswordFailTitle}
        description={literals.forgotPasswordFailBody}
        confirmText={literals.accept}
        open={showModalError}
        onClose={() => navigate(ROUTE_PATH.LOGIN)}
        onSubmit={() => navigate(ROUTE_PATH.LOGIN)}
      />
      <div className='login-bg'>
        {/* <img src={Logo} alt='logo' className='logo' /> */}
        <div className='welcome_text'>{literals.welcomeBack}</div>
      </div>
      <div className='llm-chatbot-form-content'>
        <div>
          <h1>{literals.resetPassword}</h1>
          <h2 className='mb-md'>{literals.forgotPasswordDescription}</h2>
          <Form>
            <Form.Label className='mt-xl' htmlFor='email'>{literals.email}</Form.Label>
            <Form.Control
              type='email'
              id='email'
              value={email}
              onChange={handleChangeEmail}
            />
            <Button
              variant='primary'
              className='mt-sm mb-xxl'
              onClick={onSubmit}
              disabled={!canContinue()}
            >
              {isLoading && (<i className='fa-solid fa-spinner fa-spin' />)}
              {literals.resetPassword}
            </Button>
            {error && (<div className='error'>{literals[error] || error}</div>)}
            <div className='mt-xxl'>
              {literals.haveAnAccount}
              {' '}
              <a href='/login' className='font-semibold'>{literals.loginHere}</a>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

LoginForm.propTypes = {
  email: PropTypes.string.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showModalSuccess: PropTypes.bool.isRequired,
  showModalError: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default LoginForm;
