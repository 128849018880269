import ApiFetch from 'utils/apiFetch';
import { createDatatable, deleteDatatable, updateDatatable } from './actions';

const ApiFetchInstance = ApiFetch();

const URLS = {
  getDatatables: 'datatables',
  createDatatable: 'datatables',
  updateDatatable: (datatableId) => `datatables/${datatableId}`,
  deleteDatatable: (datatableId) => `datatables/${datatableId}`,
  getDatatableRows: (datatableId) => `datatables/${datatableId}/rows`,
  createDatatableRow: (datatableId) => `datatables/${datatableId}/rows`,
  updateDatatableRow: (datatableId, rowId) => `datatables/${datatableId}/rows/${rowId}`,
  deleteDatatableRow: (datatableId, rowId) => `datatables/${datatableId}/rows/${rowId}`,
  importDatatableRows: (datatableId) => `datatables/${datatableId}/rows/import`,
};

export default {
  async getDatatables() {
    return ApiFetchInstance.get(URLS.getDatatables);
  },
  async createDatatable(data = null) {
    return ApiFetchInstance.post(URLS.createDatatable, data);
  },
  async updateDatatable(datatableId, data = null) {
    return ApiFetchInstance.put(URLS.updateDatatable(datatableId), data);
  },
  async deleteDatatable(datatableId) {
    return ApiFetchInstance.delete(URLS.deleteDatatable(datatableId));
  },
  async getDatatableRows(datatableId, from, size) {
    const url = `${URLS.getDatatableRows(datatableId)}?offset=${from}&limit=${size}`;
    return ApiFetchInstance.get(url);
  },
  async createDatatableRow(datatableId, data = null) {
    return ApiFetchInstance.post(URLS.createDatatableRow(datatableId), data);
  },
  async updateDatatableRow(datatableId, rowId, data = null) {
    return ApiFetchInstance.put(URLS.updateDatatableRow(datatableId, rowId), data);
  },
  async deleteDatatableRow(datatableId, rowId) {
    return ApiFetchInstance.delete(URLS.deleteDatatableRow(datatableId, rowId));
  },
  async importDatatableRows(datatableId, data = null) {
    return ApiFetchInstance.post(URLS.importDatatableRows(datatableId), data);
  },
};
