/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';

function CustomPaginator({ filters, total, onPageChange }) {
  const totalPages = Math.ceil(total / filters.limit);

  const getPageNumbers = () => {
    const currentPage = filters.page;
    const pageNumbers = [];
    const maxPagesToShow = 3;

    if (totalPages <= maxPagesToShow + 2) {
      // Si hay pocas páginas, mostrar todas
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Mostrar las primeras 2 páginas
      pageNumbers.push(1, 2);

      // Mostrar 3 páginas más cercanas a la página actual
      for (let i = Math.max(3, currentPage - 1); i <= Math.min(currentPage + 1, totalPages - 2); i++) {
        pageNumbers.push(i);
      }

      // Mostrar las últimas 2 páginas
      pageNumbers.push(totalPages - 1, totalPages);
    }

    return pageNumbers;
  };

  const renderPaginationItems = () => {
    const pageNumbers = getPageNumbers();

    return pageNumbers.map((pageNumber, index) => (
      <React.Fragment key={index}>
        {index > 0 && pageNumber - pageNumbers[index - 1] > 1 && <Pagination.Ellipsis />}
        <Pagination.Item
          active={pageNumber - 1 === filters.page}
          onClick={() => onPageChange(pageNumber - 1)}
        >
          {pageNumber}
        </Pagination.Item>
      </React.Fragment>
    ));
  };

  return (
    <div className='d-flex justify-content-center mt-3'>
      <Pagination>
        <Pagination.Prev onClick={() => onPageChange(Math.max(1, filters.page - 1))} disabled={filters.page === 1} />

        {renderPaginationItems()}

        <Pagination.Next onClick={() => onPageChange(Math.min(totalPages, filters.page + 1))} disabled={filters.page === totalPages} />
        <Pagination.Last onClick={() => onPageChange(totalPages)} />
      </Pagination>
    </div>
  );
}

CustomPaginator.propTypes = {
  filters: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
  }).isRequired,
  total: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default CustomPaginator;
