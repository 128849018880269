import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateChatbot } from 'modules/chatbots/actions';
import { getErrorMessage } from 'utils/errors';
import useLiterals from 'hooks/useLiterals';
import FeedbackModal from 'components/FeedbackModal';

function Tools() {
  const [currentChatbot, setCurrentChatbot] = useState({});
  const [isSaving, setIsSaving] = useState(null);
  const [error, setError] = useState(null);

  const { apis } = useSelector((state) => state.apis);
  const { tools } = useSelector((state) => state.tools);
  const { chatbots } = useSelector((state) => state.chatbots);

  const { id } = useParams();
  const literals = useLiterals('chatbot');
  const dispatch = useDispatch();

  const getChatbot = () => {
    if (!chatbots) {
      return null;
    }

    const chatbot = chatbots.find((c) => c.id === id);
    return chatbot;
  };

  useEffect(() => {
    setCurrentChatbot(getChatbot());
  }, [chatbots]);

  const updateChatbotTools = async (tool) => {
    setIsSaving(tool);
    let newTools = [];
    try {
      if (currentChatbot?.tools?.includes(tool)) {
        newTools = currentChatbot.tools.filter((t) => t !== tool);
      } else {
        newTools = [...currentChatbot.tools, tool];
      }
      await dispatch(updateChatbot(currentChatbot?.id, currentChatbot?.name, newTools, currentChatbot?.model));
    } catch (err) {
      const errorMsg = getErrorMessage(err);
      setError(errorMsg);
    } finally {
      setIsSaving(null);
    }
  };

  const renderTools = () => {
    const chatbot = getChatbot();
    return (
      <div className='tools__container'>
        {
          tools.map((tool) => {
            const isSubscribed = (chatbot.tools && chatbot.tools.includes(tool.id)) || tool.name_tag === 'agency_chat';
            if (tool.name_tag === 'agency_chat') {
              return null;
            }
            return (
              <div key={tool.id} className={`tools__container__item ${isSubscribed ? 'subscribed' : ''}`}>
                <div>
                  <div className='tools__container__item__name'>
                    {tool.name}
                  </div>
                  <div className='tools__container__item__description'>
                    {tool.description}
                  </div>
                </div>
                {isSubscribed && tool.name_tag !== 'agency_chat' && (
                  <div className='btn btn-secondary' onClick={() => updateChatbotTools(tool.id)}>
                    {isSaving === tool.id && (<i className='fa-solid fa-spinner fa-spin' />)}
                    {literals.disconnect}
                  </div>
                )}
                {!isSubscribed && tool.name_tag !== 'agency_chat' && (
                  <div className='btn btn-primary' onClick={() => updateChatbotTools(tool.id)}>
                    {isSaving === tool.id && (<i className='fa-solid fa-spinner fa-spin' />)}
                    {literals.connect}
                  </div>
                )}
              </div>
            );
          })
        }
        {
          apis.map((api) => {
            const isSubscribed = chatbot.tools && chatbot.tools.includes(api.id);
            return (
              <div key={api.id} className={`tools__container__item ${isSubscribed ? 'subscribed' : ''}`}>
                <div>
                  <div className='tools__container__item__name'>
                    {api.name}
                  </div>
                  <div className='tools__container__item__description'>
                    {api.description}
                  </div>
                </div>
                {isSubscribed && (
                  <div className='btn btn-secondary' onClick={() => updateChatbotTools(api.id)}>
                    {isSaving === api.id && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
                    {literals.disconnect}
                  </div>
                )}
                {!isSubscribed && (
                  <div className='btn btn-primary' onClick={() => updateChatbotTools(api.id)}>
                    {isSaving === api.id && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
                    {literals.connect}
                  </div>
                )}
              </div>
            );
          })
        }
      </div>
    );
  };

  const clearError = () => {
    setError(null);
  };

  const printErrorModal = () => {
    if (!error) {
      return null;
    }

    return (
      <FeedbackModal
        open
        onClose={clearError}
        title={literals.common.error}
        success={false}
        message={error}
      />
    );
  };

  return (
    <>
      {printErrorModal()}
      {renderTools()}
    </>
  );
}

export default Tools;
