/* eslint-disable no-param-reassign */

export const SESSION_LOGOUT = 'session/clear';
export const INIT_SESSION = 'session/init';
export const SAVE_TOKENS = 'session/saveTokens';
export const SET_USER_DATA = 'session/setUserData';

export const initialState = {
  authenticated: false,
  checked: false,
  error: '',
  user: {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
  },
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case INIT_SESSION:
      return {
        ...state,
        checked: true,
      };
    case SESSION_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case SET_USER_DATA:
      return {
        ...state,
        authenticated: true,
        user: payload,
      };
    default:
      return state;
  }
};

export default reducer;
