import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const STRING_CATEGORY = 'String';

export default {
  STRING_EQUALS: {
    categories: [STRING_CATEGORY],
    tag: 'stringEquals',
    name: '== (String)',
    tags: ['equals', 'string', 'compare', '=='],
    icon: 'fa-solid fa-equals',
    inputs: [
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'b',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '==',
        custom: false,
        to: [],
      },
    ],
  },
  STRING_CONCAT: {
    categories: [STRING_CATEGORY],
    tag: 'stringConcat',
    name: 'Concatenate (String)',
    tags: ['concatenate', 'string', 'join', 'concat', '+', 'append'],
    icon: 'fa-solid fa-plus',
    inputs: [
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'b',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a + b',
        custom: false,
        to: [],
      },
    ],
  },
  STRING_INDEXOF: {
    categories: [STRING_CATEGORY],
    tag: 'stringIndexOf',
    name: 'Index Of (String)',
    tags: ['index', 'indexOf', 'string', 'find', 'search', 'position'],
    icon: 'fa-solid fa-search',
    inputs: [
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'string',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'search',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'index',
        custom: false,
        to: [],
      },
    ],
  },
  TO_STRING: {
    categories: [STRING_CATEGORY],
    tag: 'toString',
    name: 'To string',
    tags: ['to', 'string', 'toString'],
    icon: 'fa-solid fa-text',
    inputs: [
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'variable',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'string',
        custom: false,
        to: [],
      },
    ],
  },
  TO_OBJECT: {
    categories: [STRING_CATEGORY],
    tag: 'toObject',
    name: 'To object',
    tags: ['to', 'string', 'toObject', 'object'],
    icon: 'fa-solid fa-rectangle-list',
    inputs: [
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'string',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'object',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'success',
        custom: false,
        to: [],
      },
    ],
  },
  PROCESS_MERGE_TAGS: {
    categories: [STRING_CATEGORY],
    tag: 'processMergeTags',
    name: 'Process merge tags',
    tags: ['tags', 'string', 'process', 'mergetags'],
    icon: 'fa-solid fa-tags',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'text',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'text',
        custom: false,
        to: [],
      },
    ],
  },
  OBTAIN_NUMBER_TOKENS: {
    categories: [STRING_CATEGORY],
    tag: 'obtainNumberTokens',
    name: 'Obtain number tokens',
    tags: ['number', 'tokens', 'obtain', 'extract'],
    icon: 'fa-solid fa-number',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'text',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'tokens',
        custom: false,
        to: [],
      },
    ],
  },
  SPLIT_TEXT_BY_CHUNK_SIZE: {
    categories: [STRING_CATEGORY],
    tag: 'splitTextByChunkSize',
    name: 'Split text by chunk size',
    tags: ['split', 'text', 'chunk', 'size', 'divide text', 'paragraph'],
    icon: 'fa-solid fa-number',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'text',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'chunk_size',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'chunks',
        custom: false,
        to: [],
      },
    ],
  },
  STRING_TO_LOWER_CASE: {
    categories: [STRING_CATEGORY],
    tag: 'stringToLowerCase',
    name: 'To lower case',
    tags: ['to', 'lower', 'case', 'string', 'toLowerCase'],
    icon: 'fa-solid fa-text',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'string',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'string',
        custom: false,
        to: [],
      },
    ],
  },
  STRING_TO_UPPER_CASE: {
    categories: [STRING_CATEGORY],
    tag: 'stringToUpperCase',
    name: 'To upper case',
    tags: ['to', 'upper', 'case', 'string', 'toUpperCase'],
    icon: 'fa-solid fa-text',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'string',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'string',
        custom: false,
        to: [],
      },
    ],
  },
  STRING_SPLIT: {
    categories: [STRING_CATEGORY],
    tag: 'stringSplit',
    name: 'Split string',
    tags: ['split', 'string', 'divide', 'text', 'paragraph', 'string split', 'explode string'],
    icon: 'fa-solid fa-number',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'string',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'delimiter',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        to: [],
      },
    ],
  },
  STRING_JOIN: {
    categories: [STRING_CATEGORY],
    tag: 'stringJoin',
    name: 'Join string',
    tags: ['join', 'string', 'merge', 'text', 'paragraph', 'string join'],
    icon: 'fa-solid fa-number',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'delimiter',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'string',
        custom: false,
        to: [],
      },
    ],
  },
  STRING_REPLACE: {
    categories: [STRING_CATEGORY],
    tag: 'stringReplace',
    name: 'Replace string',
    tags: ['replace', 'string', 'substitute', 'text', 'paragraph', 'string replace'],
    icon: 'fa-solid fa-number',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'text',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'search',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'replace',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'result',
        custom: false,
        to: [],
      },
    ],
  },
  STRING_SEARCH: {
    categories: [STRING_CATEGORY],
    tag: 'stringSearch',
    name: 'Search in string',
    tags: ['search', 'string', 'find', 'text', 'paragraph', 'string search'],
    icon: 'fa-solid fa-number',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'text',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'pattern',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'result',
        custom: false,
        to: [],
      },
    ],
  },
};
