import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

function TestQuestionResultModal(props) {
  const {
    onClose,
    question,
    literals,
  } = props;
  return (
    <Modal size='lg' show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{question?.test_question_data?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='test-question-result__container'>
          <div className='test-question-result__container__item'>
            <strong>{literals.correctAnswer}</strong>
            <p>{question?.test_question_data?.real_answer}</p>
          </div>
          <div className='test-question-result__container__separator' />
          <div className='test-question-result__container__item'>
            <strong>{`${literals.chatbotAnswer} (${question?.chatbot_data?.name}):`}</strong>
            <p>{question?.llm_answer}</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

TestQuestionResultModal.propTypes = {
  literals: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
};

export default TestQuestionResultModal;
