import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const GOOGLE_CATEGORY = 'Google';

export default {
  SEARCH_IN_GOOGLE: {
    categories: [GOOGLE_CATEGORY],
    tag: 'searchInGoogle',
    name: 'Search in Google',
    tags: ['google', 'search'],
    icon: 'fa-brands fa-google',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'search',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'type',
        options: ['web', 'image'],
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'language',
        options: ['lang_ar', 'lang_bg', 'lang_ca', 'lang_cs', 'lang_da', 'lang_de', 'lang_el', 'lang_en', 'lang_es', 'lang_et', 'lang_fi', 'lang_fr', 'lang_hr', 'lang_hu', 'lang_id', 'lang_is', 'lang_it', 'lang_iw', 'lang_ja', 'lang_ko', 'lang_lt', 'lang_lv', 'lang_nl', 'lang_no', 'lang_pl', 'lang_pt', 'lang_ro', 'lang_ru', 'lang_sk', 'lang_sl', 'lang_sr', 'lang_sv', 'lang_tr', 'lang_zh-CN', 'lang_zh-TW'],
        default_value: 'lang_en',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'exact_terms',
        default_value: false,
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'exclude_terms',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'site_search',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'results',
        custom: false,
        to: [],
      },
    ],
  },
  SEARCH_IN_YOUTUBE: {
    categories: [GOOGLE_CATEGORY],
    tag: 'searchInYoutube',
    name: 'Search in Youtube',
    tags: ['Youtube', 'search'],
    icon: 'fa-brands fa-youtube',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'search',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'max_results',
        default_value: 10,
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'results',
        custom: false,
        to: [],
      },
    ],
  },
};
