import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import { NODE_TYPES_STRUCTURE } from 'constants/orchestrator_nodes';
import { NODE_TYPES, TYPE_CONNECTIONS } from 'constants/orchestrator';
import { useSelector } from 'react-redux';

function ContextMenu({
  x, y, onSelectNodeType, orchestrator, selectedVersion, selectedFunction, selectedVersionFunction,
}) {
  const [search, setSearch] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  const inputRef = useRef(null);
  const literals = useLiterals('orchestrators');
  const { forms } = useSelector((state) => state.forms);

  const handleChangeSearch = (event) => {
    setSearch(event.target.value.toLowerCase());
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const getVariablesFromOrchestratorVersion = (orchestratorAux, selectedVersionAux) => {
    const variables = [];

    // Orchestrator variables
    const actualVersion = orchestratorAux.versions.find((version) => version.id === selectedVersionAux);
    const actualVersionVariables = actualVersion?.variables ?? [];
    actualVersionVariables.forEach((variable) => {
      variables.push(variable.name);
    });

    // Outputs from initial node
    const versionItems = orchestratorAux.children.filter((node) => node.orchestrator_version_id === selectedVersionAux);
    const initialNode = versionItems.find((node) => node.type === NODE_TYPES.INITIAL);

    if (initialNode) {
      initialNode.outputs.forEach((output) => {
        if (output.type !== TYPE_CONNECTIONS.EXEC) {
          variables.push(output.name);
        }
      });
    }

    // Fields from forms
    versionItems.forEach((node) => {
      if (node.type === NODE_TYPES.PRINT_FORM) {
        const formId = node.inputs.find((input) => input.type === TYPE_CONNECTIONS.FORM)?.default_value?.id;
        if (formId) {
          const form = forms.find((f) => f.id === formId);
          if (form) {
            const questions = form.data?.questions ?? [];
            questions.forEach((question) => {
              variables.push(question.variable_name);
            });
          }
        }
      }
    });

    return variables;
  };

  const getFilteredNodes = () => {
    const nodesStructure = { ...NODE_TYPES_STRUCTURE };

    // Insert get and setters
    const orchestratorVariables = getVariablesFromOrchestratorVersion(orchestrator, selectedVersion);
    let functionVariables = [];

    if (selectedFunction && selectedVersionFunction) {
      const functionItem = orchestrator.functions.find((f) => f.id === selectedFunction);
      functionVariables = getVariablesFromOrchestratorVersion(functionItem, selectedVersionFunction);
    }

    const variables = [...orchestratorVariables];

    variables.sort();

    variables.forEach((variable) => {
      nodesStructure[`GET_${variable}`] = {
        name: `Get ${variable}`,
        categories: ['Variables'],
        tags: ['llm'],
      };

      nodesStructure[`SET_${variable}`] = {
        name: `Set ${variable}`,
        categories: ['Variables'],
        tags: ['llm'],
      };
    });

    functionVariables.sort();

    functionVariables.forEach((variable) => {
      nodesStructure[`GET_${variable}`] = {
        name: `Get ${variable}`,
        categories: ['Local variables'],
        tags: ['llm'],
      };

      nodesStructure[`SET_${variable}`] = {
        name: `Set ${variable}`,
        categories: ['Local variables'],
        tags: ['llm'],
      };
    });

    // Functions
    orchestrator.functions.forEach((functionItem) => {
      nodesStructure[`FUNCTION_${functionItem.id}`] = {
        name: functionItem.name,
        categories: ['Functions'],
        tags: ['llm'],
      };
    });

    const filteredNodes = Object.keys(nodesStructure).filter((key) => {
      if (!search) {
        return true;
      }

      // Check node name
      const nodeName = nodesStructure[key].name.toLowerCase();
      if (nodeName.includes(search)) {
        return true;
      }

      // Check tag
      const tags = nodesStructure[key].tags;
      if (tags) {
        for (let i = 0; i < tags.length; i += 1) {
          if (tags[i].toLowerCase().includes(search)) {
            return true;
          }
        }
      }

      return false;
    });

    // Get categories
    const categories = [];
    filteredNodes.forEach((key) => {
      const categoriesNode = nodesStructure[key].categories;
      categoriesNode.forEach((category) => {
        if (!categories.includes(category)) {
          categories.push(category);
        }
      });
    });

    // Sort categories
    categories.sort();

    const nodes = [];
    // Print categories
    categories.forEach((category) => {
      filteredNodes.forEach((key) => {
        const node = nodesStructure[key];
        if (node.categories.includes(category)) {
          nodes.push(key);
        }
      });
    });

    return { nodes, categories, structure: nodesStructure };
  };

  const handleSelectNodeType = (type) => {
    let nodeType = type;
    const extraInfo = {};
    if (type.startsWith('GET_') || type.startsWith('SET_')) {
      const pieces = type.split('_');
      nodeType = pieces.shift();
      const variable = pieces.join('_');
      extraInfo.variable = variable;
    } else if (type.startsWith('FUNCTION_')) {
      const pieces = type.split('_');
      nodeType = NODE_TYPES.FUNCTION;
      extraInfo.function_id = pieces[1];
    }
    onSelectNodeType(nodeType, extraInfo);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && selectedItem) {
      handleSelectNodeType(selectedItem);
    } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      const { nodes: filteredNodes } = getFilteredNodes();
      const index = filteredNodes.indexOf(selectedItem);
      if (index !== -1) {
        if (event.key === 'ArrowDown') {
          if (index + 1 < filteredNodes.length) {
            setSelectedItem(filteredNodes[index + 1]);
          }
        } else if (event.key === 'ArrowUp') {
          if (index - 1 >= 0) {
            setSelectedItem(filteredNodes[index - 1]);
          }
        }
      }
    }
  };

  const handleMouseEnter = (event) => {
    const nodeType = event.target.getAttribute('data-type');
    setSelectedItem(nodeType);
  };

  const handleClick = (event) => {
    const nodeType = event.target.getAttribute('data-type');
    handleSelectNodeType(nodeType);
  };

  const printMethods = () => {
    const { nodes: filteredNodes, categories, structure: nodesStructure } = getFilteredNodes();
    // Print categories
    return categories.map((category) => {
      return (
        <div key={category} className='flex flex-column mb-2'>
          <div className='contextual-menu-title text-xs font-semibold'>{category}</div>
          {filteredNodes.map((key) => {
            const node = nodesStructure[key];
            const isNodeSelected = selectedItem && selectedItem === key;
            if (node.categories.includes(category)) {
              return (
                <div
                  key={key}
                  className={`pointer pl-4 text-xs py-1 ${isNodeSelected ? 'bg-primary text-white' : ''}`}
                  data-type={key}
                  onMouseEnter={handleMouseEnter}
                  onClick={handleClick}
                >
                  {node.name}
                </div>
              );
            }
            return null;
          })}
        </div>
      );
    });
  };

  useEffect(() => {
    const { nodes: filteredNodes } = getFilteredNodes();
    if (filteredNodes.length) {
      setSelectedItem(filteredNodes[0]);
    } else {
      setSelectedItem(null);
    }
  }, [search]);

  const style = {
    top: y,
    left: x,
  };

  return (
    <div style={style} className='contextual-menu flex flex-column' onKeyDown={handleKeyDown}>
      <div className='w-full input_value'>
        <input
          ref={inputRef}
          type='text'
          value={search}
          onChange={handleChangeSearch}
          className='w-full form_input_stl'
          placeholder={literals.common.search}
        />
      </div>
      <div className='flex-grow overflow-y-auto contextual-menu-methods'>
        {printMethods()}
      </div>
    </div>
  );
}

ContextMenu.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  onSelectNodeType: PropTypes.func.isRequired,
  orchestrator: PropTypes.object.isRequired,
  selectedVersion: PropTypes.string.isRequired,
  selectedFunction: PropTypes.string,
  selectedVersionFunction: PropTypes.string,
};

ContextMenu.defaultProps = {
  selectedFunction: null,
  selectedVersionFunction: null,
};

export default ContextMenu;
