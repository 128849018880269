import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {
  deleteTest,
  createChatbotQuestion,
  getTests,
  createTest,
  getTestDetails,
  getAllQuestions,
} from 'modules/tests/actions';
import {
  getMyOrganizations,
} from 'modules/organizations/actions';
import { getErrorMessage } from 'utils/errors';
import { transformDate } from 'utils/dates';
import Layout from 'components/Layout';
import CreateQuestionForm from './components/createQuestionForm';
import CreateTestForm from './components/createTestForm';
import TestDetails from './components/testDetails';
import './styles.scss';
import ExcelUpload from './components/excelUpload';

const TAB_TESTS = 'tests';
const TAB_QUESTIONS = 'questions';

function Tests(props) {
  const { chatbotId } = props;
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState([]);
  const { tests } = useSelector((state) => state.tests);
  // const [tests, setTests] = useState([]);
  const [testSelected, setTestSelected] = useState(null);
  const [testDeleted, setTestDeleted] = useState(null);
  const [testDetails, setTestDetails] = useState([]);
  const [showCreateQuestionForm, setShowCreateQuestionForm] = useState(false);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [newQuestion, setNewQuestion] = useState({
    title: '',
    answer: '',
    organizations: [],
  });
  const [showInitTestForm, setShowInitTestForm] = useState(false);
  const [activeTab, setActiveTab] = useState(TAB_TESTS);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState([]);

  const literals = useLiterals('tests');
  const { organizations } = useSelector((state) => state.organizations);
  const { chatbots } = useSelector((state) => state.chatbots);
  const { actualOrganization } = useSelector((state) => state.app);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const loadQuestions = async () => {
    try {
      const response = await getAllQuestions();
      setQuestions(response.questions);
    } catch (e) {
      setError(getErrorMessage(e));
    }
  };

  const loadTests = async () => {
    try {
      await dispatch(getTests());
    } catch (e) {
      setError(getErrorMessage(e));
    }
  };

  useEffect(() => {
    if (actualOrganization) {
      setSelectedOrganizations(actualOrganization.id === 'personal' ? [] : [{ value: actualOrganization.id, label: actualOrganization.name }]);
    }
  }, [actualOrganization]);

  useEffect(() => {
    dispatch(getMyOrganizations());
    loadQuestions();
    loadTests();
  }, [chatbotId]);

  useEffect(() => {
    if (testSelected) {
      getTestDetails(testSelected)
        .then((response) => {
          setTestDetails(response?.tests);
        })
        .catch((e) => {
          setError(getErrorMessage(e));
        });
    }
  }, [testSelected]);

  const saveQuestion = async () => {
    try {
      setIsSaving(true);
      await createChatbotQuestion(newQuestion);
      setShowCreateQuestionForm(false);
      setNewQuestion({
        title: '',
        answer: '',
        organizations: [],
      });
      loadQuestions();
    } catch (e) {
      setError(getErrorMessage(e));
    } finally {
      setIsSaving(false);
    }
  };

  const handleInitTest = (testData) => {
    try {
      dispatch(createTest(testData));
      setShowInitTestForm(false);
    } catch (e) {
      setError(getErrorMessage(e));
    }
  };

  const handleSelectTestsTab = () => {
    setActiveTab(TAB_TESTS);
  };

  const handleSelectQuestions = () => {
    setActiveTab(TAB_QUESTIONS);
  };

  const handleChangeTitle = (title) => {
    setNewQuestion({
      ...newQuestion,
      title,
    });
  };

  const handleChangeAnswer = (answer) => {
    setNewQuestion({
      ...newQuestion,
      answer,
    });
  };

  const handleSelectOrganization = (selectedValues) => {
    setNewQuestion({
      ...newQuestion,
      organizations: selectedValues.map((value) => value.value),
    });
    setSelectedOrganizations(selectedValues);
  };

  const handlePositiveConfirmation = async () => {
    if (testDeleted) {
      // Eliminar una conversación específica
      dispatch(deleteTest(testDeleted));
    }
    setTestDeleted(null);
    setShowConfirmation(false);
  };

  const handleDeleteTest = async (testId) => {
    setTestDeleted(testId);
    setShowConfirmation(true);
  };

  const closeDeleteTestForm = () => {
    setShowCreateQuestionForm(null);
    setShowInitTestForm(false);
    setTestDeleted(null);
    setShowConfirmation(false);
  };

  const printInitTestForm = () => {
    if (!showInitTestForm) {
      return null;
    }

    return (
      <CreateTestForm
        onClose={() => setShowInitTestForm(false)}
        literals={literals}
        initTest={handleInitTest}
        questions={questions}
        allOrganizations={organizations}
        allChatbots={chatbots}
      />
    );
  };

  const printCreateQuestionModalForm = () => {
    if (!showCreateQuestionForm) {
      return null;
    }

    return (
      <Modal show onHide={() => setShowCreateQuestionForm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{literals.createQuestion}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateQuestionForm
            title={newQuestion.title}
            answer={newQuestion.answer}
            allOrganizations={organizations}
            selectedOrganizations={selectedOrganizations}
            onChangeOrganizations={handleSelectOrganization}
            onChangeTitle={handleChangeTitle}
            onChangeAnswer={handleChangeAnswer}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowCreateQuestionForm(false)}>
            {literals.common.close}
          </Button>
          <Button
            variant='primary'
            onClick={saveQuestion}
            disabled={!newQuestion.title || !newQuestion.answer || isSaving}
          >
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {literals.common.accept}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const printTestDetails = () => {
    return (
      <TestDetails
        literals={literals}
        tests={testDetails}
        goBack={() => {
          setTestSelected(null);
          setTestDetails([]);
        }}
      />
    );
  };

  const printQuestions = () => {
    let rows = [];
    if (activeTab === TAB_QUESTIONS) {
      rows = questions.map((question) => {
        return (
          <div className='panel'>
            <div className='panel-header'>{question.title}</div>
            <div className='panel-body'>
              <p>{question.real_answer}</p>
            </div>
          </div>
        );
      });
    }
    if (activeTab === TAB_TESTS) {
      rows = tests.map((test) => {
        return (
          <tr key={test.id}>
            <td>{test.name}</td>
            <td>{transformDate(test.created_at)}</td>
            <td className='right'>
              <div>
                <button
                  className='btn btn-secondary btn-sm'
                  type='button'
                  onClick={() => {
                    handleDeleteTest(test.test_id);
                  }}
                >
                  {literals.deleteTest}
                </button>
                <button
                  className='btn btn-primary btn-sm'
                  type='button'
                  onClick={() => {
                    setTestSelected(test.test_id);
                  }}
                >
                  {literals.showResults}
                </button>
              </div>
            </td>
          </tr>
        );
      });
    }
    return (
      <div>
        <div className='section_content mt-3'>
          <div className='tabs'>
            <div className={`tab ${activeTab === TAB_TESTS ? 'selected' : ''}`} onClick={handleSelectTestsTab}>
              {literals.tests}
            </div>
            <div className={`tab ${activeTab === TAB_QUESTIONS ? 'selected' : ''}`} onClick={handleSelectQuestions}>
              {literals.questions}
            </div>
          </div>
        </div>
        <div className='text-align-right subsection_buttons'>
          {activeTab === TAB_QUESTIONS && (
            <>
              <Button type='button' className='btn btn-primary' onClick={() => setShowCreateQuestionForm(true)}>
                {literals.createQuestion1}
              </Button>
              <ExcelUpload
                literals={literals}
                allOrganizations={organizations}
                loadQuestions={loadQuestions}
              />
            </>
          )}
          {activeTab === TAB_TESTS && (
            <Button type='button' className='btn btn-primary' onClick={() => setShowInitTestForm(true)}>
              {literals.initTest}
            </Button>
          )}
        </div>
        {activeTab === TAB_QUESTIONS && (
          <div>
            {rows}
          </div>
        )}
        {activeTab === TAB_TESTS && (
          <table className='table_stl1'>
            <thead>
              <tr>
                <th>{literals.common.name}</th>
                <th>{literals.date}</th>
                <th>{ }</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  const printDeleteForm = () => {
    if (!showConfirmation) {
      return null;
    }
    const confirmationText = literals.deleteTest;
    return (
      <Modal show onHide={closeDeleteTestForm}>
        <Modal.Header closeButton>
          <Modal.Title>{confirmationText}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeDeleteTestForm}>
            {literals.common.close}
          </Button>
          <Button
            variant='primary'
            onClick={handlePositiveConfirmation}
            data-cy='chatbot-delete-confirm-button'
          >
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {literals.common.accept}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {printCreateQuestionModalForm()}
      {printInitTestForm()}
      {printDeleteForm()}
      <Layout selectedSection='tests'>
        {!testSelected && printQuestions()}
        {testSelected && printTestDetails()}
      </Layout>
    </>
  );
}

Tests.propTypes = {
  chatbotId: PropTypes.string,
};

Tests.defaultProps = {
  chatbotId: null,
};

export default Tests;
