export default {
  es: {
    login: {
      welcomeBack: 'Bienvenido de nuevo',
      login: 'Acceso',
      setPassword: 'Establecer contraseña',
      resetPassword: 'Restablecer contraseña',
      register: 'Registrarse',
      welcomeBackDescription: 'Bienvenido, por favor accede a tu cuenta.',
      setPasswordDescription: 'Por favor, establece una contraseña para tu cuenta.',
      registerDescription: 'Por favor, rellena los siguientes campos para crear tu cuenta.',
      email: 'Email',
      firstname: 'Nombre',
      lastname: 'Apellidos',
      password: 'Contraseña',
      confirmPassword: 'Confirmar contraseña',
      forgotPassword: '¿Has olvidado tu contraseña?',
      createAccount: 'Crea una cuenta',
      newHere: '¿Eres nuevo?',
      haveAnAccount: '¿Ya tienes una cuenta?',
      loginHere: 'Accede aquí',
      incorrect_credentials: 'Email o contraseña incorrectos',
      signupSuccessTitle: 'Registro realizado correctamente',
      signupSuccessBody: 'En breve recibirás un correo electrónico para confirmar tu cuenta. Por favor, revisa tu bandeja de entrada y tu carpeta de spam.',
      signupFailTitle: 'Error en el registro',
      signupFailBody: 'No se ha podido realizar el registro. Por favor, inténtalo de nuevo más tarde.',
      passwordSuccessTitle: 'Contraseña establecida correctamente',
      passwordSuccessBody: 'Cuenta creada correctamente. Puedes iniciar sesión con tu email y contraseña.',
      passwordFailTitle: 'Error al establecer contraseña',
      passwordFailBody: 'No se ha podido establecer la contraseña. Por favor, inténtalo de nuevo más tarde.',
      forgotPasswordSuccessTitle: 'Correo enviado correctamente',
      forgotPasswordSuccessBody: 'En breve recibirás un correo electrónico para restablecer tu contraseña. Por favor, revisa tu bandeja de entrada y tu carpeta de spam.',
      forgotPasswordFailTitle: 'Error al enviar el correo',
      forgotPasswordFailBody: 'No se ha podido enviar el correo. Por favor, inténtalo de nuevo más tarde.',
      forgotPasswordDescription: 'Por favor, introduce tu email para restablecer tu contraseña.',
      createPassword: 'Crear contraseña',
      passwordFormatError: 'Error en el formato de la contraseña, recuerda que debe incluir una Mayúscula, una minúscula y un número',
      passwordLengthError: 'Error en el formato de la contraseña, recuerda que la longitud mínima es de 10',
      accept: 'Aceptar',
      passwordLengthRequisite: 'Debe tener una longitud entre 10 y 70 caracteres',
      mustContainUpperLetter: 'Debe contener al menos una letra mayúscula',
      mustContainLowerLetter: 'Debe contener al menos una letra minúscula',
      mustContainANumber: 'Debe contener al menos un número',
      passwordsMustMatch: 'Las contraseñas deben coincidir',
    },
    layout: {
      myChatbots: 'Chatbots',
      myElements: 'Elementos',
      myOrganizations: 'Mis organizaciones',
      myApis: 'Mis APIs',
      myForms: 'formularios',
      tests: 'Pruebas',
      logout: 'Cerrar sesión',
      projects: 'Proyectos',
      orchestrators: 'Orquestradores',
      personal: 'Personal',
      allMyWorkspace: 'Mi espacio de trabajo',
      myApiKeys: 'API Keys',
      myDatastructures: 'Modelos de datos',
      myDatatables: 'Tablas de datos',
    },
    chat: {
      selecting_tool: 'Seleccionando herramienta',
      connecting_with_tool: 'Conectando con la herramienta',
      connection_with_orchestrator: 'Conectando con el orquestador',
      receiving_response: 'Recibiendo respuesta',
      optimizing_query_search: 'Optimizando consulta de búsqueda',
      getting_ambits: 'Extrayendo ámbitos de la consulta',
      searching_relevant_documents: 'Buscando posibles documentos relevantes',
      filtering_best_documents: 'Filtrando documentos más relevantes',
      selecting_content: 'Seleccionando contenido relevante',
      helps: 'Ayudas y subvenciones',
      agency_chat: 'Asesoría online',
      analyzing_info: 'Analizando información',
      received_response: 'Respuesta recibida',
      processing_file: 'Procesando archivo',
      generating_alternative_messages: 'Generando respuestas alternativas',
      attachments: 'Adjuntos',
      answer: 'Respuesta',
      of: 'de',
      writing: 'Escribiendo...',
      stopRecording: 'Terminar grabación',
      viewAsText: 'Ver como texto',
      viewAsAudio: 'Ver como audio',
      signinApiBasicAuth1: 'Para poder extraer información de la API',
      signinApiBasicAuth2: 'tienes que introducir las credenciales que uses en ella.',
      login: 'Aceptar',
      apiConfirmCall: 'Voy a realizar la siguiente operación, necesito que confirmes que te he entendido correctamente y los datos son correctos',
      action: 'Acción',
      params: 'Parámetros',
      requestBody: 'Cuerpo de la petición',
      selecting_action_and_params: 'Seleccionando acción a realizar y parámetros',
      cant_select_action_and_params: 'No se ha podido seleccionar la acción a realizar',
      executing_action: 'Realizando acción',
      get_action_result: 'Se ha recibido la respuesta de la API',
      signinApiOtpAuth1: 'Para poder extraer información de tu cuenta necesitamos confirmar tu identidad. Por favor, introduce la siguiente información.',
      signinApiOtpAuth2: 'Por favor, introduce el código que has recibido. Si no lo recibes asegúrate de que la información introducida es la que figura en tu cuenta de usuario y vuelve a intentarlo.',
      sendCode: 'Enviar código',
      verifyCode: 'Verificar código',
      back: 'Volver',
      chat: 'Chat',
      headerTitle: '¿Tienes alguna pregunta? ¡Hablemos!',
      orchestratorTrace: 'Traza del orquestador',
      attachFile: 'Adjuntar archivo',
      sendAudio: 'Enviar audio',
      send: 'Enviar',
      startingAudio: 'Iniciando componente de grabación',
      doYouHaveAnyQuestions: '¿Tienes alguna pregunta? ¡Hablemos!',
      conversationTransferred: 'La conversación anterior ha sido cerrada',
    },
    faq: {
      question: 'Pregunta',
      answer: 'Respuesta',
      noFaq: 'No has guardado ningun FAQ todavía, haz clic en Crear FAQ para añadir el primero',
      createFaq: 'Crear FAQ',
      cancel: 'Cancelar',
      deleteFaq: 'Eliminar FAQ',
      otherWaysOfExpressingTheQuestion: 'Otras formas de expresar la pregunta:',
      confirmDeleteFaqTitle: 'Eliminar FAQ',
      confirmDeleteFaqDescription: '¿Estás seguro de que quieres eliminar este FAQ?',
    },
    chatbots: {
      chatbots: 'Chatbots',
      createChatbot: 'Crear chatbot',
      deleteAllChat: 'Eliminar Chatbots',
      thereIsNoChatbots: 'No hay ningún chatbot creado',
      chatbot: 'Chatbot',
      orchestrators: 'Orquestadores',
      enabledTools: 'Herramientas activas',
      linkedElement: 'Elemento vinculado',
      editChatbot: 'Editar chatbot',
      organizations: 'Organizaciones',
      orchestrator: 'Orquestador',
    },
    organizations: {
      organizations: 'Organizaciones',
      description: 'Descripción',
      createOrganization: 'Crear organización',
      editOrganization: 'Editar organización',
      inviteUser: 'Invitar usuario',
      email: 'Email',
      viewMembers: 'Ver Miembros',
      status: 'Estado',
      firstname: 'Nombre',
      lastname: 'Apellido',
      reject: 'Rechazar',
      thereIsNoOrganizations: 'No hay ninguna organización creada',
      logo: 'Logo',
      primaryColor: 'Color principal',
      secondaryColor: 'Color secundario',
      deleteOrganizationTitle: 'Eliminar organización',
      deleteOrganizationDescription: '¿Estás seguro de que quieres eliminar la organización',
    },
    chatbot: {
      chatbot: 'Chatbot',
      conversations: 'Conversaciones',
      documents: 'Documentos',
      websites: 'Websites',
      texts: 'Textos',
      tools: 'Herramientas',
      faq: 'FAQ',
      tests: 'Pruebas',
      disclaimerok: 'Entendido',
      disclaimer: '¡Hola! Soy un chatbot diseñado para proporcionar información general y respuestas a tus preguntas. Ten en cuenta que mi objetivo es brindar asistencia de manera informativa y no constituye asesoramiento legal, financiero o profesional. La información proporcionada no debe considerarse como la base para la toma de decisiones importantes, y se recomienda buscar asesoramiento especializado en situaciones específicas. Además, ten en cuenta que la interacción con este chatbot no crea ninguna relación contractual o de otro tipo entre tú y la entidad que desarrolló este chatbot. Siempre es aconsejable consultar a un profesional calificado para obtener asesoramiento específico. ¡Gracias!',
      questions: 'Preguntas',
      settings: 'Configuración',
      backToChatbots: 'Volver a Mis chatbots',
      newConversation: 'Nueva conversación',
      deleteAll: 'Borrar todo',
      thereIsNoDocuments: 'No hay ningún documento subido',
      uploadDocument: 'Subir documento',
      uploading: 'Subiendo documento',
      confirmDeleteDocument: '¿Estás seguro de que quieres eliminar este documento?',
      deleting: 'Eliminando',
      unsubscribe: 'Darse de baja',
      subscribe: 'Suscribirse',
      model: 'Modelo',
      temperature: 'Temperatura',
      defaultTemperature: 'Temperatura por defecto',
      settingsHaveBeenSavedSuccessfully: 'La configuración se ha guardado correctamente',
      nameIsRequired: 'El nombre es obligatorio',
      initialMessage: 'Mensaje inicial',
      systemMessage: 'Definir comportamiento de la IA',
      openAIKey: 'Clave de OpenAI',
      chatbotInfo: 'Opciones del chatbot',
      llmInfo: 'Configuración del LLM',
      embeddingConfiguration: 'Incluir chatbot en tu web',
      canEmbedInExternalWebsites: 'Puedes incluir el chatbot en webs externas',
      iframeCode: 'Copia este código para incluir este chatbot en tu web',
      copy: 'Copiar',
      codeHasBeenCopied: 'El código se ha copiado al portapapeles',
      enableDebug: 'Activar modo debug',
      inputPlaceholder: 'Placeholder del texto de entrada',
      editName: 'Renombrar',
      delete: 'Eliminar',
      deleteConversation: 'Eliminar',
      addApi: 'Añadir API',
      addApiDescription: 'Añade la posibilidad de conectar con tu propia API proporcionando el archivo de swagger',
      add: 'Añadir',
      deleteApiConfirm: '¿Estás seguro de que quieres eliminar esta API?',
      editEndpoints: 'Editar endpoints',
      organizations: 'Organizaciones',
      integrations: 'Integraciones',
      zendeskConfig: 'Configuracion de Zendesk',
      zendeskEmail: 'Email de la cuenta de Zendesk',
      zendeskToken: 'Token de Zendesk',
      zendeskHost: 'Host de Zendesk',
      zendeskDomain: 'Dominio de Zendesk',
      zendeskSupport: 'Activar modo soporte',
      zendeskSupportGroup: 'Grupos de soporte',
      zendeskSchedule: 'Horario de Zendesk',
      crispConfig: 'Configuracion de Crisp',
      crispEmail: 'Email de la cuenta de Crisp',
      crispToken: 'Contraseña de Crisp',
      crispURL: 'URL de configuracion para el Crisp',
      whatsappConfig: 'Configuracion de Whatsapp',
      whatsappNumber: 'Numero de Whatsapp',
      whatsappNumberID: 'Id del Numero de Whatsapp',
      whatsappToken: 'Token de Whatsapp',
      whatsappVerifyToken: 'Token de Verificación de Whatsapp',
      twilioConfig: 'Configuración de Twilio',
      twilioSID: 'Account SID',
      twilioToken: 'Auth Token',
      twilioPhone: 'Numero de Twilio',
      saveIntegrations: 'Guardar',
      enableImages: 'Activar imágenes',
      viaScript: 'Copia esté código al final de tu página web para incluir el chatbot',
      chooseEmbedMethod: 'Puedes elegir cualquiera de las dos opciones para embeber el chatbot en tu web dependiendo de tus necesidades',
      deleteAllConversationsConfirmation: '¿Estás seguro de que quieres eliminar todas las conversaciones?',
      deleteConversationConfirmation: '¿Estás seguro de que quieres eliminar esta conversación?',
      connect: 'Conectar',
      disconnect: 'Desconectar',
      newChat: 'Nuevo chat',
      temperatureDescriptionVeryDeterministic: 'Muy determinista',
      temperatureDescriptionDeterministic: 'Determinista',
      temperatureDescriptionBalanced: 'Equilibrado',
      temperatureDescriptionCreative: 'Creativo',
      temperatureDescriptionVeryCreative: 'Muy creativo',
      download: 'Descargar',
      lookAndFeel: 'Diseño del chatbot',
      avatar: 'Avatar',
      agentName: 'Nombre del agente',
      disclaimerTitle: 'Descargo de responsabilidad',
      showDisclaimer: 'Mostrar descargo de responsabilidad',
      disclaimerText: 'Texto del descargo de responsabilidad',
      startMinimized: 'Iniciar minimizado',
      userMessageBackgroundColor: 'Color de fondo de los mensajes del usuario',
      userMessageTextColor: 'Color del texto de los mensajes del usuario',
      agentMessageBackgroundColor: 'Color de fondo de los mensajes del agente',
      agentMessageTextColor: 'Color del texto de los mensajes del agente',
      botMessageBackgroundColor: 'Color de fondo de los mensajes del bot',
      botMessageTextColor: 'Color del texto de los mensajes del bot',
      recordingAudioMessageBackgroundColor: 'Color de fondo del mensaje de audio en grabación',
      recordingAudioMessageTextColor: 'Color del texto del mensaje de audio en grabación',
      backgroundColorHeader: 'Color de fondo del header',
      textColorHeader: 'Color del texto del header',
      colorButtonSend: 'Color del botón de enviar',
      uploadAvatar: 'Subir avatar',
      change: 'Cambiar',
      thisIsAUserMessageSample: 'Este es un mensaje de usuario de ejemplo',
      thisIsABotMessageSample: 'Este es un mensaje de bot de ejemplo',
      thisIsAnAgentMessageSample: 'Este es un mensaje de agente de ejemplo',
      doYouHaveAnyQuestions: '¿Tienes alguna pregunta? ¡Hablemos!',
      headerTitle: 'Título de la cabecera',
      headerSubtitle: 'Subtítulo de la cabecera',
      chat: 'Chat',
      agentsWriteAsBot: 'Los agentes escriben como bot',
      colorButtonForm: 'Color del botón de los formularios',
      colorButtonFormText: 'Color del texto del botón de los formularios',
      showAsBubbleWhenIsMinimized: 'Mostrar como burbuja cuando está minimizado',
      colorBubble: 'Color de la burbuja',
      showDictation: 'Mostrar dictado',
      customizeIcon: 'Subir icono',
      bubbleIcon: 'Icono de la burbuja',
      bubbleSize: 'Tamaño de la burbuja (px)',
      documentsOrchestrator: 'Orquestador para procesar los documentos',
      none: 'Ninguno',
      reprocess: 'Reprocesar',
      context: 'Contexto',
      searchInDocuments: 'Buscar en información indexada',
      searchAutomatically: 'Buscar automáticamente',
      searchManually: 'No buscar, dejar que el orquestador se encargue',
      learningContext: 'Contexto de aprendizaje',
      orchestrators: 'Orquestadores',
      conversationOrchestrator: 'Orquestador para procesar mensajes',
      addEmailIntegrations: 'Añadir integración de email',
      emailIntegration: 'Integración de email',
      type: 'Tipo',
      username: 'Usuario',
      password: 'Contraseña',
      host: 'Host',
      port: 'Puerto',
      useSSL: 'Usar SSL',
      testConnection: 'Probar conexión',
      connectionSuccess: 'Conexión exitosa',
      connectionSuccessMessage: 'La conexión con el servidor de correo se ha realizado correctamente',
      connectionError: 'Error en la conexión',
      connectionErrorMessage: 'No se ha podido conectar con el servidor de correo. Por favor, comprueba los datos introducidos y vuelve a intentarlo',
      emailsOrchestrator: 'Orquestador para procesar emails',
    },
    websites: {
      addWebsiteTitle: 'Añade una URL para entrenar el chatbot',
      train: 'Entrenar',
      analyze: 'Analizar',
      results: 'Resultados',
      noTrainedWebsites: 'No has entrenado ninguna web todavía, haz clic en Añadir URL para añadir la primera. También puedes analizar todos los enlaces que tenga una web o un sitemap.xml.',
      addURL: 'Añadir URL',
      url: 'URL',
      trainedURL: 'URL entrenada',
      allTrainedURLS: 'URLS entreanadas',
      name: 'Nombre',
      characters: 'Caracteres',
      trainedDate: 'Fecha de entrenamiento',
      trainedSuccess: 'URL entrenada correctamente',
      urlDeleted: 'URL eliminada correctamente',
    },
    texts: {
      title: 'Introduce el texto que quieres entrenar',
      train: 'Entrenar',
      thereIsNoTexts: 'No has guardado ningun texto todavía, haz clic en Crear texto para añadir el primero',
      createText: 'Crear texto',
      editText: 'Editar texto',
      confirmDeleteText: '¿Estás seguro de que quieres eliminar este texto?',
    },
    confirmModal: {
      confirm: 'Confirmar',
      cancel: 'Cancelar',
      areYourSure: '¿Estás seguro?',
    },
    addApi: {
      apis: 'APIs',
      connectApi: 'Conectar API',
      thereIsNoAPIs: 'No hay ninguna API conectada',
      addApi: 'Conectar con API externa',
      swaggerUrl: 'URL del archivo swagger',
      active: 'Activado',
      inactive: 'Desactivado',
      enableAll: 'Activar todos',
      disableAll: 'Desactivar todos',
      data: 'Datos generales',
      endpoints: 'Endpoints',
      connectWithYourApi: 'Conecta con tu propia API',
      userAuthentication: 'Autenticación de usuario',
      urlBase: 'URL base',
      authType: 'Tipo de autenticación',
      clientId: 'Client ID',
      clientSecret: 'Client Secret',
      authorizationUrl: 'URL de autorización',
      tokenUrl: 'URL de token',
      redirectUri: 'URL de redirección',
      loginEndpoint: 'Endpoint de login',
      method: 'Método',
      usernameFieldName: 'Nombre del parámetro de usuario',
      passwordFieldName: 'Nombre del parámetro de contraseña',
      thereIsNoEndpoints: 'No hay ningún endpoint creado',
      addNewEndpoint: 'Añadir nuevo endpoint',
      endpoint: 'Endpoint',
      endpointData: 'Datos del endpoint',
      params: 'Parámetros',
      urlWithoutUrlBase: 'URL sin URL base',
      summary: 'Resumen',
      description: 'Descripción',
      type: 'Tipo',
      in: 'Entrada',
      required: 'Obligatorio',
      status: 'Estado',
      deleteApiEndpointConfirm: '¿Estás seguro de que quieres eliminar este endpoint?',
      requestBody: 'Request body',
      addParam: 'Añadir parámetro',
      requiresUserConfirmation: 'Requiere confirmación de usuario',
      requiresConfirmation: 'Requiere confirmación',
      notRequiresConfirmation: 'No requiere confirmación',
      sendOTPEndpoint: 'Endpoint de envío de OTP',
      userFieldName: 'Nombre del parámetro de envío',
      verifyOTPEndpoint: 'Endpoint de verificación de OTP',
      otpValueFieldName: 'Nombre del parámetro del código OTP',
      otpSendMessage: 'Para poder extraer información de tu cuenta necesitamos confirmar tu identidad. Por favor, introduce la siguiente información.',
      otpVerifyMessage: 'Por favor, introduce el código que has recibido. Si no lo recibes asegúrate de que la información introducida es la que figura en tu cuenta de usuario y vuelve a intentarlo.',
      userFieldPlaceholder: 'Texto del parámetro de envío',
      phone: 'Teléfono',
      otpValuePlaceholder: 'Texto del parámetro del código OTP',
      code: 'Código',
      sendOTPMessage: 'Mensaje de envío de OTP',
      verifyOTPMessage: 'Mensaje de verificación de OTP',
      sharedWith: 'Compartido con',
      selectOrganization: 'Selecciona una organización',
      headers: 'Cabeceras',
      addHeader: 'Añadir cabecera',
      key: 'Clave',
      value: 'Valor',
      remove: 'Eliminar',
      paramsType: 'Tipo de parámetros',
    },
    tests: {
      title: 'Título',
      answer: 'Respuesta',
      createTest: 'Crear prueba',
      chatbots: 'Chatbots',
      organizations: 'Organizaciones',
      testBattery: 'Batería de pruebas',
      selectAll: 'Seleccionar todos',
      createQuestion: 'Crear una pregunta para el Test',
      createQuestion1: 'Crear pregunta',
      deleteTest: 'Eliminar test',
      showResults: 'Ver resultados',
      tests: 'Tests',
      questions: 'Preguntas',
      initTest: 'Iniciar Test',
      date: 'Fecha',
      testResume: 'Resumen del Test:',
      chatbotsNum: 'Número de Chatbots:',
      testsNum: 'Número de Tests:',
      chatbot: 'Chatbot:',
      model: 'Modelo:',
      score: 'Puntuación:',
      viewDetail: 'Ver detalle',
      back: 'Volver',
      correctAnswer: 'Respuesta correcta:',
      chatbotAnswer: 'Respuesta del chatbot',
      selectData: 'Selecciona los datos',
      selectDataInfo: 'Selecciona las columnas del excel a las que corresponde el título y la respuesta.',
      uploadExcel: 'Subir excel',
    },
    orchestrators: {
      backToOrchestratos: 'Volver a Mis Orquestradores',
      title: 'Orquestadores',
      date: 'Fecha',
      createOrchestrator: 'Crear orquestador',
      editOrchestrator: 'Editar orquestador',
      organizations: 'Organizaciones',
      initialChatbot: 'Chatbot inicial',
      interface: 'Interfaz',
      customId: 'Custom ID',
      form: 'Formulario',
      createForm: 'Crear formulario',
      statistics: 'Estadísticas de uso',
      conversations: 'Conversaciones',
      initDate: 'Fecha de inicio:',
      endDate: 'Fecha de fin:',
      search: 'Buscar',
      dailyLimit: 'Límite en 24h ($)',
      allConversations: 'Total de conversaciones',
      totalCost: 'Coste Total',
      conversationMessages: 'Mensajes por conversación',
      conversationCost: 'Coste por conversación',
      daysConsume: 'Consumo por días',
      chatbotConsume: 'Consumo por Chatbot',
      viewForm: 'Ver formulario',
      formRules: 'Reglas del formulario',
      addFormRule: 'Añadir regla para activar formulario',
      deleteNode: 'Eliminar nodo',
      variableWhen: 'Cuando la variable',
      variableIs: 'sea',
      value: 'Valor',
      viewCode: 'Ver código',
      variableName: 'Nombre de la variable',
      label: 'Label',
      type: 'Tipo',
      addFieldToForm: 'Añadir campo al formulario',
      chatbot: 'Chatbot',
      resultVariable: 'Nombre de la variable donde se guardará el resultado',
      chatbotSystemMessage: 'System message del Chatbot',
      entryTitle: 'Condiciones de entrada y destino',
      processTitle: 'Proceso Tras recibir la info en Background',
      exitTitle: 'Condiciones de salida y destino',
      addChatbot: 'Añadir llamada a chatbot',
      addPythonCondition: 'Añadir condición Python',
      addProcess: 'Añadir proceso',
      chatbots: 'Chatbots',
      createNode: 'Crear nodo',
      selectParentNodes: 'Selecciona los nodos padre',
      createEdge: 'Crear arista',
      selectParentNode: 'Selecciona el nodo padre',
      selectChildNode: 'Selecciona el nodo hijo',
      printForm: 'Mostrar formulario',
      confirmDuplicateOrchestrator: '¿Estás seguro de que quieres duplicar el orquestador',
      confirmDeleteOrchestrator: '¿Estás seguro de que quieres eliminar el orquestador',
      addEntryVariables: 'Añadir variables de entrada',
      entryVariables: 'Variables de entrada',
      searchRelevantInfo: 'Búsqueda de información relevante',
      documents: 'Documentos',
      faqs: 'FAQs',
      websites: 'Websites',
      texts: 'Textos',
      minScore: 'Puntuación mínima (0-100)',
      addEdit: 'Añadir / editar',
      nodeAddChatbot: 'Agregar chatbot',
      executeChatbot: 'Ejecutar chatbot',
      condition: 'Condición',
      addCondition: 'Agregar condición',
      options: 'Opciones',
      sendMessage: 'Enviar mensaje',
      addVariable: 'Añadir variable',
      set: 'Set',
      addOption: 'Agregar opción',
      switch: 'Switch',
      systemMessageConcat: 'System message a concatenar',
      createCondition: 'Crear condición',
      when: 'Cuando',
      variable: 'variable',
      nodeType: 'Tipo de nodo',
      debug: 'Debug',
      debugInfo: 'Información de debug',
      requestTokens: 'Tokens de petición',
      responseTokens: 'Tokens de respuesta',
      model: 'Modelo',
      nodeExecutionResult: 'Resultado de la ejecución del nodo',
      viewDebugInfo: 'Ver información de debug',
      temperature: 'Temperatura',
      selectForm: 'Selecciona un formulario',
      connectApi: 'Conectar API',
      api: 'API',
      endpoint: 'Endpoint',
      params: 'Parámetros',
      selectApi: 'Selecciona una API',
      selectEndpoint: 'Selecciona un endpoint',
      requestBody: 'Cuerpo de la petición',
      thereIsNoParams: 'Este endpoint no recibe ningún parámetro',
      fromVariable: 'Desde variable',
      specifyValue: 'Especificar valor',
      omitParam: 'Omitir parámetro',
      param: 'Parámetro',
      selectOption: 'Selecciona una opción',
      selectVariable: 'Variable',
      processJson: 'Procesar JSON',
      data: 'Campos',
      manage: 'Configurar',
      processJsonDataExplanation: 'Define para cada campo del JSON el nombre de la variable donde se guardará el valor.',
      addField: 'Añadir campo',
      field: 'Campo',
      code: 'Código',
      addCode: 'Añadir código',
      codeNodeInfo1: 'Para acceder a una variable que se ha guardado previamente en un nodo "Set" utilizad orchestrator_variables["variable"].',
      codeNodeInfo2: 'Puedes utilizar la respuesta con "return" conectando este nodo con un nodo switch.',
      thereIsNoOrchestrators: 'No hay ningún orquestrador creado',
      deleteEdgeWarning: '¿Estás seguro de que quieres eliminar esta arista?',
      url: 'URL',
      statusCode: 'Código de respuesta',
      cost: 'Coste',
      enabled: 'Activado',
      disabled: 'Desactivado',
      temperatureDescriptionVeryDeterministic: 'Muy determinista',
      temperatureDescriptionDeterministic: 'Determinista',
      temperatureDescriptionBalanced: 'Equilibrado',
      temperatureDescriptionCreative: 'Creativo',
      temperatureDescriptionVeryCreative: 'Muy creativo',
      userDailyLimit: 'Límite diario por usuario ($)',
      addCustomVariable: 'Añadir variable de entrada',
      confirmDeleteNodes: '¿Estás seguro de que quieres eliminar los nodos seleccionados?',
      defaultValues: 'Valores por defecto',
      nodeSettings: 'Configuración del nodo',
      selectAChatbot: 'Selecciona un chatbot',
      initial: 'Inicial',
      selectAForm: 'Selecciona un formulario',
      selectAnApi: 'Selecciona una API',
      selectAnEndpoint: 'Selecciona un endpoint',
      customOutputs: 'Salidas personalizadas',
      addOutput: 'Añadir salida',
      orchestratorSettings: 'Configuración del orquestador',
      variables: 'Variables',
      editCode: 'Editar código',
      changeConversationAgent: 'Cambiar agente de la conversación',
      addZendeskTag: 'Añadir tag a Zendesk',
      removeZendeskTag: 'Eliminar tag de Zendesk',
      hasZendeskTag: 'Tiene tag de Zendesk',
      changeZendeskTicketStatus: 'Cambiar estado del ticket de Zendesk',
      sendZendeskMessage: 'Enviar mensaje a Zendesk',
      makeObject: 'Crear/editar objeto',
      addKey: 'Añadir clave',
      removeKey: 'Eliminar clave',
      isEmpty: 'Está vacío',
      extractConversationData: 'Obtener datos de la conversación',
      extractChatbotData: 'Obtener datos de un chatbot',
      booleanOr: 'O lógico',
      booleanAnd: 'Y lógico',
      stringEquals: 'Comparar textos',
      stringConcat: 'Concatenar textos',
      stringIndexOf: 'Buscar texto en otro texto',
      sleep: 'Esperar',
      getMessageData: 'Obtener datos del mensaje',
      findChatbot: 'Buscar chatbot',
      sendPrompt: 'Enviar prompt',
      selectAModel: 'Selecciona un modelo',
      toString: 'Convertir a texto',
      numberEquals: '== (Number)',
      numberLowerThan: '< (Number)',
      numberLowerOrEqualThan: '<= (Number)',
      numberGreaterThan: '> (Number)',
      numberGreaterOrEqualThan: '>= (Number)',
      sequence: 'Secuencia',
      comesFromTwilio: 'Viene de Twilio',
      twilioExtractOrigin: 'Extraer origen de Twilio',
      twilioExtractNumber: 'Extraer número de Twilio',
      toObject: 'Convertir a objeto',
      returnNode: 'Nodo de retorno',
      addResponse: 'Añadir respuesta',
      customInputs: 'Entradas personalizadas',
      addInput: 'Añadir entrada',
      mergeObjects: 'Unir objetos',
      processMergeTags: 'Procesar merge tags',
      createNewChatbot: 'Crear nuevo chatbot',
      conversational: 'Conversacional',
      documentProcessor: 'Procesador de documentos',
      emailProcessor: 'Procesador de emails',
      agent: 'Agente',
      extractDocumentContent: 'Extraer contenido de documento',
      obtainNumberTokens: 'Obtener número de tokens',
      saveAsEmbedding: 'Guardar como embedding',
      optional: 'Opcional',
      asyncSequence: 'Secuencia asíncrona',
      executeOrchestrator: 'Ejecutar orquestador',
      selectAnOrchestrator: 'Selecciona un orquestador',
      arrayClear: 'Limpiar array',
      arrayToString: 'Convertir array a texto',
      arrayAppend: 'Añadir elemento a array',
      arrayMerge: 'Unir arrays',
      arrayIndexOf: 'Buscar elemento en array',
      arrayInsert: 'Insertar elemento en array',
      arrayPop: 'Extraer elemento de array',
      arrayRemove: 'Eliminar elemento de array',
      arrayReverse: 'Invertir array',
      arraySort: 'Ordenar array',
      searchDocuments: 'Buscar documentos',
      splitTextByChunkSize: 'Dividir texto por tamaño de párrafo',
      forEachLoop: 'Bucle for each',
      createDatastructureInstance: 'Crear instancia de modelo de datos',
      breakDatastructureInstance: 'Extraer atributos de modelo de datos',
      selectADatastructure: 'Selecciona un modelo de datos',
      stringToUpperCase: 'Convertir texto a mayúsculas',
      stringToLowerCase: 'Convertir texto a minúsculas',
      selectAChatbotForDebug: 'Selecciona un chatbot para debuguear',
      editZendeskRequesterData: 'Editar datos del solicitante en Zendesk',
      writeJsonData: 'Escribir datos en JSON',
      debugOrchestrator: 'Debuguear orquestador',
      inputs: 'Entradas',
      uploadDocument: 'Subir documento',
      change: 'Cambiar',
      viewResult: 'Ver resultado',
      searchDatatableRows: 'Buscar entradas en tabla de datos',
      selectADatatable: 'Selecciona una tabla de datos',
      versions: 'Versiones',
      manageVersions: 'Gestionar versiones',
      manageOrchestratorVersions: 'Gestionar versiones del orquestador',
      version: 'Versión',
      isActive: 'Está activa',
      isActiveVersion: 'Está activa',
      setAsActiveVersion: 'Establecer como versión activa',
      createNewVersionFromThis: 'Crear nueva versión a partir de esta',
      createNewVersion: 'Crear nueva versión',
      deleteOrchestratorVersionConfirm: '¿Estás seguro de que quieres eliminar esta versión del orquestador?',
      setAsActive: 'Establecer como activa',
      sendHtml: 'Enviar HTML',
      editVersion: 'Editar versión',
      addDatatableRow: 'Añadir entrada a tabla de datos',
      updateDatatableRow: 'Actualizar entrada en tabla de datos',
      extractEmailData: 'Extraer datos de email',
      createConversation: 'Crear conversación',
      createZendeskTicket: 'Crear ticket en Zendesk',
      twilioSendTemplate: 'Enviar plantilla de Twilio',
      twilioSendMessage: 'Enviar mensaje de Twilio',
      selectAnOption: 'Selecciona una opción',
      fetchUrl: 'Obtener URL',
      stringSplit: 'Dividir texto',
      stringJoin: 'Unir texto',
      arrayGetElement: 'Obtener elemento de array',
      stringReplace: 'Reemplazar texto',
      stringSearch: 'Buscar texto',
      searchConversations: 'Buscar conversaciones',
      toNumber: 'Convertir a número',
      createFaq: 'Crear FAQ',
      updateFaq: 'Editar FAQ',
      searchFaqs: 'Buscar FAQs',
      extractFaqData: 'Obtener datos de FAQ',
      retrieveConversationMessages: 'Obtener mensajes de conversación',
      createChatbot: 'Crear chatbot',
      updateChatbot: 'Editar chatbot',
      selectAnOrganization: 'Selecciona una organización',
      apiAuthentication: 'Autenticación de API',
      createMessage: 'Crear mensaje',
      sendAttachment: 'Enviar adjunto',
      functions: 'Funciones',
      addFunction: 'Añadir función',
      function: 'Función',
      deleteFunction: 'Eliminar función',
      deleteFunctionMessage: '¿Estás seguro de que quieres eliminar esta función?',
      backToOrchestrator: 'Volver al orquestador',
      localVariables: 'Variables locales',
      localVariablesDefaultValues: 'Variables locales - Valores por defecto',
      searchInGoogle: 'Buscar en Google',
      searchInYoutube: 'Buscar en Youtube',
      defineDatastructure: 'Definir modelo de datos',
      datastructureNotFound: 'Modelo de datos no encontrado',
      datastructureNotFoundMessage: 'No se ha encontrado el modelo de datos. Por favor, asegúrate de que tienes permisos para acceder a él.',
      allProcesses: 'Nº de procesos',
      processCost: 'Coste por proceso',
      saveTheResultInCache: 'Guardar el resultado en caché',
      cacheDuration: 'Duración de la caché',
      avgTime: 'Tiempo medio',
      nodesStatistics: 'Estadísticas por nodos',
      allVersions: 'Todas las versiones',
      allFunctions: 'Todas las funciones',
      mainOrchestrator: 'Orquestador principal',
      node: 'Nodo',
      avgCost: 'Coste medio',
      avgInputTokens: 'Tokens de entrada medios',
      avgOutputTokens: 'Tokens de salida medios',
      nodeName: 'Nombre del nodo',
      actualVersion: 'Versión actual',
      get: 'Get',
      gitlabRepositoryTree: 'Extraer árbol de repositorio de Gitlab',
      gitlabGetFileContent: 'Extraer contenido de archivo de Gitlab',
      excludeFromDebug: 'Excluir de debug',
      excludeFromStatistics: 'Excluir de estadísticas',
    },
    forms: {
      forms: 'Formularios',
      createForm: 'Crear formulario',
      thereIsNoForms: 'No hay ningún formulario creado',
      data: 'Información general',
      questions: 'Preguntas',
      question: 'Pregunta',
      basicInfo: 'Información básica',
      sharedWith: 'Compartido con',
      selectOrganization: 'Selecciona una organización',
      thereIsNoQuestions: 'No hay ninguna pregunta creada',
      variableName: 'Nombre de la variable',
      addQuestion: 'Añadir pregunta',
      title: 'Título',
      text: 'Texto',
      number: 'Número',
      select: 'Selección',
      phone: 'Teléfono',
      email: 'Email',
      date: 'Fecha',
      options: 'Opciones',
      isRequired: 'Es obligatorio',
      addOption: 'Añadir opción',
      value: 'Valor',
      initMessage: 'Mensaje previo a mostrar el formulario',
      fillMessage: 'Mensaje posterior a rellenar el formulario',
      messages: 'Mensajes',
    },
    formPublicView: {
      submittedMessage: 'Gracias por rellenar el formulario. Ahora puedes continuar la conversación desde el dispositivo que estuvieras usando.',
    },
    conversations: {
      title: 'Conversaciones',
      id: 'ID',
      date: 'Fecha',
      ticket: 'Ticket',
      totalMessages: 'Mensajes',
      price: 'Precio',
      type: 'Tipo',
      whatsapp: 'Whatsapp',
      moveConversations: 'Mover conversaciones',
      downloadCSV: 'Descargar CSV',
      search: 'Buscar',
    },
    moveConversationsModal: {
      title: 'Mover conversaciones a otro orquestador',
      info: 'Todas las conversaciones asociadas a este orquestador (ORQ_NAME) se moverán al orquestador seleccionado.',
    },
    apiKeys: {
      title: 'API Keys',
      createApiKey: 'Crear nueva Api Key',
      info1: 'Estas son las API Keys que has creado para acceder a tus APIs. Puedes crear nuevas o eliminar las existentes. Por temas de seguridad no podrás ver el Client Secret de tus API Keys una vez creadas, por lo que te recomendamos que las guardes en un lugar seguro.',
      thereIsNoApiKeys: 'No hay ninguna API Key creada',
      apiKey: 'API Key',
      deleteApiKey: 'Eliminar API Key',
      deleteApiKeyConfirm: '¿Estás seguro de que quieres eliminar esta API Key?',
      apiKeyCreatedInfo: 'Esta es tu nueva API Key. Por temas de seguridad no podrás ver el Client Secret de tus API Keys una vez creadas, por lo que te recomendamos que la guardes en un lugar seguro.',
      clientSecret: 'Client Secret',
    },
    datastructures: {
      datastructures: 'Modelos de datos',
      addDatastructure: 'Añadir modelo de datos',
      thereIsNoDatastructures: 'No hay ningún modelo de datos creado',
      editDatastructure: 'Editar modelo de datos',
      datastructureData: 'Información general del modelo de datos',
      fields: 'Campos',
      addField: 'Añadir campo',
      confirmDeletDataStructure: '¿Estás seguro de que quieres eliminar el modelo de datos',
    },
    datatables: {
      datatables: 'Tablas de datos',
      addDatatable: 'Añadir tabla de datos',
      thereIsNoDatatables: 'No hay ninguna tabla de datos creada',
      editDatatable: 'Editar tabla de datos',
      datatableData: 'Información general de la tabla de datos',
      confirmDeleteDatatable: '¿Estás seguro de que quieres eliminar la tabla de datos',
      datastructure: 'Modelo de datos',
      selectDatastructure: 'Selecciona un modelo de datos',
      viewEntries: 'Ver entradas',
    },
    datatableEntries: {
      datatableEntries: 'Entradas de la tabla de datos',
      datatableNotFound: 'No se ha encontrado la tabla de datos',
      addEntry: 'Añadir entrada',
      defineADatastructureForThisDatatable: 'Define un modelo de datos para esta tabla de datos',
      editEntry: 'Editar entrada',
      confirmDeleteDatatableRow: '¿Estás seguro de que quieres eliminar esta entrada?',
      invalidJson: 'El JSON introducido no es válido',
    },
    notFound: {
      title500: 'Sorry, there was an internal error. We could not complete your request.',
      description500: 'Our team has been made aware of the issue. We apologize for the inconvenience',
      title404: 'Sorry, the page you are looking for is not on the web.',
      title401: 'Sorry, you are unauthorized to view this page',
      title400: 'Sorry, the response was a bad request',
      description404: 'This may occur for two reasons:',
      description401: 'This may occur for two reasons:',
      description400: 'This may occur for two reasons:',
      reason1400: 'The request has some missing mandatory parameters, or',
      reason2400: 'Some unexpected behaviour ocurred',
      reason1401: 'Your role is not authorized, or',
      reason2401: 'You are not in the correct view.',
      reason1404: 'You have entered an incorrect internet address, or',
      reason2404: 'The page has been eliminated or moved.',
      unknownStatus: 'The status returned is unknown and has not been handled',
      back: 'Back to Homepage',
    },
    errors: {
      generic: 'Ha ocurrido un error',
      internalServerError: 'Ha ocurrido un error inesperado',
    },
    common: {
      options: 'Opciones',
      access: 'Acceder',
      name: 'Nombre',
      description: 'Descripción',
      edit: 'Editar',
      accept: 'Aceptar',
      close: 'Cerrar',
      cancel: 'Cancelar',
      none: 'Ninguno',
      type: 'Tipo',
      delete: 'Eliminar',
      save: 'Guardar',
      saving: 'Guardando',
      saved: 'Guardado',
      copy: 'Copiar',
      copied: 'Copiado',
      error: 'Error',
      back: 'Volver',
      add: 'Añadir',
      loading: 'Cargando...',
      duplicate: 'Duplicar',
      send: 'Enviar',
      sending: 'Enviando',
      sendAgain: 'Enviar de nuevo',
      search: 'Buscar',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de fin',
      createdAt: 'Fecha de creación',
      yes: 'Sí',
      no: 'No',
      apply: 'Aplicar',
      organizations: 'Organizaciones',
      export: 'Exportar',
      import: 'Importar',
      minutes: 'Minutos',
      hours: 'Horas',
      days: 'Días',
    },
  },
};
