const moment = require('moment');

const DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss';

export const transformDate = (date) => {
  return moment(date).format(DATE_FORMAT);
};

const getGMTOffset = () => {
  const now = new Date();
  const utcNow = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
  const offset = now.getTime() - utcNow.getTime();
  return offset;
};

export const createFormattedDate = () => {
  const now = new Date(); // Usa la fecha pasada como argumento
  const offset = getGMTOffset();
  const gmtNow = new Date(now.getTime() - offset); // Crea una nueva fecha ajustada a GMT+0
  // Formatea la fecha como antes
  const year = gmtNow.getFullYear().toString().padStart(4, '0');
  const month = (gmtNow.getMonth() + 1).toString().padStart(2, '0');
  const day = gmtNow.getDate().toString().padStart(2, '0');
  const hours = gmtNow.getHours().toString().padStart(2, '0');
  const minutes = gmtNow.getMinutes().toString().padStart(2, '0');
  const seconds = gmtNow.getSeconds().toString().padStart(2, '0');
  const milliseconds = gmtNow.getMilliseconds().toString().padStart(3, '0');

  // **3. Concatenar la fecha y hora formateada:**
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  return formattedDate;
};
