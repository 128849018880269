import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

const URLS = {
  getApiKeys: 'api-keys',
  createApiKey: 'api-keys',
  updateApiKey: (apiKeyId) => `api-keys/${apiKeyId}`,
  deleteApiKey: (apiKeyId) => `api-keys/${apiKeyId}`,
};

export default {
  async getApiKeys() {
    return ApiFetchInstance.get(URLS.getApiKeys);
  },
  async createApiKey(data = null) {
    return ApiFetchInstance.post(URLS.createApiKey, data);
  },
  async updateApiKey(apiKeyId, data = null) {
    return ApiFetchInstance.put(URLS.updateApiKey(apiKeyId), data);
  },
  async deleteApiKey(apiKeyId) {
    return ApiFetchInstance.delete(URLS.deleteApiKey(apiKeyId));
  },
};
