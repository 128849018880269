import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';

function RelaunchTestForm(props) {
  const {
    onClose,
    initTest,
    isSaving,
    literals,
    questions,
    allOrganizations,
    allChatbots,
    baseTest,
  } = props;

  const [name, setName] = useState('');

  const handleOnChangeName = (event) => {
    setName(event.target.value);
  };

  const handleInitTest = () => {
    initTest({
      name,
      questions: questions.map((question) => question.id),
      organizations: allOrganizations.map((organization) => organization.id),
      chatbots: allChatbots.map((chatbot) => chatbot.id),
      baseTest,
    });
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Iniciar Test</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className='input_title'>
            {literals.common.name}
          </div>
          <div className='input_value'>
            <input type='text' className='form_input_stl' onChange={handleOnChangeName} value={name} />
          </div>
          <div className='panel-header'>{literals.chatbots}</div>
          <div className='input_title'>
            <Select
              isMulti
              disabled
              options={allChatbots.map((chatbot) => ({ value: chatbot.id, label: chatbot.name }))}
              value={allChatbots.map((chatbot) => ({ value: chatbot.id, label: chatbot.name }))}
            />
          </div>
          <div className='input_title'>
            {literals.testBattery}
          </div>
          <div className='input_value'>
            <Select
              isMulti
              disabled
              options={questions.map((question) => ({ value: question.id, label: question.title }))}
              value={questions.map((question) => ({ value: question.id, label: question.title }))}
            />
          </div>
          <div className='panel-header'>{literals.organizations}</div>
          <div className='input_title'>
            <Select
              isMulti
              disabled
              options={allOrganizations.map((organization) => ({ value: organization.id, label: organization.name }))}
              value={allOrganizations.map((organization) => ({ value: organization.id, label: organization.name }))}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {literals.common.close}
        </Button>
        <Button
          variant='primary'
          onClick={handleInitTest}
          disabled={!name || isSaving}
        >
          {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
          {literals.common.accept}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

RelaunchTestForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  initTest: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  literals: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  allOrganizations: PropTypes.array.isRequired,
  allChatbots: PropTypes.array.isRequired,
  baseTest: PropTypes.string.isRequired,
};

export default RelaunchTestForm;
