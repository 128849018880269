import React from 'react';
import PropTypes from 'prop-types';
import {
  PieChart, Pie, Cell, Legend, Tooltip,
} from 'recharts';

const COLORS = [
  '#0088FE', '#00C49F', '#FFBB28', '#FF8042',
  '#A93226', '#3498DB', '#27AE60', '#F39C12',
  '#884EA0', '#2C3E50', '#E74C3C', '#2980B9',
];

function PieChartItem({
  data,
}) {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (percent < 0.10) {
      return null;
    }
    return (
      <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <PieChart width={220} height={300}>
      <Pie
        data={data}
        cx='50%'
        cy='50%'
        outerRadius={80}
        labelLine={false}
        fill='#8884d8'
        dataKey='cost'
        label={renderCustomizedLabel}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      {/* <Legend verticalAlign='bottom' height={36} /> */}
    </PieChart>
  );
}

PieChartItem.propTypes = {
  data: PropTypes.array.isRequired,
};

PieChartItem.defaultProps = {

};

export default PieChartItem;
