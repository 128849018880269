import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { updateIntegrations, getIntegrations } from 'modules/chatbots/actions';
import useLiterals from 'hooks/useLiterals';
import { getErrorMessage } from 'utils/errors';
import Form from 'react-bootstrap/Form';
import FeedbackModal from 'components/FeedbackModal';
import EmailIntegration, { IMAP } from './emailIntegration';

function Integrations({ chatbotId }) {
  const [currentChatbot, setCurrentChatbot] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const [error, setError] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showCopiedModal, setShowCopiedModal] = useState(false);

  const { chatbots } = useSelector((state) => state.chatbots);
  const literals = useLiterals('chatbot');
  const dispatch = useDispatch();

  const [isChangedZendesk, setIsChangedZendesk] = useState(false);
  const [isChangedCrisp, setIsChangedCrisp] = useState(false);
  const [isChangedWhatsapp, setIsChangedWhatsapp] = useState(false);
  const [isChangedTwilio, setIsChangedTwilio] = useState(false);

  const [zendeskMail, setZendeskMail] = useState('');
  const [zendeskToken, setZendeskToken] = useState('');
  const [zendeskHost, setZendeskHost] = useState('');
  const [zendeskDomain, setZendeskDomain] = useState('');
  const [zendeskSupport, setZendeskSupport] = useState(false);
  const [zendeskSupportGroup, setZendeskSupportGroup] = useState('');
  const [zendeskSchedule, setZendeskSchedule] = useState(false);

  const [crispEmail, setCrispEmail] = useState('');
  const [crispToken, setCrispToken] = useState('');
  const [crispURL, setCrispURL] = useState('');

  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [whatsappNumberID, setWhatsappNumberID] = useState('');
  const [whatsappToken, setWhatsappToken] = useState('');
  const [whatsappVerifyToken, setWhatsappVerifyToken] = useState('');

  const [twilioID, setTwilioID] = useState('');
  const [twilioToken, setTwilioToken] = useState('');
  const [twilioPhone, setTwilioPhone] = useState('');

  const [emailIntegrations, setEmailIntegrations] = useState([]);

  const getChatbot = () => {
    if (!chatbots) {
      return null;
    }

    const chatbot = chatbots.find((c) => c.id === chatbotId);
    return chatbot;
  };

  const loadIntegrations = async () => {
    try {
      let response = {};
      response = await getIntegrations(chatbotId);
      setZendeskMail(response?.zendesk_mail);
      setZendeskToken(response?.zendesk_token);
      setZendeskHost(response?.zendesk_host);
      setZendeskDomain(response?.zendesk_domain);
      setZendeskSupport(response?.support);
      setZendeskSupportGroup(response?.support_group);
      setZendeskSchedule(response?.zendesk_schedule);

      setCrispEmail(response?.crisp_mail);
      setCrispToken(response?.crisp_token);
      setCrispURL(response?.crisp_URL);

      setWhatsappNumber(response?.whatsapp_number);
      setWhatsappNumberID(response?.whatsapp_number_id);
      setWhatsappToken(response?.whatsapp_token);
      setWhatsappVerifyToken(response?.whatsapp_verify_token);

      setTwilioID(response?.twilio_sid);
      setTwilioToken(response?.twilio_token);
      setTwilioPhone(response?.twilio_phone);
      setEmailIntegrations(response?.email_integrations);
    } catch (e) {
      setError(getErrorMessage(e));
    }
  };

  useEffect(() => {
    const chatbot = getChatbot();
    setCurrentChatbot(chatbot);
    loadIntegrations();
  }, [chatbots]);

  const handleOnChangeZendeskMail = (e) => {
    setIsChangedZendesk(true);
    setZendeskMail(e.target.value);
  };

  const handleOnChangeZendeskToken = (e) => {
    setIsChangedZendesk(true);
    setZendeskToken(e.target.value);
  };

  const handleOnChangeZendeskHost = (e) => {
    setIsChangedZendesk(true);
    setZendeskHost(e.target.value);
  };

  const handleOnChangeZendeskDomain = (e) => {
    setIsChangedZendesk(true);
    setZendeskDomain(e.target.value);
  };

  const handleOnChangeZendeskSupportGroup = (e) => {
    setIsChangedZendesk(true);
    setZendeskSupportGroup(e.target.value);
  };

  const handleOnChangeZendeskSupport = (e) => {
    setIsChangedZendesk(true);
    setZendeskSupport(e.target.checked);
  };

  const handleOnChangeZendeskSchedule = (e) => {
    setIsChangedZendesk(true);
    setZendeskSchedule(e.target.checked);
  };

  const handleOnChangeCrispEmail = (e) => {
    setIsChangedCrisp(true);
    setCrispEmail(e.target.value);
  };

  const handleOnChangeCrispToken = (e) => {
    setIsChangedCrisp(true);
    setCrispToken(e.target.value);
  };

  const handleOnChangeCrispURL = (e) => {
    setIsChangedCrisp(true);
    setCrispURL(e.target.value);
  };

  const handleOnChangeWhatsappNumber = (e) => {
    setIsChangedWhatsapp(true);
    setWhatsappNumber(e.target.value);
  };

  const handleOnChangeWhatsappNumberID = (e) => {
    setIsChangedWhatsapp(true);
    setWhatsappNumberID(e.target.value);
  };

  const handleOnChangeWhatsappToken = (e) => {
    setIsChangedWhatsapp(true);
    setWhatsappToken(e.target.value);
  };

  const handleOnChangeWhatsappVerifyToken = (e) => {
    setIsChangedWhatsapp(true);
    setWhatsappVerifyToken(e.target.value);
  };

  const handleOnChangeTwilioID = (e) => {
    setIsChangedTwilio(true);
    setTwilioID(e.target.value);
  };

  const handleOnChangeTwilioToken = (e) => {
    setIsChangedTwilio(true);
    setTwilioToken(e.target.value);
  };

  const handleOnChangeTwilioPhone = (e) => {
    setIsChangedTwilio(true);
    setTwilioPhone(e.target.value);
  };

  const handleCloseModal = () => {
    setShowFeedbackModal(false);
  };

  const handleCloseCopiedModal = () => {
    setShowCopiedModal(false);
  };

  const checkForm = () => {
    if (!zendeskMail || !whatsappNumber) {
      setError(literals.nameIsRequired);
      return false;
    }

    setError(null);
    return true;
  };

  const saveIntegrations = async () => {
    try {
      setIsSaving(true);
      await dispatch(updateIntegrations(
        chatbotId,
        zendeskMail,
        zendeskToken,
        zendeskHost,
        zendeskDomain,
        zendeskSupport,
        zendeskSupportGroup,
        zendeskSchedule,
        crispEmail,
        crispToken,
        crispURL,
        whatsappNumber,
        whatsappNumberID,
        whatsappToken,
        whatsappVerifyToken,
        twilioID,
        twilioToken,
        twilioPhone,
        isChangedZendesk,
        isChangedCrisp,
        isChangedWhatsapp,
        isChangedTwilio,
        emailIntegrations,
      ));
      setShowFeedbackModal(true);
    } catch (err) {
      console.error('Error al guardar integraciones:', err);
      const errorMessage = getErrorMessage(err);
      setError(errorMessage);
    } finally {
      setIsSaving(false);
      setIsChangedCrisp(false);
      setIsChangedZendesk(false);
    }
  };

  const handleAddEmailIntegration = () => {
    setEmailIntegrations([...emailIntegrations, {
      id: uuidv4(),
      type: IMAP,
      email_username: '',
      email_password: '',
      email_host: '',
      email_port: '995',
      use_ssl: true,
    }]);
  };

  const printFeedbackModal = () => {
    if (!showFeedbackModal) {
      return null;
    }

    return (
      <FeedbackModal
        message={literals.settingsHaveBeenSavedSuccessfully}
        onClose={handleCloseModal}
        open={showFeedbackModal}
      />
    );
  };

  const printCopiedModal = () => {
    if (!showCopiedModal) {
      return null;
    }

    return (
      <FeedbackModal
        message={literals.codeHasBeenCopied}
        onClose={handleCloseCopiedModal}
        open={showCopiedModal}
      />
    );
  };

  const handleChangeEmailIntegrationField = (id, field, value) => {
    const newEmailIntegrations = emailIntegrations.map((emailIntegration) => {
      if (emailIntegration.id === id) {
        return {
          ...emailIntegration,
          [field]: value,
        };
      }

      return emailIntegration;
    });

    setEmailIntegrations(newEmailIntegrations);
  };

  const handleChangeEmailIntegrationType = (id, value) => {
    handleChangeEmailIntegrationField(id, 'type', value);
  };

  const handleChangeEmailIntegrationUsername = (id, value) => {
    handleChangeEmailIntegrationField(id, 'email_username', value);
  };

  const handleChangeEmailIntegrationPassword = (id, value) => {
    handleChangeEmailIntegrationField(id, 'email_password', value);
  };

  const handleChangeEmailIntegrationHost = (id, value) => {
    handleChangeEmailIntegrationField(id, 'email_host', value);
  };

  const handleChangeEmailIntegrationPort = (id, value) => {
    handleChangeEmailIntegrationField(id, 'email_port', value);
  };

  const handleChangeEmailIntegrationUseSSL = (id, value) => {
    handleChangeEmailIntegrationField(id, 'use_ssl', value);
  };

  const handleRemoveEmailIntegration = (id) => {
    const newEmailIntegrations = emailIntegrations.filter((emailIntegration) => emailIntegration.id !== id);
    setEmailIntegrations(newEmailIntegrations);
  };

  const canContinue = () => {
    if (isSaving) {
      return false;
    }

    let returnCanContinue = true;
    emailIntegrations.forEach((emailIntegration) => {
      if (!emailIntegration.email_username || !emailIntegration.email_password || !emailIntegration.email_host || !emailIntegration.email_port) {
        returnCanContinue = false;
      }
    });

    return returnCanContinue;
  };

  const printEmailIntegrations = () => {
    if (!emailIntegrations || emailIntegrations.length === 0) {
      return null;
    }

    return emailIntegrations.map((emailIntegration) => (
      <EmailIntegration
        key={emailIntegration.id}
        chatbotId={chatbotId}
        id={emailIntegration.id}
        type={emailIntegration.type}
        username={emailIntegration.email_username}
        password={emailIntegration.email_password}
        host={emailIntegration.email_host}
        port={emailIntegration.email_port}
        useSSL={emailIntegration.use_ssl}
        onChangeType={handleChangeEmailIntegrationType}
        onChangeUsername={handleChangeEmailIntegrationUsername}
        onChangePassword={handleChangeEmailIntegrationPassword}
        onChangeHost={handleChangeEmailIntegrationHost}
        onChangePort={handleChangeEmailIntegrationPort}
        onChangeUseSSL={handleChangeEmailIntegrationUseSSL}
        onRemove={handleRemoveEmailIntegration}
      />
    ));
  };

  if (!currentChatbot) {
    return null;
  }

  return (
    <div className='p-md'>
      {printFeedbackModal()}
      {printCopiedModal()}
      <Form>
        <div className='panel'>
          <div className='panel-header'>{literals.zendeskConfig}</div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.zendeskEmail}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeZendeskMail} value={zendeskMail} />
            </div>
          </div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.zendeskToken}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeZendeskToken} value={zendeskToken} />
            </div>
          </div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.zendeskHost}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeZendeskHost} value={zendeskHost} />
            </div>
          </div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.zendeskDomain}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeZendeskDomain} value={zendeskDomain} />
            </div>
          </div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.zendeskSupportGroup}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeZendeskSupportGroup} value={zendeskSupportGroup} />
            </div>
            <div className='input_title'>
              <Form.Check
                type='switch'
                id='embed-switch'
                label={literals.zendeskSupport}
                onChange={handleOnChangeZendeskSupport}
                checked={zendeskSupport}
              />
              <Form.Check
                type='switch'
                id='embed-switch'
                label={literals.zendeskSchedule}
                onChange={handleOnChangeZendeskSchedule}
                checked={zendeskSchedule}
              />
            </div>
          </div>
        </div>
        <div className='panel'>
          <div className='panel-header'>{literals.crispConfig}</div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.crispEmail}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeCrispEmail} value={crispEmail} />
            </div>
          </div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.crispToken}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeCrispToken} value={crispToken} />
            </div>
          </div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.crispURL}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeCrispURL} value={crispURL} />
            </div>
          </div>
        </div>
        <div className='panel'>
          <div className='panel-header'>{literals.whatsappConfig}</div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.whatsappNumber}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeWhatsappNumber} value={whatsappNumber} />
            </div>
          </div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.whatsappNumberID}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeWhatsappNumberID} value={whatsappNumberID} />
            </div>
          </div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.whatsappToken}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeWhatsappToken} value={whatsappToken} />
            </div>
          </div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.whatsappVerifyToken}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeWhatsappVerifyToken} value={whatsappVerifyToken} />
            </div>
          </div>
        </div>
        <div className='panel'>
          <div className='panel-header'>{literals.twilioConfig}</div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.twilioSID}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeTwilioID} value={twilioID} />
            </div>
          </div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.twilioToken}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeTwilioToken} value={twilioToken} />
            </div>
          </div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.twilioPhone}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeTwilioPhone} value={twilioPhone} />
            </div>
          </div>
        </div>
        {printEmailIntegrations()}
        {error && (
          <div className='error'>
            {error}
          </div>
        )}
        <div className='form_buttons'>
          <button type='button' className='btn btn-secondary' onClick={handleAddEmailIntegration}>
            {literals.addEmailIntegrations}
          </button>
          <button type='button' className='btn btn-primary' onClick={saveIntegrations} disabled={!canContinue()}>
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {literals.saveIntegrations}
          </button>
        </div>
      </Form>
    </div>
  );
}

Integrations.propTypes = {
  chatbotId: PropTypes.string.isRequired,
};

export default Integrations;
