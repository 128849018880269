import Api from './repository';
import {
  CREATE_TEST, DELETE_TEST, SET_TESTS,
} from './index';

export const getChatbotQuestions = async (chatbotId) => {
  const response = await Api.getChatbotQuestions(chatbotId);
  return response;
};

export const getAllQuestions = async () => {
  const response = await Api.getAllQuestions();
  return response;
};

export const createChatbotQuestion = async (data) => {
  const response = await Api.createChatbotQuestion(data);
  return response;
};

export const getTests = () => async (dispatch) => {
  const response = await Api.getTests();
  dispatch({ type: SET_TESTS, payload: response.tests });
  return response;
};

export const createTest = (data) => async (dispatch) => {
  const response = await Api.createTest(data);
  dispatch({ type: CREATE_TEST, payload: response });
  return response;
};

export const getTestDetails = async (testId) => {
  const response = await Api.getTestDetails(testId);
  return response;
};

export const deleteTest = (testId) => async (dispatch) => {
  const response = await Api.deleteTest(testId);
  dispatch({ type: DELETE_TEST, payload: response });
  return response;
};
