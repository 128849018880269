import React, { useState, useEffect, useRef } from 'react';
import Avatar from 'react-avatar';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import useLiterals from 'hooks/useLiterals';
import { ROLE_ASSISTANT } from 'constants/messages';
import AttachmentIcon from 'assets/icons/attachment_icon.svg';
import MicrophoneIcon from 'assets/icons/microphone_icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon.svg';
import { ReactComponent as StopIcon } from 'assets/icons/stop_icon.svg';
import AddFileIcon from 'assets/icons/add_file_icon.svg';
import SendIcon from 'assets/icons/send_icon.svg';
import { getAgents } from 'modules/chats/actions';
import IconBubbleSrc from 'assets/icons/icon_bubble.svg';
import Message from './Message';

let intervalId = null;
let intervalVolume = null;
let mediaRecorder = null;
let recordedChunks = [];
let audioStream; // Almacena la transmisión del micrófono
function ChatForm(props) {
  const {
    messages, conversation, onSendMessage, onSendAudio, onUploadFile, chatbot, header, disabled, isPreview,
    showTransferedMessage, onClose,
  } = props;

  const [actualMessage, setActualMessage] = useState('');
  const [keysPressed, setKeysPressed] = useState([]); // ['Shift', 'Enter']
  const [isRecording, setIsRecording] = useState(false);
  const [recordingStartTime, setRecordingStartTime] = useState(0);
  const [timeRecording, setTimeRecording] = useState('');
  const [widthVolume, setWidthVolume] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isHidden, setIsHidden] = useState(false);
  const [availableAgents, setAvailableAgents] = useState([]);
  const [agents, setAgents] = useState(false);
  let timeout = null;
  const timeoutRecordLimit = 120;
  const [pastedFile, setPastedFile] = useState(null);
  const [isLoadingAudioRecord, setIsLoadingAudioRecord] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(true);

  const fileRef = useRef(null);
  const divRef = useRef(null);
  const messagesEndRef = useRef(null);
  const literals = useLiterals('chat');

  const handleChangeActualMessage = (event) => {
    if (event.target.value.trim()) {
      setActualMessage(event.target.value);
    } else {
      setActualMessage('');
    }
  };

  useEffect(() => {
    const startMinimized = chatbot?.extra_info?.start_minimized;
    if (startMinimized && header) {
      setIsHidden(true);
    }
  }, [chatbot]);

  useEffect(() => {
    const handlePaste = (event) => {
      const items = Array.from(event.clipboardData?.items);
      items.forEach((item) => {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file) {
            if (file.type.startsWith('image/')) {
              // Crea una URL para el archivo de imagen
              const imageSrc = URL.createObjectURL(file);
              setPastedFile({
                file,
                imageSrc,
              });
            } else {
              setPastedFile({
                file,
                imageSrc: null,
              });
            }
          }
        }
      });
    };

    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('paste', handlePaste);
      // Limpieza: revoca la URL de la imagen para evitar fugas de memoria
      if (pastedFile) {
        URL.revokeObjectURL(pastedFile.imageSrc);
      }
    };
  }, [pastedFile]);

  const scrollToBottom = () => {
    if (!isHidden && divRef?.current && !isPreview) {
      const scrollHeight = divRef?.current?.scrollHeight ?? 0;
      divRef.current.scrollTop = scrollHeight;
    }
  };

  useEffect(() => {
    if (!isHidden) {
      scrollToBottom();
    }
  }, [isHidden]);

  const sendMessage = () => {
    if (actualMessage.trim() && !disabled && !isPreview) {
      onSendMessage(actualMessage);
      setActualMessage('');
      scrollToBottom();
    }
  };

  /* eslint-disable no-param-reassign */
  const handleKeyDownMessage = (event) => {
    setKeysPressed([...keysPressed, event.key]);
    if (event.key === 'Enter' && !keysPressed.includes('Shift')) {
      sendMessage();
    }
  };

  const handleKeyUpMessage = (event) => {
    setKeysPressed(keysPressed.filter((key) => key !== event.key));
  };

  const handleBlurMessage = () => {
    setKeysPressed([]);
  };

  const handleFocusMessage = (event) => {
    event.preventDefault();
  };

  const handleErrorRecord = (error) => {
    console.error(error);
    setIsRecording(false);
  };

  const fileSelectedHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    event.target.value = null;
  };

  const fileUploadHandler = () => {
    setSelectedFile(null);
    fileRef.current.click();
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      if (typeof mediaRecorder.stop === 'function') {
        mediaRecorder.stop();
        setIsRecording(false);
      } else {
        console.error('stop no está definido');
      }
    } else {
      console.error('mediaRecorder no está definido o ya está inactivo.', mediaRecorder);
    }

    if (timeout) {
      clearTimeout(timeout);
    }
  };

  const handleSuccessRecord = (stream) => {
    let mimeType = null;
    let extension = null;
    let options = {};
    if (MediaRecorder.isTypeSupported('audio/webm')) {
      mimeType = 'audio/webm';
      extension = 'webm';
      options = { mimeType };
    } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
      mimeType = 'audio/mp4';
      extension = 'wav';
      options = { mimeType: 'audio/mp4;codecs=mp4a', audioBitrate: '128000' };
    } else if (MediaRecorder.isTypeSupported('audio/aac')) {
      mimeType = 'audio/aac';
      extension = 'aac';
    } else {
      setIsLoadingAudioRecord(false);
      console.error('No se encontró un formato de audio soportado.');
      return;
    }

    audioStream = stream; // Guarda la transmisión del micrófono
    mediaRecorder = new MediaRecorder(stream, options);

    // Crear el AudioContext y el AnalyserNode
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256; // Puedes ajustar este valor dependiendo de la precisión que necesites
    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunks.push(event.data);
      }
    };

    mediaRecorder.onstop = async () => {
      // Detener la transmisión del micrófono
      audioStream.getTracks().forEach((track) => {
        track.stop();
      });
      clearInterval(intervalVolume);
      const blob = new Blob(recordedChunks, { type: mimeType });
      const audioURL = URL.createObjectURL(blob);
      onSendAudio(blob, audioURL, extension);
      recordedChunks = [];
    };

    mediaRecorder.onstart = () => {
      setTimeRecording('00:00');
      setRecordingStartTime(Date.now());
      setIsRecording(true);
      setIsLoadingAudioRecord(false);
    };
    mediaRecorder.start(extension === 'wav' ? 1000 : 0); // Hay que pasarle 1000 para que no falle en IOS

    if (mediaRecorder && mediaRecorder.state !== 'inactive' && typeof mediaRecorder.stop === 'function') {
      timeout = setTimeout(() => {
        stopRecording();
      }, timeoutRecordLimit * 1000);
    } else {
      console.error('mediaRecorder no está definido o ya está inactivo.');
    }

    // Comenzar a monitorear los niveles de volumen
    const volumeData = new Uint8Array(analyser.frequencyBinCount);
    intervalVolume = setInterval(() => {
      analyser.getByteFrequencyData(volumeData);
      const volume = volumeData.reduce((a, b) => a + b) / volumeData.length;
      // Normalizar el volumen a un porcentaje
      const volumePercentage = Math.min(100, (volume / 255) * 100 * 3);
      setWidthVolume(volumePercentage);
    }, 50); // Este intervalo determina la frecuencia con la que se actualizan los niveles de volumen
  };

  const startRecording = async () => {
    setIsLoadingAudioRecord(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      handleSuccessRecord(stream);
    } catch (error) {
      console.error(error);
      handleErrorRecord(error);
      setIsLoadingAudioRecord(false);
    }
  };

  const getTimeRecording = () => {
    // Cálcula la diferencia en segundos
    const diffInSeconds = Math.abs(Math.round(Date.now() / 1000) - Math.round(recordingStartTime / 1000));

    // Calcula los minutos y segundos
    let minutes = Math.floor(diffInSeconds / 60);
    let seconds = diffInSeconds % 60;

    // Asegura que los minutos y segundos siempre tengan al menos dos dígitos
    minutes = `0${minutes}`.slice(-2);
    seconds = `0${seconds}`.slice(-2);

    // Retorna el resultado en formato mm:ss
    return `${minutes}:${seconds}`;
  };

  const toggleHidden = (event) => {
    if (!isPreview) {
      const viewportmeta = document.querySelector('meta[name="viewport"]');
      if (viewportmeta !== null) {
        const appliedScale = 1 - Math.random() * 0.01;
        document.querySelector('meta[name="viewport"]').setAttribute('content', `width=device-width, initial-scale=${appliedScale}`);
      }
      if (onClose && !isHidden) {
        onClose();
      }
      setIsHidden(!isHidden);
    }

    event.preventDefault();
  };

  const fetchAvailableAgents = async () => {
    try {
      const response = await getAgents(chatbot.id);
      setAvailableAgents(response?.agents);
    } catch (error) {
      console.error('Error de red:', error);
    }
  };

  useEffect(() => {
    if ((isAtBottom || divRef.current.scrollTop === 0) && !disabled) {
      scrollToBottom();
    }
  }, [messages]);

  const handleScroll = () => {
    const scrollHeight = divRef?.current?.scrollHeight ?? 0;
    const scrollTop = divRef?.current?.scrollTop ?? 0;
    const clientHeight = divRef?.current?.clientHeight ?? 0;
    const isAtBottomNew = Math.abs(scrollHeight - scrollTop - clientHeight) < 50;

    setIsAtBottom(isAtBottomNew);
  };

  useEffect(() => {
    if (!isRecording && intervalId) {
      clearInterval(intervalId);
    } else if (isRecording) {
      intervalId = setInterval(() => {
        setTimeRecording(getTimeRecording());
      }, 1000); // Se ejecuta cada segundo
    }

    // Limpia el intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, [isRecording]); // El array vacío indica que este efecto se ejecuta una vez al montar el componente

  useEffect(() => {
    if (selectedFile) {
      onUploadFile(selectedFile);
      setSelectedFile(null);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (!agents && availableAgents.length > 0) {
      fetchAvailableAgents();
      setAgents(true);
    }
  }, [availableAgents]);

  const printMessages = () => {
    const messagesElements = messages.map((message) => (
      <Message onSendMessage={onSendMessage} chatbot={chatbot} message={message} messages={messages} key={`message-${message.id}`} />
    ));

    const initialMessage = chatbot && chatbot.initial_message ? chatbot.initial_message : '¡Hola! ¿En qué te puedo ayudar hoy?';

    messagesElements.unshift((
      <Message
        chatbot={chatbot}
        message={{
          id: '0',
          conversation_id: '0',
          role: ROLE_ASSISTANT,
          content: initialMessage,
          type: 'text',
        }}
        key='message-0'
      />
    ));

    return messagesElements;
  };

  const printHeader = () => {
    if (!header) {
      return null;
    }

    const agentList = availableAgents.map((agent, index) => (
      <li key={index}>
        <Avatar name={agent} size='30' round />
      </li>
    ));

    const headerTitle = chatbot?.extra_info?.header_title || literals.chat;
    const headerSubtitle = chatbot?.extra_info?.header_subtitle || literals.doYouHaveAnyQuestions;

    return (
      <div className='llm-chatbot-header' onClick={toggleHidden}>
        {!isHidden && (
          <div className='llm-chatbot-close-icon'>
            <CloseIcon color='#fffff' />
          </div>
        )}
        <div className='llm-chatbot-title-conversation'>
          {headerTitle}
        </div>
        {!isHidden && (
          <div className='llm-chatbot-desc-conversation' style={{ justifyContent: 'center', padding: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
              <ul>
                {agentList}
              </ul>
            </div>
            <p style={{ margin: 0, padding: 0 }}>{headerSubtitle}</p>
          </div>
        )}
      </div>
    );
  };

  const deletePastedFile = () => {
    setPastedFile(null);
  };

  const sendPastedFile = () => () => {
    onUploadFile(pastedFile.file);
    setPastedFile(null);
  };

  const printPastedFile = () => {
    if (!pastedFile) {
      return null;
    }

    return (
      <div className='llm-chatbot-pasted-file-layer'>
        <div>
          {pastedFile.imageSrc ? (
            <div>
              <img src={pastedFile.imageSrc} alt='Pasted' className='llm-chatbot-preview' />
            </div>
          ) : (
            <div className='llm-chatbot-preview_icon_layer'>
              <img src={AddFileIcon} alt='Pasted' />
            </div>
          )}
          <div>
            <button type='button' className='btn btn-primary btn-sm' onClick={sendPastedFile()}>{literals.send}</button>
            <button type='button' className='btn btn-secondary btn-sm' onClick={deletePastedFile}>{literals.common.cancel}</button>
          </div>
        </div>
        <div className='llm-chatbot-filename'>{pastedFile.file.name}</div>
      </div>
    );
  };

  const printAudioRecording = () => {
    if (isLoadingAudioRecord) {
      return (
        <div className='llm-chatbot-recording-layer'>
          <div className='llm-chatbot-content'>
            {literals.startingAudio}
          </div>
        </div>
      );
    }

    return (
      <div className='llm-chatbot-recording-layer' onClick={stopRecording}>
        <div className='llm-chatbot-volume-analyzer' style={{ width: `${widthVolume}%` }} />
        <div className='llm-chatbot-content'>
          <StopIcon fill={chatbot?.extra_info?.text_color_recording_audio_message ?? '#ffffff'} />
          {literals.stopRecording}
        </div>
        <div className='llm-chatbot-time-display'>
          {timeRecording}
        </div>
      </div>
    );
  };

  const printBubble = () => {
    const headerTitle = chatbot?.extra_info?.header_title || literals.chat;
    let iconSource = IconBubbleSrc;
    const iconBubble = chatbot?.extra_info?.icon_bubble;
    if (iconBubble) {
      iconSource = `${window.LLM_CHATBOT_API_URL}/chatbots/${chatbot.id}/bubble-icon?t=${iconBubble}`;
    }

    return (
      <div className='llm-chatbot-bubble-minimized llm-in-page' onClick={toggleHidden}>
        <img src={iconSource} alt={headerTitle} />
      </div>
    );
  };

  if (isHidden) {
    const showAsBubble = chatbot?.extra_info?.show_as_bubble;
    if (showAsBubble) {
      return printBubble();
    }
    return (
      <div className='llm-chat-form minimized'>
        {printHeader()}
      </div>
    );
  }

  const printWrintingLayer = () => {
    if (messages.length === 0) {
      return null;
    }
    const lastmessage = messages[messages.length - 1];
    if (conversation && conversation.agent_life === 'Main Agent' && (lastmessage.role === 'user' || lastmessage.role === 'debug')) {
      return <p id='writing'>{literals.writing}</p>;
    }
    return null;
  };

  return (
    <div className='llm-chat-form'>
      {printHeader()}
      <div className='llm-chatbot-messages-layer' ref={divRef} data-cy='chatbot-conversation' onScroll={handleScroll}>
        {showTransferedMessage && (
          <div className='llm-chatbot-transfered-message'>
            {literals.conversationTransferred}
          </div>
        )}
        {printMessages()}
        <div ref={messagesEndRef} />
        {printWrintingLayer()}
      </div>
      {printPastedFile()}
      {!disabled && (
        <div className='llm-chatbot-form-layer'>
          <div className='llm-chatbot-form-content'>
            <TextareaAutosize autoFocus value={actualMessage} type='text' placeholder={chatbot.input_placeholder || ''} onChange={handleChangeActualMessage} onKeyDown={handleKeyDownMessage} onKeyUp={handleKeyUpMessage} onBlur={handleBlurMessage} onFocus={handleFocusMessage} data-cy='chatbot-conversation-input' />
          </div>
          {actualMessage.trim() && (
            <button className='llm-chatbot-btn-send' type='button' onClick={sendMessage}>
              <img src={SendIcon} alt='Send' />
            </button>
          )}
        </div>
      )}
      {isRecording || isLoadingAudioRecord ? printAudioRecording() : (
        <div>
          <input type='file' onChange={fileSelectedHandler} style={{ display: 'none' }} ref={fileRef} />
          <button className='llm-chatbot-btn-attachment' type='button' onClick={fileUploadHandler}>
            <img src={AttachmentIcon} alt='Attachment' />
          </button>
          <button className='llm-chatbot-btn-voice' type='button' onClick={startRecording}>
            <img src={MicrophoneIcon} alt='Send audio' />
          </button>
        </div>
      )}
    </div>
  );
}

ChatForm.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  conversation: PropTypes.object,
  onSendMessage: PropTypes.func.isRequired,
  onSendAudio: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  header: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isPreview: PropTypes.bool,
  showTransferedMessage: PropTypes.bool,
  chatbot: PropTypes.shape({
    initial_message: PropTypes.string,
    enable_debug: PropTypes.bool,
    input_placeholder: PropTypes.string,
    id: PropTypes.string,
    extra_info: PropTypes.shape({
      text_color_recording_audio_message: PropTypes.string,
      start_minimized: PropTypes.bool,
      header_title: PropTypes.string,
      header_subtitle: PropTypes.string,
      bubble_icon: PropTypes.string,
      show_as_bubble: PropTypes.bool,
      icon_bubble: PropTypes.string,
    }),
  }),
  onClose: PropTypes.func,
};

ChatForm.defaultProps = {
  conversation: null,
  chatbot: null,
  disabled: false,
  isPreview: false,
  showTransferedMessage: false,
  onClose: null,
};

export default ChatForm;
