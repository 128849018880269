import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const NUMBER_CATEGORY = 'Number';

export default {
  NUMBER_EQUALS: {
    categories: [NUMBER_CATEGORY],
    tag: 'numberEquals',
    name: '== (Number)',
    tags: ['equals', 'number', 'compare', '=='],
    icon: 'fa-solid fa-equals',
    inputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a',
        custom: false,
        default_value: '0',
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'b',
        custom: false,
        default_value: '0',
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '==',
        custom: false,
        to: [],
      },
    ],
  },
  NUMBER_LOWER_THAN: {
    categories: [NUMBER_CATEGORY],
    tag: 'numberLowerThan',
    name: '< (Number)',
    tags: ['lower', 'number', 'compare', '=='],
    icon: 'fa-solid fa-equals',
    inputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a',
        custom: false,
        default_value: '0',
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'b',
        custom: false,
        default_value: '0',
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '<',
        custom: false,
        to: [],
      },
    ],
  },
  NUMBER_LOWER_OR_EQUAL_THAN: {
    categories: [NUMBER_CATEGORY],
    tag: 'numberLowerOrEqualThan',
    name: '<= (Number)',
    tags: ['lower', 'number', 'compare', '<=', 'equal'],
    icon: 'fa-solid fa-equals',
    inputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a',
        custom: false,
        default_value: '0',
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'b',
        custom: false,
        default_value: '0',
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '<=',
        custom: false,
        to: [],
      },
    ],
  },
  NUMBER_GREATER_THAN: {
    categories: [NUMBER_CATEGORY],
    tag: 'numberGreaterThan',
    name: '> (Number)',
    tags: ['greater', 'number', 'compare', '>', 'greater'],
    icon: 'fa-solid fa-equals',
    inputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a',
        custom: false,
        default_value: '0',
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'b',
        custom: false,
        default_value: '0',
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '>',
        custom: false,
        to: [],
      },
    ],
  },
  NUMBER_GREATER_OR_EQUAL_THAN: {
    categories: [NUMBER_CATEGORY],
    tag: 'numberGreaterOrEqualThan',
    name: '>= (Number)',
    tags: ['greater', 'number', 'compare', '>=', 'greater', 'equal'],
    icon: 'fa-solid fa-equals',
    inputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a',
        custom: false,
        default_value: '0',
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'b',
        custom: false,
        default_value: '0',
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '>=',
        custom: false,
        to: [],
      },
    ],
  },
  NUMBER_ADD: {
    categories: [NUMBER_CATEGORY],
    tag: 'numberAdd',
    name: 'a + b (Number)',
    tags: ['add', 'number', 'sum', '+', 'increment'],
    icon: 'fa-solid fa-plus',
    inputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'b',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a + b',
        custom: false,
        to: [],
      },
    ],
  },
  NUMBER_SUBTRACT: {
    categories: [NUMBER_CATEGORY],
    tag: 'numberSubtract',
    name: 'a - b (Number)',
    tags: ['subtract', 'number', '-', 'decrement'],
    icon: 'fa-solid fa-minus',
    inputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'b',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a - b',
        custom: false,
        to: [],
      },
    ],
  },
  NUMBER_MULTIPLY: {
    categories: [NUMBER_CATEGORY],
    tag: 'numberMultiply',
    name: 'a * b (Number)',
    tags: ['multiply', 'number', '*', 'product'],
    icon: 'fa-solid fa-times',
    inputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'b',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a * b',
        custom: false,
        to: [],
      },
    ],
  },
  NUMBER_DIVIDE: {
    categories: [NUMBER_CATEGORY],
    tag: 'numberDivide',
    name: 'a / b (Number)',
    tags: ['divide', 'number', '/', 'quotient'],
    icon: 'fa-solid fa-divide',
    inputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'b',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a / b',
        custom: false,
        to: [],
      },
    ],
  },
  NUMBER_MODULO: {
    categories: [NUMBER_CATEGORY],
    tag: 'numberModulo',
    name: 'a % b (Number)',
    tags: ['modulo', 'number', '%', 'remainder'],
    icon: 'fa-solid fa-percent',
    inputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'b',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'a % b',
        custom: false,
        to: [],
      },
    ],
  },
  TO_NUMBER: {
    categories: [NUMBER_CATEGORY],
    tag: 'toNumber',
    name: 'To Number',
    tags: ['to number', 'number', 'convert to number', 'string to number'],
    icon: 'fa-solid fa-number',
    inputs: [
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'string',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'number',
        custom: false,
        to: [],
      },
    ],
  },
};
