import { useSelector } from 'react-redux';
import { retryMessage as retryMessageAction } from 'modules/chats/actions.js';
import { ROLE_USER } from 'constants/messages';

const useMessages = (conversationId) => {
  const messages = useSelector((state) => state.chats.chats[conversationId]?.messages ?? []);
  const userId = useSelector((state) => state.session.user?.id ?? null);

  // Return previous message from user
  const getPreviousMessage = (messageId) => {
    let index = messages.findIndex((message) => message.id === messageId);
    while (index > 0) {
      index -= 1;
      if (messages[index].role === ROLE_USER) {
        return messages[index];
      }
    }
    return null;
  };

  const retryMessage = (chatbotId, messageId) => {
    retryMessageAction(chatbotId, conversationId, messageId);
  };

  return {
    getPreviousMessage,
    retryMessage,
  };
};

export default useMessages;
