import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getErrorMessage } from 'utils/errors';
import useLiterals from 'hooks/useLiterals';
import useMessages from 'hooks/useMessages';
import moment from 'moment';
import {
  TYPE_NUMBER, TYPE_SELECT, TYPE_PHONE, TYPE_EMAIL, TYPE_DATE,
} from 'constants/forms';
import { isEmail, isDate } from 'validator';
import { fillForm } from 'modules/forms/actions';

function Form(props) {
  const {
    chatbotId, conversationId, message, onSubmit, previousMessageId,
  } = props;
  const [answers, setAnswers] = useState(message?.extra_info?.form?.values ?? {});
  const [errors, setErrors] = useState([]);
  const [isSaved, setIsSaved] = useState(!!message?.extra_info?.form?.values);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const literals = useLiterals('forms');
  const dispatch = useDispatch();

  const { retryMessage, getPreviousMessage } = useMessages(conversationId);

  const handleChangeQuestion = (e) => {
    const { name, value } = e.target;
    setAnswers({ ...answers, [name]: value });
  };

  const handleSelectFormOption = (e) => {
    const target = e.currentTarget;
    const optionValue = target.getAttribute('data-option-value');
    const questionName = target.getAttribute('data-question-name');

    setAnswers({ ...answers, [questionName]: optionValue });
  };

  const printQuestions = () => {
    const questions = message?.extra_info?.form?.questions ?? [];

    return questions.map((question) => {
      const value = answers[question.variable_name] ?? '';

      let questionContent = null;

      switch (question.type) {
        case TYPE_SELECT:
          questionContent = question.options.map((option) => {
            const isSelected = answers[question.variable_name] === option.value;
            return (
              <div className={`message-form-question-option ${isSelected ? 'selected' : ''}`} key={option.id} onClick={handleSelectFormOption} data-option-value={option.value} data-question-name={question.variable_name}>
                <div className='message-form-question-option-radio' />
                <label htmlFor={option.id}>{option.text}</label>
              </div>
            );
          });
          break;
        default: {
          let type = question.type;
          if (type === TYPE_PHONE) {
            type = 'tel';
          }

          let placeholder = '';
          if (type === TYPE_EMAIL) {
            placeholder = 'example@domain.com';
          }
          questionContent = (
            <div className='input_value'>
              <input name={question.variable_name} type={type} className='form_input_stl' onChange={handleChangeQuestion} value={value} placeholder={placeholder} />
            </div>
          );
        }
          break;
      }

      return (
        <div className={`message-form-question ${errors.includes(question.variable_name) ? 'error' : ''}`} key={question.id}>
          <div className='input_title'>
            {question.title}
            {question.required && <span className='required_field'>*</span>}
          </div>
          {questionContent}
        </div>
      );
    });
  };

  const checkForm = () => {
    const questions = message?.extra_info?.form?.questions ?? [];
    const newErrors = [];

    questions.forEach((question) => {
      const value = answers[question.variable_name] ?? '';

      if (question.required && !value) {
        newErrors.push(question.variable_name);
      } else {
        switch (question.type) {
          case TYPE_EMAIL:
            if (value && !isEmail(value)) {
              newErrors.push(question.variable_name);
            }
            break;
          case TYPE_NUMBER: {
            const regex = /^\d+$/;
            if (value && !regex.test(value)) {
              newErrors.push(question.variable_name);
            }
          }
            break;
          case TYPE_DATE:
            if (value && (!isDate(value) || !moment(value).isValid())) {
              newErrors.push(question.variable_name);
            }
            break;
          default:
            break;
        }
      }
    });

    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const submitForm = async () => {
    const isOk = checkForm();
    if (isOk) {
      try {
        setIsSaving(true);
        await dispatch(fillForm(chatbotId, conversationId, message.id, answers));
        setIsSaved(true);
        setErrorMessage('');
        if (previousMessageId) {
          retryMessage(chatbotId, previousMessageId);
        } else {
          const previousMessage = getPreviousMessage(message.id);
          if (previousMessage) {
            retryMessage(chatbotId, previousMessage.id);
          }
        }

        if (onSubmit) {
          onSubmit();
        }
      } catch (e) {
        const error = getErrorMessage(e);
        setErrorMessage(error);
      } finally {
        setIsSaving(false);
      }
    } else {
      console.error('Han ocurrido errores');
    }
  };

  let textButton = isSaved ? literals.common.sendAgain : literals.common.send;
  if (isSaving) {
    textButton = literals.common.sending;
  }

  return (
    <div className='message-form'>
      {printQuestions()}
      {errorMessage && (
        <div className='alert alert-danger mt10'>
          {errorMessage}
        </div>
      )}
      <div className='form_buttons'>
        <button type='button' className='btn btn-message-form' onClick={submitForm}>
          {isSaving && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
          {textButton}
        </button>
      </div>
    </div>
  );
}

Form.propTypes = {
  chatbotId: PropTypes.string.isRequired,
  conversationId: PropTypes.string.isRequired,
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    extra_info: PropTypes.shape({
      form: PropTypes.shape({
        questions: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          required: PropTypes.bool.isRequired,
          variable_name: PropTypes.string.isRequired,
          options: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
          })),
        })).isRequired,
        values: PropTypes.shape({}),
      }).isRequired,
    }).isRequired,
  }).isRequired,
  onSubmit: PropTypes.func,
  previousMessageId: PropTypes.string,
};

Form.defaultProps = {
  onSubmit: null,
  previousMessageId: null,
};

export default Form;
