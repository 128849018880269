import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function Avatar(props) {
  const { src, size } = props;

  const style = {
    width: size,
    height: size,
    backgroundImage: `url(${src})`,
  };
  return (
    <div className='chatbot-avatar' style={style} />
  );
}

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.number,
};

Avatar.defaultProps = {
  size: 30,
};

export default Avatar;
