import CHATBOT_CATEGORY from './node_categories/chatbot';
import FLOW_CONTROL_CATEGORY from './node_categories/flow_control';
import FORM_CATEGORY from './node_categories/form';
import DATA_CATEGORY from './node_categories/data';
import NETWORK_CATEGORY from './node_categories/network';
import UTILITIES_CATEGORY from './node_categories/utilities';
import CONVERSATION_CATEGORY from './node_categories/conversation';
import ZENDESK_CATEGORY from './node_categories/zendesk';
import BOOLEANS_CATEGORY from './node_categories/booleans';
import STRING_CATEGORY from './node_categories/string';
import MESSAGE_CATEGORY from './node_categories/message';
import NUMBER_CATEGORY from './node_categories/number';
import TWILIO_CATEGORY from './node_categories/twilio';
import API_CATEGORY from './node_categories/api';
import DOCUMENT_CATEGORY from './node_categories/document';
import EMBEDDING_CATEGORY from './node_categories/embedding';
import ORCHESTRATOR_CATEGORY from './node_categories/orchestrator';
import ARRAY_CATEGORY from './node_categories/array';
import DATASTRUCTURE_CATEGORY from './node_categories/datastructure';
import DATATABLE_CATEGORY from './node_categories/datatable';
import EMAIL_CATEGORY from './node_categories/email';
import FAQ_CATEGORY from './node_categories/faq';
import GOOGLE_CATEGORY from './node_categories/google';
import GITLAB_REPOSITORY_TREE from './node_categories/gitlab';

export const NODE_TYPES_STRUCTURE = {
  ...CHATBOT_CATEGORY,
  ...FLOW_CONTROL_CATEGORY,
  ...FORM_CATEGORY,
  ...DATA_CATEGORY,
  ...NETWORK_CATEGORY,
  ...UTILITIES_CATEGORY,
  ...CONVERSATION_CATEGORY,
  ...ZENDESK_CATEGORY,
  ...BOOLEANS_CATEGORY,
  ...STRING_CATEGORY,
  ...MESSAGE_CATEGORY,
  ...NUMBER_CATEGORY,
  ...TWILIO_CATEGORY,
  ...API_CATEGORY,
  ...DOCUMENT_CATEGORY,
  ...EMBEDDING_CATEGORY,
  ...ORCHESTRATOR_CATEGORY,
  ...ARRAY_CATEGORY,
  ...DATASTRUCTURE_CATEGORY,
  ...DATATABLE_CATEGORY,
  ...EMAIL_CATEGORY,
  ...FAQ_CATEGORY,
  ...GOOGLE_CATEGORY,
  ...GITLAB_REPOSITORY_TREE,
};
