import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { loadingActions } from 'modules/loading';
import useLiterals from 'hooks/useLiterals';
import Form from 'react-bootstrap/Form';
import FeedbackModal from 'components/FeedbackModal';
import { testEmailIntegration } from 'modules/chatbots/actions';

export const POP3 = 'POP3';
export const IMAP = 'IMAP';

function EmailIntegration(props) {
  const {
    id, type, username, password, host, port, useSSL, chatbotId,
    onChangeType, onChangeUsername, onChangePassword, onChangeHost, onChangePort, onChangeUseSSL, onRemove,
  } = props;

  const [resultTestConnection, setResultTestConnection] = useState(null);

  const literals = useLiterals('chatbot');
  const dispatch = useDispatch();

  const handleChangeType = (e) => {
    onChangeType(id, e.target.value);
  };

  const handleChangeUsername = (e) => {
    onChangeUsername(id, e.target.value);
  };

  const handleChangePassword = (e) => {
    onChangePassword(id, e.target.value);
  };

  const handleChangeHost = (e) => {
    onChangeHost(id, e.target.value);
  };

  const handleChangePort = (e) => {
    onChangePort(id, e.target.value);
  };

  const handleChangeUseSSL = (e) => {
    onChangeUseSSL(id, e.target.checked);
  };

  const handleRemoveEmailIntegration = () => {
    onRemove(id);
  };

  const canBeTested = () => {
    return username && password && host && port;
  };

  const handleTestConnection = async () => {
    try {
      dispatch(loadingActions.show());
      await testEmailIntegration(chatbotId, type, username, password, host, port, useSSL);
      setResultTestConnection(true);
    } catch (err) {
      setResultTestConnection(false);
    } finally {
      dispatch(loadingActions.hide());
    }
  };

  const handleCloseFeedbackModal = () => {
    setResultTestConnection(null);
  };

  const printResultTestConnection = () => {
    if (resultTestConnection === true) {
      return <FeedbackModal open title={literals.connectionSuccess} success message={literals.connectionSuccessMessage} onClose={handleCloseFeedbackModal} />;
    }

    if (resultTestConnection === false) {
      return <FeedbackModal open title={literals.connectionError} success={false} message={literals.connectionErrorMessage} onClose={handleCloseFeedbackModal} />;
    }

    return null;
  };

  return (
    <>
      {printResultTestConnection()}
      <div className='panel'>
        <div className='panel-header'>{literals.emailIntegration}</div>
        <div className='panel-body'>
          <div className='input_title'>
            {literals.type}
          </div>
          <div className='input_value'>
            <select className='form_select_stl' onChange={handleChangeType} value={type}>
              <option value={POP3}>{POP3}</option>
              <option value={IMAP}>{IMAP}</option>
            </select>
          </div>
          <div className='input_title'>
            {literals.username}
            <span className='required_field'>*</span>
          </div>
          <div className='input_value'>
            <input type='text' className='form_input_stl' value={username} onChange={handleChangeUsername} autoComplete='off' />
          </div>
          <div className='input_title'>
            {literals.password}
            <span className='required_field'>*</span>
          </div>
          <div className='input_value'>
            <input type='password' className='form_input_stl' value={password} onChange={handleChangePassword} autoComplete='off' />
          </div>
          <div className='input_title'>
            {literals.host}
            <span className='required_field'>*</span>
          </div>
          <div className='input_value'>
            <input type='text' className='form_input_stl' value={host} onChange={handleChangeHost} />
          </div>
          <div className='input_title'>
            {literals.port}
            <span className='required_field'>*</span>
          </div>
          <div className='input_value'>
            <input type='text' className='form_input_stl' value={port} onChange={handleChangePort} />
          </div>
          <div className='input_title'>
            <Form.Check
              type='switch'
              id={`ssl-switch-${id}`}
              label={literals.useSSL}
              onChange={handleChangeUseSSL}
              checked={useSSL}
            />
          </div>
          <div className='form_buttons'>
            <button type='button' className='btn btn-secondary' disabled={!canBeTested()} onClick={handleTestConnection}>
              {literals.testConnection}
            </button>
            <button type='button' className='btn btn-danger' onClick={handleRemoveEmailIntegration}>
              {literals.common.delete}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

EmailIntegration.propTypes = {
  id: PropTypes.string.isRequired,
  chatbotId: PropTypes.string.isRequired,
  type: PropTypes.oneOf([POP3, IMAP]).isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  host: PropTypes.string.isRequired,
  port: PropTypes.string.isRequired,
  useSSL: PropTypes.bool.isRequired,
  onChangeType: PropTypes.func.isRequired,
  onChangeUsername: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onChangeHost: PropTypes.func.isRequired,
  onChangePort: PropTypes.func.isRequired,
  onChangeUseSSL: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default EmailIntegration;
