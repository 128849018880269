import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const API_CATEGORY = 'Api';

export default {
  RETURN_NODE: {
    categories: [API_CATEGORY],
    tag: 'returnNode',
    name: 'Add return node',
    tags: ['return', 'end', 'api', 'add'],
    icon: 'fa-solid fa-file-invoice',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
    ],
  },
  CONNECT_API: {
    categories: [API_CATEGORY],
    tag: 'connectApi',
    name: 'Connect API',
    tags: ['api'],
    icon: 'fa-solid fa-network-wired',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.API,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'api',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ENDPOINT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'endpoint',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'status code',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'response text',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'response json',
        custom: false,
        to: [],
      },
    ],
  },
  API_AUTHENTICATION: {
    categories: [API_CATEGORY],
    tag: 'apiAuthentication',
    name: 'API Authentication',
    tags: ['api', 'login in api', 'login api', 'authenticate in api', 'authenticate api', 'api authentication', 'authentication', 'login', 'authenticate'],
    icon: 'fa-solid fa-network-wired',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.API,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'api',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'success',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'response text',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'response json',
        custom: false,
        to: [],
      },
    ],
  },

};
