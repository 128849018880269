import Error from './Error';
import Companies from './Companies';
import DueDiligence from './DueDiligences';
import Project from './Project';
import Login from './Login';
import Chatbots from './Chatbots';
import Chatbot from './Chatbot';
import ChatbotEmbed from './ChatbotEmbed';
import Organizations from './Organizations';
import Signup from './Signup';
import SetPassword from './SetPassword';
import ForgotPassword from './ForgotPassword';
import Tests from './Tests';
import Orchestrators from './Orchestrators';
import Orchestrator from './Orchestrator';
import OrchestratorStatistics from './OrchestratorStatistics';
import OrchestratorConversations from './OrchestratorConversations';
import Apis from './Apis';
import Forms from './Forms';
import FormPublicView from './FormPublicView';
import ApiKeys from './ApiKeys';
import Datastructures from './Datastructures';
import Datatables from './Datatables';
import DatatableEntries from './DatatableEntries';

export const ROUTE_TYPE_REDIRECT = 'redirect';
export const ROUTE_BASE_PATH = '/';

export const ROUTE_PATH = Object.freeze({
  BASE: ROUTE_BASE_PATH,
  ERROR: '/error',
  LOGIN: '/login',
  COMPANIES: '/companies',
  COMPANY: '/companies/:id',
  PROJECT: '/companies/:companyId/projects/:projectId',
  CHATBOTS: '/chatbots',
  CHATBOT: '/chatbots/:id',
  CHATBOT_EMBED: '/embed/chatbot/:id',
  ORGANIZATIONS: '/organizations',
  SIGNUP: '/signup',
  SET_TOKEN: '/set-token/:token',
  FORGOT_PASSWORD: '/forgot-password',
  TESTS: '/tests',
  PROJECTS: '/projects',
  ORCHESTRATORS: '/orchestrators',
  ORCHESTRATOR: '/orchestrators/:id',
  ORCHESTRATOR_STATISTICS: '/orchestrators/:id/statistics',
  ORCHESTRATOR_CONVERSATIONS: '/orchestrators/:orchestratorId/conversations',
  APIS: '/my-apis',
  FORMS: '/forms',
  FORM_PUBLIC_VIEW: 'public/forms/:id',
  API_KEYS: '/api-keys',
  DATASTRUCTURES: '/datastructures',
  DATATABLES: '/datatables',
  DATATABLE_ENTRIES: '/datatables/:id/entries',
});

export const routes = [
  {
    type: ROUTE_TYPE_REDIRECT,
    from: ROUTE_BASE_PATH,
    to: ROUTE_PATH.CHATBOTS,
    exact: true,
  },
  {
    path: ROUTE_PATH.COMPANIES,
    component: Companies,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.LOGIN,
    component: Login,
    exact: true,
    ignoreSession: true,
    header: false,
  },
  {
    path: ROUTE_PATH.SIGNUP,
    component: Signup,
    exact: true,
    ignoreSession: true,
    header: false,
  },
  {
    path: ROUTE_PATH.SET_TOKEN,
    component: SetPassword,
    exact: true,
    ignoreSession: true,
    header: false,
  },
  {
    path: ROUTE_PATH.FORGOT_PASSWORD,
    component: ForgotPassword,
    exact: true,
    ignoreSession: true,
    header: false,
  },
  {
    path: ROUTE_PATH.CHATBOTS,
    component: Chatbots,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.ORGANIZATIONS,
    component: Organizations,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.ORCHESTRATOR_STATISTICS,
    component: OrchestratorStatistics,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.ORCHESTRATOR_CONVERSATIONS,
    component: OrchestratorConversations,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.ORCHESTRATORS,
    component: Orchestrators,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.ORCHESTRATOR,
    component: Orchestrator,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.TESTS,
    component: Tests,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.CHATBOT,
    component: Chatbot,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.COMPANY,
    component: DueDiligence,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.PROJECT,
    component: Project,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.CHATBOT_EMBED,
    component: ChatbotEmbed,
    exact: true,
    ignoreSession: true,
    header: false,
  },
  {
    path: ROUTE_PATH.APIS,
    component: Apis,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.FORMS,
    component: Forms,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.FORM_PUBLIC_VIEW,
    component: FormPublicView,
    exact: true,
    ignoreSession: true,
    header: false,
  },
  {
    path: ROUTE_PATH.API_KEYS,
    component: ApiKeys,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.DATASTRUCTURES,
    component: Datastructures,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.DATATABLES,
    component: Datatables,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.DATATABLE_ENTRIES,
    component: DatatableEntries,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: `${ROUTE_PATH.ERROR}/:status`,
    component: Error,
    exact: false,
    ignoreSession: true,
  },
  {
    type: ROUTE_TYPE_REDIRECT,
    from: '',
    to: `${ROUTE_PATH.ERROR}/404`,
    exact: false,
  },
];
