import React from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';

function CreateOrganizationForm(props) {
  const {
    name, description, onChangeName, onChangeDescription, error,
    primaryColor, secondaryColor, onChangeLogo, onChangePrimaryColor, onChangeSecondaryColor,
  } = props;

  const literals = useLiterals('organizations');

  const handleOnChangeName = (event) => {
    onChangeName(event.target.value);
  };

  const handleOnChangeDescription = (event) => {
    onChangeDescription(event.target.value);
  };

  const handleOnChangeLogo = (event) => {
    onChangeLogo(event.target.files[0]);
  };

  const handleChangePrimaryColor = (event) => {
    onChangePrimaryColor(event.target.value);
  };

  const handleChangeSecondaryColor = (event) => {
    onChangeSecondaryColor(event.target.value);
  };

  return (
    <form>
      <div className='row'>
        <div className='col-12'>
          <div className='input_title'>
            {literals.common.name}
          </div>
          <div className='input_value'>
            <input type='text' className='form_input_stl' onChange={handleOnChangeName} value={name} />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='input_title'>
            {literals.description}
          </div>
          <div className='input_value'>
            <input type='text' className='form_input_stl' onChange={handleOnChangeDescription} value={description} />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='input_title'>
            {literals.logo}
          </div>
          <div className='input_value'>
            <input type='file' onChange={handleOnChangeLogo} />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-6'>
          <div className='input_title'>
            {literals.primaryColor}
          </div>
          <div className='input_value'>
            <input type='color' className='form_input_stl' value={primaryColor} onChange={handleChangePrimaryColor} />
          </div>
        </div>
        <div className='col-6'>
          <div className='input_title'>
            {literals.secondaryColor}
          </div>
          <div className='input_value'>
            <input type='color' className='form_input_stl' value={secondaryColor} onChange={handleChangeSecondaryColor} />
          </div>
        </div>
      </div>
      {error && (
        <div className='error'>
          {error}
        </div>
      )}
    </form>
  );
}

CreateOrganizationForm.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  onChangeLogo: PropTypes.func.isRequired,
  onChangePrimaryColor: PropTypes.func.isRequired,
  onChangeSecondaryColor: PropTypes.func.isRequired,
  error: PropTypes.string,
};

CreateOrganizationForm.defaultProps = {
  error: '',
};

export default CreateOrganizationForm;
