export const SET_ORCHESTRATORS = 'orchestrators/set-orchestrators';
export const CREATE_ORCHESTRATOR = 'orchestrators/create-orchestrator';
export const UPDATE_ORCHESTRATOR = 'orchestrators/update-orchestrator';
export const DELETE_ORCHESTRATOR = 'orchestrators/delete-orchestrator';

export const initialState = {
  orchestrators: null,
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_ORCHESTRATORS: {
      return {
        ...state,
        orchestrators: payload,
      };
    }
    case CREATE_ORCHESTRATOR: {
      const { orchestrators } = state;
      const newOrchestrators = orchestrators ? [...orchestrators] : [];
      newOrchestrators.push(payload);
      return {
        ...state,
        orchestrators: newOrchestrators,
      };
    }
    case UPDATE_ORCHESTRATOR: {
      const orchestrators = [...state.orchestrators];

      const index = orchestrators.findIndex((c) => c.id === payload.id);
      if (index !== -1) {
        orchestrators[index] = payload;
      }

      return {
        ...state,
        orchestrators,
      };
    }
    case DELETE_ORCHESTRATOR: {
      const orchestrators = [...state.orchestrators];
      const filteredOrchestrators = orchestrators.filter((orchestrator) => orchestrator.id !== payload);
      return {
        ...state,
        orchestrators: filteredOrchestrators,
      };
    }
    default:
      return state;
  }
};

export default reducer;
