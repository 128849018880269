import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLiterals from 'hooks/useLiterals';
import { Button } from 'react-bootstrap';
import { ROUTE_PATH } from 'routes';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { loadingActions } from 'modules/loading';
import {
  getOrchestratorsStatistics,
} from 'modules/orchestrators/actions';
import { ORCHESTRATOR_TYPE_CONVERSATION } from 'constants/orchestrator';
import Layout from 'components/Layout';
import { snakeToCamelCase } from 'utils/text';
import DatePicker from 'components/DatePicker';
import BarChartItem from './components/barChart';
import PieChartItem from './components/pieChart';
import './styles.scss';

function OrchestratorsStatistics() {
  const literals = useLiterals('orchestrators');
  const dispatch = useDispatch();
  const [orchestratorStatistics, setOrchestratorStatistics] = useState(null);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss.SSSSSS'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss.SSSSSS'));
  const [selectedVersion, setSelectedVersion] = useState('');
  const [selectedFunction, setSelectedFunction] = useState('');

  const { id } = useParams();
  const { orchestrators } = useSelector((state) => state.orchestrators);
  const orchestrator = orchestrators.find((o) => o.id === id);
  const navigate = useNavigate();

  const fetchOrchestratorsStatistics = () => {
    dispatch(loadingActions.show());
    getOrchestratorsStatistics(id, startDate, endDate)
      .then((response) => {
        setOrchestratorStatistics(response);
      })
      .catch((error) => {
        console.error(error);
      });
    dispatch(loadingActions.hide());
  };

  useEffect(() => {
    fetchOrchestratorsStatistics();
  }, []);

  useEffect(() => {
    setSelectedVersion('');
  }, [selectedFunction]);

  const handleBackToOrchestratos = () => {
    navigate(ROUTE_PATH.ORCHESTRATORS);
  };
  const handleStartDateChange = (event) => {
    setStartDate(moment(event.target.value).format('YYYY-MM-DD HH:mm:ss.SSSSSS'));
  };

  const handleEndDateChange = (event) => {
    const endDateMoment = moment(event.target.value).set({ hour: 23, minute: 59 });
    setEndDate(endDateMoment.format('YYYY-MM-DD HH:mm:ss.SSSSSS'));
  };

  const printDateRangeSelector = () => {
    return (
      <div className='orchestrator-stats-date'>
        <DatePicker name={literals.startDate} value={startDate} onChange={handleStartDateChange} />
        <DatePicker name={literals.endDate} value={endDate} onChange={handleEndDateChange} />
        <Button variant='primary' onClick={fetchOrchestratorsStatistics}>{literals.search}</Button>
      </div>
    );
  };

  const handleOnChangeSelectedVersion = (event) => {
    setSelectedVersion(event.target.value);
  };

  const handleOnChangeSelectedFunction = (event) => {
    setSelectedFunction(event.target.value);
  };

  const printNodeStatistics = () => {
    const filteredNodes = orchestratorStatistics.nodes_statistics.filter((node) => {
      if (selectedVersion && node.orchestrator_version_id !== selectedVersion) {
        return false;
      }
      if (selectedFunction) {
        if (selectedFunction === orchestratorStatistics.id && node.function_id) {
          return false;
        }
        if (selectedFunction !== orchestratorStatistics.id && node.function_id !== selectedFunction) {
          return false;
        }
      }
      return true;
    });

    let insertedRows = 0;
    let actualVersionId = orchestratorStatistics.actual_version_id;
    if (selectedFunction) {
      const functionData = orchestratorStatistics.functions.find((f) => f.id === selectedFunction);
      if (functionData) {
        actualVersionId = functionData.actual_version_id;
      }
    }
    console.log('actualVersionId', actualVersionId);
    const rows = filteredNodes.map((nodeStatistic) => {
      let node = null;
      let version = null;
      let functionData = null;
      let versionIndex = 0;

      if (insertedRows >= 20) {
        return null;
      }

      // if (nodeStatistic.avg_time === 0 && nodeStatistic.avg_total_cost === 0 && nodeStatistic.avg_input_tokens === 0 && nodeStatistic.avg_output_tokens === 0) {
      //   return null;
      // }

      if (nodeStatistic.function_id) {
        functionData = orchestratorStatistics.functions.find((f) => f.id === nodeStatistic.function_id);
        if (functionData) {
          node = functionData.children.find((n) => n.id === nodeStatistic.orchestrator_item_id);
          version = functionData.versions.find((v) => v.id === nodeStatistic.orchestrator_version_id);
          versionIndex = functionData.versions.findIndex((v) => v.id === nodeStatistic.orchestrator_version_id);
        }
      } else {
        node = orchestratorStatistics.children.find((n) => n.id === nodeStatistic.orchestrator_item_id);
        version = orchestratorStatistics.versions.find((v) => v.id === nodeStatistic.orchestrator_version_id);
        versionIndex = orchestratorStatistics.versions.findIndex((v) => v.id === nodeStatistic.orchestrator_version_id);
      }

      if (!node) {
        return null;
      }

      insertedRows += 1;

      let typeText = literals[[snakeToCamelCase(node.type.toLowerCase())]];
      let nameText = node.name;

      if (node.type === 'GET' || node.type === 'SET') {
        const variableName = node.extra_info?.variable || '';
        typeText = `${typeText} ${variableName}`;
        if (!nameText) {
          nameText = typeText;
        }
      }

      return (
        <tr key={node.id} data-id={node.id}>
          <td>{nameText || '-'}</td>
          <td>{typeText}</td>
          <td>{nodeStatistic.avg_time.toFixed(4)}</td>
          <td>{nodeStatistic.avg_total_cost.toFixed(4)}</td>
          <td>{nodeStatistic.avg_input_tokens.toFixed(2)}</td>
          <td>{nodeStatistic.avg_output_tokens.toFixed(2)}</td>
          <td>{version ? `${versionIndex + 1}. ${version.name}` : null}</td>
          <td>{functionData ? functionData.name : null}</td>
        </tr>
      );
    });

    const versions = selectedFunction && selectedFunction !== orchestratorStatistics.id ? orchestratorStatistics.functions.find((f) => f.id === selectedFunction).versions : orchestratorStatistics.versions;

    return (
      <div className='panel'>
        <div className='panel-header'>{literals.nodesStatistics}</div>
        <div className='panel-body'>
          <div className='flex gap-2 align-middle justify-center'>
            <div>
              <select className='form_select_stl' value={selectedFunction} onChange={handleOnChangeSelectedFunction} style={{ width: '250px' }}>
                <option value=''>{literals.allFunctions}</option>
                <option value={orchestratorStatistics.id}>{literals.mainOrchestrator}</option>
                {orchestratorStatistics.functions.map((f) => (
                  <option key={f.id} value={f.id}>{f.name}</option>
                ))}
              </select>
            </div>
            <div>
              <select className='form_select_stl' value={selectedVersion} onChange={handleOnChangeSelectedVersion} style={{ width: '250px' }}>
                <option value=''>{literals.allVersions}</option>
                {versions.map((version, k) => {
                  let nameVersion = `${k + 1}. ${version.name}`;
                  if (version.id === actualVersionId) {
                    nameVersion += ` (${literals.actualVersion})`;
                  }
                  return (
                    <option key={version.id} value={version.id}>{nameVersion}</option>
                  );
                })}
              </select>
            </div>
          </div>
          <table className='table_stl1 mt-3'>
            <thead>
              <tr>
                <th>{literals.nodeName}</th>
                <th>{literals.nodeType}</th>
                <th>{literals.avgTime}</th>
                <th>{literals.avgCost}</th>
                <th>{literals.avgInputTokens}</th>
                <th>{literals.avgOutputTokens}</th>
                <th>{literals.version}</th>
                <th>{literals.function}</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  };

  const printConversationOrchestratorStatistics = () => {
    const fields = [
      {
        label: literals.allConversations, id: 'all_conversations', type: 'number', fix: false,
      },
      {
        label: literals.totalCost, id: 'all_cost', type: 'currency', fix: true, decimals: 2,
      },
      {
        label: literals.conversationMessages, id: 'conversation_messages', type: 'number', fix: true, decimals: 2,
      },
      {
        label: literals.conversationCost, id: 'conversation_cost', type: 'currency', fix: true, decimals: 4,
      },
      {
        label: literals.avgTime, id: 'avg_time', type: 'time', fix: true, decimals: 2,
      },
    ];

    const rows = fields.map((field) => {
      return (
        <div className='panel' style={{ minWidth: '200px', textAlign: 'center' }}>
          <div className='panel-header'>{field.label}</div>
          <div className='panel-body' style={{ fontSize: '18px' }}>
            {field.fix ? orchestratorStatistics[field.id].toFixed(field.decimals) : orchestratorStatistics[field.id]}
            {field.type === 'currency' && ' €'}
            {field.type === 'time' && 's'}
          </div>
        </div>
      );
    });

    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
          {rows}
        </div>
        <div style={{
          width: '100%', height: '500px', display: 'flex', gap: '1rem',
        }}
        >
          <div className='panel' style={{ width: '100%' }}>
            <div className='panel-header'>{literals.daysConsume}</div>
            <div className='panel-body' style={{ width: '100%', height: '400px' }}>
              <BarChartItem data={orchestratorStatistics.dates_list} />
            </div>
          </div>
          <div className='panel' style={{ width: '400px' }}>
            <div className='panel-header'>{literals.chatbotConsume}</div>
            <div className='panel-body' style={{ width: '100%', height: '400px' }}>
              <PieChartItem data={orchestratorStatistics.chatbots_cost} />
            </div>
          </div>
        </div>
        {printNodeStatistics()}
      </>
    );
  };

  const printAgentOrchestratorStatistics = () => {
    const fields = [
      {
        label: literals.allProcesses, id: 'all_processes', type: 'number', fix: false,
      },
      {
        label: literals.totalCost, id: 'all_cost', type: 'currency', fix: true, decimals: 2,
      },
      {
        label: literals.processCost, id: 'process_cost', type: 'currency', fix: true, decimals: 4,
      },
      {
        label: literals.avgTime, id: 'avg_time', type: 'time', fix: true, decimals: 2,
      },
    ];

    const rows = fields.map((field) => {
      return (
        <div className='panel' style={{ minWidth: '200px', textAlign: 'center' }}>
          <div className='panel-header'>{field.label}</div>
          <div className='panel-body' style={{ fontSize: '18px' }}>
            {field.fix ? orchestratorStatistics[field.id].toFixed(field.decimals) : orchestratorStatistics[field.id]}
            {field.type === 'currency' && ' €'}
            {field.type === 'time' && 's'}
          </div>
        </div>
      );
    });

    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
          {rows}
        </div>
        <div style={{
          width: '100%', height: '500px', display: 'flex', gap: '1rem',
        }}
        >
          <div className='panel' style={{ width: '100%' }}>
            <div className='panel-header'>{literals.daysConsume}</div>
            <div className='panel-body' style={{ width: '100%', height: '400px' }}>
              <BarChartItem data={orchestratorStatistics.dates_list} />
            </div>
          </div>
        </div>
        {printNodeStatistics()}
      </>
    );
  };

  const printOrchestratorStatistics = () => {
    if (orchestrator.type === ORCHESTRATOR_TYPE_CONVERSATION) {
      return printConversationOrchestratorStatistics();
    }

    return printAgentOrchestratorStatistics();
  };

  return (
    <Layout selectedSection='orchestrators'>
      <div className='section'>
        <div className='section_header'>
          <h1>{literals.title}</h1>
          <div className='btn btn-primary' onClick={handleBackToOrchestratos}>{literals.backToOrchestratos}</div>
        </div>
        <div className='mt-lg orchestrator_statistics_container'>
          {orchestratorStatistics && (
            <>
              {printDateRangeSelector()}
              {printOrchestratorStatistics()}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default OrchestratorsStatistics;
