import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateChatbot } from 'modules/chatbots/actions';
import useLiterals from 'hooks/useLiterals';
import { getErrorMessage } from 'utils/errors';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import FeedbackModal from 'components/FeedbackModal';
import { copyTextInClipboard } from 'utils/text';
import Avatar from 'components/Avatar';
import ChatForm from 'components/Chat/components/ChatForm';
import IconBubbleSrc from 'assets/icons/icon_bubble.svg';
import {
  ORCHESTRATOR_TYPE_DOCUMENT_PROCESSOR, ORCHESTRATOR_TYPE_CONVERSATION, ORCHESTRATOR_TYPE_EMAIL_PROCESSOR,
} from 'constants/orchestrator';

const ACTUAL_DATE = Math.round(Date.now() / 1000);

function Settings({ chatbotId }) {
  const literals = useLiterals('chatbot');

  const [currentChatbot, setCurrentChatbot] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState('');
  const [model, setModel] = useState(null);
  const [systemMessage, setSystemMessage] = useState('');
  const [initialMessage, setInitialMessage] = useState('');
  const [inputPlaceholder, setInputPlaceholder] = useState('');
  const [canEmbed, setCanEmbed] = useState(false);
  const [startMinimized, setStartMinimized] = useState(false);
  const [enableDebug, setEnableDebug] = useState(false);
  const [enableImages, setEnableImages] = useState(false);
  const [temperature, setTemperature] = useState(1);
  const [llmAgentName, setLLMAgentName] = useState('');
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [disclaimer, setDisclaimer] = useState(literals.disclaimer);
  const [chatbotOrganizations, setChatbotOrganizations] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [avatarToUpload, setAvatarToUpload] = useState(null);
  const [hasRemovedAvatar, setHasRemovedAvatar] = useState(false);
  const [previewAvatarToUpload, setPreviewAvatarToUpload] = useState(null);
  const [headerTitle, setHeaderTitle] = useState(literals.chat);
  const [headerSubtitle, setHeaderSubtitle] = useState(literals.doYouHaveAnyQuestions);
  const [openaiKey, setOpenAIKey] = useState('');
  const [conversationOrchestrator, setConversationOrchestrator] = useState('0');
  const [documentsOrchestrator, setDocumentsOrchestrator] = useState('0');
  const [emailsOrchestrator, setEmailsOrchestrator] = useState('0');

  // Context
  const [searchInDocuments, setSearchInDocuments] = useState('1');
  const [learningContext, setLearningContext] = useState('');

  // Colors
  const [bgColorUserMessage, setBgColorUserMessage] = useState('#6eb3a1');
  const [textColorUserMessage, setTextColorUserMessage] = useState('#ffffff');
  const [bgColorBotMessage, setBgColorBotMessage] = useState('#eeeeee');
  const [textColorBotMessage, setTextColorBotMessage] = useState('#444444');
  const [bgColorAgentMessage, setBgColorAgentMessage] = useState('#a3dcff');
  const [textColorAgentMessage, setTextColorAgentMessage] = useState('#444444');
  const [bgColorRecordingAudioMessage, setBgColorRecordingAudioMessage] = useState('#E1BEE7');
  const [textColorRecordingAudioMessage, setTextColorRecordingAudioMessage] = useState('#ffffff');
  const [bgColorHeader, setBgColorHeader] = useState('#1cc6d0');
  const [textColorHeader, setTextColorHeader] = useState('#ffffff');
  const [colorButtonSend, setColorButtonSend] = useState('#1cc6d0');
  const [colorButtonForms, setColorButtonForms] = useState('#1cc6d0');
  const [textColorButtonForms, setTextColorButtonForms] = useState('#ffffff');
  const [agentsWriteAsBot, setAgentsWriteAsBot] = useState(false);

  // Helps
  const [isDictationEnabled, setIsDictationEnabled] = useState(false);

  // Minimized options
  const [showAsBubble, setShowAsBubble] = useState(false);
  const [colorBubble, setColorBubble] = useState('#1cc6d0');
  const [iconBubble, setIconBubble] = useState(null);
  const [iconBubbleToUpload, setIconBubbleToUpload] = useState(null);
  const [hasRemovedIconBubble, setHasRemovedIconBubble] = useState(false);
  const [previewIconBubbleToUpload, setPreviewIconBubbleToUpload] = useState(null);
  const [bubbleSize, setBubbleSize] = useState(80);

  const [error, setError] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showCopiedModal, setShowCopiedModal] = useState(false);

  const { models } = useSelector((state) => state.models);
  const { chatbots } = useSelector((state) => state.chatbots);
  const { organizations } = useSelector((state) => state.organizations);
  const { orchestrators } = useSelector((state) => state.orchestrators);
  const dispatch = useDispatch();

  const fileAvatarInputRef = useRef(null);
  const fileIconBubbleInputRef = useRef(null);

  const getChatbot = () => {
    if (!chatbots) {
      return null;
    }

    const chatbot = chatbots.find((c) => c.id === chatbotId);
    return chatbot;
  };

  useEffect(() => {
    const chatbot = getChatbot();
    setCurrentChatbot(chatbot);
    setModel(chatbot?.model);
    setName(chatbot?.name);
    setSystemMessage(chatbot?.system_message);
    setInitialMessage(chatbot?.initial_message);
    setTemperature(chatbot?.temperature ?? 1);
    setOpenAIKey(chatbot?.openai_key);
    setAvatar(chatbot?.avatar);
    setLLMAgentName(chatbot?.llm_agent_name);
    setConversationOrchestrator(chatbot?.conversation_orchestrator);
    setDocumentsOrchestrator(chatbot?.documents_orchestrator);
    setEmailsOrchestrator(chatbot?.emails_orchestrator);
    setShowDisclaimer(chatbot?.show_disclaimer);
    setDisclaimer(chatbot?.disclaimer ?? literals.disclaimer);

    // Context
    setSearchInDocuments(chatbot?.search_in_documents ? '1' : '0');
    setLearningContext(chatbot?.learning_context ?? '');

    // Colors
    setBgColorUserMessage(chatbot?.extra_info?.bg_color_user_message ?? '#6eb3a1');
    setTextColorUserMessage(chatbot?.extra_info?.text_color_user_message ?? '#ffffff');
    setBgColorBotMessage(chatbot?.extra_info?.bg_color_bot_message ?? '#eeeeee');
    setTextColorBotMessage(chatbot?.extra_info?.text_color_bot_message ?? '#444444');
    setBgColorAgentMessage(chatbot?.extra_info?.bg_color_agent_message ?? '#a3dcff');
    setTextColorAgentMessage(chatbot?.extra_info?.text_color_agent_message ?? '#444444');
    setBgColorRecordingAudioMessage(chatbot?.extra_info?.bg_color_recording_audio_message ?? '#E1BEE7');
    setTextColorRecordingAudioMessage(chatbot?.extra_info?.text_color_recording_audio_message ?? '#ffffff');
    setStartMinimized(chatbot?.extra_info.start_minimized ?? false);
    setBgColorHeader(chatbot?.extra_info?.bg_color_header ?? '#1cc6d0');
    setTextColorHeader(chatbot?.extra_info?.text_color_header ?? '#ffffff');
    setColorButtonSend(chatbot?.extra_info?.color_button_send ?? '#1cc6d0');
    setHeaderTitle(chatbot?.extra_info?.header_title ?? literals.chat);
    setHeaderSubtitle(chatbot?.extra_info?.header_subtitle ?? literals.doYouHaveAnyQuestions);
    setAgentsWriteAsBot(chatbot?.extra_info?.agents_write_as_bot ?? false);
    setColorButtonForms(chatbot?.extra_info?.color_button_forms ?? '#1cc6d0');
    setTextColorButtonForms(chatbot?.extra_info?.text_color_button_forms ?? '#ffffff');
    setShowAsBubble(chatbot?.extra_info?.show_as_bubble ?? false);
    setColorBubble(chatbot?.extra_info?.color_bubble ?? '#1cc6d0');
    setIconBubble(chatbot?.extra_info?.icon_bubble ?? null);
    setBubbleSize(chatbot?.extra_info?.bubble_size ?? 80);

    // Helps
    setIsDictationEnabled(chatbot?.extra_info?.is_dictation_enabled ?? false);

    if (chatbot.can_embed !== null) {
      setCanEmbed(chatbot?.can_embed);
    }

    if (chatbot.enable_debug !== null) {
      setEnableDebug(chatbot?.enable_debug);
    }

    if (chatbot.enable_images !== null) {
      setEnableImages(chatbot?.enable_images);
    }

    if (chatbot.input_placeholder) {
      setInputPlaceholder(chatbot.input_placeholder);
    }
    setCanEmbed(chatbot?.can_embed);
    if (chatbot.organizations_data) {
      setChatbotOrganizations(chatbot?.organizations_data?.map((organization) => ({ value: organization.id, label: organization.name })));
    }
  }, [chatbots]);

  useEffect(() => {
    document.documentElement.style.setProperty('--bg-color-user-message', bgColorUserMessage);
    document.documentElement.style.setProperty('--text-color-user-message', textColorUserMessage);
    document.documentElement.style.setProperty('--bg-color-bot-message', bgColorBotMessage);
    document.documentElement.style.setProperty('--text-color-bot-message', textColorBotMessage);
    if (agentsWriteAsBot) {
      document.documentElement.style.setProperty('--bg-color-agent-message', bgColorBotMessage);
      document.documentElement.style.setProperty('--text-color-agent-message', textColorBotMessage);
    } else {
      document.documentElement.style.setProperty('--bg-color-agent-message', bgColorAgentMessage);
      document.documentElement.style.setProperty('--text-color-agent-message', textColorAgentMessage);
    }
    document.documentElement.style.setProperty('--bg-color-recording-audio-message', bgColorRecordingAudioMessage);
    document.documentElement.style.setProperty('--text-color-recording-audio-message', textColorRecordingAudioMessage);
    document.documentElement.style.setProperty('--bg-color-header', bgColorHeader);
    document.documentElement.style.setProperty('--text-color-header', textColorHeader);
    document.documentElement.style.setProperty('--color-button-send', colorButtonSend);
    document.documentElement.style.setProperty('--color-button-forms', colorButtonForms);
    document.documentElement.style.setProperty('--text-color-button-forms', textColorButtonForms);

    document.documentElement.style.setProperty('--color-bubble-minimized', colorBubble);
    document.documentElement.style.setProperty('--size-bubble-minimized', bubbleSize);
  }, [bgColorUserMessage, textColorUserMessage, bgColorBotMessage, textColorBotMessage, bgColorAgentMessage, textColorAgentMessage, bgColorRecordingAudioMessage, textColorRecordingAudioMessage, bgColorHeader, textColorHeader, colorButtonSend, agentsWriteAsBot, colorButtonForms, textColorButtonForms, colorBubble, bubbleSize]);

  const handleOnChangeName = (e) => {
    setName(e.target.value);
  };

  const handleOnChangeModel = (e) => {
    setModel(e.target.value);
  };

  const handleCloseModal = () => {
    setShowFeedbackModal(false);
  };

  const handleCloseCopiedModal = (e) => {
    setShowCopiedModal(false);
  };

  const handleOnChangeSystemMessage = (e) => {
    setSystemMessage(e.target.value);
  };

  const handleOnChangeLearningContext = (e) => {
    setLearningContext(e.target.value);
  };

  const handleChangeOpenAIKey = (e) => {
    setOpenAIKey(e.target.value);
  };

  const handleChangeConversationOrchestrator = (e) => {
    setConversationOrchestrator(e.target.value);
  };

  const handleChangeDocumentsOrchestrator = (e) => {
    setDocumentsOrchestrator(e.target.value);
  };

  const handleChangeEmailsOrchestrator = (e) => {
    setEmailsOrchestrator(e.target.value);
  };

  const handleChangeSearchInDocuments = (e) => {
    setSearchInDocuments(e.target.value);
  };

  const handleOnChangeInitialMessage = (e) => {
    setInitialMessage(e.target.value);
  };

  const handleOnChangeCanEmbed = (e) => {
    setCanEmbed(e.target.checked);
  };

  const handleOnChangeStartMinimized = (e) => {
    setStartMinimized(e.target.checked);
  };

  const handleOnChangeEnableDebug = (e) => {
    setEnableDebug(e.target.checked);
  };

  const handleOnChangeEnableImages = (e) => {
    setEnableImages(e.target.checked);
  };

  const handleOnChangeShowDisclaimer = (e) => {
    setShowDisclaimer(e.target.checked);
  };

  const handleOnChangeDisclaimer = (e) => {
    setDisclaimer(e.target.value);
  };

  const handleOnChangeInputPlaceholder = (e) => {
    setInputPlaceholder(e.target.value);
  };

  const handleOnChangeHeaderTitle = (e) => {
    setHeaderTitle(e.target.value);
  };

  const handleOnChangeHeaderSubtitle = (e) => {
    setHeaderSubtitle(e.target.value);
  };

  const handleOnChangeAgentName = (e) => {
    setLLMAgentName(e.target.value);
  };

  const handleOnChangeBgColorUserMessage = (e) => {
    setBgColorUserMessage(e.target.value);
  };

  const handleOnChangeTextColorUserMessage = (e) => {
    setTextColorUserMessage(e.target.value);
  };

  const handleOnChangeBgColorBotMessage = (e) => {
    setBgColorBotMessage(e.target.value);
  };

  const handleOnChangeTextColorBotMessage = (e) => {
    setTextColorBotMessage(e.target.value);
  };

  const handleOnChangeBgColorAgentMessage = (e) => {
    setBgColorAgentMessage(e.target.value);
  };

  const handleOnChangeTextColorAgentMessage = (e) => {
    setTextColorAgentMessage(e.target.value);
  };

  const handleOnChangeBgColorRecordingAudioMessage = (e) => {
    setBgColorRecordingAudioMessage(e.target.value);
  };

  const handleOnChangeTextColorRecordingAudioMessage = (e) => {
    setTextColorRecordingAudioMessage(e.target.value);
  };

  const handleInChangeBgColorHeader = (e) => {
    setBgColorHeader(e.target.value);
  };

  const handleInChangeTextColorHeader = (e) => {
    setTextColorHeader(e.target.value);
  };

  const handleInChangeColorButtonSend = (e) => {
    setColorButtonSend(e.target.value);
  };

  const handleOnChangeAgentsWriteAsBot = (e) => {
    setAgentsWriteAsBot(e.target.value === '1');
  };

  const handleSelectOrganization = (selectedValues) => {
    setChatbotOrganizations(selectedValues);
  };

  const handleOnChangeTemperature = (e) => {
    setTemperature(e.target.value);
  };

  const handleInChangeColorButtonForms = (e) => {
    setColorButtonForms(e.target.value);
  };

  const handleInChangeTextColorButtonForms = (e) => {
    setTextColorButtonForms(e.target.value);
  };

  const handleOnChangeShowAsBubble = (e) => {
    setShowAsBubble(e.target.value === '1');
  };

  const handleInChangeBubbleColor = (e) => {
    setColorBubble(e.target.value);
  };

  const handleOnChangeBubbleSize = (e) => {
    setBubbleSize(e.target.value);
  };

  const handleOnChangeIsDictationEnabled = (e) => {
    setIsDictationEnabled(e.target.value === '1');
  };

  const checkForm = () => {
    if (!name) {
      setError(literals.nameIsRequired);
      return false;
    }

    setError(null);
    return true;
  };

  const saveChatbot = async () => {
    if (checkForm()) {
      setIsSaving(true);
      try {
        const extraInfo = {
          bg_color_user_message: bgColorUserMessage,
          text_color_user_message: textColorUserMessage,
          bg_color_bot_message: bgColorBotMessage,
          text_color_bot_message: textColorBotMessage,
          bg_color_agent_message: bgColorAgentMessage,
          text_color_agent_message: textColorAgentMessage,
          bg_color_recording_audio_message: bgColorRecordingAudioMessage,
          text_color_recording_audio_message: textColorRecordingAudioMessage,
          start_minimized: startMinimized,
          bg_color_header: bgColorHeader,
          text_color_header: textColorHeader,
          color_button_send: colorButtonSend,
          header_title: headerTitle,
          header_subtitle: headerSubtitle,
          agents_write_as_bot: agentsWriteAsBot,
          color_button_forms: colorButtonForms,
          text_color_button_forms: textColorButtonForms,
          show_as_bubble: showAsBubble,
          color_bubble: colorBubble,
          bubble_size: bubbleSize,
          is_dictation_enabled: isDictationEnabled,
        };
        await dispatch(updateChatbot(chatbotId, name, currentChatbot?.tools, model, initialMessage, systemMessage, canEmbed, enableDebug, inputPlaceholder, chatbotOrganizations?.map((organization) => organization.value), enableImages, temperature, avatarToUpload, hasRemovedAvatar, llmAgentName, showDisclaimer, disclaimer, extraInfo, iconBubbleToUpload, hasRemovedIconBubble, openaiKey, conversationOrchestrator, documentsOrchestrator, emailsOrchestrator, searchInDocuments === '1', learningContext));
        setShowFeedbackModal(true);
      } catch (err) {
        setError(getErrorMessage(err));
      } finally {
        setIsSaving(false);
      }
    }
  };

  const renderModels = () => {
    return models?.map((auxModel) => (
      <option key={auxModel.id} value={auxModel.id}>{auxModel.name}</option>
    ));
  };

  const renderConversationOrchestrators = () => {
    const conversationOrchestrators = orchestrators.filter((orchestrator) => orchestrator.type === ORCHESTRATOR_TYPE_CONVERSATION);

    return conversationOrchestrators.map((orchestrator) => (
      <option key={orchestrator.id} value={orchestrator.id}>{orchestrator.name}</option>
    ));
  };

  const renderDocumentsOrchestrators = () => {
    const documentsOrchestrators = orchestrators.filter((orchestrator) => orchestrator.type === ORCHESTRATOR_TYPE_DOCUMENT_PROCESSOR);

    return documentsOrchestrators.map((orchestrator) => (
      <option key={orchestrator.id} value={orchestrator.id}>{orchestrator.name}</option>
    ));
  };

  const renderEmailsOrchestrators = () => {
    const emailsOrchestrators = orchestrators.filter((orchestrator) => orchestrator.type === ORCHESTRATOR_TYPE_EMAIL_PROCESSOR);

    return emailsOrchestrators.map((orchestrator) => (
      <option key={orchestrator.id} value={orchestrator.id}>{orchestrator.name}</option>
    ));
  };

  const printFeedbackModal = () => {
    if (!showFeedbackModal) {
      return null;
    }

    return (
      <FeedbackModal
        message={literals.settingsHaveBeenSavedSuccessfully}
        onClose={handleCloseModal}
        open={showFeedbackModal}
      />
    );
  };

  const printCopiedModal = () => {
    if (!showCopiedModal) {
      return null;
    }

    return (
      <FeedbackModal
        message={literals.codeHasBeenCopied}
        onClose={handleCloseCopiedModal}
        open={showCopiedModal}
      />
    );
  };

  const printEmbedCodes = () => {
    const urlChatbot = `${window.location.origin}/embed/chatbot/${chatbotId}`;
    const url = new URL(window.LLM_CHATBOT_API_URL);
    const domain = url.hostname + (url.port ? `:${url.port}` : '');
    const isSecure = window.location.protocol === 'https:';
    const iframeCode = `<iframe
src="${urlChatbot}"
style="height: 700px; width: 400px; position: fixed; bottom: 0; left: 50px; max-height: 70%;"
frameborder="0"
allow="microphone"
></iframe>`;

    const scriptCode = `<script src='${window.location.origin}/dist/chatbot.js?t=${ACTUAL_DATE}'></script>
<script type="text/javascript">
  LLMChatbot.init({
    id: "${chatbotId}",
    save_conversation: true, // Change to false if you don't want to persist the conversation when the user refreshes the page
    variables: {
      // Add here any variable you want to send to the chatbot
    },
    domain: "${domain}",
    is_secure: ${isSecure},
  });
</script>`;
    return (
      <>
        <div className='input_title mt-md'>
          {literals.viaScript}
        </div>
        <div className='input_value'>
          <pre>
            <code>
              {scriptCode}
            </code>
          </pre>
          <button
            className='btn btn-success'
            type='button'
            onClick={() => {
              copyTextInClipboard(scriptCode);
              setShowCopiedModal(true);
            }}
          >
            {literals.copy}
          </button>
        </div>
      </>
    );
  };

  const removeAvatar = () => {
    setAvatar(null);
    setHasRemovedAvatar(true);
  };

  const removeIconBubble = () => {
    setIconBubble(null);
    setHasRemovedIconBubble(true);
    setPreviewIconBubbleToUpload(null);
  };

  const handleOnChangeAvatar = (e) => {
    setAvatarToUpload(e.target.files[0]);
    setPreviewAvatarToUpload(URL.createObjectURL(e.target.files[0]));
    setHasRemovedAvatar(false);
  };

  const handleClickUploadAvatar = () => {
    fileAvatarInputRef.current.click();
  };

  const handleOnChangeIconBubble = (e) => {
    setIconBubbleToUpload(e.target.files[0]);
    setPreviewIconBubbleToUpload(URL.createObjectURL(e.target.files[0]));
    setHasRemovedIconBubble(false);
  };

  const handleClickUploadIconBubble = () => {
    fileIconBubbleInputRef.current.click();
  };

  const printPreviewLookAndFeel = () => {
    let avatarSource = null;
    if (avatar) {
      avatarSource = `${window.LLM_CHATBOT_API_URL}/chatbots/${chatbotId}/avatar?t=${avatar}`;
    } else if (previewAvatarToUpload) {
      avatarSource = previewAvatarToUpload;
    }

    const chatbot = getChatbot();
    const chatbotPreview = {
      ...chatbot,
      initial_message: initialMessage,
      llm_agent_name: llmAgentName,
      avatar: avatarSource,
      input_placeholder: inputPlaceholder,
      extra_info: {
        bg_color_user_message: bgColorUserMessage,
        text_color_user_message: textColorUserMessage,
        bg_color_bot_message: bgColorBotMessage,
        text_color_bot_message: textColorBotMessage,
        bg_color_agent_message: bgColorAgentMessage,
        text_color_agent_message: textColorAgentMessage,
        bg_color_recording_audio_message: bgColorRecordingAudioMessage,
        text_color_recording_audio_message: textColorRecordingAudioMessage,
        bg_color_header: bgColorHeader,
        text_color_header: textColorHeader,
        color_button_send: colorButtonSend,
        header_title: headerTitle,
        header_subtitle: headerSubtitle,
        agents_write_as_bot: agentsWriteAsBot,
        color_button_forms: colorButtonForms,
        text_color_button_forms: textColorButtonForms,
        show_as_bubble: showAsBubble,
        color_bubble: colorBubble,
        bubble_size: bubbleSize,
        is_dictation_enabled: isDictationEnabled,
      },
    };

    const nullFunction = () => { };

    return (
      <div className='llm-chatbot-preview'>
        <ChatForm
          messages={[
            {
              id: '1',
              content: literals.thisIsAUserMessageSample,
              role: 'user',
              created_at: new Date(),
            },
            {
              id: '2',
              content: literals.thisIsAnAgentMessageSample,
              role: 'agent',
              created_at: new Date(),
            },
            {
              id: '3',
              content: '',
              type: 'form',
              role: 'assistant',
              extra_info: {
                form: {
                  id: '1',
                  name: 'Form',
                  questions: [
                    {
                      id: '1',
                      type: 'email',
                      title: 'Email',
                      options: [],
                      required: true,
                      variable_name: 'email',
                    },
                  ],
                },
              },
            },
          ]}
          onSendMessage={nullFunction}
          onSendAudio={nullFunction}
          onUploadFile={nullFunction}
          chatbot={chatbotPreview}
          header
          isPreview
        />
      </div>
    );
  };

  const printBubblePreview = () => {
    let iconSource = IconBubbleSrc;
    if (iconBubble) {
      iconSource = `${window.LLM_CHATBOT_API_URL}/chatbots/${chatbotId}/bubble-icon?t=${iconBubble}`;
    } else if (previewIconBubbleToUpload) {
      iconSource = previewIconBubbleToUpload;
    }

    return (
      <div className='llm-chatbot-bubble-minimized'>
        <img src={iconSource} alt={headerTitle} />
      </div>
    );
  };

  const printTemperature = () => {
    let temperatureDescription = '';

    if (temperature < 0.2) {
      temperatureDescription = literals.temperatureDescriptionVeryDeterministic;
    } else if (temperature < 0.5) {
      temperatureDescription = literals.temperatureDescriptionDeterministic;
    } else if (temperature < 1.0) {
      temperatureDescription = literals.temperatureDescriptionBalanced;
    } else if (temperature < 1.5) {
      temperatureDescription = literals.temperatureDescriptionCreative;
    } else {
      temperatureDescription = literals.temperatureDescriptionVeryCreative;
    }

    return (
      <>
        <strong>{temperature}</strong>
        {` - ${temperatureDescription}`}
      </>
    );
  };

  if (!currentChatbot) {
    return null;
  }

  return (
    <div className='p-md'>
      {printFeedbackModal()}
      {printCopiedModal()}
      <Form>
        <div className='panel'>
          <div className='panel-header'>{literals.chatbotInfo}</div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.common.name}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeName} value={name} />
            </div>
            <div className='input_title'>
              <Form.Check
                type='switch'
                id='embed-switch'
                label={literals.enableDebug}
                onChange={handleOnChangeEnableDebug}
                checked={enableDebug}
              />
            </div>
            <div className='input_title'>
              <Form.Check
                type='switch'
                id='embed-switch'
                label={literals.enableImages}
                onChange={handleOnChangeEnableImages}
                checked={enableImages}
              />
            </div>
          </div>
        </div>
        <div className='panel'>
          <div className='panel-header'>{literals.lookAndFeel}</div>
          <div className='panel-body'>
            <div className='row'>
              <div className='col-md-8 col-sm-12'>
                <div className='settings_avatar'>
                  <div>
                    <div className='input_title'>
                      {literals.avatar}
                    </div>
                    <div className='input_value'>
                      {avatar ? (
                        <>
                          <div>
                            <Avatar src={`${window.LLM_CHATBOT_API_URL}/chatbots/${chatbotId}/avatar?t=${avatar}`} size={128} />
                          </div>
                          <button className='btn btn-sm btn-secondary' type='button' onClick={removeAvatar}>{literals.common.delete}</button>
                        </>
                      ) : (
                        <>
                          {previewAvatarToUpload && <Avatar src={previewAvatarToUpload} size={128} />}
                          <input type='file' onChange={handleOnChangeAvatar} accept='image/jpeg, image/png, image/gif, image/webp' style={{ display: 'none' }} ref={fileAvatarInputRef} />
                          <button className='btn btn-sm btn-secondary' type='button' onClick={handleClickUploadAvatar}>{previewAvatarToUpload ? literals.change : literals.uploadAvatar}</button>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className='input_title'>
                      {literals.agentName}
                    </div>
                    <div className='input_value'>
                      <input type='text' className='form_input_stl' onChange={handleOnChangeAgentName} value={llmAgentName} />
                    </div>
                    <div className='input_title'>
                      {literals.initialMessage}
                    </div>
                    <div className='input_value'>
                      <input type='text' className='form_input_stl' onChange={handleOnChangeInitialMessage} value={initialMessage} />
                    </div>
                    <div className='input_title'>
                      {literals.headerTitle}
                    </div>
                    <div className='input_value'>
                      <input type='text' className='form_input_stl' onChange={handleOnChangeHeaderTitle} value={headerTitle} />
                    </div>
                    <div className='input_title'>
                      {literals.headerSubtitle}
                    </div>
                    <div className='input_value'>
                      <input type='text' className='form_input_stl' onChange={handleOnChangeHeaderSubtitle} value={headerSubtitle} />
                    </div>
                    <div className='input_title'>
                      {literals.inputPlaceholder}
                    </div>
                    <div className='input_value'>
                      <input type='text' className='form_input_stl' onChange={handleOnChangeInputPlaceholder} value={inputPlaceholder} />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <div className='input_title'>
                      {literals.userMessageBackgroundColor}
                    </div>
                    <div className='input_value'>
                      <input type='color' className='form_input_stl' onChange={handleOnChangeBgColorUserMessage} value={bgColorUserMessage} />
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='input_title'>
                      {literals.userMessageTextColor}
                    </div>
                    <div className='input_value'>
                      <input type='color' className='form_input_stl' onChange={handleOnChangeTextColorUserMessage} value={textColorUserMessage} />
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='input_title'>
                      {literals.botMessageBackgroundColor}
                    </div>
                    <div className='input_value'>
                      <input type='color' className='form_input_stl' onChange={handleOnChangeBgColorBotMessage} value={bgColorBotMessage} />
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='input_title'>
                      {literals.botMessageTextColor}
                    </div>
                    <div className='input_value'>
                      <input type='color' className='form_input_stl' onChange={handleOnChangeTextColorBotMessage} value={textColorBotMessage} />
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='input_title'>
                      {literals.agentsWriteAsBot}
                    </div>
                    <div className='input_value'>
                      <select className='form_select_stl' onChange={handleOnChangeAgentsWriteAsBot} value={agentsWriteAsBot ? '1' : '0'}>
                        <option value='0'>{literals.common.no}</option>
                        <option value='1'>{literals.common.yes}</option>
                      </select>
                    </div>
                  </div>
                  {!agentsWriteAsBot && (
                    <>
                      <div className='col-md-6 col-sm-12'>
                        <div className='input_title'>
                          {literals.agentMessageBackgroundColor}
                        </div>
                        <div className='input_value'>
                          <input type='color' className='form_input_stl' onChange={handleOnChangeBgColorAgentMessage} value={bgColorAgentMessage} />
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className='input_title'>
                          {literals.agentMessageTextColor}
                        </div>
                        <div className='input_value'>
                          <input type='color' className='form_input_stl' onChange={handleOnChangeTextColorAgentMessage} value={textColorAgentMessage} />
                        </div>
                      </div>
                    </>
                  )}
                  <div className='col-md-6 col-sm-12'>
                    <div className='input_title'>
                      {literals.recordingAudioMessageBackgroundColor}
                    </div>
                    <div className='input_value'>
                      <input type='color' className='form_input_stl' onChange={handleOnChangeBgColorRecordingAudioMessage} value={bgColorRecordingAudioMessage} />
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='input_title'>
                      {literals.recordingAudioMessageTextColor}
                    </div>
                    <div className='input_value'>
                      <input type='color' className='form_input_stl' onChange={handleOnChangeTextColorRecordingAudioMessage} value={textColorRecordingAudioMessage} />
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='input_title'>
                      {literals.backgroundColorHeader}
                    </div>
                    <div className='input_value'>
                      <input type='color' className='form_input_stl' onChange={handleInChangeBgColorHeader} value={bgColorHeader} />
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='input_title'>
                      {literals.textColorHeader}
                    </div>
                    <div className='input_value'>
                      <input type='color' className='form_input_stl' onChange={handleInChangeTextColorHeader} value={textColorHeader} />
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='input_title'>
                      {literals.colorButtonForm}
                    </div>
                    <div className='input_value'>
                      <input type='color' className='form_input_stl' onChange={handleInChangeColorButtonForms} value={colorButtonForms} />
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='input_title'>
                      {literals.colorButtonFormText}
                    </div>
                    <div className='input_value'>
                      <input type='color' className='form_input_stl' onChange={handleInChangeTextColorButtonForms} value={textColorButtonForms} />
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='input_title'>
                      {literals.colorButtonSend}
                    </div>
                    <div className='input_value'>
                      <input type='color' className='form_input_stl' onChange={handleInChangeColorButtonSend} value={colorButtonSend} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4 col-sm-12'>
                {printPreviewLookAndFeel()}
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='input_title'>
                  {literals.showDictation}
                </div>
                <div className='input_value'>
                  <select className='form_select_stl' onChange={handleOnChangeIsDictationEnabled} value={isDictationEnabled ? '1' : '0'}>
                    <option value='0'>{literals.common.no}</option>
                    <option value='1'>{literals.common.yes}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-8 col-sm-12'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='input_title'>
                      {literals.showAsBubbleWhenIsMinimized}
                    </div>
                    <div className='input_value'>
                      <select className='form_select_stl' onChange={handleOnChangeShowAsBubble} value={showAsBubble ? '1' : '0'}>
                        <option value='0'>{literals.common.no}</option>
                        <option value='1'>{literals.common.yes}</option>
                      </select>
                    </div>
                  </div>
                  {showAsBubble && (
                    <>
                      <div className='col-md-6 col-sm-12'>
                        <div className='input_title'>
                          {literals.colorBubble}
                        </div>
                        <div className='input_value'>
                          <input type='color' className='form_input_stl' onChange={handleInChangeBubbleColor} value={colorBubble} />
                        </div>
                      </div>
                      <div className='col-md-3 col-sm-12'>
                        <div className='input_title'>
                          {literals.bubbleSize}
                        </div>
                        <div className='input_value'>
                          <Form.Range min={32} max={128} step={1} value={bubbleSize} onChange={handleOnChangeBubbleSize} />
                          { }
                          {bubbleSize}
                        </div>
                      </div>
                      <div className='col-md-3 col-sm-12'>
                        <div className='input_title'>
                          {literals.bubbleIcon}
                        </div>
                        {iconBubble ? (
                          <>
                            <Avatar src={`${window.LLM_CHATBOT_API_URL}/chatbots/${chatbotId}/bubble-icon?t=${iconBubble}`} size={24} />
                            <button className='btn btn-sm btn-secondary' type='button' onClick={removeIconBubble}>{literals.common.delete}</button>
                          </>
                        ) : (
                          <div className='input_value'>
                            {previewIconBubbleToUpload && <Avatar src={previewIconBubbleToUpload} size={24} />}
                            <input type='file' onChange={handleOnChangeIconBubble} accept='image/jpeg, image/png, image/gif, image/webp' style={{ display: 'none' }} ref={fileIconBubbleInputRef} />
                            <button className='btn btn-sm btn-secondary mx-0' type='button' onClick={handleClickUploadIconBubble}>{previewIconBubbleToUpload ? literals.change : literals.customizeIcon}</button>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='col-md-4 col-sm-12'>
                {showAsBubble ? printBubblePreview() : null}
              </div>
            </div>
          </div>
        </div>
        <div className='panel'>
          <div className='panel-header'>{literals.llmInfo}</div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.model}
            </div>
            <div className='input_value'>
              <select className='form_select_stl' onChange={handleOnChangeModel} value={model}>
                {renderModels()}
              </select>
            </div>
            <div className='input_title'>
              {literals.temperature}
            </div>
            <div className='input_value'>
              <Form.Range min={0} max={1.4} step={0.1} value={temperature} onChange={handleOnChangeTemperature} />
              { }
              {printTemperature()}
            </div>
            <div className='input_title'>
              {literals.systemMessage}
            </div>
            <div className='input_value'>
              <textarea className='form_input_stl' onChange={handleOnChangeSystemMessage} value={systemMessage} />
            </div>
            <div className='input_title'>
              {literals.openAIKey}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleChangeOpenAIKey} value={openaiKey} />
            </div>
          </div>
        </div>
        <div className='panel'>
          <div className='panel-header'>{literals.orchestrators}</div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.conversationOrchestrator}
            </div>
            <div className='input_value'>
              <select className='form_select_stl' onChange={handleChangeConversationOrchestrator} value={conversationOrchestrator}>
                <option value='0'>{literals.none}</option>
                {renderConversationOrchestrators()}
              </select>
            </div>
            <div className='input_title'>
              {literals.documentsOrchestrator}
            </div>
            <div className='input_value'>
              <select className='form_select_stl' onChange={handleChangeDocumentsOrchestrator} value={documentsOrchestrator}>
                <option value='0'>{literals.none}</option>
                {renderDocumentsOrchestrators()}
              </select>
            </div>
            <div className='input_title'>
              {literals.emailsOrchestrator}
            </div>
            <div className='input_value'>
              <select className='form_select_stl' onChange={handleChangeEmailsOrchestrator} value={emailsOrchestrator}>
                <option value='0'>{literals.none}</option>
                {renderEmailsOrchestrators()}
              </select>
            </div>
          </div>
        </div>
        <div className='panel'>
          <div className='panel-header'>{literals.context}</div>
          <div className='panel-body'>
            <div className='input_title'>
              {literals.searchInDocuments}
            </div>
            <div className='input_value'>
              <select className='form_select_stl' onChange={handleChangeSearchInDocuments} value={searchInDocuments}>
                <option value='1'>{literals.searchAutomatically}</option>
                <option value='0'>{literals.searchManually}</option>
              </select>
            </div>
            <div className='input_title'>
              {literals.learningContext}
            </div>
            <div className='input_value'>
              <textarea className='form_input_stl' onChange={handleOnChangeLearningContext} value={learningContext} />
            </div>
          </div>
        </div>
        <div className='panel'>
          <div className='panel-header'>{literals.disclaimerTitle}</div>
          <div className='panel-body'>
            <div className='input_title'>
              <Form.Check
                type='switch'
                id='embed-switch'
                label={literals.showDisclaimer}
                onChange={handleOnChangeShowDisclaimer}
                checked={showDisclaimer}
              />
            </div>
            {showDisclaimer && (
              <>
                <div className='input_title'>
                  {literals.disclaimerText}
                </div>
                <div className='input_value'>
                  <textarea className='form_input_stl' onChange={handleOnChangeDisclaimer} value={disclaimer} />
                </div>
              </>
            )}
          </div>
        </div>
        <div className='panel'>
          <div className='panel-header'>{literals.embeddingConfiguration}</div>
          <div className='panel-body'>
            <div className='input_title'>
              <Form.Check
                type='switch'
                id='embed-switch'
                label={literals.canEmbedInExternalWebsites}
                onChange={handleOnChangeCanEmbed}
                checked={canEmbed}
              />
            </div>
            {canEmbed && (
              <div className='input_title'>
                <Form.Check
                  type='switch'
                  id='embed-switch'
                  label={literals.startMinimized}
                  onChange={handleOnChangeStartMinimized}
                  checked={startMinimized}
                />
              </div>
            )}
            {canEmbed && printEmbedCodes()}
          </div>
        </div>
        <div className='panel'>
          <div className='panel-header'>{literals.organizations}</div>
          <div className='panel-body'>
            <div className='input_title'>
              <Select
                isMulti
                options={organizations.map((organization) => ({ value: organization.id, label: organization.name }))}
                onChange={handleSelectOrganization}
                value={chatbotOrganizations}
              />
            </div>
          </div>
        </div>
        {
          error && (
            <div className='error'>
              {error}
            </div>
          )
        }
        <div className='form_buttons'>
          <button type='button' className='btn btn-primary' onClick={saveChatbot} disabled={isSaving}>
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {isSaving === model ? literals.common.saving : literals.common.save}
          </button>
        </div>
      </Form>
    </div>
  );
}

Settings.propTypes = {
  chatbotId: PropTypes.string.isRequired,
};

export default Settings;
