import React from 'react';
import useLiterals from 'hooks/useLiterals';
import { Link, useParams } from 'react-router-dom';

import { ROUTE_PATH } from 'routes';

import './styles.scss';

function Error() {
  const literals = useLiterals('notFound');
  const params = useParams();
  const status = params?.status;
  const availableStatus = ['500', '401', '404', '400'];

  const renderUnknownStatus = () => {
    return (
      <div className='Container ErrorPage mx-auto position-relative'>
        <div className='ErrorPage__container'>
          <div className='ErrorPage__image'>
            <div className='ErrorPage__person ErrorPage__person--500' />
            <div className='ErrorPage__code'>{status}</div>
          </div>
          <div className='ErrorPage__content mt-5'>
            <p
              className='ErrorPage__title decoration-underline font-bold mb-4 pb-3 text-dark'
            >
              {literals.unknownStatus}
            </p>
            <Link
              className='btn btn-primary mt-3'
              to={ROUTE_PATH.BASE}
            >
              {literals.back}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const render500Status = () => {
    return (
      <div className='Container ErrorPage mx-auto position-relative'>
        <div className='ErrorPage__container'>
          <div className='ErrorPage__image'>
            <div className='ErrorPage__person ErrorPage__person--500' />
            <div className='ErrorPage__code'>500</div>
          </div>
          <div className='ErrorPage__content mt-5'>
            <p
              className='ErrorPage__title decoration-underline font-bold mb-4 pb-3 text-dark'
            >
              {literals.title500}
            </p>
            <div className='text-lg text-dark'>
              <p>{literals.description500}</p>
            </div>
            <Link
              className='btn btn-primary mt-3'
              to={ROUTE_PATH.BASE}
            >
              {literals.back}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  if (!availableStatus.includes(status)) {
    return renderUnknownStatus();
  }
  if (status && status === '500') {
    return render500Status();
  }

  return (
    <div className='Container ErrorPage mx-auto position-relative'>
      <div className='ErrorPage__container'>
        <div className='ErrorPage__image'>
          <div className='ErrorPage__person ErrorPage__person--404' />
          <div className='ErrorPage__code'>{status}</div>
        </div>
        <div className='ErrorPage__content mt-5'>
          <p
            className='ErrorPage__title decoration-underline font-bold mb-4 pb-3 text-dark'
          >
            {literals[`title${status}`]}
          </p>
          <div className='text-lg text-dark'>
            <p>{literals[`description${status}`]}</p>
            <ul>
              <li>{literals[`reason1${status}`]}</li>
              <li>{literals[`reason2${status}`]}</li>
            </ul>
          </div>
          <Link
            className='btn btn-primary mt-3'
            to={ROUTE_PATH.BASE}
          >
            {literals.back}
          </Link>
        </div>
      </div>
    </div>
  );
}

Error.displayName = 'Error';

export default Error;
