import {
  INIT_SESSION, SESSION_LOGOUT, SET_USER_DATA,
} from '.';
import SessionApi from './repository';

export const getSession = () => async (dispatch) => {
  try {
    const session = await SessionApi.getSession();
    dispatch({ type: SET_USER_DATA, payload: session });
  } catch (err) {
    console.error('No hay sesión');
  }
};

export const initSession = () => (dispatch) => {
  try {
    dispatch(getSession());
  } catch (err) {
    console.error('No hay sesión');
  } finally {
    dispatch({ type: INIT_SESSION });
  }
};

export const doSignup = async (email, firstname, lastname) => {
  const response = await SessionApi.signup(email, firstname, lastname);
  return response;
};

export const doSetPassword = async (password, token) => {
  const response = await SessionApi.setPassword(password, token);
  return response;
};

export const doForgotPassword = async (email) => {
  const response = await SessionApi.forgotPassword(email);
  return response;
};

export const doLogin = async (email, password) => {
  const response = await SessionApi.login(email, password);
  return response;
};

export const saveTokens = (accessToken, refreshToken) => async (dispatch) => {
  localStorage.setItem('access_token', accessToken);
  localStorage.setItem('refresh_token', refreshToken);
  dispatch(getSession());
};

export const refreshTokens = () => async (dispatch) => {
  try {
    const response = await SessionApi.refreshTokens();
    dispatch(saveTokens(response.access_token, response.refresh_token));
  } catch (err) {
    console.error('No se pudo refrescar el token', err);
  }
};

export const doLogout = () => async (dispatch) => {
  await SessionApi.logout();
  dispatch({ type: SESSION_LOGOUT });
};

export const saveExtraField = (field, value) => async (dispatch) => {
  try {
    await SessionApi.saveExtraField(field, value);
    dispatch(getSession());
  } catch (err) {
    console.error('No se pudo guardar el campo extra', err);
  }
};
