export default {
  en: {
    login: {
      welcomeBack: 'Welcome back',
      login: 'Login',
      setPassword: 'Set password',
      resetPassword: 'Restablecer contraseña',
      register: 'Register',
      welcomeBackDescription: 'Welcome back, please login to your account.',
      setPasswordDescription: 'Please set your password to continue.',
      registerDescription: 'Please fill the following fields to create your account.',
      email: 'Email',
      firstname: 'Name',
      lastname: 'Last name',
      password: 'Password',
      confirmPassword: 'Confirm password',
      forgotPassword: 'Forgot password?',
      createAccount: 'Create an account',
      newHere: 'New here?',
      haveAnAccount: 'Already have an account?',
      loginHere: 'Login here',
      incorrect_credentials: 'Email o contraseña incorrectos',
      signupSuccessTitle: 'Registro realizado correctamente',
      signupSuccessBody: 'En breve recibirás un correo electrónico para confirmar tu cuenta. Por favor, revisa tu bandeja de entrada y tu carpeta de spam.',
      signupFailTitle: 'Error en el registro',
      signupFailBody: 'No se ha podido realizar el registro. Por favor, inténtalo de nuevo más tarde.',
      passwordSuccessTitle: 'Contraseña establecida correctamente',
      passwordSuccessBody: 'Cuenta creada correctamente. Puedes iniciar sesión con tu email y contraseña.',
      passwordFailTitle: 'Error al establecer contraseña',
      passwordFailBody: 'No se ha podido establecer la contraseña. Por favor, inténtalo de nuevo más tarde.',
      forgotPasswordSuccessTitle: 'Correo enviado correctamente',
      forgotPasswordSuccessBody: 'En breve recibirás un correo electrónico para restablecer tu contraseña. Por favor, revisa tu bandeja de entrada y tu carpeta de spam.',
      forgotPasswordFailTitle: 'Error al enviar el correo',
      forgotPasswordFailBody: 'No se ha podido enviar el correo. Por favor, inténtalo de nuevo más tarde.',
      forgotPasswordDescription: 'Por favor, introduce tu email para restablecer tu contraseña.',
      createPassword: 'Crear contraseña',
      passwordFormatError: 'Error in the format of the password, remember that it must include a Capital, a small letter and a number.',
      passwordLengthError: 'Error in the format of the password, remember that the minimum length is 10.',
      accept: 'Accept',
      passwordLengthRequisite: 'It must be between 10 and 70 characters long',
      mustContainUpperLetter: 'It must contain at least one uppercase letter',
      mustContainLowerLetter: 'It must contain at least one lowercase letter',
      mustContainANumber: 'It must contain at least one number',
      passwordsMustMatch: 'The passwords must match',
    },
    layout: {
      myChatbots: 'Chatbots',
      myElements: 'Elements',
      myOrganizations: 'Organizations',
      myApis: 'APIs',
      myForms: 'Forms',
      tests: 'Tests',
      logout: 'Logout',
      projects: 'Projects',
      orchestrators: 'Orchestrators',
      personal: 'Personal',
      allMyWorkspace: 'All my workspace',
      myApiKeys: 'API keys',
      myDatastructures: 'Datastructures',
      myDatatables: 'Datatables',
    },
    chat: {
      selecting_tool: 'Selecting tool',
      connecting_with_tool: 'Connecting with the tool',
      connection_with_orchestrator: 'Connecting with the orchestrator',
      receiving_response: 'Receiving response',
      optimizing_query_search: 'Optimizing search query',
      getting_ambits: 'Extracting query scopes',
      searching_relevant_documents: 'Searching for possible relevant documents',
      filtering_best_documents: 'Filtering most relevant documents',
      selecting_content: 'Selecting relevant content',
      helps: 'Helps and subsidies',
      agency_chat: 'Online advisory',
      analyzing_info: 'Analyzing information',
      received_response: 'Response received',
      processing_file: 'Processing file',
      generating_alternative_messages: 'Generating alternative responses',
      attachments: 'Attachments',
      answer: 'Answer',
      of: 'of',
      writing: 'Writing...',
      stopRecording: 'Stop recording',
      viewAsText: 'View as text',
      viewAsAudio: 'View as audio',
      signinApiBasicAuth1: 'To be able to extract information from the API',
      signinApiBasicAuth2: 'you have to enter the credentials you use in it.',
      login: 'Aceptar',
      apiConfirmCall: 'I am going to perform the following operation, I need you to confirm that I have understood you correctly and the data is correct',
      action: 'Action',
      params: 'Parameters',
      requestBody: 'Request body',
      selecting_action_and_params: 'Selecting action to perform and parameters',
      cant_select_action_and_params: 'Could not select action to perform',
      executing_action: 'Executing action',
      get_action_result: 'API response received',
      signinApiOtpAuth1: 'In order to extract your account information we need to confirm your identity. Please enter the following information',
      signinApiOtpAuth2: 'Please enter the code you received. If you do not receive it, please make sure that the information you entered is the one in your user account and try again.',
      sendCode: 'Send code',
      verifyCode: 'Verify code',
      back: 'Back',
      chat: 'Chat',
      headerTitle: 'Do you have any questions? Let\'s talk!',
      orchestratorTrace: 'Orchestrator trace',
      attachFile: 'Attach file',
      sendAudio: 'Send audio',
      send: 'Send',
      startingAudio: 'Starting audio',
      doYouHaveAnyQuestions: 'Do you have any questions? Let\'s talk!',
      conversationTransferred: 'The previous conversation has been closed',
    },
    chatbots: {
      chatbots: 'Chatbots',
      createChatbot: 'Create chatbot',
      deleteAllChat: 'Delete chatbots',
      thereIsNoChatbots: 'There are no created chatbots',
      chatbot: 'Chatbot',
      orchestrators: 'Orchestrators',
      enabledTools: 'Active tools',
      linkedElement: 'Linked element',
      editChatbot: 'Edit chatbot',
      organizations: 'Organizations',
      orchestrator: 'Orchestrator',
    },
    organizations: {
      organizations: 'Organizations',
      description: 'Description',
      createOrganization: 'Create organization',
      editOrganization: 'Edit organization',
      inviteUser: 'Invite user',
      viewMembers: 'View members',
      email: 'Email',
      status: 'Status',
      firstname: 'Name',
      lastname: 'Surname',
      reject: 'Reject',
      thereIsNoOrganizations: 'There are no created organizations',
      logo: 'Logo',
      primaryColor: 'Primary color',
      secondaryColor: 'Secondary color',
      deleteOrganizationTitle: 'Delete organization',
      deleteOrganizationDescription: 'Are you sure you want to delete the organization',
    },
    chatbot: {
      chatbot: 'Chatbot',
      conversations: 'Conversations',
      documents: 'Documents',
      websites: 'Websites',
      tests: 'Tests',
      disclaimerok: 'Understood',
      disclaimer: 'Hello! I am a chatbot designed to provide general information and answers to your questions. Please note that my purpose is to offer assistance in an informative manner and does not constitute legal, financial, or professional advice. The information provided should not be considered as the basis for making important decisions, and it is recommended to seek specialized advice in specific situations. Additionally, be aware that interaction with this chatbot does not create any contractual or other relationship between you and the entity that developed this chatbot. It is always advisable to consult a qualified professional for specific advice. Thank you!',
      questions: 'Questions',
      texts: 'Texts',
      tools: 'Tools',
      faq: 'FAQ',
      settings: 'Settings',
      backToChatbots: 'Back to My chatbots',
      newConversation: 'New conversation',
      thereIsNoDocuments: 'There are no uploaded documents',
      uploadDocument: 'Upload document',
      uploading: 'Uploading document',
      confirmDeleteDocument: 'Are you sure you want to delete this document?',
      deleting: 'Deleting',
      unsubscribe: 'Unsubscribe',
      subscribe: 'Subscribe',
      model: 'Model',
      temperature: 'Temperature',
      defaultTemperature: 'Default temperature',
      settingsHaveBeenSavedSuccessfully: 'Settings have been saved successfully',
      nameIsRequired: 'Name is required',
      initialMessage: 'Initial message',
      systemMessage: 'Define AI behavior',
      openAIKey: 'OpenAI API Key',
      chatbotInfo: 'Chatbot Information',
      llmInfo: 'LLM Configuration',
      embeddingConfiguration: 'Embed Chatbot in Your Website',
      canEmbedInExternalWebsites: 'You can embed the chatbot on external websites',
      iframeCode: 'Copy this code to embed this chatbot on your website',
      copy: 'Copy',
      codeHasBeenCopied: 'The code has been copied to the clipboard',
      integrations: 'Integrations',
      zendeskConfig: 'Zendesk Configuration',
      zendeskEmail: 'Zendesk Account Email',
      zendeskToken: 'Zendesk Password',
      zendeskHost: 'Host de Zendesk',
      zendeskDomain: 'Zendesk Domain',
      zendeskURL: 'Zendesk Configuration URL',
      zendeskSupport: 'Enable support mode',
      zendeskSupportGroup: 'Support groups',
      zendeskSchedule: 'Zendesk schedule',
      crispConfig: 'Crisp Configuration',
      crispEmail: 'Crisp Account Email',
      crispToken: 'Crisp Password',
      crispURL: 'Crisp Configuration URL',
      whatsappConfig: 'Whatsapp Configuration',
      whatsappNumber: 'Whatsapp Account Number',
      whatsappNumberID: 'Whatsapp Account ID Number',
      whatsappToken: 'Whatsapp Password',
      whatsappVerifyToken: 'Whatsapp Verify Password',
      twilioConfig: 'Twilio Configuration',
      twilioSID: 'Account SID',
      twilioToken: 'Auth Token',
      twilioPhone: 'Twilio Phone Number',
      saveIntegrations: 'Save',
      enableDebug: 'Enable debug mode',
      inputPlaceholder: 'Input placeholder',
      editName: 'Rename',
      delete: 'Delete',
      deleteConversation: 'Delete',
      deleteAll: 'Delete All',
      addApi: 'Add API',
      addApiDescription: 'Add the ability to connect with your own API by providing the swagger file',
      add: 'Add',
      deleteApiConfirm: 'Are you sure you want to delete this API?',
      editEndpoints: 'Edit endpoints',
      organizations: 'Organizations',
      enableImages: 'Enable images',
      viaScript: 'Copy this code at the bottom of your web page to include the chatbot',
      chooseEmbedMethod: 'You can choose either of the two options to embed the chatbot on your website depending on your needs',
      deleteAllConversationsConfirmation: 'Are you sure you want to delete all conversations?',
      deleteConversationConfirmation: 'Are you sure you want to delete this conversation?',
      connect: 'Connect',
      disconnect: 'Disconnect',
      newChat: 'New chat',
      temperatureDescriptionVeryDeterministic: 'Very deterministic',
      temperatureDescriptionDeterministic: 'Deterministic',
      temperatureDescriptionBalanced: 'Balanced',
      temperatureDescriptionCreative: 'Creative',
      temperatureDescriptionVeryCreative: 'Very creative',
      download: 'Descargar',
      lookAndFeel: 'Look and feel',
      avatar: 'Avatar',
      agentName: 'Agent name',
      disclaimerTitle: 'Disclaimer',
      showDisclaimer: 'Show disclaimer',
      disclaimerText: 'Disclaimer text',
      startMinimized: 'Start minimized',
      userMessageBackgroundColor: 'User message background color',
      userMessageTextColor: 'User message text color',
      agentMessageBackgroundColor: 'Agent message background color',
      agentMessageTextColor: 'Agent message text color',
      botMessageBackgroundColor: 'Bot message background color',
      botMessageTextColor: 'Bot message text color',
      recordingAudioMessageBackgroundColor: 'Recording audio message background color',
      recordingAudioMessageTextColor: 'Recording audio message text color',
      backgroundColorHeader: 'Header background color',
      textColorHeader: 'Header text color',
      colorButtonSend: 'Send button color',
      uploadAvatar: 'Upload avatar',
      change: 'Change',
      thisIsAUserMessageSample: 'This is a user message sample',
      thisIsABotMessageSample: 'This is a bot message sample',
      thisIsAnAgentMessageSample: 'This is an agent message sample',
      doYouHaveAnyQuestions: 'Do you have any questions? Let\'s talk!',
      headerTitle: 'Header title',
      headerSubtitle: 'Header subtitle',
      chat: 'Chat',
      agentsWriteAsBot: 'Agents write as bot',
      colorButtonForm: 'Form button color',
      colorButtonFormText: 'Form button text color',
      showAsBubbleWhenIsMinimized: 'Show as a bubble when minimized',
      colorBubble: 'Bubble color',
      showDictation: 'Show dictation',
      customizeIcon: 'Customize icon',
      bubbleIcon: 'Bubble icon',
      bubbleSize: 'Bubble size (px)',
      documentsOrchestrator: 'Orchestrator for processing documents',
      none: 'None',
      reprocess: 'Reprocess',
      context: 'Context',
      searchInDocuments: 'Search in indexed information',
      searchAutomatically: 'Search automatically',
      searchManually: 'Do not search, let the orchestrator handle it',
      learningContext: 'Learning context',
      orchestrators: 'Orchestrators',
      conversationOrchestrator: 'Orchestrator for processing conversations',
      addEmailIntegrations: 'Add email integrations',
      emailIntegration: 'Email Integration',
      type: 'Type',
      username: 'Username',
      password: 'Password',
      host: 'Host',
      port: 'Port',
      useSSL: 'Use SSL',
      testConnection: 'Test connection',
      connectionSuccess: 'Connection successful',
      connectionSuccessMessage: 'The connection to the mail server was successful',
      connectionError: 'Connection error',
      connectionErrorMessage: 'Unable to connect to the mail server. Please check the entered data and try again',
      emailsOrchestrator: 'Orchestrator for processing emails',
    },
    websites: {
      addWebsiteTitle: 'Add a URL to train the chatbot',
      train: 'Train',
      analyze: 'Analyze',
      results: 'Results',
      noTrainedWebsites: 'You havent trained any websites yet, click Add URL to add the first one. You can also analyze all the links that a website has.',
      addURL: 'Add URL',
      url: 'URL',
      trainedURL: 'trained URL',
      allTrainedURLS: 'trained URLS',
      name: 'Name',
      characters: 'Characters',
      trainedDate: 'Training Date',
      trainedSuccess: 'URL trained successfully',
      urlDeleted: 'URL successfully deleted',
    },
    texts: {
      title: 'Enter the text you want to train',
      train: 'Train',
      thereIsNoTexts: 'You have not trained any texts yet, click on Train to add the first one',
      createText: 'Create text',
      editText: 'Edit text',
      confirmDeleteText: 'Are you sure you want to delete this text?',
    },
    confirmModal: {
      confirm: 'Confirm',
      cancel: 'Cancel',
      areYourSure: 'Are you sure?',
    },
    tests: {
      title: 'Title',
      answer: 'Answer',
      createTest: 'Create test',
      chatbots: 'Chatbots',
      organizations: 'Organizations',
      testBattery: 'Test battery',
      selectAll: 'Select all',
      createQuestion: 'Create a question for the Test',
      createQuestion1: 'Create question',
      deleteTest: 'Delete test',
      showResults: 'View results',
      tests: 'Tests',
      questions: 'Questions',
      initTest: 'Initiate Test',
      date: 'Date',
      testResume: 'Test Summary:',
      chatbotsNum: 'Number of Chatbots:',
      testsNum: 'Number of Tests:',
      chatbot: 'Chatbot:',
      model: 'Model:',
      score: 'Score:',
      viewDetail: 'View details',
      back: 'Back',
      correctAnswer: 'Correct Answer:',
      chatbotAnswer: 'Chatbot Response:',
      selectData: 'Select data',
      selectDataInfo: 'Select the data you want to use to create the questions',
      uploadExcel: 'Upload Excel',
    },
    faq: {
      question: 'Question',
      answer: 'Answer',
      noFaq: 'You have not saved any FAQ yet, click on Create FAQ to add the first one',
      createFaq: 'Create FAQ',
      cancel: 'Cancel',
      deleteFaq: 'Delete FAQ',
      otherWaysOfExpressingTheQuestion: 'Other ways of expressing the question:',
      confirmDeleteFaqTitle: 'Delete FAQ',
      confirmDeleteFaqDescription: 'Are you sure you want to delete this FAQ?',
    },
    addApi: {
      apis: 'APIs',
      connectApi: 'Connect API',
      thereIsNoAPIs: 'There are no APIs created',
      addApi: 'Connect with external API',
      swaggerUrl: 'Swagger file URL',
      active: 'Enabled',
      inactive: 'Disabled',
      enableAll: 'Enable all',
      disableAll: 'Disable all',
      data: 'General data',
      endpoints: 'Endpoints',
      connectWithYourApi: 'Connect with your own API',
      userAuthentication: 'User authentication',
      urlBase: 'Base URL',
      authType: 'Authentication type',
      clientId: 'Client ID',
      clientSecret: 'Client Secret',
      authorizationUrl: 'Authorization URL',
      tokenUrl: 'Token URL',
      redirectUri: 'Redirect URL',
      loginEndpoint: 'Login endpoint',
      method: 'Method',
      usernameFieldName: 'Username parameter name',
      passwordFieldName: 'Password parameter name',
      thereIsNoEndpoints: 'There are no endpoints created',
      addNewEndpoint: 'Add new endpoint',
      endpoint: 'Endpoint',
      endpointData: 'Endpoint data',
      params: 'Parameters',
      urlWithoutUrlBase: 'URL without base URL',
      summary: 'Summary',
      description: 'Description',
      type: 'Type',
      in: 'Input',
      required: 'Required',
      status: 'Status',
      deleteApiEndpointConfirm: 'Are you sure you want to delete this endpoint?',
      requestBody: 'Request body',
      addParam: 'Add parameter',
      requiresUserConfirmation: 'Requires user confirmation',
      notRequiresConfirmation: 'Does not require user confirmation',
      requiresConfirmation: 'Requires confirmation',
      sendOTPEndpoint: 'OTP Sending Endpoint',
      userFieldName: 'User Parameter Name',
      verifyOTPEndpoint: 'OTP Verification Endpoint',
      otpValueFieldName: 'OTP Code Parameter Name',
      otpSendMessage: 'In order to extract information from your account, we need to confirm your identity. Please enter the following information.',
      otpVerifyMessage: 'Please enter the code you have received. If you do not receive it, make sure that the information entered is the one listed on your user account and try again.',
      userFieldPlaceholder: 'Send Parameter Text',
      phone: 'Phone',
      otpValuePlaceholder: 'OTP Code Parameter Text',
      code: 'Code',
      sendOTPMessage: 'OTP Sending Message',
      verifyOTPMessage: 'OTP Verification Message',
      sharedWith: 'Shared with',
      selectOrganization: 'Select organization',
      headers: 'Headers',
      addHeader: 'Add header',
      key: 'Key',
      value: 'Value',
      remove: 'Remove',
      paramsType: 'Parameters type',
    },
    orchestrators: {
      title: 'Orchestrators',
      backToOrchestratos: 'Back to My Orchestrators',
      date: 'Date',
      createOrchestrator: 'Create orchestrator',
      editOrchestrator: 'Edit orchestrator',
      organizations: 'Organizations',
      initialChatbot: 'Initial chatbot',
      interface: 'Interface',
      customId: 'Custom ID',
      form: 'Form',
      createForm: 'Create form',
      statistics: 'Usage Statistics',
      conversations: 'Conversations',
      initDate: 'Start Date:',
      endDate: 'End Date:',
      search: 'Search',
      allConversations: 'Total Conversations',
      dailyLimit: 'Daily Limit ($)',
      totalCost: 'Total Cost',
      conversationMessages: 'Messages per Conversation',
      conversationCost: 'Cost per Conversation',
      daysConsume: 'Daily Consumption',
      chatbotConsume: 'Chatbot Consumption',
      viewForm: 'View form',
      formRules: 'Form rules',
      addFormRule: 'Add form rule',
      deleteNode: 'Delete node',
      variableWhen: 'When the variable',
      variableIs: 'is',
      value: 'Value',
      viewCode: 'View code',
      variableName: 'Variable name',
      label: 'Label',
      type: 'Type',
      addFieldToForm: 'Add field to form',
      chatbot: 'Chatbot',
      resultVariable: 'Name of the variable where the result will be saved',
      chatbotSystemMessage: 'Chatbot system message',
      entryTitle: 'Conditions Entry & Destination',
      processTitle: 'Process',
      exitTitle: 'Conditions Exit & Destination',
      addChatbot: 'Add chatbot call',
      addPythonCondition: 'Add python condition',
      addProcess: 'Add process',
      chatbots: 'Chatbots',
      createNode: 'Create node',
      selectParentNodes: 'Select parent nodes',
      createEdge: 'Create edge',
      selectParentNode: 'Select parent node',
      selectChildNode: 'Select child node',
      printForm: 'Print form',
      confirmDuplicateOrchestrator: 'Are you sure you want to duplicate the orchestrator',
      confirmDeleteOrchestrator: 'Are you sure you want to delete the orchestrator',
      addEntryVariables: 'Add input variables',
      entryVariables: 'Input variables',
      searchRelevantInfo: 'Search for relevant information',
      documents: 'Documents',
      faqs: 'FAQs',
      websites: 'Websites',
      texts: 'Texts',
      minScore: 'Minimum score',
      addEdit: 'Add / Edit',
      nodeAddChatbot: 'Add Chatbot',
      executeChatbot: 'Execute Chatbot',
      condition: 'Condition',
      addCondition: 'Add Condition',
      options: 'Options',
      sendMessage: 'Send Message',
      addVariable: 'Add Variable',
      set: 'Set',
      addOption: 'Add Option',
      switch: 'Switch',
      systemMessageConcat: 'System Message to Concatenate',
      createCondition: 'Create Condition',
      when: 'When',
      variable: 'Variable',
      nodeType: 'Node type',
      debug: 'Debug',
      debugInfo: 'Debug Information',
      requestTokens: 'Request Tokens',
      responseTokens: 'Response Tokens',
      model: 'Model',
      nodeExecutionResult: 'Node Execution Result',
      viewDebugInfo: 'View Debug Information',
      temperature: 'Temperature',
      selectForm: 'Select form',
      connectApi: 'Connect API',
      api: 'API',
      endpoint: 'Endpoint',
      params: 'Params',
      selectApi: 'Select API',
      selectEndpoint: 'Select endpoint',
      requestBody: 'Request body',
      thereIsNoParams: 'This endpoint does not receive any parameters',
      fromVariable: 'From variable',
      specifyValue: 'Specify value',
      omitParam: 'Omit parameter',
      param: 'Param',
      selectOption: 'Select option',
      selectVariable: 'Variable',
      processJson: 'Process JSON',
      data: 'Data',
      manage: 'Manage',
      processJsonDataExplanation: 'Defines for each Json field the name of the variable where the value will be stored.',
      addField: 'Add field',
      field: 'Field',
      code: 'Code',
      codeNodeInfo1: 'To access a variable that has been previously stored in a "Set" node, use orchestrator_variables["variable"].',
      codeNodeInfo2: 'You can employ the response with "return" by connecting this node to a switch node.',
      addCode: 'Add code',
      thereIsNoOrchestrators: 'There are no created orchestrators',
      deleteEdgeWarning: 'Are you sure you want to delete this edge?',
      url: 'URL',
      statusCode: 'Status code',
      cost: 'Cost',
      enabled: 'Enabled',
      disabled: 'Disabled',
      temperatureDescriptionVeryDeterministic: 'Very deterministic',
      temperatureDescriptionDeterministic: 'Deterministic',
      temperatureDescriptionBalanced: 'Balanced',
      temperatureDescriptionCreative: 'Creative',
      temperatureDescriptionVeryCreative: 'Very creative',
      userDailyLimit: 'User daily limit ($)',
      addCustomVariable: 'Add entry variable',
      confirmDeleteNodes: 'Are you sure you want to delete the selected nodes?',
      defaultValues: 'Default values',
      nodeSettings: 'Node settings',
      selectAChatbot: 'Select a chatbot',
      initial: 'Initial',
      selectAForm: 'Select a form',
      selectAnApi: 'Select an API',
      selectAnEndpoint: 'Select an endpoint',
      customOutputs: 'Custom outputs',
      addOutput: 'Add output',
      orchestratorSettings: 'Orchestrator settings',
      variables: 'Variables',
      editCode: 'Edit code',
      changeConversationAgent: 'Change conversation agent',
      addZendesktag: 'Add tag to Zendesk',
      removeZendeskTag: 'Remove tag from Zendesk',
      hasZendeskTag: 'Has tag in Zendesk',
      changeZendeskTicketStatus: 'Change Zendesk ticket status',
      sendZendeskMessage: 'Send message to Zendesk',
      makeObject: 'Create/edit object',
      addKey: 'Add key',
      removeKey: 'Remove key',
      isEmpty: 'Is empty',
      extractConversationData: 'Get conversation data',
      extractChatbotData: 'Get chatbot data',
      booleanOr: 'Boolean OR',
      booleanAnd: 'Boolean AND',
      stringEquals: 'Compare texts',
      stringConcat: 'Concatenate texts',
      stringIndexOf: 'Search text in another text',
      sleep: 'Sleep',
      getMessageData: 'Get message data',
      findChatbot: 'Find chatbot',
      sendPrompt: 'Send prompt',
      selectAModel: 'Select a model',
      toString: 'Convert to text',
      numberEquals: '== (Number)',
      numberLowerThan: '< (Number)',
      numberLowerOrEqualThan: '<= (Number)',
      numberGreaterThan: '> (Number)',
      numberGreaterOrEqualThan: '>= (Number)',
      sequence: 'Sequence',
      comesFromTwilio: 'Comes from Twilio',
      twilioExtractOrigin: 'Extract Origin from Twilio',
      twilioExtractNumber: 'Extract Number from Twilio',
      toObject: 'Convert to object',
      returnNode: 'Return',
      addResponse: 'Add response',
      customInputs: 'Custom inputs',
      addInput: 'Add input',
      mergeObjects: 'Merge objects',
      processMergeTags: 'Process merge tags',
      createNewChatbot: 'Create new chatbot',
      conversational: 'Conversational',
      documentProcessor: 'Document processor',
      emailProcessor: 'Email processor',
      agent: 'Agent',
      extractDocumentContent: 'Extract document content',
      obtainNumberTokens: 'Obtain number of tokens',
      saveAsEmbedding: 'Save as embedding',
      optional: 'Optional',
      asyncSequence: 'Async sequence',
      executeOrchestrator: 'Execute orchestrator',
      selectAnOrchestrator: 'Select an orchestrator',
      arrayClear: 'Clear array',
      arrayToString: 'Convert array to text',
      arrayAppend: 'Append to array',
      arrayMerge: 'Merge arrays',
      arrayIndexOf: 'Search in array',
      arrayInsert: 'Insert in array',
      arrayPop: 'Extract element from array',
      arrayRemove: 'Remove element from array',
      arrayReverse: 'Reverse array',
      arraySort: 'Sort array',
      searchDocuments: 'Search documents',
      splitTextByChunkSize: 'Split text by chunk size',
      forEachLoop: 'For each loop',
      createDatastructureInstance: 'Create datastructure instance',
      breakDatastructureInstance: 'Break datastructure instance',
      selectADatastructure: 'Select a datastructure',
      stringToUpperCase: 'Convert to uppercase',
      stringToLowerCase: 'Convert to lowercase',
      selectAChatbotForDebug: 'Select a chatbot to debug',
      editZendeskRequesterData: 'Edit Zendesk requester data',
      writeJsonData: 'Write JSON data',
      debugOrchestrator: 'Debug orchestrator',
      inputs: 'Inputs',
      uploadDocument: 'Upload document',
      change: 'Change',
      viewResult: 'View result',
      searchDatatableRows: 'Search datatable rows',
      selectADatatable: 'Select a datatable',
      versions: 'Versions',
      manageVersions: 'Manage versions',
      manageOrchestratorVersions: 'Manage orchestrator versions',
      version: 'Version',
      isActive: 'Is active',
      isActiveVersion: 'Active version',
      setAsActiveVersion: 'Set as active version',
      createNewVersionFromThis: 'Create new version from this',
      createNewVersion: 'Create new version',
      deleteOrchestratorVersionConfirm: 'Are you sure you want to delete this version?',
      setAsActive: 'Set as active',
      sendHtml: 'Send HTML',
      editVersion: 'Edit version',
      addDatatableRow: 'Add datatable row',
      updateDatatableRow: 'Update datatable row',
      extractEmailData: 'Extract email data',
      createConversation: 'Create conversation',
      createZendeskTicket: 'Create Zendesk ticket',
      twilioSendMessage: 'Send Twilio message',
      twilioSendTemplate: 'Send Twilio template',
      selectAnOption: 'Select an option',
      fetchUrl: 'Fetch URL',
      stringSplit: 'Split text',
      stringJoin: 'Join text',
      arrayGetElement: 'Get element from array',
      stringReplace: 'Replace text',
      stringSearch: 'Search text',
      searchConversations: 'Search conversations',
      toNumber: 'Convert to number',
      createFaq: 'Create FAQ',
      updateFaq: 'Update FAQ',
      searchFaqs: 'Search FAQs',
      extractFaqData: 'Get FAQ data',
      retrieveConversationMessages: 'Retrieve conversation messages',
      createChatbot: 'Create chatbot',
      updateChatbot: 'Update chatbot',
      selectAnOrganization: 'Select an organization',
      apiAuthentication: 'API authentication',
      createMessage: 'Create message',
      sendAttachment: 'Send attachment',
      functions: 'Functions',
      addFunction: 'Add function',
      function: 'Function',
      deleteFunction: 'Delete function',
      deleteFunctionMessage: 'Are you sure you want to delete this function?',
      backToOrchestrator: 'Back to orchestrator',
      localVariables: 'Local variables',
      localVariablesDefaultValues: 'Local variables - Default values',
      searchInGoogle: 'Search in Google',
      searchInYoutube: 'Search in Youtube',
      defineDatastructure: 'Define datastructure',
      datastructureNotFound: 'The datastructure has not been found',
      datastructureNotFoundMessage: 'The data model has not been found. Please make sure you have permissions to access it.',
      allProcesses: 'All processes',
      processCost: 'Process cost',
      saveTheResultInCache: 'Save the result in cache',
      cacheDuration: 'Cache duration',
      avgTime: 'Avg. time',
      nodesStatistics: 'Nodes statistics',
      allVersions: 'All versions',
      allFunctions: 'All functions',
      mainOrchestrator: 'Main orchestrator',
      node: 'Node',
      avgCost: 'Avg. cost',
      avgInputTokens: 'Avg. input tokens',
      avgOutputTokens: 'Avg. output tokens',
      nodeName: 'Node name',
      actualVersion: 'Actual version',
      get: 'Get',
      gitlabRepositoryTree: 'Get Gitlab repository tree',
      gitlabGetFileContent: 'Get Gitlab file content',
      excludeFromDebug: 'Exclude from debug',
      excludeFromStatistics: 'Exclude from statistics',
    },
    forms: {
      forms: 'Forms',
      createForm: 'Create form',
      thereIsNoForms: 'There are no created forms',
      data: 'Data',
      questions: 'Questions',
      question: 'Question',
      basicInfo: 'Basic information',
      sharedWith: 'Shared with',
      selectOrganization: 'Select organization',
      thereIsNoQuestions: 'There are no created questions',
      variableName: 'Variable name',
      addQuestion: 'Add question',
      title: 'Title',
      text: 'Text',
      number: 'Number',
      select: 'Select',
      phone: 'Phone',
      email: 'Email',
      date: 'Date',
      options: 'Options',
      isRequired: 'Is required',
      addOption: 'Add option',
      value: 'Value',
      initMessage: 'Message before displaying the form',
      fillMessage: 'Message after filling in the form',
      messages: 'Messages',
    },
    formPublicView: {
      submittedMessage: 'Thank you for filling out the form. Now you can continue the conversation from the device you were using.',
    },
    conversations: {
      title: 'Conversations',
      id: 'ID',
      date: 'Date',
      ticket: 'Ticket',
      totalMessages: 'Messages',
      price: 'Price',
      type: 'Type',
      whatsapp: 'Whatsapp',
      moveConversations: 'Move conversations',
      downloadCSV: 'Download CSV',
      search: 'Search',
    },
    moveConversationsModal: {
      title: 'Move conversations to another orchestrator',
      info: 'All conversations associated with this orchestrator (ORQ_NAME) will be moved to the selected orchestrator.',
    },
    apiKeys: {
      title: 'API keys',
      createApiKey: 'Create API key',
      info1: 'Those are the API keys you have created to access to your APIs. You can create new ones or delete the existing ones. For security reasons you will not be able to see the Client Secret of your API Keys once created, so we recommend you to save them in a safe place.',
      thereIsNoApiKeys: 'No API Key has been created yet',
      apiKey: 'API Key',
      deleteApiKey: 'Delete API Key',
      deleteApiKeyConfirm: 'Are you sure you want to delete this API Key?',
      apiKeyCreatedInfo: 'This is your new API Key. For security reasons you will not be able to see the Client Secret of your API Keys once created, so we recommend you to save them in a safe place.',
      clientSecret: 'Client Secret',
    },
    datastructures: {
      datastructures: 'Datastructures',
      addDatastructure: 'Add datastructure',
      thereIsNoDatastructures: 'There are no created datastructures',
      editDatastructure: 'Edit datastructure',
      datastructureData: 'Datastructure general information',
      fields: 'Fields',
      addField: 'Add field',
      confirmDeletDataStructure: 'Are you sure you want to delete the datastructure',
    },
    datatables: {
      datatables: 'Datatables',
      addDatatable: 'Add datatable',
      thereIsNoDatatables: 'There are no created datatables',
      editDatatable: 'Edit datatable',
      datatableData: 'Datatable general information',
      confirmDeleteDatatable: 'Are you sure you want to delete the datatable',
      datastructure: 'Datastructure',
      selectDatastructure: 'Select datastructure',
      viewEntries: 'View entries',
    },
    datatableEntries: {
      datatableEntries: 'Datatable entries',
      datatableNotFound: 'The datatable has not been found',
      addEntry: 'Add entry',
      defineADatastructureForThisDatatable: 'Define a datastructure for this datatable',
      editEntry: 'Edit entry',
      confirmDeleteDatatableRow: 'Are you sure you want to delete this row?',
      invalidJson: 'Invalid JSON',
    },
    notFound: {
      title500: 'Sorry, there was an internal server error. We could not complete your request.',
      description500: 'Our team has been made aware of the issue. We apologize for the inconvenience',
      title404: 'Sorry, the page you are looking for is not on the web.',
      title401: 'Sorry, you are unauthorized to view this page',
      title400: 'Sorry, the response was a bad request',
      description404: 'This may occur for two reasons:',
      description401: 'This may occur for two reasons:',
      description400: 'This may occur for two reasons:',
      reason1400: 'The request has some missing mandatory parameters, or',
      reason2400: 'Some unexpected behaviour ocurred',
      reason1401: 'Your role is not authorized, or',
      reason2401: 'You are not in the correct view.',
      reason1404: 'You have entered an incorrect internet address, or',
      reason2404: 'The page has been eliminated or moved.',
      unknownStatus: 'The status returned is unknown and has not been handled',
      back: 'Back to Homepage',
    },
    errors: {
      generic: 'An error has occurred',
      internalServerError: 'An unexpected error has occurred',
    },
    common: {
      options: 'Options',
      access: 'Access',
      name: 'Name',
      description: 'Description',
      edit: 'Edit',
      accept: 'Accept',
      close: 'Close',
      cancel: 'Cancel',
      none: 'None',
      type: 'Type',
      delete: 'Delete',
      save: 'Save',
      saving: 'Saving',
      saved: 'Saved',
      copy: 'Copy',
      copied: 'Copied',
      error: 'Error',
      back: 'Back',
      add: 'Add',
      loading: 'Loading',
      duplicate: 'Duplicate',
      send: 'Send',
      sending: 'Sending',
      sendAgain: 'Send again',
      search: 'Search',
      startDate: 'Start date',
      endDate: 'End date',
      createdAt: 'Created at',
      yes: 'Yes',
      no: 'No',
      apply: 'Apply',
      organizations: 'Organizations',
      export: 'Export',
      import: 'Import',
      minutes: 'Minutes',
      hours: 'Hours',
      days: 'Days',
    },
  },
};
