import React from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function Endpoint(props) {
  const {
    data, isSaving, onChangeStatus, onChangeRequiresConfirmation, onEdit, onDelete,
  } = props;

  const literals = useLiterals('addApi');

  const handleChangeStatus = () => {
    if (!isSaving) {
      onChangeStatus(data.id, !data.active);
    }
  };

  const handleChangeRequiresConfirmation = () => {
    if (!isSaving) {
      onChangeRequiresConfirmation(data, !data.requires_confirmation);
    }
  };

  const handleUpdateEndpoint = (event) => {
    const id = event.currentTarget.getAttribute('data-id');
    onEdit(id);
  };

  const handleDeleteEndpoint = (event) => {
    const id = event.currentTarget.getAttribute('data-id');
    onDelete(id);
  };

  return (
    <div className={`endpoint ${data.method.toLowerCase()}`}>
      <div className='content'>
        <div className='content__left'>
          <div className='method'>{data.method}</div>
          <div className='path'>{data.endpoint}</div>
          <div className='summary'>{data.summary}</div>
        </div>
        {data.requires_confirmation ? (
          <div className='requires_confirmation' onClick={handleChangeRequiresConfirmation}>
            <i className='fa-solid fa-exclamation-triangle' />
            {literals.requiresConfirmation}
          </div>
        ) : (
          <div className='not_requires_confirmation' onClick={handleChangeRequiresConfirmation}>
            {literals.notRequiresConfirmation}
          </div>
        )}
        {data.active ? (
          <div className='status active' onClick={handleChangeStatus}>
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {literals.active}
          </div>
        ) : (
          <div className='status inactive' onClick={handleChangeStatus}>
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {literals.inactive}
          </div>
        )}
        <div className='menu-opener'>
          <DropdownButton
            align={{ lg: 'end' }}
            title={(<i className='fa-solid fa-ellipsis-vertical' />)}
          >
            <Dropdown.Item eventKey='1' data-id={data.id} onClick={handleUpdateEndpoint}>{literals.common.edit}</Dropdown.Item>
            <Dropdown.Item eventKey='2' data-id={data.id} className='danger' onClick={handleDeleteEndpoint}>{literals.common.delete}</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
    </div>
  );
}

Endpoint.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    summary: PropTypes.string,
    active: PropTypes.bool.isRequired,
    requires_confirmation: PropTypes.bool.isRequired,
  }).isRequired,
  isSaving: PropTypes.bool.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onChangeRequiresConfirmation: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Endpoint;
