import React from 'react';
import propTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

function ModalWrapper({
  open, onClose, onSubmit,
  title, confirmText, description,
}) {
  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{description}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='btn-primary'
          onClick={onSubmit}
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalWrapper.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
  title: propTypes.string,
  description: propTypes.string,
  confirmText: propTypes.string,
};

ModalWrapper.defaultProps = {
  title: null,
  description: null,
  confirmText: null,
};

export default ModalWrapper;
