import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import { createOrchestratorFunction, updateOrchestratorFunction } from 'modules/orchestrators/actions';

function FunctionModal({
  functionId, name: originalName, onClose, orchestrator, onUpdateOrchestrator,
}) {
  const [name, setName] = useState(originalName);

  const literals = useLiterals('orchestrators');
  const { showErrorModal, printErrorModal } = useErrorHandling();

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleSaveFunction = async () => {
    try {
      const newOrchestrator = { ...orchestrator };
      if (functionId) {
        const response = await updateOrchestratorFunction(orchestrator.id, functionId, { name });

        const functionIndex = newOrchestrator.functions.findIndex((func) => func.id === functionId);
        if (functionIndex >= 0) {
          newOrchestrator.functions[functionIndex].name = response.name;
        }
      } else {
        const response = await createOrchestratorFunction(orchestrator.id, { name });
        newOrchestrator.functions.push(response);
      }
      onUpdateOrchestrator(newOrchestrator);
      onClose();
    } catch (err) {
      console.log('error', err);
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    }
  };

  return (
    <>
      {printErrorModal()?.modal}
      <Modal show size='lg' onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{literals.function}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className='input_title'>
              {literals.common.name}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' value={name} onChange={handleChangeName} />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClose}>
            {literals.common.close}
          </Button>
          <Button variant='primary' onClick={handleSaveFunction} disabled={!name.trim()}>
            {literals.common.accept}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

FunctionModal.propTypes = {
  functionId: PropTypes.string,
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  orchestrator: PropTypes.object.isRequired,
  onUpdateOrchestrator: PropTypes.func.isRequired,
};

FunctionModal.defaultProps = {
  functionId: null,
  name: '',
};

export default FunctionModal;
