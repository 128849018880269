import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import {
  getChatbotDocuments, uploadDocumentToChatbot, deleteDocumentFromChatbot, downloadDocument,
  reprocessDocument,
} from 'modules/documents/actions';
import { getErrorMessage } from 'utils/errors';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownMenuToggle from 'components/DropdownMenuToggle';
import ConfirmModal from 'components/ConfirmModal';

function Documents(props) {
  const { chatbotId } = props;

  const [documents, setDocuments] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [documentsBeingDeleted, setDocumentsBeingDeleted] = useState([]);
  const [error, setError] = useState([]);

  const literals = useLiterals('chatbot');
  const fileInputRef = useRef(null);

  const loadDocuments = async () => {
    try {
      const response = await getChatbotDocuments(chatbotId);
      setDocuments(response.documents);
    } catch (e) {
      setError(getErrorMessage(e));
    }
  };

  useEffect(() => {
    loadDocuments();
  }, [chatbotId]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    try {
      setIsUploading(true);
      await uploadDocumentToChatbot(chatbotId, formData);
      loadDocuments();
    } catch (err) {
      setError(getErrorMessage(err));
    } finally {
      setIsUploading(false);
    }
  };

  const handleUploadFile = () => {
    if (!isUploading) {
      fileInputRef.current.click();
    }
  };

  const cancelDeleteDocument = () => {
    setDocumentToDelete(null);
  };

  const submitDeleteDocument = async () => {
    setDocumentsBeingDeleted([...documentsBeingDeleted, documentToDelete.id]);
    setDocumentToDelete(null);
    try {
      await deleteDocumentFromChatbot(chatbotId, documentToDelete.id);
      setDocuments(documents.filter((document) => document.id !== documentToDelete.id));
    } catch (e) {
      setError(getErrorMessage(e));
    } finally {
      setDocumentsBeingDeleted(documentsBeingDeleted.filter((id) => id !== documentToDelete.id));
    }
  };

  const handleDownloadDocument = async (event) => {
    const documentId = event.target.parentElement.parentElement.getAttribute('data-id');
    const documentData = documents.find((doc) => doc.id === documentId);
    const documentName = documentData.name;

    try {
      const blob = await downloadDocument(documentId);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = documentName;
      link.click();
    } catch (err) {
      console.error('Error al descargar el archivo:', err);
    }
  };

  const handleReprocessDocument = async (event) => {
    const documentId = event.currentTarget.getAttribute('data-id');
    try {
      setDocumentsBeingDeleted([...documentsBeingDeleted, documentId]);
      await reprocessDocument(chatbotId, documentId);
      setDocumentsBeingDeleted(documentsBeingDeleted.filter((id) => id !== documentId));
    } catch (err) {
      setError(getErrorMessage(err));
    }
  };

  const printTable = () => {
    const rows = documents.map((document) => {
      return (
        <tr key={document.id} data-id={document.id} className={`${documentsBeingDeleted.includes(document.id) ? 'disabled_row' : ''}`}>
          <td>{document.name}</td>
          <td>{document.type}</td>
          <td className='right'>
            <button type='button' className='btn btn-primary btn-sm' onClick={handleDownloadDocument}>{literals.download}</button>
            <Dropdown className='dropdown-menu-expand'>
              <Dropdown.Toggle as={DropdownMenuToggle} id={`dropdown-custom-components-${document.id}`} />
              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey='1'
                  onClick={handleReprocessDocument}
                  data-id={document.id}
                >
                  {literals.reprocess}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey='2'
                  onClick={() => {
                    setDocumentToDelete(document);
                  }}
                  className='text-danger'
                >
                  {literals.common.delete}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
    return (
      <div>
        <div className='text-align-right subsection_buttons'>
          <button type='button' className='btn btn-primary btn-sm' onClick={handleUploadFile}>
            {isUploading ? (
              <>
                <i className='fa-solid fa-spinner fa-spin' />
                {literals.uploading}
              </>
            ) : literals.uploadDocument}
          </button>
        </div>
        <table className='table_stl1'>
          <thead>
            <tr>
              <th>{literals.common.name}</th>
              <th>{literals.common.type}</th>
              <th className='right'>{literals.common.options}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const printNoElements = () => {
    return (
      <div className='no-elements-container'>
        <p>{literals.thereIsNoDocuments}</p>
        <button type='button' className='btn btn-primary' onClick={handleUploadFile}>
          {isUploading ? (
            <>
              <i className='fa-solid fa-spinner fa-spin' />
              {literals.uploading}
            </>
          ) : literals.uploadDocument}
        </button>
      </div>
    );
  };

  const printDeleteModal = () => {
    if (!documentToDelete) {
      return null;
    }

    return (
      <ConfirmModal
        open
        title={literals.confirmDeleteDocument}
        onClose={cancelDeleteDocument}
        onSubmit={submitDeleteDocument}
      />
    );
  };

  return (
    <div>
      {printDeleteModal()}
      <input type='file' ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
      {documents.length ? printTable() : printNoElements()}
    </div>
  );
}

Documents.propTypes = {
  chatbotId: PropTypes.string.isRequired,
};

export default Documents;
