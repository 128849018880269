import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
// eslint-disable-next-line no-unused-vars
import hljs from 'highlight.js'; // No quitar, es necesario para que funcione el highlighter
import { monokai as syntaxStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export const copyTextInClipboard = (textToCopy) => {
  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export const escapeHtml = (unsafe) => {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&quot;')
    .replace(/'/g, '&#039;');
};

function CodeBlock({ language, code, literals }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    copyTextInClipboard(code);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div className='code-wrapper'>
      <SyntaxHighlighter language={language} style={syntaxStyle}>
        {code}
      </SyntaxHighlighter>
      <button
        className='btn btn-sm btn-success btn-code-copy'
        type='button'
        onClick={handleCopy}
      >
        {copied ? literals.common.copied : literals.common.copy}
      </button>
    </div>
  );
}

CodeBlock.propTypes = {
  language: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  literals: PropTypes.object.isRequired,
};

export const prepareHtmlFromMessage = (msg, literals) => {
  let message = msg;
  const codeQuotesBlockRegex = /```/g;
  let count = -1;
  let matchQuotes;

  do {
    matchQuotes = codeQuotesBlockRegex.exec(message);
    count += 1;
  } while (matchQuotes !== null);

  if (count % 2 !== 0) {
    message += '```';
  }

  const codeBlockRegex = /```([\w+#-]+)\n([\s\S]*?)```/g;

  // Una función para convertir texto en componentes con enlaces y saltos de línea
  const textToComponentsWithLinksBreaksAndBold = (text) => {
    const urlRegex = /(\bhttps?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    const boldRegex = /\*\*(.*?)\*\*/g;
    const imgRegex = /<img\s[^>]*>/g;
    const imgMap = {};
    let imgCounter = 0;

    // Paso 1: Extraer etiquetas <img> y reemplazarlas con marcadores de posición.
    const textWithImgPlaceholders = text.replace(imgRegex, (match) => {
      const placeholder = `__IMG${imgCounter}__`;
      imgMap[placeholder] = match; // Almacenar la etiqueta <img> con su placeholder.
      imgCounter += 1;
      return placeholder;
    });

    return textWithImgPlaceholders.split(/\n/).map((line, lineIndex, array) => {
      const parts = line.split(urlRegex).flatMap((part, partIndex) => {
        if (imgMap[part]) {
          return <div dangerouslySetInnerHTML={{ __html: imgMap[part] }} key={`${lineIndex}-${partIndex}`} />;
        }
        if (urlRegex.test(part)) {
          return <a href={part} rel='noreferrer' target='_blank' key={`${lineIndex}-${partIndex}`}>{part}</a>;
        }
        // Aquí manejamos el texto en negrita
        return part.split(boldRegex).map((subPart, subPartIndex) => {
          if (subPartIndex % 2 === 1) { // Si es impar, es un texto que estaba entre asteriscos
            return <strong key={`${lineIndex}-${partIndex}-b${subPartIndex}`}>{subPart}</strong>;
          }
          return subPart;
        });
      });

      const lineComponent = <span key={`${lineIndex}-line`}>{parts}</span>;
      if (lineIndex < array.length - 1) {
        return [lineComponent, <br key={`${lineIndex}-br`} />];
      }
      return lineComponent;
    });
  };

  const components = [];
  let lastIndex = 0;
  message.replace(codeBlockRegex, (match, language, code, index) => {
    components.push(message.slice(lastIndex, index));
    components.push(
      <CodeBlock language={language} code={code} literals={literals} />,
    );
    lastIndex = index + match.length;
  });

  components.push(message.slice(lastIndex));

  const components2 = [];
  const singleCodeBlockRegex = /`([\s\S]*?)`/g;
  components.forEach((component) => {
    if (typeof component === 'string') {
      lastIndex = 0;
      component.replace(singleCodeBlockRegex, (match, language, index2) => {
        components2.push(component.slice(lastIndex, index2));
        components2.push(
          <b>{language}</b>,
        );
        lastIndex = index2 + match.length;
      });

      components2.push(component.slice(lastIndex));
    } else {
      components2.push(component);
    }
  });

  components2.forEach((component, index) => {
    if (typeof component === 'string') {
      // Reemplazar \n por <br />
      const htmlWithLineBreaks = component.replace(/\n/g, '<br />');
      components2[index] = <span dangerouslySetInnerHTML={{ __html: htmlWithLineBreaks }} />;
    } else {
      // Si es un componente JSX, procesar los hijos recursivamente
      const processedChildren = React.Children.map(component.props.children, (child) => {
        if (typeof child === 'string') {
          return <span dangerouslySetInnerHTML={{ __html: child.replace(/\n/g, '<br />') }} />;
        }
        return child;
      });

      components2[index] = React.cloneElement(component, {}, processedChildren);
    }
  });

  const componentsKey = components.map((component, index) => {
    if (typeof component === 'string') {
      // Usar la función textToComponentsWithLinks para procesar las cadenas de texto
      return <span key={`${message.id}-text-${index}`} data-cy='chatbot-conversation-message'>{textToComponentsWithLinksBreaksAndBold(component)}</span>;
    }
    return React.cloneElement(component, { key: `${message.id}-codeBlock-${index}` });
  });

  return componentsKey;
};

export const snakeToCamelCase = (str) => {
  return str
    // Divide el string en partes usando el guión bajo como separador
    .split('_')
    // Itera sobre cada palabra; convierte la primera letra de cada palabra a mayúscula,
    // excepto para el primer elemento del array.
    .map((word, index) => {
      if (index === 0) {
        // La primera palabra se mantiene en minúsculas
        return word;
      }
      // Convierte el primer carácter de cada palabra restante a mayúscula
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    // Une todas las palabras transformadas para formar el string en camelCase
    .join('');
};
