export const getIconByMimetype = (mimeType) => {
  if (!mimeType) {
    return 'far fa-file';
  }
  const mimeToClassMap = {
    'application/pdf': 'far fa-file-pdf',
    'application/msword': 'far fa-file-word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'far fa-file-word',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'far fa-file-excel',
    'application/vnd.ms-excel': 'far fa-file-excel',
    'application/vnd.ms-powerpoint': 'far fa-file-powerpoint',
    'text/plain': 'far fa-file-alt',
    'image/jpeg': 'far fa-file-image',
    'image/png': 'far fa-file-image',
    'image/gif': 'far fa-file-image',
    'audio/mpeg': 'far fa-file-audio',
    'video/mp4': 'far fa-file-video',
    'application/zip': 'far fa-file-archive',
    'text/x-python': 'far fa-file-code',
    'application/json': 'far fa-file-code',
  };

  // Obtener la clase de Font Awesome correspondiente al mimetype
  const faClass = mimeToClassMap[mimeType.toLowerCase()];

  // Si el mimetype no está en el mapeo, usar una clase genérica para archivos desconocidos
  return faClass || 'far fa-file';
};
