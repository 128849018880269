/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/snippets/python';
import 'ace-builds/src-noconflict/theme-chaos';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import useLiterals from 'hooks/useLiterals';

function CodeModal({
  onClose,
  onSave,
  code: originalCode,
}) {
  const [dark, setDark] = useState(false);
  const [code, setCode] = useState(originalCode || `def function(orchestrator_variables):
  return True`);

  const literals = useLiterals('orchestrators');

  const handleSetDark = () => {
    setDark(!dark);
  };

  const canSave = () => {
    return code !== '';
  };

  const handleClose = () => {
    setCode('');
    onClose();
  };

  const handleSave = () => {
    onSave(code);
  };

  console.log('code', code);
  return (
    <Modal size='xl' show onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{literals.code}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-between'>
          <ul>
            <li>{literals.codeNodeInfo1}</li>
            <li>{literals.codeNodeInfo2}</li>
          </ul>
          <div className='dark-mode-btn' onClick={handleSetDark}>
            {!dark && <i className='fa-solid fa-moon' />}
            {dark && <i className='fa-solid fa-sun' />}
          </div>
        </div>
        <AceEditor
          mode='python'
          theme={dark ? 'chaos' : 'github'}
          onChange={setCode}
          value={code}
          wrapEnabled
          showPrintMargin
          showGutter
          highlightActiveLine
          fontSize={14}
          setOptions={{
            useWorker: false,
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2,
            showPrintMargin: false,
          }}
          width='100%'
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          {literals.common.close}
        </Button>
        <Button
          variant='primary'
          onClick={handleSave}
          disabled={!canSave()}
        >
          {literals.common.save}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CodeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  code: PropTypes.string,
};

CodeModal.defaultProps = {
  code: '',
};

export default CodeModal;
