import Api from './repository';

export const getChatbotDocuments = async (chatbotId) => {
  const response = await Api.getChatbotDocuments(chatbotId);
  return response;
};

export const uploadDocumentToChatbot = async (chatbotId, formData) => {
  const response = await Api.uploadDocumentToChatbot(chatbotId, formData);
  return response;
};

export const uploadDocumentToConversation = async (chatbotId, conversationId, formData) => {
  const response = await Api.uploadDocumentToConversation(chatbotId, conversationId, formData);
  return response;
};

export const deleteDocumentFromChatbot = async (chatbotId, documentId) => {
  const response = await Api.deleteDocumentFromChatbot(chatbotId, documentId);
  return response;
};

export const downloadDocument = async (documentId) => {
  const response = await Api.downloadDocument(documentId);
  return response;
};

export const reprocessDocument = async (chatbotId, documentId) => {
  const response = await Api.reprocessDocument(chatbotId, documentId);
  return response;
};

export const uploadDocument = async (formData) => {
  const response = await Api.uploadDocument(formData);
  return response;
};
