import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';
import { NODE_TYPES, TYPE_CONNECTIONS } from 'constants/orchestrator';
import { snakeToCamelCase } from 'utils/text';
import { NODE_TYPES_STRUCTURE } from 'constants/orchestrator_nodes';
import NodeResult from '../NodeResult';

function DefaultNode({ data, literals }) {
  const [showResult, setShowResult] = useState(false);

  const printInputs = () => {
    return data.inputs.map((input) => {
      if (!input.id) {
        return null;
      }

      const isRequired = typeof input.is_required !== 'undefined' ? input.is_required : true;
      return (
        <div key={input.id}>
          <Handle
            type='target'
            position={Position.Left}
            id={input.id}
            className={`pin_${input.type.toLowerCase()} pin_${input.type_set.toLowerCase()}`}
          />
          {`${input.name}${isRequired ? '' : ` (${literals.optional})`}`}
        </div>
      );
    });
  };

  const printOutputs = () => {
    return data.outputs.map((output) => {
      if (!output.id) {
        return null;
      }
      return (
        <div key={output.id}>
          {output.name}
          <Handle
            type='source'
            position={Position.Right}
            id={output.id}
            className={`pin_${output.type.toLowerCase()} pin_${output.type_set.toLowerCase()}`}
          />
        </div>
      );
    });
  };

  const printNodeIcon = () => {
    if (NODE_TYPES_STRUCTURE[data.type] && NODE_TYPES_STRUCTURE[data.type].icon) {
      return (<i className={NODE_TYPES_STRUCTURE[data.type].icon} />);
    }
    switch (data.type) {
      case NODE_TYPES.INITIAL:
        return (<i className='fa-solid fa-play' />);
      case NODE_TYPES.SET:
        return (<i className='fa-solid fa-floppy-disk' />);
      case NODE_TYPES.FUNCTION:
        return (<i className='fa-solid fa-code' />);
      default:
        return (<i className='fa-solid fa-circle' />);
    }
  };

  const handleViewResult = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowResult(true);
  };

  const handleHideResult = () => {
    setShowResult(false);
  };

  const printResultModal = () => {
    if (!showResult || !data?.result) {
      return null;
    }

    return (
      <NodeResult
        name={data.label}
        result={data.result}
        onClose={handleHideResult}
      />
    );
  };

  const hasHeader = (data.inputs && data.inputs.length && data.inputs[0].type === TYPE_CONNECTIONS.EXEC) || data.type === NODE_TYPES.INITIAL;
  const classesName = [];
  if (data?.isSelected) {
    classesName.push('node-selected');
  } else {
    classesName.push('node-not-selected');
  }

  if (data.isExecuted) {
    classesName.push('node-executed');
  }

  return (
    <>
      {printResultModal()}
      <div className={classesName.join(' ')}>
        {hasHeader ? (
          <div className={`node-type node-type__${data.type.toLowerCase()}`}>
            <div>
              {printNodeIcon()}
            </div>
            <div>
              <div className='node-type__title'>{literals[snakeToCamelCase(data.type.toLowerCase())]}</div>
              <div className='node-type__subtitle'>{data.label}</div>
            </div>
          </div>
        ) : null}
        <div className={`node_body ${!hasHeader ? 'no-inputs' : ''} ${data?.result ? 'has-result' : ''}`}>
          <div className='node_body_inputs'>
            {printInputs()}
          </div>
          <div className='node_body_outputs'>
            {printOutputs()}
          </div>
        </div>
        {data?.result ? (
          <div className='node_footer'>
            <a href='#' onClick={handleViewResult}>
              {literals.viewResult}
            </a>
          </div>
        ) : null}
      </div>
    </>
  );
}

DefaultNode.propTypes = {
  data: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
};

export default DefaultNode;
