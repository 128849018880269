import React from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function NodeResult(props) {
  const { name, result, onClose } = props;

  const literals = useLiterals('orchestrators');

  const printResult = () => {
    if (typeof result === 'boolean') {
      return result ? 'true' : 'false';
    }

    if (typeof result === 'object' || Array.isArray(result)) {
      return (<pre>{JSON.stringify(result, null, 2)}</pre>);
    }

    return (<div>{result}</div>);
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {printResult()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {literals.common.close}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

NodeResult.propTypes = {
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  result: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

NodeResult.defaultProps = {
  result: '',
};

export default NodeResult;
