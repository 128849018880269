import { SET_MESSAGES, DELETE_MESSAGE } from 'modules/chats/index';
import Api from './repository';

export const getAudioTranscription = async (data) => {
  const response = await Api.getAudioTranscription(data);
  return response;
};

export const uploadFileToChatbot = async (data) => {
  const response = await Api.uploadFileToChatbot(data);
  return response;
};

export const generateNameConversation = async (chatbotId, message) => {
  const response = await Api.generateNameConversation(chatbotId, message);
  return response;
};

export const getMessages = async (chatbotId, conversationId, offset = 0, limit = 100) => {
  const response = await Api.getMessages(chatbotId, conversationId, offset, limit);
  return response;
};

export const sendMessage = (chatbotId, messages, conversationId) => async (dispatch) => {
  dispatch({ type: SET_MESSAGES, payload: messages });
  try {
    const response = await Api.sendMessage(chatbotId, messages, conversationId);
    return response;
  } catch (error) {
    console.error('error', error);
    return null;
  }
};

export const startConversation = (conversationId) => (dispatch) => {
  dispatch({ type: SET_MESSAGES, payload: { conversation_id: conversationId, messages: [] } });
};

export const deleteMessage = (conversationId, messageId) => (dispatch) => {
  dispatch({ type: DELETE_MESSAGE, payload: { conversationId, messageId } });
};

export const getAgents = async (chatbotId) => {
  const response = await Api.getAgents(chatbotId);
  return response;
};

export const retryMessage = async (chatbotId, conversationId, messageId) => {
  const response = await Api.retryMessage(chatbotId, conversationId, messageId);
  return response;
};
