import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const FAQ_CATEGORY = 'Faqs';

export default {
  CREATE_FAQ: {
    categories: [FAQ_CATEGORY],
    tag: 'createFaq',
    name: 'Create FAQ',
    tags: ['faq', 'create faq', 'add faq', 'new faq'],
    icon: 'fa-solid fa-question-circle',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.CHATBOT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'chatbot',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'title',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'similar_questions',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'answer',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.FAQ,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'faq',
        custom: false,
        to: [],
      },
    ],
  },
  UPDATE_FAQ: {
    categories: [FAQ_CATEGORY],
    tag: 'updateFaq',
    name: 'Update FAQ',
    tags: ['faq', 'update faq', 'edit faq', 'modify faq'],
    icon: 'fa-solid fa-question-circle',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.FAQ,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'faq',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'title',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'similar_questions',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'answer',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.FAQ,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'faq',
        custom: false,
        to: [],
      },
    ],
  },
  SEARCH_FAQS: {
    categories: [FAQ_CATEGORY],
    tag: 'searchFaqs',
    name: 'Search FAQs',
    tags: ['faq', 'search faqs', 'find faqs', 'get faqs', 'retrieve faqs', 'list faqs'],
    icon: 'fa-solid fa-question-circle',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.CHATBOT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'chatbot',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'search',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'min_score',
        custom: false,
        default_value: 82,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.FAQ,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'results',
        custom: false,
        to: [],
      },
    ],
  },
  EXTRACT_FAQ_DATA: {
    categories: [FAQ_CATEGORY],
    tag: 'getFaqData',
    name: 'Get FAQ data',
    tags: ['faq', 'get faq data', 'extract faq data', 'faq data', 'break faq data'],
    icon: 'fa-solid fa-file-lines',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.FAQ,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'faq',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'id',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'chatbot_id',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'title',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'similar_questions',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'answer',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'created_at',
        custom: false,
        to: [],
      },
    ],
  },
};
