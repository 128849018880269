import React from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import DefaultNode from './NodeTypes/DefaultNode';

function CustomNode({ data }) {
  const literals = useLiterals('orchestrators');
  const renderNodeByType = (type) => {
    return <DefaultNode data={data} literals={literals} />;
  };
  return renderNodeByType(data.type);
}

CustomNode.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CustomNode;
