export const RESET_EXECUTION = 'RESET_EXECUTION';
export const INSERT_NODE_IN_EXECUTION = 'INSERT_NODE_IN_EXECUTION';
export const INSERT_NODE_RESULT = 'INSERT_NODE_RESULT';

export const initialState = {
  processes: {},
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case RESET_EXECUTION: {
      return {
        ...state,
        processes: {
          ...state.processes,
          [payload.processId]: {
            executed_nodes: [],
            results: {},
          },
        },
      };
    }
    case INSERT_NODE_IN_EXECUTION: {
      const { processId, nodeId } = payload;
      const currentProcess = state.processes[processId] || {};
      const executedNodes = currentProcess.executed_nodes || [];

      return {
        ...state,
        processes: {
          ...state.processes,
          [processId]: {
            ...currentProcess,
            executed_nodes: [...executedNodes, nodeId],
          },
        },
      };
    }
    case INSERT_NODE_RESULT: {
      const { processId, nodeId, result } = payload;
      const currentProcess = state.processes[processId] || {};
      const currentResults = currentProcess.results || {};

      return {
        ...state,
        processes: {
          ...state.processes,
          [processId]: {
            ...currentProcess,
            results: {
              ...currentResults,
              [nodeId]: result,
            },
          },
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
