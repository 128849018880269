import Api from './repository';

export const getApiKeys = async () => {
  const response = await Api.getApiKeys();
  return response;
};

export const createApiKey = async (data = null) => {
  const response = await Api.createApiKey(data);
  return response;
};

export const updateApiKey = async (apiKeyId, data = null) => {
  const response = await Api.updateApiKey(apiKeyId, data);
  return response;
};

export const deleteApiKey = async (apiKeyId) => {
  const response = await Api.deleteApiKey(apiKeyId);
  return response;
};
