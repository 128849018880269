import React, { useState, useEffect } from 'react';
import { doLogin, saveTokens } from 'modules/session/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from 'utils/errors';
import LoginForm from './components/form';
import { ROUTE_PATH } from '..';
import './styles.scss';

function Login() {
  // const [email, setEmail] = useState('pablo.torres+admin@globalincubator.com');
  // const [password, setPassword] = useState('cocacolaP78');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useSelector((state) => state.session);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.id) {
      navigate(ROUTE_PATH.CHATBOTS);
    }
  }, [user, navigate]);

  const handleOnSubmit = async () => {
    setError('');
    setIsLoading(true);
    try {
      const response = await doLogin(email, password);
      const { access_token: accessToken, refresh_token: refreshToken } = response;
      dispatch(saveTokens(accessToken, refreshToken));
    } catch (e) {
      const messageError = getErrorMessage(e);
      setError(messageError);
    }
    setIsLoading(false);
  };

  return (
    <LoginForm
      email={email}
      password={password}
      error={error}
      isLoading={isLoading}
      onChangeEmail={setEmail}
      onChangePassword={setPassword}
      onSubmit={handleOnSubmit}
    />
  );
}

export default Login;
