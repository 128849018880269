export const ADD_UPDATED_CONVERSATION = 'conversations/add-updated-conversation';
export const DELETE_UPDATED_CONVERSATION = 'conversations/delete-updated-conversation';
export const ADD_DELETED_CONVERSATION = 'conversations/add-deleted-conversation';
export const DELETE_DELETED_CONVERSATION = 'conversations/delete-deleted-conversation';
export const ADD_TRANSFERED_CONVERSATION = 'conversations/add-transfered-conversation';
export const DELETE_TRANSFERED_CONVERSATION = 'conversations/delete-transfered-conversation';

export const initialState = {
  updatedConversations: {},
  deletedConversations: [],
  transferedConversations: [],
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case ADD_UPDATED_CONVERSATION:
      return {
        ...state,
        updatedConversations: {
          ...state.updatedConversations,
          [payload.id]: payload,
        },
      };
    case DELETE_UPDATED_CONVERSATION: {
      const updatedConversations = { ...state.updatedConversations };
      delete updatedConversations[payload];
      return {
        ...state,
        updatedConversations,
      };
    }
    case ADD_DELETED_CONVERSATION:
      return {
        ...state,
        deletedConversations: [...state.deletedConversations, payload],
      };
    case DELETE_DELETED_CONVERSATION:
      return {
        ...state,
        deletedConversations: state.deletedConversations.filter((conversation) => conversation.id !== payload),
      };
    case ADD_TRANSFERED_CONVERSATION:
      return {
        ...state,
        transferedConversations: [...state.transferedConversations, payload],
      };

    case DELETE_TRANSFERED_CONVERSATION:
      return {
        ...state,
        transferedConversations: state.transferedConversations.filter((conversation) => conversation.from !== payload.from || conversation.to !== payload.to),
      };
    default:
      return state;
  }
};

export default reducer;
