import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useLiterals from 'hooks/useLiterals';
import Select from 'react-select';

function ChatbotForm(props) {
  const {
    name, onChangeName, onChangeOrganizations, error, chatbotOrganizations,
  } = props;

  const literals = useLiterals('chatbots');

  const { organizations } = useSelector((state) => state.organizations);

  const handleOnChangeName = (event) => {
    onChangeName(event.target.value);
  };

  return (
    <form>
      <div className='input_title'>
        {literals.common.name}
      </div>
      <div className='input_value'>
        <input type='text' className='form_input_stl' onChange={handleOnChangeName} value={name} data-cy='create-chatbot-name' />
      </div>
      <div className='input_title'>
        {literals.organizations}
      </div>
      <div className='input_value'>
        <Select
          isMulti
          options={organizations.map((organization) => ({ value: organization.id, label: organization.name }))}
          onChange={onChangeOrganizations}
          value={chatbotOrganizations}
        />
      </div>
      {error && (
        <div className='error'>
          {error}
        </div>
      )}
    </form>
  );
}

ChatbotForm.propTypes = {
  name: PropTypes.string.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangeOrganizations: PropTypes.func.isRequired,
  error: PropTypes.string,
  chatbotOrganizations: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })).isRequired,
};

ChatbotForm.defaultProps = {
  error: '',
};

export default ChatbotForm;
