import { STATUS_CONNECTING } from 'constants/status';

export const SET_STATUS = 'socket/set-status';
export const SET_CONNECTED_TIME = 'socket/set-connected-time';
export const SET_DISCONNECTED_TIME = 'socket/set-disconnected-time';

export const initialState = {
  status: STATUS_CONNECTING,
  connectedTime: null,
  disconnectedTime: null,
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_STATUS:
      return {
        ...state,
        status: payload,
      };
    case SET_CONNECTED_TIME:
      return {
        ...state,
        connectedTime: payload,
      };
    case SET_DISCONNECTED_TIME:
      return {
        ...state,
        disconnectedTime: payload,
      };
    default:
      return state;
  }
};

export default reducer;
