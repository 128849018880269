import { useSelector } from 'react-redux';

const useLiterals = (name) => {
  const literals = useSelector((state) => ({
    ...state.i18n.literals[name],
    errors: state.i18n.literals.errors,
    common: state.i18n.literals.common,
  }));

  return literals;
};

export default useLiterals;
