import React from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';

function InviteUserForm(props) {
  const {
    email, onChangeEmail, error,
  } = props;

  const literals = useLiterals('organizations');

  const handleOnChangeEmail = (event) => {
    onChangeEmail(event.target.value);
  };

  return (
    <form>
      <div className='input_title'>
        {literals.email}
      </div>
      <div className='input_value'>
        <input type='text' className='form_input_stl' onChange={handleOnChangeEmail} value={email} />
      </div>
      {error && (
        <div className='error'>
          {error}
        </div>
      )}
    </form>
  );
}

InviteUserForm.propTypes = {
  email: PropTypes.string.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  error: PropTypes.string,
};

InviteUserForm.defaultProps = {
  error: '',
};

export default InviteUserForm;
