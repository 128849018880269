import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { PROJECTS_TYPES } from 'constants/project';

function FormProject(props) {
  const {
    name, description, onChangeName, onChangeDescription, types, onChangeType,
  } = props;

  const handleOnChangeName = (event) => {
    onChangeName(event.target.value);
  };

  const printChecks = () => {
    return PROJECTS_TYPES.map((type) => {
      return (
        <Form.Check
          key={type}
          type='checkbox'
          label={type}
          value={type}
          onChange={onChangeType}
        />
      );
    });
  };

  return (
    <form>
      <Container>
        <Row>
          <Col xs={12}>
            <div className='input_title'>
              Name
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeName} value={name} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div className='input_title'>
              Goals
            </div>
            <div className='input_value'>
              <textarea className='form_input_stl' onChange={onChangeDescription}>{description}</textarea>
            </div>
          </Col>
          <Col xs={6}>
            <div style={{ paddingTop: '20px' }}>
              {printChecks()}
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
}

FormProject.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  types: PropTypes.array.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
  onChangeType: PropTypes.func.isRequired,
};

export default FormProject;
