import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector, useDispatch } from 'react-redux';
import { getDueDiligences, addDueDiligence } from 'modules/elements/actions';
import { useNavigate, useParams } from 'react-router-dom';
import Form from './components/form';

function DueDiligences() {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [types, setTypes] = useState([]);
  const { dueDiligences } = useSelector((state) => state.elements);
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getDueDiligences(id));
  }, []);

  const openCreateForm = () => {
    setShowCreateForm(true);
  };

  const closeCreateForm = () => {
    setShowCreateForm(false);
  };

  const handleAcceptCompany = () => {
    if (name) {
      dispatch(addDueDiligence(name, id));
      closeCreateForm();
      setName('');
    }
  };

  const handleChangeName = (newName) => {
    setName(newName);
  };

  const handleChangeDescription = (newDescription) => {
    setDescription(newDescription);
  };

  const handleChangeType = (newType) => {
    const newTypes = [...types];
    if (newTypes.includes(newType)) {
      newTypes.splice(newTypes.indexOf(newType), 1);
    } else {
      newTypes.push(newType);
    }
  };

  const printNoElements = () => {
    return (
      <div className='page_content'>
        <div className='no_elements'>There is no projects</div>
      </div>
    );
  };

  const printTable = () => {
    const rows = dueDiligences.map((project) => {
      return (
        <tr key={project.element_id}>
          <td>{project.name}</td>
          <td className='right'>
            <button
              className='btn btn-primary'
              type='button'
              onClick={() => navigate(`/companies/${id}/projects/${project.element_id}`)}
            >
              Access
            </button>
          </td>
        </tr>
      );
    });

    return (
      <div className='page_content'>
        <table className='table_stl1'>
          <thead>
            <tr>
              <th>Due Diligence Data Room</th>
              <th className='right'>Options</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const printCreationModal = () => {
    if (!showCreateForm) {
      return null;
    }

    return (
      <Modal show onHide={closeCreateForm} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Add Due Diligene Data Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            name={name}
            description={description}
            types={types}
            onChangeName={handleChangeName}
            onChangeDescription={handleChangeDescription}
            onChangeType={handleChangeType}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeCreateForm}>
            Close
          </Button>
          <Button variant='primary' onClick={handleAcceptCompany}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {printCreationModal()}
      <div className='section'>
        <div className='section_header'>
          <h1>Due Diligences Data Room</h1>
          <div className='btn btn-primary' onClick={openCreateForm}>Add Due Diligence Data Room</div>
        </div>
        {dueDiligences.length ? printTable() : printNoElements()}
      </div>
    </>
  );
}

export default DueDiligences;
