export const SET_ORGANIZATIONS = 'organizations/set-organizations';
export const SET_INVITATIONS = 'organizations/set-invitations';

export const initialState = {
  organizations: [],
  invitations: {
    inviter: [],
    invitee: [],
  },
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_ORGANIZATIONS: {
      return {
        ...state,
        organizations: payload,
      };
    }
    case SET_INVITATIONS: {
      return {
        ...state,
        invitations: payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
