import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const FLOW_CONTROL_CATEGORY = 'Flow Control';

export default {
  SWITCH: {
    categories: [FLOW_CONTROL_CATEGORY],
    tag: 'switch',
    name: 'Switch',
    tags: ['switch'],
    icon: 'fa-solid fa-code-branch',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'variable',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'default',
        custom: false,
        to: [],
      },
    ],
  },
  CONDITION: {
    categories: [FLOW_CONTROL_CATEGORY],
    tag: 'condition',
    name: 'Condition',
    tags: ['branch', 'if_else'],
    icon: 'fa-solid fa-code-branch',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'condition',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'true',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'false',
        custom: false,
        to: [],
      },
    ],
  },
  SEQUENCE: {
    categories: [FLOW_CONTROL_CATEGORY],
    tag: 'sequence',
    name: 'Sequence',
    tags: ['sequence', 'order', 'flow'],
    icon: 'fa-solid fa-code-branch',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: true,
        to: [],
      },
    ],
  },
  ASYNC_SEQUENCE: {
    categories: [FLOW_CONTROL_CATEGORY],
    tag: 'asyncSequence',
    name: 'Asynchronous Sequence',
    tags: ['asynchronous', 'sequence', 'order', 'flow'],
    icon: 'fa-solid fa-code-branch',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'on_completed',
        custom: false,
        to: [],
      },
    ],
  },
  FOREACH_LOOP: {
    categories: [FLOW_CONTROL_CATEGORY],
    tag: 'forEachLoop',
    name: 'For Each Loop',
    tags: ['for', 'each', 'loop', 'iterate', 'array', 'foreach'],
    icon: 'fa-solid fa-arrow-rotate-left',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'in_each_loop',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'element',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'index',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'on_completed',
        custom: false,
        to: [],
      },
    ],
  },
};
