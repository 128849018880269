export const SET_MODELS = 'models/set-models';

export const initialState = {
  models: null,
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_MODELS: {
      return {
        ...state,
        models: payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
