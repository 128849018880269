import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { initSocket } from 'modules/socket/actions';
import { getPublicChatbot } from 'modules/chatbots/actions';
import { getConversation } from 'modules/conversations/actions';
import { useParams } from 'react-router-dom';
import Chat from 'components/Chat';

function ChatbotEmbed(props) {
  const {
    id: propId, conversationId, saveConversation, variables,
  } = props;

  const [chatbot, setChatbot] = useState(null);
  const [conversationData, setConversationData] = useState(null);
  const { id: paramId } = useParams();

  const { transferedConversations } = useSelector((state) => state.conversations);

  const id = propId || paramId;

  const dispatch = useDispatch();

  useEffect(() => {
    const transfered = transferedConversations.findLast((conv) => conv.from === conversationData?.id);
    if (transfered && saveConversation) {
      localStorage.setItem('llm_conversation_id', transfered.to);
      setConversationData({ ...conversationData, id: transfered.to });
    }
  }, [transferedConversations]);

  useEffect(() => {
    dispatch(initSocket());
  }, []);

  const loadChatbot = async () => {
    const chatbotData = await getPublicChatbot(id);
    setChatbot(chatbotData);
  };

  const loadInitialData = async () => {
    if (conversationId) {
      // Solicitamos la conversación para asegurarnos de que existe y que no está eliminada
      try {
        const conversation = await getConversation(id, conversationId);
        setConversationData(conversation);
      } catch (error) {
        console.error('Error loading conversation', error);
      }
    }
    await loadChatbot();
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const handleCreateConversation = (conversation) => {
    if (saveConversation) {
      localStorage.setItem('llm_chatbot_id', id);
      localStorage.setItem('llm_conversation_id', conversation.id);
      localStorage.setItem('llm_conversation_created_at', conversation.created_at);
    }
    setConversationData(conversation);
  };

  if (!chatbot) {
    return null;
  }

  return (
    <div className='llm-chatbot-embed'>
      <Chat
        chatbot={chatbot}
        header
        conversationId={conversationData?.id}
        onCreateConversation={handleCreateConversation}
        variables={variables}
      />
    </div>
  );
}

ChatbotEmbed.propTypes = {
  id: PropTypes.string,
  conversationId: PropTypes.string,
  saveConversation: PropTypes.bool,
  variables: PropTypes.object,
};

ChatbotEmbed.defaultProps = {
  id: null,
  conversationId: null,
  saveConversation: false,
  variables: {},
};

export default ChatbotEmbed;
