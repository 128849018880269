import ApiFetch from 'utils/apiFetch';
import { deleteOrchestrator, importOrchestrator } from './actions';

const ApiFetchInstance = ApiFetch();

const URLS = {
  createOrchestrator: () => 'orchestrators',
  updateOrchestrator: (orchestratorId) => `orchestrators/${orchestratorId}`,
  deleteOrchestrator: (orchestratorId) => `orchestrators/${orchestratorId}`,
  getOrchestrators: () => 'orchestrators',
  getOrchestratorsStatistics: (orchestratorId) => `orchestrators/${orchestratorId}/statistics`,
  getOrchestrator: (orchestratorId) => `orchestrators/${orchestratorId}`,
  getOrchestratorNode: (orchestratorId, nodeId) => `orchestrators/${orchestratorId}/nodes/${nodeId}`,
  deleteOrchestratorNode: (orchestratorId, nodeId) => `orchestrators/${orchestratorId}/nodes/${nodeId}`,
  createOrchestratorNode: (orchestratorId) => `orchestrators/${orchestratorId}/nodes`,
  updateOrchestratorNode: (orchestratorId, nodeId) => `orchestrators/${orchestratorId}/nodes/${nodeId}`,
  createOrchestratorNodeAction: (orchestratorId, nodeId) => `orchestrators/${orchestratorId}/nodes/${nodeId}/actions`,
  updateOrchestratorNodeAction: (orchestratorId, nodeId, actionId) => `orchestrators/${orchestratorId}/nodes/${nodeId}/actions/${actionId}`,
  deleteOrchestratorNodeAction: (orchestratorId, nodeId, actionId) => `orchestrators/${orchestratorId}/nodes/${nodeId}/actions/${actionId}`,
  getAllNodeActions: (orchestratorId) => `orchestrators/${orchestratorId}/actions`,
  createEdge: (orchestratorId, parentId, childId) => `orchestrators/${orchestratorId}/edges/${parentId}/${childId}`,
  deleteEdge: (orchestratorId, parentId, childId, actionId) => `orchestrators/${orchestratorId}/edges/${parentId}/${childId}${actionId ? `?actionId=${actionId}` : ''}`,
  duplicateOrchestrator: (orchestratorId) => `orchestrators/${orchestratorId}/duplicate`,
  moveConversations: (orchestratorId, selectedOrchestratorId) => `orchestrators/${orchestratorId}/move/${selectedOrchestratorId}`,
  debugOrchestrator: (orchestratorId) => `orchestrators/${orchestratorId}/debug`,
  exportOrchestrator: (orchestratorId) => `orchestrators/${orchestratorId}/export`,
  importOrchestrator: () => 'orchestrators/import',
  createOrchestratorVersion: (orchestratorId) => `orchestrators/${orchestratorId}/versions`,
  updateOrchestratorVersion: (orchestratorId, versionId) => `orchestrators/${orchestratorId}/versions/${versionId}`,
  deleteOrchestratorVersion: (orchestratorId, versionId) => `orchestrators/${orchestratorId}/versions/${versionId}`,
  createOrchestratorFunction: (orchestratorId) => `orchestrators/${orchestratorId}/functions`,
  editOrchestratorFunction: (orchestratorId, functionId) => `orchestrators/${orchestratorId}/functions/${functionId}`,
  deleteOrchestratorFunction: (orchestratorId, functionId) => `orchestrators/${orchestratorId}/functions/${functionId}`,
};

export default {
  async createOrchestrator(orchestrator) {
    return ApiFetchInstance.post(URLS.createOrchestrator(), orchestrator);
  },
  async updateOrchestrator(orchestratorId, orchestrator) {
    return ApiFetchInstance.patch(URLS.updateOrchestrator(orchestratorId), orchestrator);
  },
  async deleteOrchestrator(orchestratorId) {
    return ApiFetchInstance.delete(URLS.deleteOrchestrator(orchestratorId));
  },
  async getOrchestrators() {
    return ApiFetchInstance.get(URLS.getOrchestrators());
  },
  async getOrchestratorsStatistics(orchestratorId, start, end) {
    const url = `${URLS.getOrchestratorsStatistics(orchestratorId)}?start=${start}&end=${end}`;
    return ApiFetchInstance.get(url);
  },
  async getOrchestrator(orchestratorId) {
    return ApiFetchInstance.get(URLS.getOrchestrator(orchestratorId));
  },
  async getOrchestratorNode(orchestratorId, nodeId) {
    return ApiFetchInstance.get(URLS.getOrchestratorNode(orchestratorId, nodeId));
  },
  async deleteOrchestratorNode(orchestratorId, nodeId) {
    return ApiFetchInstance.delete(URLS.deleteOrchestratorNode(orchestratorId, nodeId));
  },
  async createOrchestratorNodeAction(orchestratorId, nodeId, action) {
    return ApiFetchInstance.post(URLS.createOrchestratorNodeAction(orchestratorId, nodeId), action);
  },
  async updateOrchestratorNodeAction(orchestratorId, nodeId, actionId, action) {
    return ApiFetchInstance.patch(URLS.updateOrchestratorNodeAction(orchestratorId, nodeId, actionId), action);
  },
  async deleteOrchestratorNodeAction(orchestratorId, nodeId, actionId) {
    return ApiFetchInstance.delete(URLS.deleteOrchestratorNodeAction(orchestratorId, nodeId, actionId));
  },
  async createOrchestratorNode(orchestratorId, node) {
    return ApiFetchInstance.post(URLS.createOrchestratorNode(orchestratorId), node);
  },
  async updateOrchestratorNode(orchestratorId, nodeId, node) {
    return ApiFetchInstance.patch(URLS.updateOrchestratorNode(orchestratorId, nodeId), node);
  },
  async getAllNodeActions(orchestratorId) {
    return ApiFetchInstance.get(URLS.getAllNodeActions(orchestratorId));
  },
  async createEdge(orchestratorId, parentId, childId) {
    return ApiFetchInstance.post(URLS.createEdge(orchestratorId, parentId, childId));
  },
  async deleteEdge(orchestratorId, parentId, childId, actionId) {
    return ApiFetchInstance.delete(URLS.deleteEdge(orchestratorId, parentId, childId, actionId));
  },
  async duplicateOrchestrator(orchestratorId) {
    return ApiFetchInstance.post(URLS.duplicateOrchestrator(orchestratorId));
  },
  async moveConversations(orchestratorId, selectedOrchestratorId) {
    return ApiFetchInstance.post(URLS.moveConversations(orchestratorId, selectedOrchestratorId));
  },
  async debugOrchestrator(orchestratorId, processId, inputs, versionId = null) {
    const data = {
      process_id: processId,
      inputs,
      version_id: versionId,
    };
    return ApiFetchInstance.post(URLS.debugOrchestrator(orchestratorId), data);
  },
  async exportOrchestrator(orchestratorId) {
    return ApiFetchInstance.get(URLS.exportOrchestrator(orchestratorId));
  },
  async importOrchestrator(formData) {
    return ApiFetchInstance.post(URLS.importOrchestrator(), formData);
  },
  async createOrchestratorVersion(orchestratorId, data) {
    return ApiFetchInstance.post(URLS.createOrchestratorVersion(orchestratorId), data);
  },
  async updateOrchestratorVersion(orchestratorId, versionId, data) {
    return ApiFetchInstance.patch(URLS.updateOrchestratorVersion(orchestratorId, versionId), data);
  },
  async deleteOrchestratorVersion(orchestratorId, versionId) {
    return ApiFetchInstance.delete(URLS.deleteOrchestratorVersion(orchestratorId, versionId));
  },
  async createOrchestratorFunction(orchestratorId, data) {
    return ApiFetchInstance.post(URLS.createOrchestratorFunction(orchestratorId), data);
  },
  async updateOrchestratorFunction(orchestratorId, functionId, data) {
    return ApiFetchInstance.patch(URLS.editOrchestratorFunction(orchestratorId, functionId), data);
  },
  async deleteOrchestratorFunction(orchestratorId, functionId) {
    return ApiFetchInstance.delete(URLS.deleteOrchestratorFunction(orchestratorId, functionId));
  },
};
