import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFormByMessageId } from 'modules/forms/actions';
import useLiterals from 'hooks/useLiterals';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import Form from 'components/Chat/components/Form';
import { STATUS_CONNECTED } from 'constants/status';
import { sendNewConversation } from 'modules/socket/actions';
import './styles.scss';

function FormPublicView() {
  const [formData, setFormData] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { id: messageId } = useParams();

  const socketStatus = useSelector((state) => state.socket.status);
  const literals = useLiterals('formPublicView');

  useEffect(() => {
    if (socketStatus === STATUS_CONNECTED && formData && formData.conversation_id) {
      sendNewConversation(formData.conversation_id);
    }
  }, [socketStatus, formData]);

  const { showErrorModal, printErrorModal } = useErrorHandling();

  const loadData = async () => {
    try {
      const response = await getFormByMessageId(messageId);
      setFormData(response);
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    }
  };

  useEffect(() => {
    loadData();
  }, [messageId]);

  const printInitMessage = () => {
    if (!formData?.init_message) {
      return null;
    }

    return (
      <div className='message'>
        <div>
          {formData.init_message}
        </div>
      </div>
    );
  };

  const printFillMessage = () => {
    if (!isSubmitted) {
      return null;
    }

    const message = formData.fill_message || 'Thank you for your submission!';

    return (
      <div className='message'>
        <div>
          {message}
        </div>
      </div>
    );
  };

  const handleSubmitForm = () => {
    setIsSubmitted(true);
  };

  const printForm = () => {
    return (
      <div className='message'>
        <div>
          <Form
            chatbotId={formData.chatbot_id}
            conversationId={formData.conversation_id}
            previousMessageId={formData.previous_message_id}
            message={formData.message}
            onSubmit={handleSubmitForm}
          />
        </div>
      </div>
    );
  };

  const printContent = () => {
    if (!formData) {
      return null;
    }

    return (
      <div className='form-public-view-content'>
        {printInitMessage()}
        {printForm()}
        {printFillMessage()}
      </div>
    );
  };

  return (
    <div className='form-public-view'>
      {printErrorModal()?.modal}
      {printContent()}
    </div>
  );
}

export default FormPublicView;
