import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { initSession } from 'modules/session/actions';
import { Routes, Route, Navigate } from 'react-router-dom';
import Loading from 'components/Loading';
import ErrorBoundary from 'components/ErrorBoundary';
import { initSocket } from 'modules/socket/actions';
import { fetchForms } from 'modules/forms/actions';
import { getModels } from 'modules/models/actions';
import { getMyOrganizations } from 'modules/organizations/actions';
import { getOrchestrators } from 'modules/orchestrators/actions';
import { changeActualOrganization } from 'modules/app/actions';
import { routes, ROUTE_PATH, ROUTE_TYPE_REDIRECT } from 'routes';
import { getAPIs } from 'modules/apis/actions';
import { getTools } from 'modules/tools/actions';
import { loadingActions } from 'modules/loading';
import { getChatbots } from 'modules/chatbots/actions';
import { getDatastructures } from 'modules/datastructures/actions';
import { getDatatables } from 'modules/datatables/actions';

function App(props) {
  const { isAuthenticated, sessionChecked } = props;

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.session);

  useEffect(() => {
    dispatch(initSession());
    dispatch(initSocket());
  }, []);

  const loadData = async () => {
    dispatch(loadingActions.show());
    if (typeof user.extra.default_organization !== 'undefined' && user.extra.default_organization) {
      if (user.extra.default_organization === 'personal') {
        dispatch(getMyOrganizations());
        dispatch(changeActualOrganization({ id: 'personal' }));
      } else {
        const { organizations } = await dispatch(getMyOrganizations());
        const organization = organizations.find((org) => org.id === user.extra.default_organization);
        if (organization) {
          dispatch(changeActualOrganization(organization));
        }
      }
    } else {
      dispatch(getMyOrganizations());
    }
    dispatch(fetchForms());
    dispatch(getChatbots());
    dispatch(getTools());
    dispatch(getAPIs());
    dispatch(getModels());
    dispatch(getOrchestrators());
    dispatch(getDatastructures());
    dispatch(getDatatables());
    dispatch(loadingActions.hide());
  };

  useEffect(() => {
    if (isAuthenticated) {
      loadData();
    }
  }, [isAuthenticated]);

  return (
    <>
      <Loading />
      <Routes>
        {sessionChecked && routes.map((route, index) => {
          switch (route.type) {
            case ROUTE_TYPE_REDIRECT:
              return (
                <Route
                  key={index}
                  path={route.from}
                  exact={route.exact}
                  element={<Navigate to={route.to} />}
                />
              );

            default:
              if (!isAuthenticated && !route.ignoreSession) {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    element={<Navigate to={ROUTE_PATH.LOGIN} />}
                  />
                );
              }
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  element={
                    (route.ignoreSession || isAuthenticated)
                      ? <ErrorBoundary user={user}><route.component /></ErrorBoundary>
                      : <Navigate to={ROUTE_PATH.LOGIN} />
                  }
                />
              );
          }
        })}
      </Routes>
    </>
  );
}

App.displayName = 'App';
App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  sessionChecked: PropTypes.bool.isRequired,
};

/* *************************************** */
/* ********       CONTAINER       ******** */
/* *************************************** */

function mapStateToProps(state) {
  return {
    isAuthenticated: state.session.authenticated,
    sessionChecked: state.session.checked,
    user: state.session.user,
  };
}

function mapDispatchToProps() {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
