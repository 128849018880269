import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { updateConversation } from 'modules/conversations/actions';
import { getErrorMessage } from 'utils/errors';

function RenameForm(props) {
  const {
    name: originalName, chatbotId, conversationId, onClose, onSubmit,
  } = props;

  const [name, setName] = useState(originalName);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const literals = useLiterals('chatbot');

  const inputRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.select();
    }, 100);
  }, []);

  const handleOnChangeName = (event) => {
    setName(event.target.value);
  };

  const handleSave = async () => {
    if (!name.trim()) {
      setError(literals.nameIsRequired);
      return;
    }

    setIsSaving(true);
    try {
      await updateConversation(chatbotId, conversationId, { name });
      onSubmit(name);
    } catch (err) {
      setError(getErrorMessage(err));
    } finally {
      setIsSaving(false);
    }
  };

  const handleKeyUpName = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{literals.editName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='input_title'>
          {literals.common.name}
        </div>
        <div className='input_value'>
          <input ref={inputRef} type='text' className='form_input_stl' onChange={handleOnChangeName} onKeyUp={handleKeyUpName} value={name} />
        </div>
        {error && (
          <div className='error'>
            {error}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {literals.common.close}
        </Button>
        <Button
          variant='primary'
          onClick={handleSave}
        >
          {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
          {literals.common.accept}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

RenameForm.propTypes = {
  name: PropTypes.string.isRequired,
  chatbotId: PropTypes.string.isRequired,
  conversationId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RenameForm;
