import React from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';

function MembersTable({ members }) {
  const literals = useLiterals('organizations');

  return (
    <div>
      <h2>{literals.members}</h2>
      <table className='table_stl1'>
        <thead>
          <tr>
            <th>{literals.firstname}</th>
            <th>{literals.lastname}</th>
            <th>{literals.email}</th>
            <th>{literals.status}</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(members) && members.map((member) => (
            <tr key={member.id}>
              <td>{member.first_name}</td>
              <td>{member.last_name}</td>
              <td>{member.email}</td>
              <td>{member.invitation_status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

MembersTable.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      invitation_status: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default MembersTable;
