import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Form from 'react-bootstrap/Form';
import { NODE_TYPES, TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from 'constants/orchestrator';
import { TYPE_BASIC } from 'constants/chatbotsApis';
import useLiterals from 'hooks/useLiterals';
import { ResizableBox } from 'react-resizable';
import ConfirmModal from 'components/ConfirmModal';
import { deleteOrchestratorFunction } from 'modules/orchestrators/actions';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import VariableRow from './VariableRow';
import CodeModal from './CodeModal';
import FunctionModal from './FunctionModal';

const typesWithCustomInputs = [NODE_TYPES.RETURN_NODE, NODE_TYPES.MAKE_OBJECT, NODE_TYPES.PROCESS_MERGE_TAGS, NODE_TYPES.EXECUTE_ORCHESTRATOR];
const typesWithCustomOutputs = [NODE_TYPES.SWITCH, NODE_TYPES.PROCESS_JSON, NODE_TYPES.INITIAL, NODE_TYPES.SEQUENCE, NODE_TYPES.ASYNC_SEQUENCE, NODE_TYPES.EXECUTE_ORCHESTRATOR];
const typeWithExecOutput = [NODE_TYPES.SWITCH, NODE_TYPES.SEQUENCE, NODE_TYPES.ASYNC_SEQUENCE];
const variablesNotSelectables = [TYPE_CONNECTIONS.EXEC, TYPE_CONNECTIONS.ANY, TYPE_CONNECTIONS.DATASTRUCTURE];
const variablesNotSelectablesInCustomInputs = [TYPE_CONNECTIONS.EXEC, TYPE_CONNECTIONS.ANY, TYPE_CONNECTIONS.API, TYPE_CONNECTIONS.ENDPOINT, TYPE_CONNECTIONS.DATASTRUCTURE];

let toSaveNode = null;
let toSaveOrchestrator = null;
function OverviewPanel({
  orchestrator, node, onUpdateNode, onUpdateOrchestrator, onManageOrchestratorVersions,
  onChangeOrchestratorVersion, selectedVersion, onGoToFunction, selectedFunction, selectedVersionFunction,
}) {
  console.log('node en el overview panel', node);
  const [name, setName] = useState(node?.data?.label);
  const [inputs, setInputs] = useState(node?.data?.inputs ?? []);
  const [outputs, setOutputs] = useState(node?.data?.outputs ?? []);
  const [extraInfo, setExtraInfo] = useState(node?.data?.extra_info ?? {});
  const [disableDebug, setDisableDebug] = useState(node?.data?.disable_debug ?? false);
  const [disableStatistics, setDisableStatistics] = useState(node?.data?.disable_statistics ?? false);
  const [orchestratorVariables, setOrchestratorVariables] = useState([]);
  const [isChanged, setIsChanged] = useState(0);
  const [isChangedOrchestrator, setIsChangedOrchestrator] = useState(false);
  const literals = useLiterals('orchestrators');

  const nodeIdPrevious = useRef(null);

  // Code node
  const [showCodeModal, setShowCodeModal] = useState(false);

  // Functions
  const [showFunctionsModal, setShowFunctionsModal] = useState(false);
  const [selectedFunctionToEdit, setSelectedFunctionToEdit] = useState(null);
  const [selectedFunctionToDelete, setSelectedFunctionToDelete] = useState(null);
  const [functionVariables, setFunctionVariables] = useState([]);

  const { showErrorModal, printErrorModal } = useErrorHandling();

  const { apis } = useSelector((state) => state.apis);
  const { orchestrators } = useSelector((state) => state.orchestrators);
  const { datastructures } = useSelector((state) => state.datastructures);
  const { datatables } = useSelector((state) => state.datatables);

  useEffect(() => {
    const actualVersion = orchestrator?.versions?.find((item) => item.id === selectedVersion);
    if (actualVersion) {
      const variables = actualVersion.variables ?? [];
      setOrchestratorVariables([...variables]);
    }
  }, [orchestrator, selectedVersion]);

  useEffect(() => {
    if (selectedFunction) {
      const functionItem = orchestrator.functions.find((f) => f.id === selectedFunction);
      const actualVersion = functionItem?.versions?.find((item) => item.id === selectedVersionFunction);
      if (actualVersion) {
        const variables = actualVersion.variables ?? [];
        setFunctionVariables([...variables]);
      }
    }
  }, [selectedFunction, selectedVersionFunction, orchestrator]);

  /* **************************************************************** */
  /*                       NODE SETTINGS                              */
  /* **************************************************************** */
  const handleUpdateNode = useCallback(() => {
    onUpdateNode(node.id, {
      name, inputs, outputs, extra_info: extraInfo, disable_debug: disableDebug, disable_statistics: disableStatistics,
    });
  }, [name, inputs, outputs, extraInfo, disableDebug, disableStatistics, onUpdateNode, node]);

  useEffect(() => {
    if (isChanged) {
      if (toSaveNode) {
        clearTimeout(toSaveNode);
      }
      if (node) {
        toSaveNode = setTimeout(() => {
          handleUpdateNode();
          setIsChanged(0);
        }, 1000);
      }
    }
  }, [name, extraInfo, inputs, outputs, isChanged]);

  const handleChangeName = (event) => {
    setName(event.target.value);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const handleChangeDisableDebug = (event) => {
    setDisableDebug(event.target.checked);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const handleChangeDisableStatistics = (event) => {
    setDisableStatistics(event.target.checked);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const handleChangeInput = (input) => {
    let newInputs = inputs.map((inputItem) => {
      if (inputItem.id === input.id) {
        return input;
      }
      return inputItem;
    });
    let newOutputs = [...outputs];

    switch (input.type) {
      case TYPE_CONNECTIONS.ORCHESTRATOR: {
        // Inputs from orchestrator
        const orchestratorId = input.default_value?.id;
        if (orchestratorId && node.data.type === NODE_TYPES.EXECUTE_ORCHESTRATOR) {
          const selectedOrchestrator = orchestrators.find((orchestratorItem) => orchestratorItem.id === orchestratorId);
          if (selectedOrchestrator) {
            newInputs = newInputs.filter((inputItem) => !inputItem.custom);
            selectedOrchestrator.inputs.forEach((output) => {
              newInputs.push({
                ...output,
                id: uuidv4(),
                custom: true,
                from: [],
              });
            });
            newOutputs = newOutputs.filter((outputItem) => !outputItem.custom);
            const insertedOutputs = [];
            selectedOrchestrator.outputs.forEach((output) => {
              if (!insertedOutputs.includes(output.name)) {
                insertedOutputs.push(output.name);
                newOutputs.push({
                  ...output,
                  id: uuidv4(),
                  custom: true,
                  to: [],
                });
              }
            });
          }
        }
      }
        break;
      case TYPE_CONNECTIONS.ENDPOINT: {
        // Delete the custom inputs
        newInputs = newInputs.filter((inputItem) => !inputItem.custom);

        const endpointId = input.default_value?.id;
        if (endpointId) {
          // Search api and endpoint
          const apiId = inputs.find((inputItem) => inputItem.type === TYPE_CONNECTIONS.API)?.default_value?.id;
          if (apiId) {
            const api = apis.find((apiItem) => apiItem.id === apiId);
            if (api) {
              const endpoint = api.endpoints.find((endpointItem) => endpointItem.id === endpointId);
              if (endpoint) {
                endpoint.parameters.forEach((parameter) => {
                  newInputs.push({
                    id: uuidv4(),
                    type: parameter.type !== 'array' ? parameter.type.toUpperCase() : TYPE_CONNECTIONS.STRING,
                    type_set: parameter.type !== 'array' ? TYPE_SET_CONNECTIONS.SINGLE : TYPE_SET_CONNECTIONS.ARRAY,
                    name: parameter.name,
                    custom: true,
                    from: [],
                  });
                });

                const content = endpoint.request_body?.content ?? {};
                Object.keys(content).forEach((key) => {
                  const type = content[key];
                  newInputs.push({
                    id: uuidv4(),
                    type: type !== 'array' ? type.toUpperCase() : TYPE_CONNECTIONS.STRING,
                    type_set: type !== 'array' ? TYPE_SET_CONNECTIONS.SINGLE : TYPE_SET_CONNECTIONS.ARRAY,
                    name: key,
                    custom: true,
                    from: [],
                  });
                });
              }
            }
          }
        }
      }
        break;
      case TYPE_CONNECTIONS.DATASTRUCTURE: {
        // Delete the custom inputs and outputs
        newInputs = newInputs.filter((inputItem) => !inputItem.custom);
        newOutputs = newOutputs.filter((outputItem) => !outputItem.custom);

        const datastructureId = input.default_value?.id;
        if (datastructureId) {
          const datastructure = datastructures.find((datastructureItem) => datastructureItem.id === datastructureId);
          if (datastructure) {
            if (node.data.type === NODE_TYPES.CREATE_DATASTRUCTURE_INSTANCE) {
              datastructure.fields.forEach((field) => {
                newInputs.push({
                  id: uuidv4(),
                  type: field.type,
                  type_set: field.type_set || TYPE_SET_CONNECTIONS.SINGLE,
                  name: field.name,
                  custom: true,
                  from: [],
                });
              });

              // Add the datastructure as output
              newOutputs.push({
                id: uuidv4(),
                type: `DATASTRUCTURE-${datastructure.id}`,
                type_set: TYPE_SET_CONNECTIONS.SINGLE,
                name: datastructure.name,
                custom: true,
                to: [],
              });
            } else if (node.data.type === NODE_TYPES.BREAK_DATASTRUCTURE_INSTANCE) {
              datastructure.fields.forEach((field) => {
                newOutputs.push({
                  id: uuidv4(),
                  type: field.type,
                  type_set: field.type_set || TYPE_SET_CONNECTIONS.SINGLE,
                  name: field.name,
                  custom: true,
                  to: [],
                });
              });

              // Add the datastructure as input
              newInputs.push({
                id: uuidv4(),
                type: `DATASTRUCTURE-${datastructure.id}`,
                type_set: TYPE_SET_CONNECTIONS.SINGLE,
                name: datastructure.name,
                custom: true,
                from: [],
              });
            }
          }
        }
      }
        break;
      case TYPE_CONNECTIONS.DATATABLE: {
        // Delete the custom inputs and outputs
        newInputs = newInputs.filter((inputItem) => !inputItem.custom);
        newOutputs = newOutputs.filter((outputItem) => !outputItem.custom);

        const datatableId = input.default_value?.id;
        if (datatableId) {
          const datatable = datatables.find((d) => d.id === datatableId);
          if (datatable) {
            if (node.data.type === NODE_TYPES.SEARCH_DATATABLE_ROWS && datatable.datastructure_id) {
              const datastructure = datastructures.find((d) => d.id === datatable.datastructure_id);
              if (datastructure) {
                datastructure.fields.forEach((field) => {
                  newInputs.push({
                    id: uuidv4(),
                    type: field.type,
                    type_set: field.type_set || TYPE_SET_CONNECTIONS.SINGLE,
                    name: field.name,
                    custom: true,
                    from: [],
                  });
                });

                // Add the datatable as output
                newOutputs.push({
                  id: uuidv4(),
                  type: `DATASTRUCTURE-${datastructure.id}`,
                  type_set: TYPE_SET_CONNECTIONS.ARRAY,
                  name: datastructure.name,
                  custom: true,
                  to: [],
                });
              }
            } else if (node.data.type === NODE_TYPES.ADD_DATATABLE_ROW && datatable.datastructure_id) {
              const datastructure = datastructures.find((d) => d.id === datatable.datastructure_id);
              if (datastructure) {
                // Add the datatable as input
                newInputs.push({
                  id: uuidv4(),
                  type: `DATASTRUCTURE-${datastructure.id}`,
                  type_set: TYPE_SET_CONNECTIONS.SINGLE,
                  name: datastructure.name,
                  custom: true,
                  from: [],
                });
              }
            } else if (node.data.type === NODE_TYPES.UPDATE_DATATABLE_ROW && datatable.datastructure_id) {
              const datastructure = datastructures.find((d) => d.id === datatable.datastructure_id);
              if (datastructure) {
                // Add the datatable as input
                newInputs.push({
                  id: uuidv4(),
                  type: `DATASTRUCTURE-${datastructure.id}`,
                  type_set: TYPE_SET_CONNECTIONS.SINGLE,
                  name: datastructure.name,
                  custom: true,
                  from: [],
                });

                // Add the datastructure fields as inputs
                datastructure.fields.forEach((field) => {
                  newInputs.push({
                    id: uuidv4(),
                    type: field.type,
                    type_set: field.type_set || TYPE_SET_CONNECTIONS.SINGLE,
                    name: field.name,
                    custom: true,
                    from: [],
                  });
                });
              }
            }
          }
        }
      }
        break;
      case TYPE_CONNECTIONS.API: {
        // Delete the custom inputs
        newInputs = newInputs.filter((inputItem) => !inputItem.custom);
        const apiId = input.default_value?.id;
        if (apiId) {
          const api = apis.find((apiItem) => apiItem.id === apiId);
          if (api) {
            switch (api.type_auth) {
              case TYPE_BASIC:
                newInputs.push({
                  id: uuidv4(),
                  type: TYPE_CONNECTIONS.STRING,
                  type_set: TYPE_SET_CONNECTIONS.SINGLE,
                  name: api.auth_params.basic_username_fieldname,
                  custom: true,
                  from: [],
                });
                newInputs.push({
                  id: uuidv4(),
                  type: TYPE_CONNECTIONS.STRING,
                  type_set: TYPE_SET_CONNECTIONS.SINGLE,
                  name: api.auth_params.basic_password_fieldname,
                  custom: true,
                  from: [],
                });
                break;
              default:
                break;
            }
          }
        }
      }
        break;
      default:
        break;
    }

    setInputs(newInputs);
    setOutputs(newOutputs);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const handleAddOutput = () => {
    setOutputs([
      ...outputs,
      {
        id: uuidv4(),
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: true,
        to: [],
      }]);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const handleAddExecOutput = () => {
    setOutputs([
      ...outputs,
      {
        id: uuidv4(),
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: true,
        to: [],
      }]);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const printNodeDefaultValues = () => {
    const ignoredTypes = [TYPE_CONNECTIONS.EXEC, TYPE_CONNECTIONS.CONVERSATION, TYPE_CONNECTIONS.MESSAGE, TYPE_CONNECTIONS.DOCUMENT];
    const inputsToPrint = inputs.filter((input) => !ignoredTypes.includes(input.type));
    if (!inputsToPrint.length) {
      return null;
    }
    return (
      <div>
        <div className='separator'>
          {literals.defaultValues}
        </div>
        <div className='plr-sm'>
          <div className='input_value'>
            {inputsToPrint.map((input) => (
              <VariableRow key={`variable-row-${input.id}`} input={input} onChange={handleChangeInput} inputs={inputs} />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const handleChangeOutputName = (event) => {
    const id = event.target.getAttribute('data-id');

    const newOutputs = outputs.map((output) => {
      if (output.id === id) {
        return { ...output, name: event.target.value };
      }
      return output;
    });

    setOutputs(newOutputs);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const handleChangeOutputType = (event) => {
    const id = event.currentTarget.getAttribute('data-id');

    const newOutputs = outputs.map((output) => {
      if (output.id === id) {
        return { ...output, type: event.target.value };
      }
      return output;
    });

    setOutputs(newOutputs);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const handleChangeOutputTypeSet = (event) => {
    const id = event.currentTarget.getAttribute('data-id');

    const newOutputs = outputs.map((output) => {
      if (output.id === id) {
        return { ...output, type_set: event.target.value };
      }
      return output;
    });

    setOutputs(newOutputs);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const handleDeleteOutput = (event) => {
    const id = event.currentTarget.getAttribute('data-id');
    const newOutputs = outputs.filter((output) => output.id !== id);
    setOutputs(newOutputs);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const printCustomOutputs = () => {
    if (typesWithCustomOutputs.includes(node.data.type)) {
      return (
        <div>
          <div className='separator'>
            {literals.customOutputs}
          </div>
          <div className='plr-sm'>
            {outputs.map((output) => {
              if (!output.custom) {
                return null;
              }
              return (
                <div className='flex gap-1' key={output.id}>
                  <div className='input_value flex-grow'>
                    <input
                      type='text'
                      value={output.name}
                      className='form_input_stl'
                      placeholder={literals.value}
                      data-id={output.id}
                      onChange={handleChangeOutputName}
                    />
                  </div>
                  {output.type !== TYPE_CONNECTIONS.EXEC && (
                    <>
                      <div className='input_value flex-grow'>
                        <select className='form_select_stl' value={output.type} data-id={output.id} onChange={handleChangeOutputType}>
                          {Object.keys(TYPE_CONNECTIONS).map((key) => {
                            if (variablesNotSelectables.includes(TYPE_CONNECTIONS[key])) {
                              return null;
                            }
                            return (
                              <option key={key} value={TYPE_CONNECTIONS[key]}>
                                {TYPE_CONNECTIONS[key]}
                              </option>
                            );
                          })}
                          {datastructures.map((datastructure) => (
                            <option key={datastructure.id} value={`DATASTRUCTURE-${datastructure.id}`}>
                              {datastructure.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='input_value flex-grow'>
                        <select className='form_select_stl' value={output.type_set} data-id={output.id} onChange={handleChangeOutputTypeSet}>
                          {Object.keys(TYPE_SET_CONNECTIONS).map((key) => {
                            return (
                              <option key={`${output.id}-${TYPE_SET_CONNECTIONS[key]}`} value={TYPE_SET_CONNECTIONS[key]}>
                                {TYPE_SET_CONNECTIONS[key]}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </>
                  )}
                  <div>
                    <button
                      type='button'
                      className='btn btn-xs btn-secondary'
                      data-id={output.id}
                      onClick={handleDeleteOutput}
                    >
                      <i className='fa-solid fa-trash-can mx-0' aria-label={literals.common.delete} />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <button type='button' className='btn btn-secondary btn-sm' onClick={typeWithExecOutput.includes(node.data.type) ? handleAddExecOutput : handleAddOutput}>{literals.addOutput}</button>
          </div>
        </div>
      );
    }
    return null;
  };

  const handleShowCodeModal = () => {
    setShowCodeModal(true);
  };

  const handleCloseClodeModal = () => {
    setShowCodeModal(false);
  };

  const handleSaveCodeModal = (code) => {
    setExtraInfo({ ...extraInfo, code });
    setShowCodeModal(false);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const printCodeModal = () => {
    if (showCodeModal) {
      return (
        <CodeModal
          code={extraInfo?.code}
          onSave={handleSaveCodeModal}
          onClose={handleCloseClodeModal}
        />
      );
    }
    return null;
  };

  const addReturnNodeResponse = () => {
    setInputs([
      ...inputs,
      {
        id: uuidv4(),
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: true,
        from: [],
      },
    ]);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const handleChangeCustomInputName = (event) => {
    const id = event.target.getAttribute('data-id');

    const newInputs = inputs.map((input) => {
      if (input.id === id) {
        return { ...input, name: event.target.value };
      }
      return input;
    });

    setInputs(newInputs);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const handleChangeCustomInputType = (event) => {
    const id = event.target.getAttribute('data-id');

    const newInputs = inputs.map((input) => {
      if (input.id === id) {
        return { ...input, type: event.target.value };
      }
      return input;
    });

    setInputs(newInputs);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const handleChangeCustomInputTypeSet = (event) => {
    const id = event.target.getAttribute('data-id');

    const newInputs = inputs.map((input) => {
      if (input.id === id) {
        return { ...input, type_set: event.target.value };
      }
      return input;
    });

    setInputs(newInputs);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const handleDeleteCustomInput = (event) => {
    const id = event.currentTarget.getAttribute('data-id');

    const newInputs = inputs.filter((input) => input.id !== id);

    setInputs(newInputs);
    setIsChanged(Math.floor(Math.random() * 100000));
  };

  const printCustomInputs = () => {
    if (typesWithCustomInputs.includes(node.data.type)) {
      const customInputs = inputs.filter((input) => input.custom);
      return (
        <>
          <div className='separator mt-0'>
            {literals.customInputs}
          </div>
          {customInputs.map((variable) => {
            return (
              <div className='flex gap-1' key={variable.id}>
                <div className='input_value flex-grow'>
                  <input
                    type='text'
                    value={variable.name}
                    className='form_input_stl'
                    placeholder={literals.value}
                    data-id={variable.id}
                    onChange={handleChangeCustomInputName}
                  />
                </div>
                <div className='input_value flex-grow'>
                  <select className='form_select_stl' value={variable.type} data-id={variable.id} onChange={handleChangeCustomInputType}>
                    {Object.keys(TYPE_CONNECTIONS).map((key) => {
                      if (variablesNotSelectablesInCustomInputs.includes(TYPE_CONNECTIONS[key])) {
                        return null;
                      }
                      return (
                        <option key={key} value={TYPE_CONNECTIONS[key]}>
                          {TYPE_CONNECTIONS[key]}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className='input_value flex-grow'>
                  <select className='form_select_stl' value={variable.type_set} data-id={variable.id} onChange={handleChangeCustomInputTypeSet}>
                    {Object.keys(TYPE_SET_CONNECTIONS).map((key) => {
                      return (
                        <option key={`${variable.id}-${TYPE_SET_CONNECTIONS[key]}`} value={TYPE_SET_CONNECTIONS[key]}>
                          {TYPE_SET_CONNECTIONS[key]}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-xs btn-secondary'
                    data-id={variable.id}
                    onClick={handleDeleteCustomInput}
                  >
                    <i className='fa-solid fa-trash-can mx-0' aria-label={literals.common.delete} />
                  </button>
                </div>
              </div>
            );
          })}
          <div className='flex gap-2 w-full'>
            <div className='flex-grow'>
              <button type='button' className='btn btn-secondary btn-sm w-full' onClick={addReturnNodeResponse}>{literals.addInput}</button>
            </div>
          </div>
        </>
      );
    }

    return null;
  };

  const printCustomSettings = () => {
    switch (node.data.type) {
      case NODE_TYPES.CODE:
        return (
          <button type='button' className='btn btn-secondary btn-md' onClick={handleShowCodeModal}>{literals.editCode}</button>
        );
      default:
        return null;
    }
  };

  const printNodeSettings = () => {
    const { data } = node;
    if (!data) {
      return null;
    }
    return (
      <>
        {printCodeModal()}
        <div className='separator mt-0'>
          {literals.nodeSettings}
        </div>
        <div className='plr-sm'>
          <div className='input_title'>
            {literals.common.name}
          </div>
          <div className='input_value'>
            <input type='text' value={name} className='form_input_stl' onChange={handleChangeName} />
          </div>
          <div className='input_title'>
            <Form.Check
              type='switch'
              id='disable-debug-switch'
              label={literals.excludeFromDebug}
              onChange={handleChangeDisableDebug}
              checked={disableDebug}
            />
          </div>
          <div className='input_title'>
            <Form.Check
              type='switch'
              id='disable-statistics-switch'
              label={literals.excludeFromStatistics}
              onChange={handleChangeDisableStatistics}
              checked={disableStatistics}
            />
          </div>
        </div>
        {printCustomSettings()}
        {printCustomInputs()}
        {printNodeDefaultValues()}
        {printCustomOutputs()}
        <div className='form_buttons'>
          <button disabled={!isChanged} type='button' className='btn btn-primary' onClick={handleUpdateNode}>
            {isChanged ? literals.common.apply : literals.common.saved}
          </button>
        </div>
      </>
    );
  };

  /* *************************************************************************** */
  /*                        ORCHESTRATOR SETTINGS                                */
  /* *************************************************************************** */

  const handleAddOrchestratorFunction = () => {
    setSelectedFunctionToEdit(null);
    setShowFunctionsModal(true);
  };

  const handleFunctionGoTo = (event) => {
    const functionId = event.currentTarget.getAttribute('data-id');
    onGoToFunction(functionId);
  };

  const handleFunctionEdit = (event) => {
    const functionId = event.currentTarget.getAttribute('data-id');
    setSelectedFunctionToEdit(functionId);
    setShowFunctionsModal(true);
  };

  const handleFunctionDelete = (event) => {
    const functionId = event.currentTarget.getAttribute('data-id');
    setSelectedFunctionToDelete(functionId);
  };

  const handleChangeOrchestratorVariableName = (event) => {
    const id = event.target.getAttribute('data-id');

    const newVariables = orchestratorVariables.map((variable) => {
      if (variable.id === id) {
        return { ...variable, name: event.target.value };
      }
      return variable;
    });

    setOrchestratorVariables(newVariables);
    setIsChangedOrchestrator(true);
  };

  const handleChangeFunctionVariableName = (event) => {
    const id = event.target.getAttribute('data-id');

    const newVariables = functionVariables.map((variable) => {
      if (variable.id === id) {
        return { ...variable, name: event.target.value };
      }
      return variable;
    });

    setFunctionVariables(newVariables);
    setIsChangedOrchestrator(true);
  };

  const handleChangeOrchestratorVariableType = (event) => {
    const id = event.target.getAttribute('data-id');

    const newVariables = orchestratorVariables.map((variable) => {
      if (variable.id === id) {
        return { ...variable, type: event.target.value };
      }
      return variable;
    });

    setOrchestratorVariables(newVariables);
    setIsChangedOrchestrator(true);
  };

  const handleChangeFunctionVariableType = (event) => {
    const id = event.target.getAttribute('data-id');

    const newVariables = functionVariables.map((variable) => {
      if (variable.id === id) {
        return { ...variable, type: event.target.value };
      }
      return variable;
    });

    setFunctionVariables(newVariables);
    setIsChangedOrchestrator(true);
  };

  const handleChangeOrchestratorVariableTypeSet = (event) => {
    const id = event.target.getAttribute('data-id');

    const newVariables = orchestratorVariables.map((variable) => {
      if (variable.id === id) {
        return { ...variable, type_set: event.target.value };
      }
      return variable;
    });

    setOrchestratorVariables(newVariables);
    setIsChangedOrchestrator(true);
  };

  const handleChangeFunctionVariableTypeSet = (event) => {
    const id = event.target.getAttribute('data-id');

    const newVariables = functionVariables.map((variable) => {
      if (variable.id === id) {
        return { ...variable, type_set: event.target.value };
      }
      return variable;
    });

    setFunctionVariables(newVariables);
    setIsChangedOrchestrator(true);
  };

  const handleDeleteOrchestratorVariable = (event) => {
    const id = event.currentTarget.getAttribute('data-id');

    const newVariables = orchestratorVariables.filter((variable) => variable.id !== id);

    setOrchestratorVariables(newVariables);
    setIsChangedOrchestrator(true);
  };

  const handleDeleteFunctionVariable = (event) => {
    const id = event.currentTarget.getAttribute('data-id');

    const newVariables = functionVariables.filter((variable) => variable.id !== id);

    setFunctionVariables(newVariables);
    setIsChangedOrchestrator(true);
  };

  const handleAddOrchestratorVariable = () => {
    setOrchestratorVariables([
      ...orchestratorVariables,
      {
        id: uuidv4(),
        name: '',
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
      }]);
    setIsChangedOrchestrator(true);
  };

  const handleAddFunctionVariable = () => {
    setFunctionVariables([
      ...functionVariables,
      {
        id: uuidv4(),
        name: '',
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
      }]);
    setIsChangedOrchestrator(true);
  };

  const handleChangeOrchestratorVersion = (event) => {
    const versionId = event.target.value;
    onChangeOrchestratorVersion(versionId);
  };

  const printOrchestratorVersions = () => {
    const orchestratorAux = selectedFunction ? orchestrator.functions.find((f) => f.id === selectedFunction) : orchestrator;
    const selectedVersionAux = selectedFunction ? selectedVersionFunction : selectedVersion;
    return (
      <>
        <div className='separator mt-0'>
          {literals.versions}
        </div>
        <div className='input_value flex-grow'>
          <select className='form_select_stl' onChange={handleChangeOrchestratorVersion} value={selectedVersionAux}>
            {orchestratorAux.versions.map((version, k) => (
              <option key={version.id} value={version.id}>
                {`${k + 1}. ${version.name}`}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button type='button' className='btn btn-secondary btn-sm mb-4' onClick={onManageOrchestratorVersions}>{literals.manageVersions}</button>
        </div>
      </>
    );
  };

  const printOrchestratorFunctions = () => {
    return (
      <>
        <div className='separator mt-0'>
          {literals.functions}
        </div>
        {orchestrator.functions.map((f) => {
          return (
            <div className='flex gap-1 mb-1' key={f.id}>
              <div className='input_title flex-grow break-all'>
                {f.name}
              </div>
              <div className='whitespace-nowrap'>
                <button
                  type='button'
                  className='btn btn-xs btn-primary'
                  data-id={f.id}
                  onClick={handleFunctionGoTo}
                >
                  <i className='fa-solid fa-square-arrow-up-right mx-0' aria-label={literals.common.access} />
                </button>
                <button
                  type='button'
                  className='btn btn-xs btn-secondary'
                  data-id={f.id}
                  onClick={handleFunctionEdit}
                >
                  <i className='fa-solid fa-pen mx-0' aria-label={literals.common.edit} />
                </button>
                <button
                  type='button'
                  className='btn btn-xs btn-danger'
                  data-id={f.id}
                  onClick={handleFunctionDelete}
                >
                  <i className='fa-solid fa-trash-can mx-0' aria-label={literals.common.delete} />
                </button>
              </div>
            </div>
          );
        })}
        <div>
          <button type='button' className='btn btn-secondary btn-sm mb-4' onClick={handleAddOrchestratorFunction}>{literals.addFunction}</button>
        </div>
      </>
    );
  };

  const printOrchestratorVariables = () => {
    return (
      <>
        <div className='separator mt-0'>
          {literals.variables}
        </div>
        {orchestratorVariables.map((variable) => {
          return (
            <div className='flex gap-1' key={variable.id}>
              <div className='input_value flex-grow'>
                <input
                  type='text'
                  value={variable.name}
                  className='form_input_stl'
                  placeholder={literals.value}
                  data-id={variable.id}
                  onChange={handleChangeOrchestratorVariableName}
                />
              </div>
              <div className='input_value flex-grow'>
                <select className='form_select_stl' value={variable.type} data-id={variable.id} onChange={handleChangeOrchestratorVariableType}>
                  {Object.keys(TYPE_CONNECTIONS).map((key) => {
                    if (variablesNotSelectables.includes(TYPE_CONNECTIONS[key])) {
                      return null;
                    }
                    return (
                      <option key={key} value={TYPE_CONNECTIONS[key]}>
                        {TYPE_CONNECTIONS[key]}
                      </option>
                    );
                  })}
                  {datastructures.map((datastructure) => (
                    <option key={datastructure.id} value={`DATASTRUCTURE-${datastructure.id}`}>
                      {datastructure.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='input_value flex-grow'>
                <select className='form_select_stl' value={variable.type_set} data-id={variable.id} onChange={handleChangeOrchestratorVariableTypeSet}>
                  {Object.keys(TYPE_SET_CONNECTIONS).map((key) => {
                    return (
                      <option key={`${variable.id}-${TYPE_SET_CONNECTIONS[key]}`} value={TYPE_SET_CONNECTIONS[key]}>
                        {TYPE_SET_CONNECTIONS[key]}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <button
                  type='button'
                  className='btn btn-xs btn-danger'
                  data-id={variable.id}
                  onClick={handleDeleteOrchestratorVariable}
                >
                  <i className='fa-solid fa-trash-can mx-0' aria-label={literals.common.delete} />
                </button>
              </div>
            </div>
          );
        })}
        <div>
          <button type='button' className='btn btn-secondary btn-sm' onClick={handleAddOrchestratorVariable}>{literals.addVariable}</button>
        </div>
      </>
    );
  };

  const printFunctionLocalVariables = () => {
    if (!selectedFunction) {
      return null;
    }

    return (
      <>
        <div className='separator mt-0'>
          {literals.localVariables}
        </div>
        {functionVariables.map((variable) => {
          return (
            <div className='flex gap-1' key={variable.id}>
              <div className='input_value flex-grow'>
                <input
                  type='text'
                  value={variable.name}
                  className='form_input_stl'
                  placeholder={literals.value}
                  data-id={variable.id}
                  onChange={handleChangeFunctionVariableName}
                />
              </div>
              <div className='input_value flex-grow'>
                <select className='form_select_stl' value={variable.type} data-id={variable.id} onChange={handleChangeFunctionVariableType}>
                  {Object.keys(TYPE_CONNECTIONS).map((key) => {
                    if (variablesNotSelectables.includes(TYPE_CONNECTIONS[key])) {
                      return null;
                    }
                    return (
                      <option key={key} value={TYPE_CONNECTIONS[key]}>
                        {TYPE_CONNECTIONS[key]}
                      </option>
                    );
                  })}
                  {datastructures.map((datastructure) => (
                    <option key={datastructure.id} value={`DATASTRUCTURE-${datastructure.id}`}>
                      {datastructure.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='input_value flex-grow'>
                <select className='form_select_stl' value={variable.type_set} data-id={variable.id} onChange={handleChangeFunctionVariableTypeSet}>
                  {Object.keys(TYPE_SET_CONNECTIONS).map((key) => {
                    return (
                      <option key={`${variable.id}-${TYPE_SET_CONNECTIONS[key]}`} value={TYPE_SET_CONNECTIONS[key]}>
                        {TYPE_SET_CONNECTIONS[key]}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <button
                  type='button'
                  className='btn btn-xs btn-danger'
                  data-id={variable.id}
                  onClick={handleDeleteFunctionVariable}
                >
                  <i className='fa-solid fa-trash-can mx-0' aria-label={literals.common.delete} />
                </button>
              </div>
            </div>
          );
        })}
        <div>
          <button type='button' className='btn btn-secondary btn-sm mb-4' onClick={handleAddFunctionVariable}>{literals.addVariable}</button>
        </div>
      </>
    );
  };

  const handleChangeOrchestratorVariable = (variable) => {
    const newVariables = orchestratorVariables.map((variableItem) => {
      if (variableItem.id === variable.id) {
        return variable;
      }
      return variableItem;
    });

    setOrchestratorVariables(newVariables);
    setIsChangedOrchestrator(true);
  };

  const handleChangeFunctionVariable = (variable) => {
    const newVariables = functionVariables.map((variableItem) => {
      if (variableItem.id === variable.id) {
        return variable;
      }
      return variableItem;
    });

    setFunctionVariables(newVariables);
    setIsChangedOrchestrator(true);
  };

  const printOrchestratorDefaultValues = () => {
    const ignoredTypes = [TYPE_CONNECTIONS.EXEC, TYPE_CONNECTIONS.DOCUMENT, TYPE_CONNECTIONS.CONVERSATION, TYPE_CONNECTIONS.MESSAGE];
    datastructures.forEach((datastructure) => {
      ignoredTypes.push(`DATASTRUCTURE-${datastructure.id}`);
    });
    const variablesToPrint = orchestratorVariables.filter((variable) => !ignoredTypes.includes(variable.type));
    if (!variablesToPrint.length) {
      return null;
    }
    return (
      <div>
        <div className='separator'>
          {literals.defaultValues}
        </div>
        <div className='plr-sm'>
          <div className='input_value'>
            {variablesToPrint.map((variable) => {
              return (<VariableRow key={`variable-row-${variable.id}`} input={variable} onChange={handleChangeOrchestratorVariable} inputs={orchestratorVariables} />);
            })}
          </div>
        </div>
      </div>
    );
  };

  const printFunctionDefaultValues = () => {
    if (!selectedFunction) {
      return null;
    }

    const ignoredTypes = [TYPE_CONNECTIONS.EXEC, TYPE_CONNECTIONS.DOCUMENT, TYPE_CONNECTIONS.CONVERSATION, TYPE_CONNECTIONS.MESSAGE];
    datastructures.forEach((datastructure) => {
      ignoredTypes.push(`DATASTRUCTURE-${datastructure.id}`);
    });
    const variablesToPrint = functionVariables.filter((variable) => !ignoredTypes.includes(variable.type));
    if (!variablesToPrint.length) {
      return null;
    }
    return (
      <div>
        <div className='separator mt-0'>
          {literals.localVariablesDefaultValues}
        </div>
        <div className='plr-sm'>
          <div className='input_value'>
            {variablesToPrint.map((variable) => {
              return (<VariableRow key={`variable-row-${variable.id}`} input={variable} onChange={handleChangeFunctionVariable} inputs={orchestratorVariables} />);
            })}
          </div>
        </div>
      </div>
    );
  };

  const handleUpdateOrchestrator = () => {
    if (selectedFunction) {
      const functionItem = orchestrator.functions.find((item) => item.id === selectedFunction);
      onUpdateOrchestrator({
        ...functionItem,
        variables: functionVariables,
      });
    } else {
      onUpdateOrchestrator({
        ...orchestrator,
        variables: orchestratorVariables,
      });
    }
  };

  useEffect(() => {
    if (isChangedOrchestrator) {
      if (toSaveOrchestrator) {
        clearTimeout(toSaveOrchestrator);
      }
      if (orchestrator) {
        toSaveOrchestrator = setTimeout(() => {
          handleUpdateOrchestrator();
          setIsChangedOrchestrator(false);
        }, 1000);
      }
    }
  }, [orchestratorVariables, functionVariables, isChangedOrchestrator]);

  const printOrchestratorSettings = () => {
    return (
      <>
        <div className='separator mt-0'>
          {literals.orchestratorSettings}
        </div>
        {printOrchestratorVersions()}
        {printOrchestratorFunctions()}
        {printFunctionLocalVariables()}
        {printFunctionDefaultValues()}
        {printOrchestratorVariables()}
        {printOrchestratorDefaultValues()}
        <div className='form_buttons'>
          <button disabled={!isChangedOrchestrator} type='button' className='btn btn-primary' onClick={handleUpdateOrchestrator}>{literals.common.apply}</button>
        </div>
      </>
    );
  };

  const closeFunctionModal = () => {
    setShowFunctionsModal(false);
    setSelectedFunctionToEdit(null);
  };

  const printDeleteFunctionModal = () => {
    if (!selectedFunctionToDelete) {
      return null;
    }

    const functionItem = orchestrator.functions.find((item) => item.id === selectedFunctionToDelete);
    if (!functionItem) {
      return null;
    }

    return (
      <ConfirmModal
        open
        title={functionItem.name}
        message={literals.deleteFunctionMessage}
        onSubmit={async () => {
          try {
            await deleteOrchestratorFunction(orchestrator.id, selectedFunctionToDelete);
            const newFunctions = orchestrator.functions.filter((item) => item.id !== selectedFunctionToDelete);
            onUpdateOrchestrator({ ...orchestrator, functions: newFunctions });
            setSelectedFunctionToDelete(null);
          } catch (err) {
            const errorMessage = getErrorMessage(err);
            showErrorModal(errorMessage);
          }
        }}
        onClose={() => setSelectedFunctionToDelete(null)}
      />
    );
  };

  const printFunctionModal = () => {
    if (!showFunctionsModal) {
      return null;
    }

    let functionName = '';

    if (selectedFunctionToEdit) {
      const functionItem = orchestrator.functions.find((item) => item.id === selectedFunctionToEdit);
      if (functionItem) {
        functionName = functionItem.name;
      }
    }

    return (
      <FunctionModal
        orchestrator={orchestrator}
        onUpdateOrchestrator={onUpdateOrchestrator}
        functionId={selectedFunctionToEdit}
        name={functionName}
        onClose={closeFunctionModal}
      />
    );
  };

  useEffect(() => {
    if (node) {
      if (node.id !== nodeIdPrevious.current) {
        setIsChanged(0);
        setName(node.data.label);
        setInputs(node.data.inputs);
        setOutputs(node.data.outputs);
        setExtraInfo(node.data.extra_info);
        setDisableStatistics(node.data.disable_statistics);
        setDisableDebug(node.data.disable_debug);
      }
    } else {
      setIsChanged(0);
      setName('');
      setInputs([]);
      setOutputs([]);
      setExtraInfo({});
      setDisableStatistics(false);
      setDisableDebug(false);
    }
    nodeIdPrevious.current = node?.id;
  }, [node]);

  return (
    <ResizableBox
      width={300}
      axis='x'
      resizeHandles={['w']}
      minConstraints={[200, 600]} // Mínimo tamaño permitido
      maxConstraints={[600, 600]} // Máximo tamaño permitido
    >
      <div className='orchestrator__node-settings-panel'>
        {printErrorModal()?.modal}
        {printFunctionModal()}
        {printDeleteFunctionModal()}
        {node ? printNodeSettings() : printOrchestratorSettings()}
      </div>
    </ResizableBox>
  );
}

OverviewPanel.propTypes = {
  orchestrator: PropTypes.object.isRequired,
  node: PropTypes.object,
  onUpdateNode: PropTypes.func.isRequired,
  onUpdateOrchestrator: PropTypes.func.isRequired,
  onManageOrchestratorVersions: PropTypes.func.isRequired,
  onChangeOrchestratorVersion: PropTypes.func.isRequired,
  selectedVersion: PropTypes.string.isRequired,
  onGoToFunction: PropTypes.func.isRequired,
  selectedFunction: PropTypes.string,
  selectedVersionFunction: PropTypes.string,
};

OverviewPanel.defaultProps = {
  node: null,
  selectedFunction: null,
  selectedVersionFunction: null,
};

export default OverviewPanel;
