import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import Logo from 'assets/img/logo_llms.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MENU_SECTIONS from 'constants/sections';
// import { getOrganizationLogo } from 'modules/organizations/actions';

const LogoComponent = memo(({ actualOrganization }) => {
  if (actualOrganization && actualOrganization.logoContent) {
    return (<img src={actualOrganization.logoContent} alt='logo' />);
  }

  return (<img src={Logo} alt='logo' />);
});

LogoComponent.propTypes = {
  actualOrganization: PropTypes.shape({
    logoContent: PropTypes.string,
  }),
};

LogoComponent.defaultProps = {
  actualOrganization: {
    logoContent: '',
  },
};

function LateralMenu(props) {
  const { selectedSection } = props;

  const literals = useLiterals('layout');
  const navigate = useNavigate();
  const { actualOrganization } = useSelector((state) => state.app);

  const printSection = () => {
    return MENU_SECTIONS.map((section) => {
      const isSelected = section.value === selectedSection;

      return (
        <div
          className={`option ${isSelected ? 'selected' : ''}`}
          data-cy={`menu-${section.value}`}
          key={section.value}
          onClick={() => {
            navigate(section.path);
          }}
        >
          <i className={`icon ${section.icon}`} />
          {literals[section.name]}
        </div>
      );
    });
  };

  return (
    <div className='left_menu'>
      <div className='logo'>
        <LogoComponent actualOrganization={actualOrganization} />
      </div>
      <div className='options'>
        {printSection()}
      </div>
    </div>
  );
}

LateralMenu.propTypes = {
  selectedSection: PropTypes.string,
};

LateralMenu.defaultProps = {
  selectedSection: '',
};

export default LateralMenu;
