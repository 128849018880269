import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useLiterals from 'hooks/useLiterals';
import { useNavigate } from 'react-router-dom';
import { loadingActions } from 'modules/loading';
import {
  exportOrchestrator, importOrchestrator,
  deleteOrchestrator, duplicateOrchestrator,
} from 'modules/orchestrators/actions';
import useErrorHandling from 'hooks/useErrorHandling';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownMenuToggle from 'components/DropdownMenuToggle';
import { ORCHESTRATOR_TYPES, ORCHESTRATOR_TYPE_CONVERSATION } from 'constants/orchestrator';
import { getErrorMessage } from 'utils/errors';
import { transformDate } from 'utils/dates';
import Layout from 'components/Layout';
import ConfirmModal from 'components/ConfirmModal';
import CreateOrchestratorModalForm from './components/CreateOrchestratorModalForm';

function Orchestrators() {
  const literals = useLiterals('orchestrators');
  const dispatch = useDispatch();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [orchestratorSelected, setOrchestratorSelected] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorForm, setErrorForm] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  const { orchestrators } = useSelector((state) => state.orchestrators);
  const { actualOrganization } = useSelector((state) => state.app);

  const fileInputRef = useRef(null);

  const { showErrorModal, printErrorModal } = useErrorHandling();

  const createNewOrchestrator = () => {
    setShowCreateForm(true);
    setErrorForm(null);
  };

  const closeCreateForm = () => {
    setShowCreateForm(false);
    setShowEditModal(false);
    setOrchestratorSelected(null);
  };

  const handleEditOrchestrator = (orchestratorId) => {
    setShowEditModal(true);
    setOrchestratorSelected(orchestrators.find((orchestrator) => orchestrator.id === orchestratorId));
  };

  const handleDuplicateOrchestrator = (orchestratorId) => {
    setShowDuplicateModal(true);
    setOrchestratorSelected(orchestrators.find((orchestrator) => orchestrator.id === orchestratorId));
  };

  const handleDeleteOrchestrator = (orchestratorId) => {
    setShowDeleteModal(true);
    setOrchestratorSelected(orchestrators.find((orchestrator) => orchestrator.id === orchestratorId));
  };

  const closeDuplicateModal = () => {
    setShowDuplicateModal(false);
    setOrchestratorSelected(null);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setOrchestratorSelected(null);
  };

  const confirmDeleteOrchestrator = async () => {
    closeDeleteModal();
    try {
      dispatch(loadingActions.show());
      await dispatch(deleteOrchestrator(orchestratorSelected?.id));
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    } finally {
      dispatch(loadingActions.hide());
    }
  };

  const confirmDuplicateOrchestrator = async () => {
    closeDuplicateModal();
    try {
      dispatch(loadingActions.show());
      await dispatch(duplicateOrchestrator(orchestratorSelected?.id));
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    } finally {
      dispatch(loadingActions.hide());
    }
  };

  const getFilteredOrchestrators = () => {
    if (!orchestrators) {
      return [];
    }

    if (!actualOrganization) {
      return orchestrators;
    }

    if (actualOrganization.id === 'personal') {
      return orchestrators.filter((orchestrator) => !orchestrator.organizations || !orchestrator.organizations.length);
    }

    return orchestrators.filter((orchestrator) => orchestrator.organizations && orchestrator.organizations.includes(actualOrganization.id));
  };

  const handleExportOrchestrator = async (orchestratorId) => {
    const orchestrator = orchestrators.find((o) => o.id === orchestratorId);
    try {
      dispatch(loadingActions.show());
      const response = await exportOrchestrator(orchestratorId);
      const element = document.createElement('a');
      const file = new Blob([JSON.stringify(response)], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = `${orchestrator.name}.json`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    } finally {
      dispatch(loadingActions.hide());
    }
  };

  const printOrchestratorsTable = () => {
    const filteredOrchestrators = getFilteredOrchestrators();
    const rows = filteredOrchestrators?.map((orchestrator) => {
      const type = ORCHESTRATOR_TYPES.find((t) => t.value === orchestrator.type);
      return (
        <tr key={orchestrator.id} data-id={orchestrator.id}>
          <td>{orchestrator?.name}</td>
          <td>{orchestrator?.description}</td>
          <td>{type ? literals[type.label] : orchestrator.type}</td>
          <td>{transformDate(orchestrator?.created_at)}</td>
          <td className='right'>
            <button
              className='btn btn-primary btn-sm'
              type='button'
              onClick={() => navigate(`/orchestrators/${orchestrator.id}`)}
            >
              {literals.common.access}
            </button>
            <Dropdown className='dropdown-menu-expand'>
              <Dropdown.Toggle as={DropdownMenuToggle} id={`dropdown-custom-components-${orchestrator.id}`} />
              <Dropdown.Menu>
                {orchestrator.type === ORCHESTRATOR_TYPE_CONVERSATION && (
                  <Dropdown.Item
                    eventKey='1'
                    onClick={() => navigate(`/orchestrators/${orchestrator.id}/conversations`)}
                  >
                    {literals.conversations}
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  eventKey='2'
                  onClick={() => navigate(`/orchestrators/${orchestrator.id}/statistics`)}
                >
                  {literals.statistics}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey='3'
                  onClick={() => handleEditOrchestrator(orchestrator?.id)}
                >
                  {literals.common.edit}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey='4'
                  onClick={() => handleDuplicateOrchestrator(orchestrator?.id)}
                >
                  {literals.common.duplicate}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey='5'
                  onClick={() => handleExportOrchestrator(orchestrator?.id)}
                >
                  {literals.common.export}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey='6'
                  onClick={() => handleDeleteOrchestrator(orchestrator?.id)}
                  className='text-danger'
                >
                  {literals.common.delete}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });

    return (
      <div>
        <table className='table_stl1'>
          <thead>
            <tr>
              <th>{literals.common.name}</th>
              <th>{literals.common.description}</th>
              <th>{literals.common.type}</th>
              <th>{literals.date}</th>
              <th className='right'>{literals.common.options}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const printDuplicateModal = () => {
    if (!showDuplicateModal) {
      return null;
    }

    const title = `${literals.confirmDuplicateOrchestrator} ${orchestratorSelected?.name}?`;

    return (
      <ConfirmModal
        open
        onClose={closeDuplicateModal}
        onSubmit={confirmDuplicateOrchestrator}
        title={title}
      />
    );
  };

  const printNoElements = () => {
    return (
      <div className='page_content'>
        <div className='no_elements'>{literals.thereIsNoOrchestrators}</div>
      </div>
    );
  };

  const printDeleteModal = () => {
    const title = `${literals.confirmDeleteOrchestrator} ${orchestratorSelected?.name}?`;
    return (
      <ConfirmModal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        onSubmit={confirmDeleteOrchestrator}
        title={title}
      />
    );
  };

  const handleImportOrchestrator = () => {
    if (!isUploading) {
      fileInputRef.current.click();
    }
  };

  const handleImportFileChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    const organizations = [];

    if (actualOrganization && actualOrganization.id !== 'personal') {
      organizations.push(actualOrganization.id);
    }

    formData.append('organizations', JSON.stringify(organizations));

    try {
      setIsUploading(true);
      await dispatch(importOrchestrator(formData));
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <>
      {printErrorModal()?.modal}
      <CreateOrchestratorModalForm
        literals={literals}
        showCreateForm={showCreateForm || showEditModal}
        onClose={closeCreateForm}
        orchestrator={orchestratorSelected}
      />
      {printDuplicateModal()}
      {printDeleteModal()}
      <Layout selectedSection='orchestrators'>
        <div className='section'>
          <div className='section_header'>
            <h1>{literals.title}</h1>
            <div>
              <input type='file' ref={fileInputRef} onChange={handleImportFileChange} style={{ display: 'none' }} accept='application/json' />
              <button type='button' onClick={handleImportOrchestrator} className='btn btn-secondary' disabled={isUploading}>
                {isUploading && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
                {literals.common.import}
              </button>
              <button type='button' className='btn btn-primary' onClick={createNewOrchestrator}>{literals.createOrchestrator}</button>
            </div>

          </div>
          {orchestrators?.length > 0 && (
            <div className='mt-lg'>
              {getFilteredOrchestrators().length ? printOrchestratorsTable() : printNoElements()}
            </div>
          )}
        </div>
      </Layout>
    </>
  );
}

export default Orchestrators;
