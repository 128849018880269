import {
  ADD_UPDATED_CONVERSATION, DELETE_UPDATED_CONVERSATION, ADD_DELETED_CONVERSATION,
  DELETE_DELETED_CONVERSATION, ADD_TRANSFERED_CONVERSATION, DELETE_TRANSFERED_CONVERSATION,
} from './index';
import Api from './repository';

export const getConversations = async (chatbotId, offset = 0, limit = 100) => {
  const response = await Api.getConversations(chatbotId, offset, limit);
  return response;
};

export const getConversation = async (chatbotId, conversationId) => {
  const response = await Api.getConversation(chatbotId, conversationId);
  return response;
};

export const getConversationsStats = async (orchestratorId, offset, limit, sortType, sortField, startDate, endDate, search) => {
  const response = await Api.getConversationsStats(orchestratorId, offset, limit, sortType, sortField, startDate, endDate, search);
  return response;
};

export const downloadCSV = async (chatbotId, startDate, endDate) => {
  const response = await Api.downloadCSV(chatbotId, 'DOWNLOAD_CSV', startDate, endDate);
  console.log(response);
  let csv = '';
  csv += 'Conversation ID,Conversation_name, Ticket ID,Total Messages,Total Price,Whatsapp,Date,Messages\n'; // Agregamos la nueva columna "Mensajes"
  response?.conversations.forEach((conversation) => {
    let whatsapp = '';
    if (conversation?.conversation?.contact
      && conversation?.conversation?.contact?.twilio
      && conversation?.conversation?.contact?.twilio?.length > 1
      && conversation?.conversation?.contact?.twilio[0] === 'whatsapp') {
      whatsapp = conversation?.conversation?.contact?.twilio[1];
    }
    csv += `${conversation?.conversation?.id},${conversation?.conversation_name},${conversation?.conversation?.ticket_id},${conversation.total_messages},${conversation.total_price},${whatsapp},${conversation?.conversation?.created_at},"`; // Agregamos las columnas existentes
    // Iteramos sobre los mensajes y los agregamos al CSV
    conversation?.messages?.forEach((message) => {
      csv += `${message?.content}___%MSG_FIELD%___${message?.created_at}___%MSG_FIELD%___${message?.role}___%MSG%___`; // Agregamos el contenido del mensaje y un salto de línea
    });
    csv += '"\n'; // Cerramos la celda y la fila
  });
  return csv;
};

export const createConversation = async (id, chatbotId, name, variables) => {
  const response = await Api.createConversation(id, chatbotId, name, variables);
  return response;
};

export const updateConversation = async (chatbotId, conversationId, data) => {
  const response = await Api.updateConversation(chatbotId, conversationId, data);
  return response;
};

export const deleteConversation = async (chatbotId, conversationId) => {
  const response = await Api.deleteConversation(chatbotId, conversationId);
  return response;
};

export const deleteConversations = async (chatbotId) => {
  const response = await Api.deleteConversations(chatbotId);
  return response;
};

export const addUpdatedConversation = (conversation) => (dispatch) => {
  dispatch({
    type: ADD_UPDATED_CONVERSATION,
    payload: conversation,
  });
};

export const deleteUpdatedConversation = (conversationId) => (dispatch) => {
  dispatch({
    type: DELETE_UPDATED_CONVERSATION,
    payload: conversationId,
  });
};

export const addDeletedConversation = (conversationId) => (dispatch) => {
  dispatch({
    type: ADD_DELETED_CONVERSATION,
    payload: conversationId,
  });
};

export const deleteDeletedConversation = (conversationId) => (dispatch) => {
  dispatch({
    type: DELETE_DELETED_CONVERSATION,
    payload: conversationId,
  });
};

export const addTransferedConversation = (conversation) => (dispatch) => {
  dispatch({
    type: ADD_TRANSFERED_CONVERSATION,
    payload: conversation,
  });
};

export const deleteTransferedConversation = (conversation) => (dispatch) => {
  dispatch({
    type: DELETE_TRANSFERED_CONVERSATION,
    payload: conversation,
  });
};
