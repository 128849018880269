import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout';
import { useSelector, useDispatch } from 'react-redux';
import useLiterals from 'hooks/useLiterals';
import { fetchForms } from 'modules/forms/actions';
import List from './components/List';
import Form from './components/Form';

const LIST_VIEW = 'list';
const EDIT_VIEW = 'edit';

function Forms() {
  const [actualView, setActualView] = useState(LIST_VIEW);
  const [selectedForm, setSelectedForm] = useState(null);

  const literals = useLiterals('forms');

  const dispatch = useDispatch();
  const { forms } = useSelector((state) => state.forms);

  useEffect(() => {
    dispatch(fetchForms());
  }, []);

  const handleCreateForm = () => {
    setSelectedForm(null);
    setActualView(EDIT_VIEW);
  };

  const handleEditForm = (formId) => {
    const form = forms.find((f) => f.id === formId);
    if (form) {
      setSelectedForm(form);
      setActualView(EDIT_VIEW);
    }
  };

  const backToList = () => {
    dispatch(fetchForms());
    setActualView(LIST_VIEW);
    setSelectedForm(null);
  };

  const printListView = () => {
    return (
      <List forms={forms} onEdit={handleEditForm} onCreate={handleCreateForm} />
    );
  };

  const printEditView = () => {
    return (
      <Form form={selectedForm} onBack={backToList} />
    );
  };

  const printActualView = () => {
    switch (actualView) {
      case LIST_VIEW:
        return printListView();
      case EDIT_VIEW:
        return printEditView();
      default:
        return null;
    }
  };

  if (!forms) {
    return (
      <Layout selectedSection='forms'>
        <div className='section'>
          <div className='section_header'>
            <h1>{literals.forms}</h1>
          </div>
          <div className='mt-lg'>
            {literals.common.loading}
          </div>
        </div>
      </Layout>
    );
  }

  return printActualView();
}

export default Forms;
