import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

const URLS = {
  getFaqs: (chatbotId) => `chatbots/${chatbotId}/faqs`,
  createFaq: (chatbotId) => `chatbots/${chatbotId}/faqs`,
  importFaqsInChatbot: (chatbotId) => `chatbots/${chatbotId}/faqs/import`,
};

export default {
  async getFaqs(chatbotId, offset = 0, limit = 100) {
    const url = `${URLS.getFaqs(chatbotId)}?offset=${offset}&limit=${limit}`;
    return ApiFetchInstance.get(url);
  },
  async createFaq(chatbotId, data) {
    const params = data;
    return ApiFetchInstance.post(URLS.createFaq(chatbotId), params);
  },
  async deleteFaq(chatbotId, faqId) {
    return ApiFetchInstance.delete(`${URLS.getFaqs(chatbotId)}/${faqId}`);
  },
  async getFaq(chatbotId, faqId) {
    const url = `${URLS.getFaqs(chatbotId)}/${faqId}`;
    return ApiFetchInstance.get(url);
  },
  async updateFaq(chatbotId, faqId, data) {
    const url = `${URLS.getFaqs(chatbotId)}/${faqId}`;
    return ApiFetchInstance.put(url, data);
  },
  async importFaqsInChatbot(chatbotId, formData) {
    return ApiFetchInstance.post(URLS.importFaqsInChatbot(chatbotId), formData);
  },
};
