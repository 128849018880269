import React from 'react';
import PropTypes from 'prop-types';

const MenuToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    className='handler-menu-expand'
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <i className='fa-solid fa-ellipsis-vertical' />
  </div>
));

MenuToggle.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

MenuToggle.defaultProps = {
  children: null,
  onClick: () => { },
};

export default MenuToggle;
