import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ORCHESTRATOR_STEPS } from 'constants/messages';
import useLiterals from 'hooks/useLiterals';

function DebugMessage(props) {
  const [showSteps, setShowSteps] = useState(false);
  const { message } = props;

  const literals = useLiterals('chat');

  const toggleShowSteps = () => {
    setShowSteps(!showSteps);
  };

  const printContent = () => {
    switch (message.type) {
      case ORCHESTRATOR_STEPS:
        return (
          <>
            <i className='icon fa-solid fa-sitemap' />
            {literals.orchestratorTrace}
          </>
        );
      default: {
        const icon = message.extra_info?.icon || 'fa-solid fa-bug';
        return (
          <>
            <i className={icon} />
            {message.content}
          </>
        );
      }
    }
  };

  const printSteps = () => {
    switch (message.type) {
      case ORCHESTRATOR_STEPS:
        return (
          <ul className='steps'>
            {message.steps.map((step) => {
              let result = null;
              if (step.result) {
                switch (typeof step.result) {
                  case 'boolean':
                    result = step.result ? 'true' : 'false';
                    break;
                  case 'object':
                    result = (<pre>{JSON.stringify(step.result, null, 2)}</pre>);
                    break;
                  default:
                    result = step.result;
                }
              }
              return (
                <li className='step' key={step.id}>
                  <div className='step-name'>{`${step.name} (${step.time.toFixed(2)}s)`}</div>
                  {result && (
                    <div className='step-result'>{result}</div>
                  )}
                </li>
              );
            })}
          </ul>
        );
      default:
        return (<pre>{JSON.stringify(message.extra_info, null, 2)}</pre>);
    }
  };

  return (
    <div className='last-step'>
      <div className='content'>
        <div onClick={toggleShowSteps}>
          {printContent()}
          <i className={`fa-solid ${showSteps ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
        </div>
        {showSteps && (printSteps())}
      </div>
    </div>
  );
}

DebugMessage.propTypes = {
  message: PropTypes.object.isRequired,
};

export default DebugMessage;
