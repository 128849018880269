import React from 'react';
import PropTypes from 'prop-types';
import Chat from 'components/Chat';

function Conversation({ chatbotId, conversationId, onClose }) {
  if (!conversationId) {
    return null;
  }
  return (
    <div
      className='debug-chat__container'
      style={{
        position: 'fixed', top: 'auto', bottom: '0', height: '70%',
      }}
    >
      <Chat
        chatbot={{ id: chatbotId, enable_debug: true }}
        header
        conversationId={conversationId}
        onCreateConversation={() => { }}
        disabled
        onClose={onClose}
      />
    </div>
  );
}

Conversation.propTypes = {
  chatbotId: PropTypes.string.isRequired,
  conversationId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

Conversation.defaultProps = {
  conversationId: null,
};

export default Conversation;
