import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { ROUTE_PATH } from 'routes';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true }, () => {
      if (window.location.hostname === 'localhost') {
        console.error('<----- ERROR ---->');
        console.error(error ? error.toString() : '');
        console.error(errorInfo.componentStack);
      } else {
        /* TODO: MAKE API CALL TO SEND LOG VIA EMAIL */
        /* createLog({
          sysURL: window.location.origin,
          currentPath: window.location.href,
          navigator: window.navigator.userAgent,
          error: error ? error.toString() : '',
          errorInfo: errorInfo.componentStack,
        }); */
      }
    });
  }

  resetState = () => {
    this.setState({ hasError: false });
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      this.resetState();
      return (
        <Navigate replace to={ROUTE_PATH.setError(500)} />
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
