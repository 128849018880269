/* eslint-disable no-unsafe-optional-chaining */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import useLiterals from 'hooks/useLiterals';
import Button from 'react-bootstrap/Button';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import {
  getOrchestrator, updateOrchestratorNode, deleteOrchestratorNode, updateOrchestrator,
  createOrchestratorNode, debugOrchestrator,
} from 'modules/orchestrators/actions';
import { resetExecution } from 'modules/debug/actions';
import { joinConversation } from 'modules/socket/actions';
import Layout from 'components/Layout';
import ConfirmModal from 'components/ConfirmModal';
import Chat from 'components/Chat';
import {
  NODE_TYPES, TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS, ORCHESTRATOR_TYPE_CONVERSATION,
} from 'constants/orchestrator';
import { v4 as uuidv4 } from 'uuid';
import useOrchestrator from 'hooks/useOrchestrator';
import { NODE_TYPES_STRUCTURE } from 'constants/orchestrator_nodes';
import OrchestratorDiagram from './components/OrchestratorDiagram';
import ModalSelectChatbotForDebug from './components/ModalSelectChatbotForDebug';
import DebugOrchestratorWithParamsModal from './components/DebugOrchestratorWithParamsModal';
import ModalManageOrchestratorVersions from './components/ModalManageOrchestratorVersions';
import './styles.scss';

function Orchestrator() {
  const [orchestrator, setOrchestrator] = useState(null);
  const [nodesSelected, setNodesSelected] = useState([]);
  const [edgesSelected, setEdgesSelected] = useState([]);
  const [showDeleteNodeWarning, setShowDeleteNodeWarning] = useState(false);
  const [actualProcessId, setActualProcessId] = useState(null);
  const [showDebugChat, setShowDebugChat] = useState(false);
  const [actualPosition, setActualPosition] = useState({ x: 0, y: 0 });
  const [forceShowContextualMenu, setForceShowContextualMenu] = useState(false);
  const [selectedDebugChatbot, setSelectedDebugChatbot] = useState(null);
  const [showFormSelectChatbotForDebug, setShowFormSelectChatbotForDebug] = useState(false);
  const [version, setVersion] = useState(0);
  const [isDebugMaximized, setIsDebugMaximized] = useState(false);

  // Orchestrator versions
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [showManageOrchestratorVersionsForm, setShowManageOrchestratorVersionsForm] = useState(false);

  // Functions
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [selectedVersionFunction, setSelectedFunctionVersion] = useState(null);

  const { chatbots } = useSelector((state) => state.chatbots);
  const { id } = useParams();
  const literals = useLiterals('orchestrators');

  const { processes: processesDebug } = useSelector((state) => state.debug);

  const { getVariableByName } = useOrchestrator(orchestrator);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedFunction) {
      const functionItem = orchestrator.functions.find((item) => item.id === selectedFunction);
      if (functionItem) {
        setSelectedFunctionVersion(functionItem.actual_version_id);
      }
    }

    setVersion(version + 1);
  }, [selectedFunction]);

  const handleGetOrchestrator = async () => {
    const response = await getOrchestrator(id);
    setOrchestrator(response);

    if (selectedVersion === null) {
      setSelectedVersion(response.actual_version_id);
    }
  };

  const handlCreateOrchestratorVersion = async (newVersion) => {
    setShowManageOrchestratorVersionsForm(false);
    await handleGetOrchestrator();
    if (selectedFunction) {
      setSelectedFunctionVersion(newVersion.id);
    } else {
      setSelectedVersion(newVersion.id);
    }
  };

  const handlEditOrchestratorVersion = async (editedVersion) => {
    const newOrchestrator = { ...orchestrator };
    if (selectedFunction) {
      const versionIndex = newOrchestrator.functions.find((item) => item.id === selectedFunction).versions.findIndex((versionItem) => versionItem.id === editedVersion.id);
      newOrchestrator.functions.find((item) => item.id === selectedFunction).versions[versionIndex] = editedVersion;
    } else {
      const versionIndex = newOrchestrator.versions.findIndex((versionItem) => versionItem.id === editedVersion.id);
      newOrchestrator.versions[versionIndex] = editedVersion;
    }
    setOrchestrator(newOrchestrator);
    setVersion(version + 1);
  };

  const handleDebugMaximized = () => {
    setIsDebugMaximized(!isDebugMaximized);
  };

  useEffect(() => {
    if (selectedVersion !== null) {
      // handleGetOrchestrator();
      setVersion(version + 1);
    }
  }, [selectedVersion]);

  useEffect(() => {
    if (selectedVersionFunction !== null) {
      setVersion(version + 1);
    }
  }, [selectedVersionFunction]);

  const handleRefreshOrchestrator = useCallback(() => {
    handleGetOrchestrator();
  }, [id]);

  useEffect(() => {
    handleRefreshOrchestrator();
  }, []);

  const handleDeleteOrchestatorNode = (nodesToDelete = null) => {
    const orchestratorAux = selectedFunction ? orchestrator.functions.find((item) => item.id === selectedFunction) : orchestrator;
    const finalNodesToDelete = nodesToDelete || nodesSelected;
    const deletedNodes = [];
    const promises = finalNodesToDelete.map((nodeSelected) => {
      const node = orchestratorAux.children.find((item) => item.id === nodeSelected);
      if (node && node.type !== NODE_TYPES.INITIAL) {
        deletedNodes.push(nodeSelected);
        return deleteOrchestratorNode(orchestratorAux.id, nodeSelected);
      }
      return null;
    });

    Promise.all(promises).then(() => {
      const newOrchestrator = { ...orchestrator };
      if (selectedFunction) {
        const functionIndex = newOrchestrator.functions.findIndex((item) => item.id === selectedFunction);
        newOrchestrator.functions[functionIndex].children = newOrchestrator.functions[functionIndex].children.filter((node) => !deletedNodes.includes(node.id));
      } else {
        newOrchestrator.children = newOrchestrator.children.filter((node) => !deletedNodes.includes(node.id));
      }

      setOrchestrator(newOrchestrator);
      setNodesSelected([]);
      setVersion(version + 1);
    });
  };

  const handleDeleteOrchestratorEdges = (edgesToDelete = null) => {
    const finalEdgesToDelete = edgesToDelete || edgesSelected;
    finalEdgesToDelete.forEach((edge) => {
      const sourceNodeId = edge.source;
      const outputId = edge.sourceHandle;
      const targetNodeId = edge.target;
      const inputId = edge.targetHandle;

      const newOrchestrator = { ...orchestrator };

      const orchestratorAux = selectedFunction ? orchestrator.functions.find((item) => item.id === selectedFunction) : orchestrator;

      const sourceNode = orchestratorAux.children.find((item) => item.id === sourceNodeId);
      if (sourceNode) {
        const output = sourceNode.outputs.find((item) => item.id === outputId);
        const outputIndex = output.to.findIndex((item) => item.node_id === targetNodeId && item.input_id === inputId);
        output.to.splice(outputIndex, 1);
        updateOrchestratorNode(orchestratorAux.id, sourceNodeId, { outputs: sourceNode.outputs });
      }

      const targetNode = orchestratorAux.children.find((item) => item.id === targetNodeId);
      if (targetNode) {
        const input = targetNode.inputs.find((item) => item.id === inputId);
        const inputIndex = input.from.findIndex((item) => item.node_id === sourceNodeId && item.output_id === outputId);
        input.from.splice(inputIndex, 1);
        updateOrchestratorNode(orchestratorAux.id, targetNodeId, { inputs: targetNode.inputs });
      }

      setOrchestrator(newOrchestrator);
      setVersion(version + 1);
    });
  };

  const handleChangeNodes = (nodes) => {
    const newOrchestrator = { ...orchestrator };
    const orchestratorAux = selectedFunction ? orchestrator.functions.find((item) => item.id === selectedFunction) : newOrchestrator;
    orchestratorAux.children = nodes.map((node) => {
      const nodeItem = orchestratorAux.children.find((item) => item.id === node.id);

      if (!nodeItem) {
        // Is a new node
        return node;
      }
      return {
        ...nodeItem,
        x: node.position.x,
        y: node.position.y,
        inputs: node.data.inputs,
        outputs: node.data.outputs,
        name: node.data.label,
        extra_info: node.data.extra_info,
      };
    });
    setOrchestrator(newOrchestrator);
    setVersion(version + 1);
  };

  const handleUpdateOrchestrator = async (orchestratorChanged) => {
    // Gestiono cambio de variables
    const newOrchestrator = {
      ...orchestratorChanged,
    };

    const orchestratorAux = selectedFunction ? orchestrator.functions.find((item) => item.id === selectedFunction) : orchestrator;
    const selectedVersionAux = selectedFunction ? selectedVersionFunction : selectedVersion;

    const actualVersion = orchestratorAux.versions.find((versionItem) => versionItem.id === selectedVersionAux);
    if (newOrchestrator.variables) {
      newOrchestrator.variables.forEach((variable) => {
        // Si ya no tenemos una variable con ese nombre
        if (!actualVersion.variables.find((item) => item.name === variable.name)) {
          // Pero el id sigue estando es que se ha cambiado de nombre
          const oldVariable = actualVersion.variables.find((item) => item.id === variable.id);
          if (oldVariable) {
            // Busco los nodos get y set de la variable y los modificamos
            const nodes = orchestratorAux.children.filter((node) => (node.type === 'GET' || node.type === 'SET') && node.extra_info.variable === oldVariable.name && node.orchestrator_version_id === selectedVersionAux);
            nodes.forEach((node) => {
              const newInputs = node.inputs.map((input) => {
                if (input.name === oldVariable.name) {
                  return {
                    ...input,
                    name: variable.name,
                  };
                }
                return input;
              });

              const newOutputs = node.outputs.map((output) => {
                if (output.name === oldVariable.name) {
                  return {
                    ...output,
                    name: variable.name,
                  };
                }
                return output;
              });

              const newExtraInfo = { ...node.extra_info };
              newExtraInfo.variable = variable.name;
              updateOrchestratorNode(orchestratorAux.id, node.id, { inputs: newInputs, outputs: newOutputs, extra_info: newExtraInfo });

              newOrchestrator.children = newOrchestrator.children.map((item) => {
                if (item.id === node.id) {
                  return {
                    ...item,
                    inputs: newInputs,
                    outputs: newOutputs,
                    extra_info: newExtraInfo,
                  };
                }
                return item;
              });
            });
          }
        }
      });
    }

    // Update version variables
    const actualVersionAux = newOrchestrator.versions.find((versionItem) => versionItem.id === selectedVersionAux);
    actualVersionAux.variables = newOrchestrator.variables;

    if (selectedFunction) {
      const functionIndex = orchestrator.functions.findIndex((item) => item.id === selectedFunction);
      orchestrator.functions[functionIndex] = newOrchestrator;
      await dispatch(updateOrchestrator(selectedFunction, { ...newOrchestrator, selected_version_id: selectedVersionAux }));
    } else {
      setOrchestrator(newOrchestrator);
      await dispatch(updateOrchestrator(id, { ...newOrchestrator, selected_version_id: selectedVersionAux }));
    }

    setVersion(version + 1);
  };

  const handleCopyNodes = useCallback((nodesToCopy, variablesToCopy) => {
    const auxOrchestrator = selectedFunction ? orchestrator.functions.find((item) => item.id === selectedFunction) : orchestrator;
    const auxSelectedVersion = selectedFunction ? selectedVersionFunction : selectedVersion;
    const versionIndex = auxOrchestrator.versions.findIndex((item) => item.id === auxSelectedVersion);
    const versionData = auxOrchestrator.versions[versionIndex];
    const newOrchestrator = { ...orchestrator };

    const promises = [];
    const equivalences = {};
    let relativeX = null;
    let relativeY = null;
    const finalNodesToCopy = nodesToCopy.filter((nodeItem) => {
      return nodeItem.type !== NODE_TYPES.INITIAL;
    });

    const nodes = finalNodesToCopy.map((nodeItem) => {
      const node = { ...nodeItem };
      node.id = uuidv4();
      node.orchestrator_version_id = auxSelectedVersion;

      // Is it's the first node set the position as actual position
      if (relativeX === null && relativeY === null) {
        relativeX = nodeItem.x;
        relativeY = nodeItem.y;
        node.x = actualPosition.x;
        node.y = actualPosition.y;
      } else {
        node.x = nodeItem.x - relativeX + actualPosition.x;
        node.y = nodeItem.y - relativeY + actualPosition.y;
      }

      equivalences[nodeItem.id] = node.id;

      node.inputs = node.inputs.map((input) => {
        const newInput = {
          ...input,
          id: uuidv4(),
        };

        equivalences[input.id] = newInput.id;

        return newInput;
      });

      node.outputs = node.outputs.map((output) => {
        const newOutput = {
          ...output,
          id: uuidv4(),
        };

        equivalences[output.id] = newOutput.id;

        return newOutput;
      });

      return node;
    });

    const finalNodes = nodes.map((n) => {
      const node = { ...n };
      node.inputs = node.inputs.map((i) => {
        const input = { ...i };
        const from = [];
        input.from.forEach((fromInput) => {
          if (fromInput.node_id in equivalences && fromInput.output_id in equivalences) {
            from.push({
              ...fromInput,
              node_id: equivalences[fromInput.node_id],
              output_id: equivalences[fromInput.output_id],
            });
          }
        });
        input.from = from;
        return input;
      });

      node.outputs = node.outputs.map((o) => {
        const output = { ...o };
        const to = [];
        output.to.forEach((toOutput) => {
          if (toOutput.node_id in equivalences && toOutput.input_id in equivalences) {
            to.push({
              ...toOutput,
              node_id: equivalences[toOutput.node_id],
              input_id: equivalences[toOutput.input_id],
            });
          }
        });
        output.to = to;
        return output;
      });

      promises.push(createOrchestratorNode(auxOrchestrator.id, node));

      return node;
    });

    Promise.all(promises).then(() => {
      auxOrchestrator.children = [...auxOrchestrator.children, ...finalNodes];

      if (variablesToCopy.length) {
        let hasToBeSaved = false;
        variablesToCopy.forEach((variable) => {
          if (!versionData.variables.find((item) => item.name === variable.name)) {
            hasToBeSaved = true;
            versionData.variables.push({
              ...variable,
              id: uuidv4(),
            });
          }
        });

        if (hasToBeSaved) {
          auxOrchestrator.versions[versionIndex] = versionData;
          auxOrchestrator.variables = versionData.variables;
          dispatch(updateOrchestrator(auxOrchestrator.id, { ...auxOrchestrator, selected_version_id: auxSelectedVersion }));
        }
      }

      if (selectedFunction) {
        const functionIndex = newOrchestrator.functions.findIndex((item) => item.id === selectedFunction);
        newOrchestrator.functions[functionIndex].children = auxOrchestrator.children;
      } else {
        newOrchestrator.children = auxOrchestrator.children;
      }

      setOrchestrator(newOrchestrator);
      setVersion(version + 1);
    })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedVersion, selectedFunction, orchestrator, selectedVersionFunction, version, actualPosition]);

  const renderDeleteNodeWarning = () => {
    const nodesToDelete = orchestrator?.children.filter((nodeItem) => nodesSelected.includes(nodeItem.id));
    if (nodesToDelete.length) {
      return (
        <ConfirmModal
          open={showDeleteNodeWarning}
          onClose={() => setShowDeleteNodeWarning(false)}
          onSubmit={() => {
            setShowDeleteNodeWarning(false);
            handleDeleteOrchestatorNode();
          }}
          message={literals.confirmDeleteNodes}
        />
      );
    }
    return null;
  };

  const handleManageOrchestratorVersions = () => {
    setShowManageOrchestratorVersionsForm(true);
  };

  const handleChangeOrchestratorVersion = (versionId) => {
    if (!selectedFunction) {
      setSelectedVersion(versionId);
    } else {
      setSelectedFunctionVersion(versionId);
    }
  };

  const handleGoToFunction = (functionId) => {
    setSelectedFunction(functionId);
  };

  const handleBackToOrchestrator = () => {
    setSelectedFunction(null);
  };

  const parseOrchestrator = () => {
    let orchestratorAux = orchestrator;
    let selectedVersionAux = selectedVersion;
    if (selectedFunction) {
      const functionItem = orchestrator.functions.find((item) => item.id === selectedFunction);
      if (functionItem) {
        orchestratorAux = functionItem;
        selectedVersionAux = selectedVersionFunction;
      }
    }

    const actualVersionItems = orchestratorAux.children.filter((item) => item.orchestrator_version_id === selectedVersionAux);
    const nodes = actualVersionItems.map((item) => {
      return {
        id: item.id,
        position: { x: item.x, y: item.y },
        data: {
          label: item.name,
          type: item.type,
          inputs: item.inputs,
          outputs: item.outputs,
          extra_info: item.extra_info,
          disable_debug: item.disable_debug,
          disable_statistics: item.disable_statistics,
        },
        type: 'custom',
      };
    });

    const edges = [];

    actualVersionItems.forEach((item) => {
      item.outputs.forEach((output) => {
        output.to.forEach((to) => {
          edges.push({
            id: `${output.id}-${to.input_id}`,
            source: item.id,
            sourceHandle: output.id,
            target: to.node_id,
            targetHandle: to.input_id,
          });
        });
      });
    });

    return { nodes, edges };
  };

  const handleSelectNodeTypeToCreate = (nodeType, extraInfo) => {
    const defaultStructure = NODE_TYPES_STRUCTURE[nodeType] || {};

    const name = defaultStructure.name || '';

    const params = {
      inputs: defaultStructure.inputs || [],
      outputs: defaultStructure.outputs || [],
      name,
      orchestrator_version_id: selectedFunction ? selectedVersionFunction : selectedVersion,
      type: nodeType,
      x: extraInfo.x,
      y: extraInfo.y,
      extra_info: {},
    };

    // Check if node is a getter or a setter
    if (nodeType === 'GET' || nodeType === 'SET') {
      const variableName = extraInfo.variable;
      const variable = getVariableByName(variableName, selectedVersion, selectedFunction, selectedVersionFunction);
      if (variable) {
        params.extra_info = {
          ...params.extra_info,
          variable: variableName,
        };

        if (nodeType === 'GET') {
          params.outputs.push(
            {
              ...variable,
              custom: false,
              to: [],
            },
          );
        } else if (nodeType === 'SET') {
          params.inputs = [
            {
              type: TYPE_CONNECTIONS.EXEC,
              type_set: TYPE_SET_CONNECTIONS.SINGLE,
              name: '',
              custom: false,
              from: [],
            },
            {

              ...variable,
              custom: false,
              from: [],
            },
          ];

          params.outputs = [
            {
              type: TYPE_CONNECTIONS.EXEC,
              type_set: TYPE_SET_CONNECTIONS.SINGLE,
              name: '',
              custom: false,
              to: [],
            },
            {
              ...variable,
              custom: false,
              to: [],
            },
          ];
        }
      }
    } else if (nodeType === NODE_TYPES.FUNCTION) {
      const functionId = extraInfo.function_id;
      const functionItem = orchestrator.functions.find((item) => item.id === functionId);
      params.extra_info.function_id = functionId;

      if (functionItem) {
        params.name = functionItem.name;
        // Search initial node
        const initialNode = functionItem.children.find((item) => item.type === NODE_TYPES.INITIAL && item.orchestrator_version_id === functionItem.actual_version_id);
        // Insert EXEC input and output
        params.inputs.push({
          type: TYPE_CONNECTIONS.EXEC,
          type_set: TYPE_SET_CONNECTIONS.SINGLE,
          name: '',
          custom: false,
          from: [],
        });

        params.outputs.push({
          type: TYPE_CONNECTIONS.EXEC,
          type_set: TYPE_SET_CONNECTIONS.SINGLE,
          name: '',
          custom: false,
          to: [],
        });

        if (initialNode) {
          initialNode.outputs.forEach((output) => {
            if (output.type !== TYPE_CONNECTIONS.EXEC) {
              params.inputs.push({
                ...output,
                custom: false,
                from: [],
              });
            }
          });
        }

        // Search final nodes
        const finalNodes = functionItem.children.filter((item) => item.type === NODE_TYPES.RETURN_NODE && item.orchestrator_version_id === functionItem.actual_version_id);
        const insertedOutputs = {};
        finalNodes.forEach((node) => {
          node.inputs.forEach((input) => {
            if (input.type !== TYPE_CONNECTIONS.EXEC) {
              const idOutput = `${input.type}-${input.name}`;
              if (!insertedOutputs[idOutput]) {
                params.outputs.push({
                  ...input,
                  custom: false,
                  to: [],
                });
                insertedOutputs[idOutput] = true;
              }
            }
          });
        });
      }
    }

    if (typeof params.inputs !== 'undefined') {
      params.inputs = params.inputs.map((input) => {
        return {
          ...input,
          id: uuidv4(),
        };
      });
    }

    if (typeof params.outputs !== 'undefined') {
      params.outputs = params.outputs.map((output) => {
        return {
          ...output,
          id: uuidv4(),
        };
      });
    }

    createOrchestratorNode(selectedFunction || id, params)
      .then((newNode) => {
        const newOrchestrator = { ...orchestrator };
        if (selectedFunction) {
          const indexFunction = newOrchestrator.functions.findIndex((item) => item.id === selectedFunction);
          newOrchestrator.functions[indexFunction].children.push(newNode);
        } else {
          newOrchestrator.children.push(newNode);
        }
        setOrchestrator(newOrchestrator);
        setVersion(version + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleShowCreateModal = () => {
    setForceShowContextualMenu(true);

    setTimeout(() => {
      setForceShowContextualMenu(false);
    }, 100);
  };

  const incrementVersion = () => {
    setVersion(version + 1);
  };

  const handleCreateConversation = (conversation) => {
    setActualProcessId(conversation.id);
    dispatch(resetExecution(conversation.id));
  };

  const handleBeforeSendMessage = () => {
    if (actualProcessId) {
      dispatch(resetExecution(actualProcessId));
    }
  };

  const handleCloseChat = () => {
    setShowDebugChat(false);
    if (actualProcessId) {
      dispatch(resetExecution(actualProcessId));
    }
  };

  const handleViewDebug = useCallback(() => {
    const orchestratorAux = selectedFunction ? orchestrator.functions.find((item) => item.id === selectedFunction) : orchestrator;
    if (orchestratorAux.type === ORCHESTRATOR_TYPE_CONVERSATION) {
      if (showDebugChat || selectedDebugChatbot) {
        setShowDebugChat(!showDebugChat);
        return;
      }

      if (orchestratorAux && orchestratorAux.entry_chatbots.length) {
        const chatbotsOrchestrator = chatbots.filter((chatbot) => orchestratorAux.entry_chatbots.includes(chatbot.id));
        if (chatbotsOrchestrator.length === 1) {
          setSelectedDebugChatbot(chatbotsOrchestrator[0].id);
          setShowDebugChat(true);
          return;
        }

        if (chatbotsOrchestrator.length > 1) {
          setShowFormSelectChatbotForDebug(true);
        }
      }
    } else {
      setShowDebugChat(true);
    }
  }, [selectedFunction, orchestrator]);

  const handleCloseDebugChat = () => {
    setShowDebugChat(false);
  };

  const handleSubmitDebugOrchestrator = (inputs) => {
    const processId = uuidv4();
    dispatch(resetExecution(processId));
    joinConversation(processId);
    setActualProcessId(processId);
    handleCloseDebugChat();

    const orchestratorAux = selectedFunction ? orchestrator.functions.find((item) => item.id === selectedFunction) : orchestrator;
    const selectedVersionAux = selectedFunction ? selectedVersionFunction : selectedVersion;

    debugOrchestrator(orchestratorAux.id, processId, inputs, selectedVersionAux);
  };

  const printModalSelectChatbotForDebug = () => {
    if (!showFormSelectChatbotForDebug || orchestrator.type !== ORCHESTRATOR_TYPE_CONVERSATION) {
      return null;
    }

    return (
      <ModalSelectChatbotForDebug
        chatbots={chatbots.filter((chatbot) => orchestrator.entry_chatbots.includes(chatbot.id))}
        onClose={() => setShowFormSelectChatbotForDebug(false)}
        onSelect={(chatbotId) => {
          setSelectedDebugChatbot(chatbotId);
          setShowFormSelectChatbotForDebug(false);
          setShowDebugChat(true);
        }}
      />
    );
  };

  const printDebugOrchestratorWithParamsModal = () => {
    const orchestratorAux = selectedFunction ? orchestrator.functions.find((item) => item.id === selectedFunction) : orchestrator;
    const selectedVersionAux = selectedFunction ? selectedVersionFunction : selectedVersion;
    return (
      <DebugOrchestratorWithParamsModal
        orchestrator={orchestratorAux}
        selectedVersion={selectedVersionAux}
        onSubmit={handleSubmitDebugOrchestrator}
        onClose={handleCloseDebugChat}
        visible={showDebugChat}
      />
    );
  };

  const printDebugChat = () => {
    const orchestratorAux = selectedFunction ? orchestrator.functions.find((item) => item.id === selectedFunction) : orchestrator;
    if (orchestratorAux.type !== ORCHESTRATOR_TYPE_CONVERSATION) {
      return printDebugOrchestratorWithParamsModal();
    }

    if (!showDebugChat || !selectedDebugChatbot) {
      return null;
    }

    const chatbotData = chatbots.find((chatbot) => chatbot.id === selectedDebugChatbot);
    return (
      <Draggable
        handle='.handle_move_debug'
        scale={1}
        bounds='parent'
      >
        <div
          className={`debug-chat__container ${isDebugMaximized ? 'maximized' : ''}`}
          style={{
            position: 'absolute',
          }}
        >
          <div className='handle_move_debug'>
            <i className='fa-solid fa-arrows-up-down-left-right' />
          </div>
          <div className='handle_maximize_debug' onClick={handleDebugMaximized}>
            {isDebugMaximized ? (<i className='fa-solid fa-compress' />) : (<i className='fa-solid fa-expand' />)}
          </div>
          <Chat
            chatbot={
              {
                ...chatbotData,
                enable_debug: true,
                extra_info: {
                  ...chatbotData.extra_info,
                  start_minimized: false,
                  show_as_bubble: false,
                },
              }
            }
            header
            conversationId={actualProcessId}
            orchestratorVersionId={selectedVersion}
            isDebug
            onCreateConversation={handleCreateConversation}
            onBeforeSendMessage={handleBeforeSendMessage}
            onClose={handleCloseChat}
          />
        </div>
      </Draggable>
    );
  };

  const handleCloseOrchestratorVersionsForm = () => {
    setShowManageOrchestratorVersionsForm(false);
  };

  const handleChangeDefaultVersion = async (versionId) => {
    const newOrchestrator = { ...orchestrator };
    if (selectedFunction) {
      const functionIndex = newOrchestrator.functions.findIndex((item) => item.id === selectedFunction);
      newOrchestrator.functions[functionIndex].actual_version_id = versionId;
      await dispatch(updateOrchestrator(selectedFunction, newOrchestrator.functions[functionIndex]));
    } else {
      newOrchestrator.actual_version_id = versionId;
      await dispatch(updateOrchestrator(id, newOrchestrator));
    }

    setOrchestrator(newOrchestrator);
    setVersion(version + 1);
  };

  const handleRefreshOrquestrator = (newOrchestrator) => {
    setOrchestrator(newOrchestrator);
    setVersion(version + 1);
  };

  const printPathName = () => {
    if (selectedFunction) {
      const functionItem = orchestrator.functions.find((item) => item.id === selectedFunction);
      if (functionItem) {
        return (
          <div className='text-white text-lg self-center bg-slate-600 px-3 py-1 rounded-2xl'>
            {functionItem.name}
          </div>
        );
      }
    }

    return (
      <div className='text-white text-lg self-center bg-slate-600 px-3 py-1 rounded-2xl'>
        {orchestrator.name}
      </div>
    );
  };

  const printManageOrchestratorVersionsForm = () => {
    if (!showManageOrchestratorVersionsForm) {
      return null;
    }

    const orchestratorAux = selectedFunction ? orchestrator.functions.find((item) => item.id === selectedFunction) : orchestrator;

    return (
      <ModalManageOrchestratorVersions
        orchestratorId={orchestratorAux.id}
        defaultVersion={orchestratorAux.actual_version_id}
        versions={orchestratorAux.versions}
        onCreate={handlCreateOrchestratorVersion}
        onEdit={handlEditOrchestratorVersion}
        onChange={handleGetOrchestrator}
        onClose={handleCloseOrchestratorVersionsForm}
        onChangeDefaultVersion={handleChangeDefaultVersion}
      />
    );
  };

  if (!orchestrator || !selectedVersion) {
    return null;
  }

  const orchestratorData = parseOrchestrator(orchestrator);

  return (
    <>
      {printModalSelectChatbotForDebug()}
      {renderDeleteNodeWarning()}
      {printManageOrchestratorVersionsForm()}
      <Layout selectedSection='orchestrators'>
        <div
          style={{
            background: 'gray', display: 'flex', gap: '1rem', padding: '0.5rem',
          }}
          data-version={version}
          id='orchestrator_top_menu'
          tabIndex={-1}
          role='button'
          aria-label='Orchestrator menu'
        >
          <Button className='d-flex flex-end' variant='primary' onClick={handleShowCreateModal}>
            {literals.createNode}
          </Button>
          <Button className='d-flex flex-end align-items-center' variant='primary' onClick={handleViewDebug}>
            <i className='fa-solid fa-play' />
            {literals.debug}
          </Button>
          {selectedFunction && (
            <Button className='d-flex flex-end align-items-center' variant='primary' onClick={handleBackToOrchestrator}>
              <i className='fa-solid fa-angle-left' />
              {literals.backToOrchestrator}
            </Button>
          )}
          {printPathName()}
        </div>
        <div>
          <div
            className='orchestrator__container'
          >
            {orchestrator !== null && (
              <ReactFlowProvider>
                <OrchestratorDiagram
                  nodes={orchestratorData.nodes}
                  edges={orchestratorData.edges}
                  nodesSelected={nodesSelected}
                  edgesSelected={edgesSelected}
                  orchestrator={orchestrator}
                  version={version}
                  forceShowContextualMenu={forceShowContextualMenu}
                  setNodesSelected={setNodesSelected}
                  setEdgesSelected={setEdgesSelected}
                  onChangeVersion={incrementVersion}
                  onChangeNodes={handleChangeNodes}
                  onChangePosition={setActualPosition}
                  onDeleteNodes={handleDeleteOrchestatorNode}
                  onDeleteEdges={handleDeleteOrchestratorEdges}
                  onChangeOrchestrator={handleUpdateOrchestrator}
                  onCreateNodeType={handleSelectNodeTypeToCreate}
                  onCopyPasteNodes={handleCopyNodes}
                  onManageOrchestratorVersions={handleManageOrchestratorVersions}
                  onChangeOrchestratorVersion={handleChangeOrchestratorVersion}
                  onGoToFunction={handleGoToFunction}
                  onRefreshOrchestrator={handleRefreshOrquestrator}
                  selectedVersion={selectedVersion}
                  selectedFunction={selectedFunction}
                  selectedVersionFunction={selectedVersionFunction}
                  debugNodes={processesDebug[actualProcessId] || {
                    executed_nodes: [],
                    results: {},
                  }}
                />
              </ReactFlowProvider>
            )}
          </div>
        </div>
        {printDebugChat()}
      </Layout>
    </>
  );
}

export default Orchestrator;
