import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useLiterals from 'hooks/useLiterals';
import Form from 'react-bootstrap/Form';
import { loadingActions } from 'modules/loading';
import FeedbackModal from 'components/FeedbackModal';
import {
  getWebsiteLinks,
  getTrainedWebsiteLinks,
  trainWebsite,
  deleteWebsite,
} from 'modules/websites/actions';

const VIEW_NEW_URL = 'VIEW_NEW_URL';
const VIEW_URLS = 'VIEW_URLS';

function Websites({ chatbotId }) {
  const [url, setUrl] = useState('');

  const [error, setError] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [websiteLinks, setWebsiteLinks] = useState({ loaded: false, data: [] });
  const [trainedWebsiteLinks, setTrainedWebsiteLinks] = useState([]);
  const [view, setView] = useState(VIEW_URLS);

  const dispatch = useDispatch();
  const literals = useLiterals('websites');

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    handleGetTrainedWebsites();
  }, []);

  const handleCloseModal = () => {
    setShowFeedbackModal(false);
  };

  const handleChangeURL = (e) => {
    setUrl(e.target.value);
  };

  const handleChangeView = () => {
    if (view === VIEW_URLS) {
      setView(VIEW_NEW_URL);
    } else {
      setView(VIEW_URLS);
    }
  };

  const areUrlsEqual = (url1, url2) => {
    const cleanUrl1 = url1.replace(/^(http:\/\/|https:\/\/)/, '');
    const cleanUrl2 = url2.replace(/^(http:\/\/|https:\/\/)/, '');

    return cleanUrl1 === cleanUrl2;
  };

  const handleGetWebsiteLinks = async () => {
    try {
      dispatch(loadingActions.show());
      const response = await getWebsiteLinks(url);
      setWebsiteLinks({ loaded: true, data: response?.website_links });
      dispatch(loadingActions.hide());
    } catch (err) {
      console.error(err);
    }
  };

  const handleGetTrainedWebsites = async () => {
    try {
      dispatch(loadingActions.show());
      const response = await getTrainedWebsiteLinks(chatbotId);
      setTrainedWebsiteLinks(response?.websites);
      dispatch(loadingActions.hide());
    } catch (err) {
      console.error(err);
    }
  };

  const handleTrainWebsite = async (websiteToTrain, redirectToList = true) => {
    try {
      dispatch(loadingActions.show());
      await trainWebsite(chatbotId, websiteToTrain);
      setShowFeedbackModal(true);
      setFeedbackMessage(literals.trainedSuccess);
      if (redirectToList) {
        setView(VIEW_URLS);
        setUrl('');
      }
      dispatch(loadingActions.hide());
      handleGetTrainedWebsites();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteWebsite = async (websiteId) => {
    try {
      dispatch(loadingActions.show());
      await deleteWebsite(chatbotId, websiteId);
      setShowFeedbackModal(true);
      setFeedbackMessage(literals.urlDeleted);
      setView(VIEW_URLS);
      dispatch(loadingActions.hide());
      handleGetTrainedWebsites();
    } catch (err) {
      console.error(err);
    }
  };

  const printTrainedWebsiteLinksTable = () => {
    const rows = trainedWebsiteLinks.map((websiteLink) => {
      return (
        <tr key={websiteLink.id}>
          <td>{websiteLink.name}</td>
          <td>{websiteLink.size}</td>
          <td>{websiteLink.url}</td>
          <td>{websiteLink.updated_at}</td>
          <td className='right'>
            <button
              className='btn btn-primary btn-sm'
              type='button'
              onClick={() => handleDeleteWebsite(websiteLink.id)}
            >
              {literals.common.delete}
            </button>
          </td>
        </tr>
      );
    });
    return (
      <div>
        <table className='table_stl1'>
          <thead>
            <tr>
              <th>{literals.name}</th>
              <th>{literals.characters}</th>
              <th>{literals.url}</th>
              <th>{literals.trainedDate}</th>
              <th className='right'>{literals.common.options}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTrainedWebsites = () => {
    return (
      <Form>
        <div className='panel fetch-panel'>
          <div className='panel-header'>{literals.allTrainedURLS}</div>
          <div className='panel-body'>
            {printTrainedWebsiteLinksTable()}
          </div>
          {error && (
            <div className='error'>
              {error}
            </div>
          )}
        </div>
      </Form>
    );
  };

  const printWebsiteLinksTable = () => {
    const rows = websiteLinks?.data?.map((websiteLink) => {
      const isTrained = trainedWebsiteLinks.find((trainedWebsiteLink) => {
        return areUrlsEqual(trainedWebsiteLink.url, websiteLink);
      });
      return (
        <tr key={document.id}>
          <td>{websiteLink}</td>
          <td className='right'>
            {isTrained && (
              <div className='trained-indicator'>{literals.trainedURL}</div>
            )}
            {!isTrained && (
              <button
                className='btn btn-primary btn-sm'
                type='button'
                onClick={() => handleTrainWebsite(websiteLink, false)}
              >
                {literals.train}
              </button>
            )}
          </td>
        </tr>
      );
    });
    return (
      <div>
        <table className='table_stl1'>
          <thead>
            <tr>
              <th>{literals.url}</th>
              <th className='right'>{literals.common.options}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const printFeedbackModal = () => {
    if (!showFeedbackModal) {
      return null;
    }

    return (
      <FeedbackModal
        message={feedbackMessage}
        onClose={handleCloseModal}
        open={showFeedbackModal}
      />
    );
  };

  const renderNewURLView = () => {
    return (
      <Form>
        <div className='panel fetch-panel'>
          <div className='panel-header'>{literals.addWebsiteTitle}</div>
          <div className='panel-body'>
            {/* <div className='input_title'>
              URL de la página (ej: https://www.google.es)
            </div> */}
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleChangeURL} value={url} />
              <button type='button' className='btn btn-primary' onClick={() => handleTrainWebsite(url)}>
                {literals.train}
              </button>
              <button type='button' className='btn btn-primary' onClick={handleGetWebsiteLinks}>
                {literals.analyze}
              </button>
            </div>
          </div>
        </div>
        {websiteLinks.loaded && (
          <div className='panel fetch-panel'>
            <div className='panel-header'>{literals.results}</div>
            <div className='panel-body'>
              {printWebsiteLinksTable()}
            </div>
          </div>
        )}
        {error && (
          <div className='error'>
            {error}
          </div>
        )}
      </Form>
    );
  };

  if (!trainedWebsiteLinks.length && view === VIEW_URLS) {
    return (
      <div className='faq-wrapper'>
        <div className='no-elements-container'>
          <p>{literals.noTrainedWebsites}</p>
          <div onClick={handleChangeView} className='btn btn-primary'>{literals.addURL}</div>
        </div>
      </div>
    );
  }

  return (
    <div className='p-md websites__container'>
      {printFeedbackModal()}
      <div className='websites__container__create'>
        <button type='button' className='btn btn-primary' onClick={handleChangeView}>
          {view === VIEW_URLS ? 'Añadir URL' : 'Ver URLs entrenadas'}
        </button>
      </div>
      {view === VIEW_NEW_URL && renderNewURLView()}
      {view === VIEW_URLS && renderTrainedWebsites()}
    </div>
  );
}

Websites.propTypes = {
  chatbotId: PropTypes.string.isRequired,
};

export default Websites;
