import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useLiterals from 'hooks/useLiterals';
import { loadingActions } from 'modules/loading';
import { getChatbots } from 'modules/chatbots/actions';
import { getConversations, deleteUpdatedConversation } from 'modules/conversations/actions';
import { getTools } from 'modules/tools/actions';
import { getAPIs, clearChatbotApis } from 'modules/apis/actions';
import { getMyOrganizations } from 'modules/organizations/actions';
import Layout from 'components/Layout';
import { ROUTE_PATH } from 'routes';
import Conversations from './components/conversations';
import Documents from './components/documents';
import Faq from './components/faq';
import Tools from './components/tools';
import Settings from './components/settings';
import Integrations from './components/integrations';
import Websites from './components/websites';
import Texts from './components/texts';
import './styles.scss';

const TAB_CONVERSATIONS = 'conversations';
const TAB_DOCUMENTS = 'documents';
const TAB_FAQ = 'faq';
const TAB_TOOLS = 'tools';
const TAB_SETTINGS = 'settings';
const TAB_INTEGRATIONS = 'integrations';
const TAB_WEBSITES = 'websites';
const TAB_TEXTS = 'texts';

const CONVERSATIONS_PAGE_SIZE = 10;

function Chatbot() {
  const [activeTab, setActiveTab] = useState(TAB_CONVERSATIONS);
  const [conversationsActualPage, setConversationsActualPage] = useState(0);
  const [conversations, setConversations] = useState([]);
  const [totalConversations, setTotalConversations] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const literals = useLiterals('chatbot');
  const { chatbots } = useSelector((state) => state.chatbots);
  const { updatedConversations } = useSelector((state) => state.conversations);

  useEffect(() => {
    Object.keys(updatedConversations).forEach((conversationId) => {
      const conversationIndex = conversations.findIndex((c) => c.id === conversationId);
      if (conversationIndex >= 0) {
        const newConversations = [...conversations];
        newConversations[conversationIndex] = updatedConversations[conversationId];
        setConversations(newConversations);
      }
    });
    Object.keys(updatedConversations).forEach((conversationId) => {
      dispatch(deleteUpdatedConversation(conversationId));
    });
  }, [updatedConversations]);

  const getChatbot = () => {
    if (!chatbots) {
      return null;
    }

    const chatbot = chatbots.find((c) => c.id === id);
    return chatbot;
  };

  const loadConversations = async () => {
    const response = await getConversations(id, conversationsActualPage, 500);
    setConversations(response.conversations);
    setTotalConversations(response.total);
  };

  const loadInitialData = async () => {
    dispatch(loadingActions.show());
    dispatch(clearChatbotApis());
    const requests = [];
    requests.push(dispatch(getTools()));
    requests.push(dispatch(getAPIs()));
    requests.push(dispatch(getChatbots()));
    requests.push(dispatch(getMyOrganizations()));
    requests.push(loadConversations());

    Promise.all(requests)
      .then(() => dispatch(loadingActions.hide()))
      .catch((e) => console.error('Error:', e));
  };

  useEffect(() => {
    loadInitialData();
  }, [id]);

  const handleSelectConversationsTab = () => {
    setActiveTab(TAB_CONVERSATIONS);
  };

  const handleSelectDocumentsTab = () => {
    setActiveTab(TAB_DOCUMENTS);
  };

  const handleSelectFaqTab = () => {
    setActiveTab(TAB_FAQ);
  };

  const handleSelectToolsTab = () => {
    setActiveTab(TAB_TOOLS);
  };

  const handleSelectSettingsTab = () => {
    setActiveTab(TAB_SETTINGS);
  };

  const handleSelectIntegrationTab = () => {
    setActiveTab(TAB_INTEGRATIONS);
  };
  const handleSelectWebsitesTab = () => {
    setActiveTab(TAB_WEBSITES);
  };

  const handleSelectTextsTab = () => {
    setActiveTab(TAB_TEXTS);
  };

  const handleBackToChatbots = () => {
    navigate(ROUTE_PATH.CHATBOTS);
  };

  const handleCreateConversation = (conversation) => {
    const newConversations = [conversation, ...conversations];
    setConversations(newConversations);
  };

  const handleUpdateConversation = (conversation) => {
    const newConversations = conversations.map((c) => {
      if (c.id === conversation.id) {
        return conversation;
      }
      return c;
    });
    setConversations(newConversations);
  };

  const handleDeleteConversation = (conversation) => {
    const newConversations = conversations.filter((c) => c.id !== conversation);
    setConversations(newConversations);
  };

  const handleDeleteAllConversations = () => {
    const newConversations = [];
    setConversations(newConversations);
  };

  const printMainView = () => {
    const chatbot = getChatbot();
    if (!chatbot) {
      return null;
    }

    return (
      <div className='section'>
        <div className='section_header'>
          <h1>{`${literals.chatbot}: ${chatbot.name}`}</h1>
          <div className='btn btn-primary' onClick={handleBackToChatbots}>{literals.backToChatbots}</div>
        </div>
        <div className='section_content'>
          <div className='tabs'>
            <div className={`tab ${activeTab === TAB_CONVERSATIONS ? 'selected' : ''}`} onClick={handleSelectConversationsTab} data-cy='chatbot-tab-conversations'>
              {literals.conversations}
            </div>
            <div className={`tab ${activeTab === TAB_DOCUMENTS ? 'selected' : ''}`} onClick={handleSelectDocumentsTab}>
              {literals.documents}
            </div>
            <div className={`tab ${activeTab === TAB_WEBSITES ? 'selected' : ''}`} onClick={handleSelectWebsitesTab}>
              {literals.websites}
            </div>
            <div className={`tab ${activeTab === TAB_TEXTS ? 'selected' : ''}`} onClick={handleSelectTextsTab}>
              {literals.texts}
            </div>
            <div className={`tab ${activeTab === TAB_FAQ ? 'selected' : ''}`} onClick={handleSelectFaqTab}>
              {literals.faq}
            </div>
            <div className={`tab ${activeTab === TAB_TOOLS ? 'selected' : ''}`} onClick={handleSelectToolsTab}>
              {literals.tools}
            </div>
            <div className={`tab ${activeTab === TAB_SETTINGS ? 'selected' : ''}`} onClick={handleSelectSettingsTab}>
              {literals.settings}
            </div>
            <div className={`tab ${activeTab === TAB_INTEGRATIONS ? 'selected' : ''}`} onClick={handleSelectIntegrationTab}>
              {literals.integrations}
            </div>
          </div>
          <div className='tab_content'>
            <div style={{ display: activeTab === TAB_CONVERSATIONS ? 'block' : 'none' }}>
              <Conversations
                chatbotId={id}
                conversations={conversations}
                totalConversations={totalConversations}
                onCreateConversation={handleCreateConversation}
                onUpdateConversation={handleUpdateConversation}
                onDeleteConversation={handleDeleteConversation}
                onDeleteAllConversations={handleDeleteAllConversations}
              />
            </div>
            <div style={{ display: activeTab === TAB_DOCUMENTS ? 'block' : 'none' }}>
              <Documents
                chatbotId={id}
              />
            </div>
            <div style={{ display: activeTab === TAB_FAQ ? 'block' : 'none' }}>
              <Faq />
            </div>
            <div style={{ display: activeTab === TAB_WEBSITES ? 'block' : 'none' }}>
              <Websites chatbotId={id} />
            </div>
            <div style={{ display: activeTab === TAB_TEXTS ? 'block' : 'none' }}>
              <Texts chatbotId={id} />
            </div>
            <div style={{ display: activeTab === TAB_TOOLS ? 'block' : 'none' }}>
              <Tools />
            </div>
            <div style={{ display: activeTab === TAB_SETTINGS ? 'block' : 'none' }}>
              <Settings
                chatbotId={id}
              />
            </div>
            <div style={{ display: activeTab === TAB_INTEGRATIONS ? 'block' : 'none' }}>
              <Integrations
                chatbotId={id}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout selectedSection='chatbots'>
      {printMainView()}
    </Layout>
  );
}

export default Chatbot;
