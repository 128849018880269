import { ADD_MESSAGE } from 'modules/chats/index';
import { SET_FORMS } from './index';
import Api from './repository';

export const getForms = async (offset = 0, limit = 1000) => {
  const response = await Api.getForms(offset, limit);
  return response;
};

export const createForm = async (data) => {
  const response = await Api.createForm(data);
  return response;
};

export const updateForm = async (formId, data) => {
  const response = await Api.updateForm(formId, data);
  return response;
};

export const deleteForm = async (formId) => {
  const response = await Api.deleteForm(formId);
  return response;
};

/* Funciones con dispatch */
export const fetchForms = (offset, limit) => async (dispatch) => {
  const response = await getForms(offset, limit);
  dispatch({
    type: SET_FORMS,
    payload: response.forms,
  });
};

export const fillForm = (chatbotId, conversationId, messageId, values) => async (dispatch) => {
  const response = await Api.fillForm(chatbotId, conversationId, messageId, values);

  dispatch({
    type: ADD_MESSAGE,
    payload: response,
  });

  return response;
};

export const getFormByMessageId = async (messageId) => {
  const response = await Api.getFormByMessageId(messageId);
  return response;
};
