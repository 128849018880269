import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

function BarChartItem({
  data,
}) {
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='date' />
        <YAxis />
        <Bar dataKey='cost' fill='#8884d8' />
        <Tooltip />
        {/* <Tooltip formatter={(value, name) => [value, getChatbotName(name)]} />
        <Legend formatter={(name) => getChatbotName(name)} /> */}
        {/* {Object.keys(chatbotTests).map((chatbotId, index) => (
          <Bar dataKey={chatbotId} maxBarSize={10} fill={colors[index]} />
        ))} */}
      </BarChart>
    </ResponsiveContainer>
  );
}

BarChartItem.propTypes = {
  data: PropTypes.array.isRequired,
};

BarChartItem.defaultProps = {

};

export default BarChartItem;
