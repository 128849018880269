import React from 'react';
import propTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import useLiterals from 'hooks/useLiterals';
import './styles.scss';

function FeedbackModal({
  open, onClose,
  title, success, message,
}) {
  const literals = useLiterals('feedbackModal');
  return (
    <Modal show={open} onHide={onClose}>
      {title && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <div className='feedback-body'>
          <div className='feedback-icon'>
            {success ? (<i className='fa-regular fa-circle-check success' />) : (<i className='fa-regular fa-circle-xmark error' />)}
          </div>
          <div className='feedback-message'>
            {message}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='btn-primary'
          onClick={onClose}
        >
          {literals.common.close}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

FeedbackModal.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  title: propTypes.string,
  success: propTypes.bool,
  message: propTypes.string,
};

FeedbackModal.defaultProps = {
  title: null,
  success: true,
  message: null,
};

export default FeedbackModal;
