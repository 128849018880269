import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

const URLS = {
  getChatbotTexts: (chatbotId) => `chatbots/${chatbotId}/texts`,
  createText: (chatbotId) => `chatbots/${chatbotId}/texts`,
  updateText: (chatbotId, textId) => `chatbots/${chatbotId}/texts/${textId}`,
  deleteText: (chatbotId, textId) => `chatbots/${chatbotId}/texts/${textId}`,
};

export default {
  async getChatbotTexts(chatbotId) {
    const url = URLS.getChatbotTexts(chatbotId);
    return ApiFetchInstance.get(url);
  },
  async createText(chatbotId, text) {
    const url = URLS.createText(chatbotId);
    return ApiFetchInstance.post(url, { text });
  },
  async updateText(chatbotId, textId, text) {
    const url = URLS.updateText(chatbotId, textId);
    return ApiFetchInstance.patch(url, { text });
  },
  async deleteText(chatbotId, textId) {
    const url = URLS.deleteText(chatbotId, textId);
    return ApiFetchInstance.delete(url);
  },
};
