import React from 'react';
import Select from 'react-select';
import propTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import useLiterals from 'hooks/useLiterals';

function MoveConversationsModal({
  open, onClose, onSubmit, orchestrator, orchestrators,
}) {
  const [orchestratorSelected, setOrchestratorSelected] = React.useState(null);
  const literals = useLiterals('moveConversationsModal');

  const handleSelectOrchestrator = (selected) => {
    setOrchestratorSelected(selected);
  };

  const handleSubmit = () => {
    onSubmit(orchestratorSelected.value);
  };

  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{literals.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {literals.info.replace('ORQ_NAME', orchestrator?.name)}
          <Select
            isMulti={false}
            options={orchestrators.map((orch) => ({ value: orch.id, label: orch.name }))}
            onChange={handleSelectOrchestrator}
            value={orchestratorSelected}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {literals.common.cancel}
        </Button>
        <Button
          className='btn-danger'
          onClick={handleSubmit}
          disabled={!orchestratorSelected}
        >
          {literals.common.save}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

MoveConversationsModal.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
  orchestrator: propTypes.object.isRequired,
  orchestrators: propTypes.array.isRequired,
};

export default MoveConversationsModal;
