import { v4 as uuidv4 } from 'uuid';
import { ELEMENT_DUE_DILIGENCE, ELEMENT_COMPANY } from 'constants/elements';
import {
  SET_COMPANIES, SET_DUE_DILIGENCES, ADD_COMPANY, ADD_DUE_DILIGENCE, SET_ELEMENTS,
} from './index';
import Api from './repository';

export const getAllElements = (offset = 0, limit = 1000) => async (dispatch) => {
  const response = await Api.getAllElements(offset, limit);
  dispatch({ type: SET_ELEMENTS, payload: response.elements });
  return response.elements;
};

export const getCompanies = () => async (dispatch) => {
  const response = await Api.getElements(ELEMENT_COMPANY);
  dispatch({
    type: SET_COMPANIES,
    payload: response.elements,
  });
  return response.elements;
};

export const getDueDiligences = (companyId) => async (dispatch) => {
  const response = await Api.getElements(`${ELEMENT_DUE_DILIGENCE}-${companyId}`);
  dispatch({
    type: SET_DUE_DILIGENCES,
    payload: response.elements,
  });
  return response.elements;
};

export const addCompany = (name) => async (dispatch) => {
  const elementType = ELEMENT_COMPANY;
  const elementId = uuidv4();
  const response = await Api.createElement(name, elementType, elementId);

  dispatch({
    type: ADD_COMPANY,
    payload: response,
  });
  return response;
};

export const addDueDiligence = (name, companyId) => async (dispatch) => {
  const elementType = `${ELEMENT_DUE_DILIGENCE}-${companyId}`;
  const elementId = uuidv4();
  const response = await Api.createElement(name, elementType, elementId);

  dispatch({
    type: ADD_DUE_DILIGENCE,
    payload: response,
  });
  return response;
};
