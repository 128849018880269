import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './styles.scss';

class Loading extends Component {
  render() {
    const { isLoading } = this.props;
    return (
      <div className={`loader-wrapper ${isLoading <= 0 ? 'hidden' : ''}`}>
        <span className='loader' />
      </div>
    );
  }
}

Loading.displayName = 'Loading';
Loading.propTypes = {
  isLoading: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    isLoading: state.loading.count,
  };
}

export default connect(mapStateToProps)(Loading);
