import { useSelector } from 'react-redux';
import { NODE_TYPES, TYPE_SET_CONNECTIONS, TYPE_CONNECTIONS } from 'constants/orchestrator';
import { TYPE_NUMBER } from 'constants/forms';

const useOrchestrator = (orchestrator) => {
  const { forms } = useSelector((state) => state.forms);

  const findVariableByName = (variableName, orchestratorAux, selectedVersion) => {
    const version = orchestratorAux.versions.find((v) => v.id === selectedVersion);
    const variable = version.variables.find((v) => v.name === variableName);
    if (variable) {
      return variable;
    }

    // Search in initial node Outputs
    const initialNode = orchestratorAux.children.find((node) => node.type === NODE_TYPES.INITIAL && node.orchestrator_version_id === selectedVersion);
    if (initialNode) {
      const output = initialNode.outputs.find((o) => o.name === variableName);
      if (output) {
        return output;
      }
    }

    // Search in forms
    const formNodes = orchestratorAux.children.filter((node) => node.type === NODE_TYPES.PRINT_FORM && node.orchestrator_version_id === selectedVersion);
    const formIds = formNodes.map((node) => node.inputs.find((input) => input.type === TYPE_CONNECTIONS.FORM)?.default_value?.id);
    const formsInOrchestrator = forms.filter((f) => formIds.includes(f.id));
    const formVariables = formsInOrchestrator.flatMap((form) => form.data?.questions);
    const formVariable = formVariables.find((v) => v.variable_name === variableName);
    if (formVariable) {
      const formTypesEquivalences = {
        [TYPE_NUMBER]: TYPE_CONNECTIONS.NUMBER,
      };
      const type = formTypesEquivalences[formVariable.type] || TYPE_CONNECTIONS.STRING;
      return {
        type,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: variableName,
      };
    }

    return null;
  };

  const getVariableByName = (variableName, selectedVersion, selectedFunction, selectedFunctionVersion) => {
    const variable = findVariableByName(variableName, orchestrator, selectedVersion);

    if (variable) {
      return variable;
    }

    // Search in selected function Inputs
    if (selectedFunction && selectedFunctionVersion) {
      const functionItem = orchestrator.functions.find((f) => f.id === selectedFunction);
      const variableFunction = findVariableByName(variableName, functionItem, selectedFunctionVersion);
      if (variableFunction) {
        return variableFunction;
      }
    }

    return null;
  };

  return {
    getVariableByName,
  };
};

export default useOrchestrator;
