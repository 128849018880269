import Api from './repository';

export const getWebsiteLinks = async (url) => {
  const response = await Api.getWebsiteLinks(url);
  return response;
};

export const getTrainedWebsiteLinks = async (chatbotId) => {
  const response = await Api.getTrainedWebsiteLinks(chatbotId);
  return response;
};

export const trainWebsite = async (chatbotId, website) => {
  const response = await Api.trainWebsite(chatbotId, website);
  return response;
};

export const deleteWebsite = async (chatbotId, websiteId) => {
  const response = await Api.deleteWebsite(chatbotId, websiteId);
  return response;
};
