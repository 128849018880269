import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { NODE_TYPES, TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from 'constants/orchestrator';
import VariableRow from './VariableRow';

function DebugOrchestratorWithParamsModal(props) {
  const {
    orchestrator, visible, onSubmit, onClose, selectedVersion,
  } = props;
  const [inputs, setInputs] = useState({});

  const literals = useLiterals('orchestrators');

  const handleSubmit = () => {
    onSubmit(inputs);
  };

  const handleChangeInput = (input) => {
    setInputs({
      ...inputs,
      [input.name]: input.default_value,
    });
  };

  const printInitialInputs = () => {
    const initialNode = orchestrator.children.find((node) => node.type === NODE_TYPES.INITIAL && node.orchestrator_version_id === selectedVersion);

    if (!initialNode) {
      return null;
    }

    const ignoredTypes = [TYPE_CONNECTIONS.EXEC, TYPE_CONNECTIONS.CONVERSATION, TYPE_CONNECTIONS.MESSAGE];
    const inputsToPrint = initialNode.outputs.filter((input) => !ignoredTypes.includes(input.type));
    if (!inputsToPrint.length) {
      return null;
    }
    return (
      <div>
        <div className='separator mt-0'>
          {literals.inputs}
        </div>
        <div className='plr-sm'>
          <div className='input_value'>
            {inputsToPrint.map((i) => {
              const input = {
                ...i,
                default_value: inputs[i.id] || inputs[i.name] || i.default_value || '',
              };
              return (<VariableRow key={`variable-row-${input.id}`} input={input} onChange={handleChangeInput} inputs={inputsToPrint} />);
            })}
          </div>
        </div>
      </div>
    );
  };

  if (!visible) {
    return null;
  }

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{literals.debugOrchestrator}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {printInitialInputs()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {literals.common.close}
        </Button>
        <Button
          variant='primary'
          onClick={handleSubmit}
        >
          {literals.common.accept}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

DebugOrchestratorWithParamsModal.propTypes = {
  orchestrator: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  selectedVersion: PropTypes.string.isRequired,
};

export default DebugOrchestratorWithParamsModal;
