export const SET_TOOLS = 'tools/set-tools';

export const initialState = {
  tools: null,
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_TOOLS: {
      return {
        ...state,
        tools: payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
