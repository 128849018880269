import { SET_ORGANIZATIONS, SET_INVITATIONS } from './index';
import Api from './repository';

export const getMyOrganizations = () => async (dispatch) => {
  const response = await Api.getMyOrganizations();
  dispatch({ type: SET_ORGANIZATIONS, payload: response.organizations });
  return response;
};

export const createOrganization = (organization) => async (dispatch) => {
  const response = await Api.createOrganization(organization);
  dispatch(getMyOrganizations());
  return response;
};

export const updateOrganization = (organizationId, organization) => async (dispatch) => {
  const response = await Api.updateOrganization(organizationId, organization);
  dispatch(getMyOrganizations());
  return response;
};

export const getMyInvitations = () => async (dispatch) => {
  const response = await Api.getMyInvitations();
  dispatch({ type: SET_INVITATIONS, payload: response });
};

export const createInvitation = (organizationId, invitation) => {
  return Api.createInvitation(organizationId, invitation);
};

export const acceptInvitation = (invitationId) => async (dispatch) => {
  const response = await Api.acceptInvitation(invitationId);
  dispatch(getMyInvitations());
  dispatch(getMyOrganizations());
  return response;
};

export const rejectInvitation = (invitationId) => async (dispatch) => {
  const response = await Api.rejectInvitation(invitationId);
  dispatch(getMyInvitations());
  dispatch(getMyOrganizations());
  return response;
};

export const deleteOrganization = (organizationId) => async (dispatch) => {
  const response = await Api.deleteOrganization(organizationId);
  dispatch(getMyOrganizations());
  return response;
};

export const getOrganizationLogo = async (organizationId) => {
  const response = await Api.getOrganizationLogo(organizationId);
  const imageBlob = await response.blob();
  return imageBlob;
};
export const getOrganizationMembers = async (organizationId) => {
  return Api.getOrganizationMembers(organizationId);
};
