import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

const URLS = {
  getDatastructures: 'datastructures',
  createDatastructure: 'datastructures',
  updateDatastructure: (datastructureId) => `datastructures/${datastructureId}`,
  deleteDatastructure: (datastructureId) => `datastructures/${datastructureId}`,
};

export default {
  async getDatastructures() {
    return ApiFetchInstance.get(URLS.getDatastructures);
  },
  async createDatastructure(data = null) {
    return ApiFetchInstance.post(URLS.createDatastructure, data);
  },
  async updateDatastructure(datastructureId, data = null) {
    return ApiFetchInstance.put(URLS.updateDatastructure(datastructureId), data);
  },
  async deleteDatastructure(datastructureId) {
    return ApiFetchInstance.delete(URLS.deleteDatastructure(datastructureId));
  },
};
