import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
// import Logo from 'assets/img/logo.svg';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'components/Modal';
import { ROUTE_PATH } from 'routes';

function LoginForm(props) {
  const {
    error,
    onSubmit,
    isLoading,
    password,
    confirmPassword,
    onChangePassword,
    onChangeConfirmPassword,
    showModalSuccess,
    showModalError,
    navigate,
    clearError,
  } = props;

  const literals = useLiterals('login');

  const handleChangePassword = (e) => {
    onChangePassword(e.target.value);
  };

  const handleChangeConfirmPassword = (e) => {
    onChangeConfirmPassword(e.target.value);
  };

  const passwordHasLength = () => {
    return password.length >= 10 && password.length <= 70;
  };

  const passwordContainsUpperLetter = () => {
    return /[A-Z]/.test(password);
  };

  const passwordContainsLowerLetter = () => {
    return /[a-z]/.test(password);
  };

  const passwordContainsNumber = () => {
    return /[0-9]/.test(password);
  };

  const passwordMatches = () => {
    return password === confirmPassword && password.length > 0;
  };

  const canContinue = () => {
    return passwordHasLength() && passwordContainsUpperLetter() && passwordContainsLowerLetter() && passwordContainsNumber() && passwordMatches();
  };

  return (
    <div className='login-form'>
      <Modal
        title={literals.passwordSuccessTitle}
        description={literals.passwordSuccessBody}
        confirmText={literals.accept}
        open={showModalSuccess}
        onClose={() => navigate(ROUTE_PATH.LOGIN)}
        onSubmit={() => navigate(ROUTE_PATH.LOGIN)}
      />
      <Modal
        title={literals.passwordFailTitle}
        description={literals.passwordFailBody}
        confirmText={literals.accept}
        open={showModalError}
        onClose={clearError}
        onSubmit={clearError}
      />
      <div className='login-bg'>
        {/* <img src={Logo} alt='logo' className='logo' /> */}
        <div className='welcome_text'>{literals.welcomeBack}</div>
      </div>
      <div className='llm-chatbot-form-content'>
        <div>
          <h1>{literals.setPassword}</h1>
          <h2 className='mb-md' style={{ marginBottom: '5px' }}>{literals.setPasswordDescription}</h2>
          <Form>
            <Form.Label className='mt-xl' htmlFor='password' style={{ marginTop: '0px' }}>{literals.password}</Form.Label>
            <Form.Control
              type='password'
              id='password'
              value={password}
              onChange={handleChangePassword}
            />
            <Form.Label className='mt-xl' htmlFor='confirmPassword'>{literals.confirmPassword}</Form.Label>
            <Form.Control
              type='password'
              id='confirmPassword'
              value={confirmPassword}
              onChange={handleChangeConfirmPassword}
            />
            <ul className='mt-3 checklist'>
              <li>
                <i className={`fa-solid fa-check ${passwordHasLength() ? 'success' : 'fail'}`} />
                {' '}
                {literals.passwordLengthRequisite}
              </li>
              <li>
                <i className={`fa-solid fa-check ${passwordContainsUpperLetter() ? 'success' : 'fail'}`} />
                {' '}
                {literals.mustContainUpperLetter}
              </li>
              <li>
                <i className={`fa-solid fa-check ${passwordContainsLowerLetter() ? 'success' : 'fail'}`} />
                {' '}
                {literals.mustContainLowerLetter}
              </li>
              <li>
                <i className={`fa-solid fa-check ${passwordContainsNumber() ? 'success' : 'fail'}`} />
                {' '}
                {literals.mustContainANumber}
              </li>
              <li>
                <i className={`fa-solid fa-check ${passwordMatches() ? 'success' : 'fail'}`} />
                {' '}
                {literals.passwordsMustMatch}
              </li>
            </ul>
            <Button
              variant='primary'
              className='mt-sm mb-xxl'
              onClick={onSubmit}
              disabled={!canContinue()}
            >
              {isLoading && (<i className='fa-solid fa-spinner fa-spin' />)}
              {literals.createPassword}
            </Button>
            {error && (<div className='error'>{literals[error] || error}</div>)}
            <div className='mt-xxl'>
              {literals.haveAnAccount}
              {' '}
              <a href='/login' className='font-semibold'>{literals.loginHere}</a>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

LoginForm.propTypes = {
  password: PropTypes.string.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onChangeConfirmPassword: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showModalSuccess: PropTypes.bool.isRequired,
  showModalError: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
};

export default LoginForm;
