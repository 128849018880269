import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.data.isSelectAllOption && 'white',
    background: state.data.isSelectAllOption && '#86cfd1',
  }),
};

function CreateTestForm(props) {
  const {
    onClose,
    initTest,
    isSaving,
    literals,
    questions,
    allOrganizations,
    allChatbots,
  } = props;

  const [name, setName] = useState('');
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [selectedChatbots, setSelectedChatbots] = useState([]);
  const SELECT_ALL_OPTION = { value: 'all', label: literals.selectAll, isSelectAllOption: true };

  const { actualOrganization } = useSelector((state) => state.app);

  useEffect(() => {
    if (actualOrganization) {
      setSelectedOrganizations(actualOrganization.id === 'personal' ? [] : [{ value: actualOrganization.id, label: actualOrganization.name }]);
    }
  }, [actualOrganization]);

  const handleOnChangeName = (event) => {
    setName(event.target.value);
  };

  const handleSelectQuestions = (selectedValues) => {
    const isSelectAll = selectedValues.some((value) => value.value === SELECT_ALL_OPTION.value);
    if (isSelectAll) {
      setSelectedQuestions(
        questions.map((question) => ({ value: question.id, label: question.title })),
      );
      return;
    }
    setSelectedQuestions(selectedValues);
  };

  const handleSelectOrganization = (selectedValues) => {
    setSelectedOrganizations(selectedValues);
  };

  const handleSelectChatbots = (selectedValues) => {
    setSelectedChatbots(selectedValues);
  };

  const handleInitTest = () => {
    initTest({
      name,
      questions: selectedQuestions.map((question) => question.value),
      organizations: selectedOrganizations.map((organization) => organization.value),
      chatbots: selectedChatbots.map((chatbot) => chatbot.value),
    });
  };

  const canInitTest = () => {
    return name && isSaving && selectedChatbots.length >= 1 && selectedQuestions.length >= 1;
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Iniciar Test</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className='input_title'>
            {literals.common.name}
            <span className='required_field'>*</span>
          </div>
          <div className='input_value'>
            <input type='text' className='form_input_stl' onChange={handleOnChangeName} value={name} />
          </div>
          <div className='panel-header'>
            {literals.chatbots}
            <span className='required_field'>*</span>
          </div>
          <div className='input_value'>
            <Select
              isMulti
              options={allChatbots.map((chatbot) => ({ value: chatbot.id, label: chatbot.name }))}
              onChange={handleSelectChatbots}
              value={selectedChatbots}
            />
          </div>
          <div className='panel-header'>
            {literals.testBattery}
            <span className='required_field'>*</span>
          </div>
          <div className='input_value'>
            <Select
              isMulti
              options={[
                SELECT_ALL_OPTION,
                ...questions.map((question) => ({ value: question.id, label: question.title })),
              ]}
              onChange={handleSelectQuestions}
              value={selectedQuestions}
              styles={customStyles}
            />
          </div>
          <div className='panel-header'>{literals.organizations}</div>
          <div className='input_title'>
            <Select
              isMulti
              options={allOrganizations.map((organization) => ({ value: organization.id, label: organization.name }))}
              onChange={handleSelectOrganization}
              value={selectedOrganizations}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {literals.common.close}
        </Button>
        <Button
          variant='primary'
          onClick={handleInitTest}
          disabled={canInitTest()}
        >
          {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
          {literals.common.accept}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CreateTestForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  initTest: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  literals: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  allOrganizations: PropTypes.array.isRequired,
  allChatbots: PropTypes.array.isRequired,
};

export default CreateTestForm;
