import {
  TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS,
  ORCHESTRATOR_TYPE_CONVERSATION, ORCHESTRATOR_TYPE_DOCUMENT_PROCESSOR, ORCHESTRATOR_TYPE_EMAIL_PROCESSOR,
} from '../orchestrator';

const CHATBOT_CATEGORY = 'Chatbot';

export default {
  FIND_CHATBOT: {
    categories: [CHATBOT_CATEGORY],
    tag: 'findChatbot',
    name: 'Find chatbot',
    tags: ['find', 'chatbot', 'get'],
    icon: 'fa-solid fa-robot',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'id',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.CHATBOT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'chatbot',
        custom: false,
        to: [],
      },
    ],
  },
  EXECUTE_CHATBOT: {
    categories: [CHATBOT_CATEGORY],
    tag: 'executeChatbot',
    name: 'Execute Chatbot',
    tags: ['llm'],
    icon: 'fa-solid fa-robot',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.CHATBOT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'chatbot',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.MESSAGE,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'message',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'variables',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'additional_system_message',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'stream',
        default_value: true,
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'response',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'request_tokens',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'response_tokens',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'total_cost',
        custom: false,
        to: [],
      },
    ],
  },
  SEND_MESSAGE: {
    categories: [CHATBOT_CATEGORY],
    tag: 'sendMessage',
    name: 'Send message',
    tags: ['text', 'chat'],
    icon: 'fa-solid fa-message',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.CONVERSATION,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'conversation',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'text',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'text',
        custom: false,
        to: [],
      },
    ],
  },
  SEND_PROMPT: {
    categories: [CHATBOT_CATEGORY],
    tag: 'sendPrompt',
    name: 'Send prompt',
    tags: ['llm', 'prompt', 'chat', 'send'],
    icon: 'fa-solid fa-robot',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'prompt',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.LLM_MODEL,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'llm_model',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'system_message',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'temperature',
        custom: false,
        default_value: '0.7',
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.DOCUMENT,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'attachments',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'response',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'request_tokens',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'response_tokens',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'total_cost',
        custom: false,
        to: [],
      },
    ],
  },
  CREATE_CHATBOT: {
    categories: [CHATBOT_CATEGORY],
    tag: 'createChatbot',
    name: 'Create chatbot',
    tags: ['create chatbot', 'chatbot', 'new chatbot', 'add chatbot'],
    icon: 'fa-solid fa-robot',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'name',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.LLM_MODEL,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'llm_model',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'debug_mode',
        custom: false,
        default_value: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'temperature',
        custom: false,
        default_value: '0.7',
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'ai_behavior',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'learning_context',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'search_in_indexed_data',
        custom: false,
        default_value: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ORCHESTRATOR,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'conversation_orchestrator',
        custom: false,
        orchestrator_type: ORCHESTRATOR_TYPE_CONVERSATION,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ORCHESTRATOR,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'documents_orchestrator',
        custom: false,
        orchestrator_type: ORCHESTRATOR_TYPE_DOCUMENT_PROCESSOR,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ORCHESTRATOR,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'emails_orchestrator',
        custom: false,
        orchestrator_type: ORCHESTRATOR_TYPE_EMAIL_PROCESSOR,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ORGANIZATION,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'organizations',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'can_embed',
        custom: false,
        default_value: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.CHATBOT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'chatbot',
        custom: false,
        to: [],
      },
    ],
  },
  UPDATE_CHATBOT: {
    categories: [CHATBOT_CATEGORY],
    tag: 'updateChatbot',
    name: 'Update chatbot',
    tags: ['update chatbot', 'chatbot', 'edit chatbot', 'set chatbot data', 'change chatbot data', 'modify chatbot'],
    icon: 'fa-solid fa-robot',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.CHATBOT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'chatbot',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'name',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.LLM_MODEL,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'llm_model',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'debug_mode',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'temperature',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'ai_behavior',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'learning_context',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'search_in_indexed_data',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ORCHESTRATOR,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'conversation_orchestrator',
        custom: false,
        orchestrator_type: ORCHESTRATOR_TYPE_CONVERSATION,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ORCHESTRATOR,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'documents_orchestrator',
        custom: false,
        orchestrator_type: ORCHESTRATOR_TYPE_DOCUMENT_PROCESSOR,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ORCHESTRATOR,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'emails_orchestrator',
        custom: false,
        orchestrator_type: ORCHESTRATOR_TYPE_EMAIL_PROCESSOR,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ORGANIZATION,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'organizations',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'can_embed',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.CHATBOT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'chatbot',
        custom: false,
        to: [],
      },
    ],
  },
  EXTRACT_CHATBOT_DATA: {
    categories: [CHATBOT_CATEGORY],
    tag: 'getChatbotData',
    name: 'Get chatbot data',
    tags: ['get chatbot data', 'chatbot data', 'extract chatbot data', 'chatbot', 'data', 'break chatbot data', 'retrieve chatbot data'],
    icon: 'fa-solid fa-robot',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.CHATBOT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'chatbot',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'id',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'name',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.LLM_MODEL,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'llm_model',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'debug_mode',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'temperature',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'ai_behavior',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'learning_context',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'search_in_indexed_data',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.ORCHESTRATOR,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'conversation_orchestrator',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.ORCHESTRATOR,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'documents_orchestrator',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.ORCHESTRATOR,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'emails_orchestrator',
        custom: false,
        orchestrator_type: ORCHESTRATOR_TYPE_EMAIL_PROCESSOR,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.ORGANIZATION,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'organizations',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.BOOLEAN,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'can_embed',
        custom: false,
        to: [],
      },
    ],
  },
};
