/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

export const URLS = {
  session: 'users/session',
  login: 'users/login',
  signup: 'users/signup',
  refreshTokens: 'users/token/refresh',
  setPassword: 'users/password',
  logout: 'users/session',
  forgotPassword: 'users/recoveryPassword',
  saveExtraField: 'users/extra',
};

export default {
  async getSession() {
    return ApiFetchInstance.get(URLS.session);
  },
  async signup(email, firstname, lastname) {
    const data = { email, firstname, lastname };
    return ApiFetchInstance.post(URLS.signup, data);
  },
  async setPassword(password, token) {
    const data = { password, token };
    return ApiFetchInstance.post(URLS.setPassword, data);
  },
  async forgotPassword(email) {
    const data = { email };
    return ApiFetchInstance.post(URLS.forgotPassword, data);
  },
  async login(email, password) {
    const data = { email, password };
    return ApiFetchInstance.post(URLS.login, data);
  },
  async refreshTokens() {
    return ApiFetchInstance.post(URLS.refreshTokens);
  },
  async logout() {
    return ApiFetchInstance.delete(URLS.logout);
  },
  async saveExtraField(field, value) {
    const data = { field, value };
    return ApiFetchInstance.post(URLS.saveExtraField, data);
  },
};
