import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

const URLS = {
  getAPIs: 'apis',
  createApi: 'api',
  updateApi: (apiId) => `api/${apiId}`,
  deleteApi: (apiId) => `api/${apiId}`,
  getApiEndpoints: (apiId) => `api/${apiId}/endpoints`,
  createApiEndpoint: (apiId) => `api/${apiId}/endpoints`,
  updateApiEndpoint: (apiId, endpointId) => `api/${apiId}/endpoints/${endpointId}`,
  patchApiEndpoint: (apiId, endpointId) => `api/${apiId}/endpoints/${endpointId}`,
  deleteApiEndpoint: (apiId, endpointId) => `api/${apiId}/endpoints/${endpointId}`,
  changeStatusApiEndpoints: (apiId) => `api/${apiId}/endpoints-status`,
  loginInChatbotApi: (chatbotId, conversationId, apiId) => `chatbots/${chatbotId}/conversations/${conversationId}/api/${apiId}/login`,
  sendOtpInChatbotApi: (chatbotId, conversationId, apiId) => `chatbots/${chatbotId}/conversations/${conversationId}/api/${apiId}/send-otp`,
  verifyOtpInChatbotApi: (chatbotId, conversationId, apiId) => `chatbots/${chatbotId}/conversations/${conversationId}/api/${apiId}/verify-otp`,
  executeRequest: (chatbotId, conversationId, messageId) => `chatbots/${chatbotId}/conversations/${conversationId}/messages/${messageId}/execute_request`,
};

export default {
  async getAPIs(offset = 0, limit = 1000) {
    const url = `${URLS.getAPIs}?offset=${offset}&limit=${limit}`;
    return ApiFetchInstance.get(url);
  },
  async createApi(data = null) {
    return ApiFetchInstance.post(URLS.createApi, data);
  },
  async updateApi(apiId, data = null) {
    return ApiFetchInstance.put(URLS.updateApi(apiId), data);
  },
  async deleteApi(apiId) {
    return ApiFetchInstance.delete(URLS.deleteApi(apiId));
  },
  async getApiEndpoints(apiId) {
    return ApiFetchInstance.get(URLS.getApiEndpoints(apiId));
  },
  async createApiEndpoint(apiId, data = null) {
    return ApiFetchInstance.post(URLS.createApiEndpoint(apiId), data);
  },
  async updateApiEndpoint(apiId, endpointId, data = null) {
    return ApiFetchInstance.put(URLS.updateApiEndpoint(apiId, endpointId), data);
  },
  async patchApiEndpoint(apiId, endpointId, data = null) {
    return ApiFetchInstance.patch(URLS.patchApiEndpoint(apiId, endpointId), data);
  },
  async deleteApiEndpoint(apiId, endpointId) {
    return ApiFetchInstance.delete(URLS.deleteApiEndpoint(apiId, endpointId));
  },
  async changeStatusApiEndpoints(apiId, active) {
    return ApiFetchInstance.put(URLS.changeStatusApiEndpoints(apiId), { active });
  },
  async loginInChatbotApi(chatbotId, conversationId, apiId, data = null) {
    return ApiFetchInstance.post(URLS.loginInChatbotApi(chatbotId, conversationId, apiId), data);
  },
  async sendOtpInChatbotApi(chatbotId, conversationId, apiId, data = null) {
    return ApiFetchInstance.post(URLS.sendOtpInChatbotApi(chatbotId, conversationId, apiId), data);
  },
  async verifyOtpInChatbotApi(chatbotId, conversationId, apiId, data = null) {
    return ApiFetchInstance.post(URLS.verifyOtpInChatbotApi(chatbotId, conversationId, apiId), data);
  },
  async executeRequest(chatbotId, conversationId, messageId) {
    return ApiFetchInstance.post(URLS.executeRequest(chatbotId, conversationId, messageId));
  },
};
