import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector, useDispatch } from 'react-redux';
import { getCompanies, addCompany } from 'modules/elements/actions';
import { useNavigate } from 'react-router-dom';
import Form from './components/form';

function Companies() {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [name, setName] = useState('');
  const { companies } = useSelector((state) => state.elements);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  const openCreateForm = () => {
    setShowCreateForm(true);
  };

  const closeCreateForm = () => {
    setShowCreateForm(false);
  };

  const handleAcceptCompany = () => {
    if (name) {
      dispatch(addCompany(name));
      closeCreateForm();
      setName('');
    }
  };

  const handleChangeName = (newName) => {
    setName(newName);
  };

  const printNoElements = () => {
    return (
      <div className='page_content'>
        <div className='no_elements'>There is no companies</div>
      </div>
    );
  };

  const printTable = () => {
    const rows = companies.map((company) => {
      return (
        <tr key={company.element_id}>
          <td>{company.name}</td>
          <td className='right'>
            <button
              className='btn btn-primary'
              type='button'
              onClick={() => navigate(`/companies/${company.element_id}`)}
            >
              Access
            </button>
          </td>
        </tr>
      );
    });

    return (
      <div>
        <table className='table_stl1'>
          <thead>
            <tr>
              <th>Company</th>
              <th className='right'>Options</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const printCreationModal = () => {
    if (!showCreateForm) {
      return null;
    }

    return (
      <Modal show onHide={closeCreateForm}>
        <Modal.Header closeButton>
          <Modal.Title>Add company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            name={name}
            onChangeName={handleChangeName}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeCreateForm}>
            Close
          </Button>
          <Button variant='primary' onClick={handleAcceptCompany}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {printCreationModal()}
      <Layout selectedSection='chatbots'>
        <div className='section'>
          <div className='section_header'>
            <h1>Companies</h1>
            <div className='btn btn-primary' onClick={openCreateForm}>Add company</div>
          </div>
          <div className='mt-lg'>
            {companies.length ? printTable() : printNoElements()}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Companies;
