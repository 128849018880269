import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

const URLS = {
  getConversations: (chatbotId) => `chatbots/${chatbotId}/conversations`,
  getConversation: (chatbotId, conversationId) => `chatbots/${chatbotId}/conversations/${conversationId}`,
  getConversationsStats: (chatbotId) => `orchestrators/${chatbotId}/conversations-stats`,
  createConversation: (chatbotId) => `chatbots/${chatbotId}/conversations`,
  updateConversation: (chatbotId, conversationId) => `chatbots/${chatbotId}/conversations/${conversationId}`,
  deleteConversation: (chatbotId, conversationId) => `chatbots/${chatbotId}/conversations/${conversationId}`,
  deleteConversations: (chatbotId) => `chatbots/${chatbotId}/conversations`,
};

export default {
  async getConversations(chatbotId, offset = 0, limit = 100) {
    const url = `${URLS.getConversations(chatbotId)}?offset=${offset}&limit=${limit}`;
    return ApiFetchInstance.get(url);
  },
  async getConversation(chatbotId, conversationId) {
    return ApiFetchInstance.get(URLS.getConversation(chatbotId, conversationId));
  },
  async getConversationsStats(chatbotId, offset, limit, sortType, sortField, startDate, endDate, search) {
    const url = `${URLS.getConversationsStats(chatbotId)}?&offset=${offset}&limit=${limit}&sort_type=${sortType}&sort_field=${sortField}&start_date=${startDate}&end_date=${endDate}&search=${search}`;
    return ApiFetchInstance.get(url);
  },
  async downloadCSV(chatbotId, type, startDate, endDate) {
    const url = `${URLS.getConversationsStats(chatbotId)}?type=${type}&start_date=${startDate}&end_date=${endDate}`;
    return ApiFetchInstance.get(url);
  },
  async createConversation(id, chatbotId, name, variables) {
    const params = { id, name, variables };
    return ApiFetchInstance.post(URLS.createConversation(chatbotId), params);
  },
  async updateConversation(chatbotId, conversationId, data) {
    return ApiFetchInstance.patch(URLS.updateConversation(chatbotId, conversationId), data);
  },
  async deleteConversation(chatbotId, conversationId) {
    return ApiFetchInstance.delete(URLS.deleteConversation(chatbotId, conversationId));
  },
  async deleteConversations(chatbotId) {
    return ApiFetchInstance.delete(URLS.deleteConversations(chatbotId));
  },
};
