import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useLiterals from 'hooks/useLiterals';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {
  createChatbot, updateChatbot, deleteChatbot, deleteChatbots,
} from 'modules/chatbots/actions';
import { getErrorMessage } from 'utils/errors';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownMenuToggle from 'components/DropdownMenuToggle';
import Layout from 'components/Layout';
import Form from './components/form';

function Chatbots() {
  const literals = useLiterals('chatbots');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tools } = useSelector((state) => state.tools);
  const { chatbots } = useSelector((state) => state.chatbots);
  const { apis } = useSelector((state) => state.apis);
  const { actualOrganization } = useSelector((state) => state.app);
  const { organizations: userOrganizations } = useSelector((state) => state.organizations);
  const { orchestrators } = useSelector((state) => state.orchestrators);

  // Form creation/edition
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [selectedChatbot, setSelectedChatbot] = useState(0);
  const [name, setName] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [errorForm, setErrorForm] = useState(null);

  const [chatbotToDelete, setChatbotToDelete] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);

  useEffect(() => {
    if (actualOrganization) {
      setOrganizations(actualOrganization.id === 'personal' ? [] : [{ value: actualOrganization.id, label: actualOrganization.name }]);
    } else {
      setOrganizations([]);
    }
  }, [actualOrganization]);

  const handleDeleteAllChats = () => {
    setDeleteAll(true);
    setShowConfirmation(true);
  };

  const handleDeleteChat = (event) => {
    const id = event.currentTarget.getAttribute('data-id');
    setChatbotToDelete(id);
    setShowConfirmation(true);
  };
  const handlePositiveConfirmation = async () => {
    if (deleteAll) {
      // Eliminar todas las conversaciones
      await dispatch(deleteChatbots());
    } else if (chatbotToDelete) {
      // Eliminar una conversación específica
      await dispatch(deleteChatbot(chatbotToDelete));
    }
    setDeleteAll(false);
    setChatbotToDelete(null);
    setShowConfirmation(false);
  };

  const handleChangeName = (newName) => {
    setName(newName);
  };

  const handleChangeOrganizations = (newOrganizations) => {
    setOrganizations(newOrganizations);
  };

  const createNewChatbot = () => {
    setName('');
    setSelectedChatbot(0);
    setShowCreateForm(true);
    setErrorForm(null);
  };

  const closeCreateForm = () => {
    setShowCreateForm(false);
  };

  const closeDeleteChatbotForm = () => {
    setDeleteAll(false);
    setChatbotToDelete(null);
    setShowConfirmation(false);
    setErrorForm(null);
  };

  const canSave = () => {
    return name !== '' && !isSaving;
  };

  const saveChatbot = async () => {
    setIsSaving(true);
    setErrorForm(null);
    try {
      const organizationsIds = organizations.map((org) => org.value);
      if (selectedChatbot) {
        await dispatch(updateChatbot(selectedChatbot, name, [], null, null, null, null, null, null, organizationsIds));
      } else {
        await dispatch(createChatbot(name, organizationsIds));
      }
      closeCreateForm();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      setErrorForm(literals.errors[errorMessage]);
    } finally {
      setIsSaving(false);
    }
  };

  const getFilteredChatbots = () => {
    if (!chatbots) {
      return [];
    }

    if (!actualOrganization) {
      return chatbots.filter((chatbot) => !chatbot.is_from_orchestrator);
    }

    if (actualOrganization.id === 'personal') {
      return chatbots.filter((chatbot) => !chatbot.is_from_orchestrator && (!chatbot.organizations || !chatbot.organizations.length));
    }

    return chatbots.filter((chatbot) => !chatbot.is_from_orchestrator && chatbot.organizations && chatbot.organizations.includes(actualOrganization.id));
  };

  const printTable = () => {
    const filteredChatbots = getFilteredChatbots();
    const rows = filteredChatbots.map((chatbot) => {
      if (!chatbot) {
        return null;
      }
      const organizationsNames = [];
      if (chatbot.organizations && chatbot.organizations.length) {
        chatbot.organizations.forEach((organizationId) => {
          if (organizationId) {
            const organization = userOrganizations.find((org) => org.id === organizationId);
            if (organization && organizationsNames) {
              organizationsNames.push(organization.name);
            }
          }
        });
      }

      const orchestratorName = chatbot.conversation_orchestrator ? orchestrators && orchestrators.find((orchestrator) => orchestrator.id === chatbot.conversation_orchestrator)?.name : '';
      return (
        <tr key={chatbot.id} data-id={chatbot.id}>
          <td data-cy='chatbot-list-name'>{chatbot.name}</td>
          <td>{orchestratorName}</td>
          <td>{organizationsNames.join(', ')}</td>
          <td className='right'>
            <div className='buttons-container' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                className='btn btn-primary btn-sm'
                type='button'
                onClick={() => navigate(`/chatbots/${chatbot.id}`)}
                data-cy='chatbot-access-button'
              >
                {literals.common.access}
              </button>
              <Dropdown className='dropdown-menu-expand'>
                <Dropdown.Toggle as={DropdownMenuToggle} id={`dropdown-custom-components-${chatbot.id}`} />
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey='1'
                    onClick={handleDeleteChat}
                    className='text-danger'
                    data-id={chatbot.id}
                  >
                    {literals.common.delete}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </td>
        </tr>
      );
    });
    return (
      <div>
        <table className='table_stl1' data-cy='chatbot-list'>
          <thead>
            <tr>
              <th>{literals.common.name}</th>
              <th>{literals.orchestrator}</th>
              <th>{literals.organizations}</th>
              <th className='right'>{literals.common.options}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  const printNoElements = () => {
    return (
      <div className='page_content'>
        <div className='no_elements'>{literals.thereIsNoChatbots}</div>
      </div>
    );
  };

  if (tools === null || chatbots === null) {
    return null;
  }

  const printModalForm = () => {
    if (!showCreateForm) {
      return null;
    }

    return (
      <Modal show onHide={closeCreateForm}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedChatbot !== 0 ? literals.editChatbot : literals.createChatbot}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            name={name}
            chatbotOrganizations={organizations}
            error={errorForm}
            onChangeName={handleChangeName}
            onChangeOrganizations={handleChangeOrganizations}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeCreateForm}>
            {literals.common.close}
          </Button>
          <Button
            data-cy='create-chatbot-button'
            variant='primary'
            onClick={saveChatbot}
            disabled={!canSave()}
          >
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {literals.common.accept}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const printDeleteForm = () => {
    if (!showConfirmation) {
      return null;
    }
    const confirmationText = deleteAll ? 'Borrar todos los Chatbots' : 'Borrar el Chatbot';
    return (
      <Modal show onHide={closeDeleteChatbotForm}>
        <Modal.Header closeButton>
          <Modal.Title>{confirmationText}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeDeleteChatbotForm}>
            {literals.common.close}
          </Button>
          <Button
            variant='primary'
            onClick={handlePositiveConfirmation}
            data-cy='chatbot-delete-confirm-button'
          >
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {literals.common.accept}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  return (
    <>
      {printModalForm()}
      {printDeleteForm()}
      <Layout selectedSection='chatbots'>
        <div className='section'>
          <div className='section_header'>
            <h1>{literals.chatbots}</h1>
            <div>
              <div className='btn btn-primary' onClick={createNewChatbot} data-cy='create-chatbot-modal-button'>
                {literals.createChatbot}
              </div>
              {/* <div className='btn btn-danger' onClick={handleDeleteAllChats}>
                {literals.deleteAllChat}
              </div> */}
            </div>
          </div>
          <div className='mt-lg'>
            {getFilteredChatbots().length ? printTable() : printNoElements()}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Chatbots;
