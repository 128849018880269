import React, { useEffect, useState, useRef } from 'react';
import Layout from 'components/Layout';
import useLiterals from 'hooks/useLiterals';
import {
  getApiKeys, createApiKey, deleteApiKey, updateApiKey,
} from 'modules/apiKeys/actions';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import moment from 'moment';
import ConfirmModal from 'components/ConfirmModal';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownMenuToggle from 'components/DropdownMenuToggle';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { copyTextInClipboard } from 'utils/text';

function ApiKeys() {
  const [apiKeys, setApiKeys] = useState(null);
  const [apiKeyToDelete, setApiKeyToDelete] = useState(null);

  // Creation fields
  const [apiKeyToEdit, setApiKeyToEdit] = useState(null);
  const [showCreateApiKeyForm, setShowCreateApiKeyForm] = useState(false);
  const [name, setName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [createdApiKey, setCreatedApiKey] = useState(null);

  const apiKeyInputRef = useRef(null);
  const clientSecretInputRef = useRef(null);

  const literals = useLiterals('apiKeys');

  const { showErrorModal, printErrorModal } = useErrorHandling();

  const loadInitialData = async () => {
    try {
      const response = await getApiKeys();
      setApiKeys(response.api_keys);
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    }
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const handleOnChangeName = (event) => {
    setName(event.target.value);
  };

  const handleCreateApiKey = () => {
    setName('');
    setApiKeyToEdit(null);
    setShowCreateApiKeyForm(true);
  };

  const handleEditApiKey = (event) => {
    const apiKeyId = event.currentTarget.getAttribute('data-id');
    const apiKey = apiKeys.find((ak) => ak.id === apiKeyId);
    if (apiKey) {
      setName(apiKey.name);
      setApiKeyToEdit(apiKey.id);
      setShowCreateApiKeyForm(true);
    }
  };

  const handleCloseCreateApiKeyForm = () => {
    setShowCreateApiKeyForm(false);
  };

  const handleSaveApiKey = async () => {
    const params = { name };

    try {
      setIsSaving(true);
      if (apiKeyToEdit) {
        await updateApiKey(apiKeyToEdit, params);
        const newApiKeys = apiKeys.map((apiKey) => {
          if (apiKey.id === apiKeyToEdit) {
            return { ...apiKey, name };
          }
          return apiKey;
        });
        setApiKeys(newApiKeys);
      } else {
        const response = await createApiKey(params);
        setCreatedApiKey(response);
        setApiKeys((oldValue) => [...oldValue, response]);
      }
      setIsSaving(false);
      setShowCreateApiKeyForm(false);
    } catch (err) {
      setIsSaving(false);
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    }
  };

  const canSave = () => {
    return name.length > 0;
  };

  const handleCloseCreatedApiKeyModal = () => {
    setCreatedApiKey(null);
  };

  const handleDeleteApiKey = (event) => {
    const apiKeyId = event.currentTarget.getAttribute('data-id');
    setApiKeyToDelete(apiKeyId);
  };

  const handleCloseDeleteApiKeyModal = () => {
    setApiKeyToDelete(null);
  };

  const handleCopyApiKey = () => {
    copyTextInClipboard(createdApiKey.api_key);

    const input = apiKeyInputRef.current;
    input.disabled = false; // Temporalmente habilita el input para seleccionar el texto
    input.focus();
    input.select();
    input.setSelectionRange(0, 99999); // Para dispositivos móviles
    input.disabled = true; // Vuelve a deshabilitar el input
  };

  const handleCopyClientSecret = () => {
    copyTextInClipboard(createdApiKey.client_secret);

    const input = clientSecretInputRef.current;
    input.disabled = false; // Temporalmente habilita el input para seleccionar el texto
    input.focus();
    input.select();
    input.setSelectionRange(0, 99999); // Para dispositivos móviles
    input.disabled = true; // Vuelve a deshabilitar el input
  };

  const handleConfirmDeleteApiKey = async () => {
    if (!apiKeyToDelete) {
      return;
    }

    try {
      await deleteApiKey(apiKeyToDelete);
      setApiKeys((oldValue) => oldValue.filter((apiKey) => apiKey.id !== apiKeyToDelete));
      setApiKeyToDelete(null);
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    }
  };

  const printDeleteApiKeyModal = () => {
    if (apiKeyToDelete === null) {
      return null;
    }

    return (
      <ConfirmModal
        open
        onClose={handleCloseDeleteApiKeyModal}
        onSubmit={handleConfirmDeleteApiKey}
        title={literals.deleteApiKey}
        message={literals.deleteApiKeyConfirm}
      />
    );
  };

  const printCreateApiKeyForm = () => {
    if (!showCreateApiKeyForm) {
      return null;
    }

    return (
      <Modal show onHide={handleCloseCreateApiKeyForm}>
        <Modal.Header closeButton>
          <Modal.Title>{literals.createApiKey}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='input_title'>
            {literals.common.name}
          </div>
          <div className='input_value'>
            <input type='text' className='form_input_stl' onChange={handleOnChangeName} value={name} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseCreateApiKeyForm}>
            {literals.common.close}
          </Button>
          <Button
            data-cy='create-chatbot-button'
            variant='primary'
            onClick={handleSaveApiKey}
            disabled={!canSave()}
          >
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {literals.common.accept}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const printCreatedApiKeyModal = () => {
    if (createdApiKey === null) {
      return null;
    }

    return (
      <Modal show onHide={handleCloseCreatedApiKeyModal}>
        <Modal.Header closeButton>
          <Modal.Title>{literals.createApiKey}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-xl'>{`${literals.apiKeyCreatedInfo}:`}</div>
          <div className='input_title'>
            {literals.apiKey}
          </div>
          <div className='input_value'>
            <input type='text' className='form_input_stl' disabled value={createdApiKey.api_key} ref={apiKeyInputRef} />
            <button type='button' className='btn-clipboard-input' aria-label={literals.common.copy} onClick={handleCopyApiKey}>
              <i className='fa-regular fa-clipboard' />
            </button>
          </div>
          <div className='input_title'>
            {literals.clientSecret}
          </div>
          <div className='input_value'>
            <input type='text' className='form_input_stl' disabled value={createdApiKey.client_secret} ref={clientSecretInputRef} />
            <button type='button' className='btn-clipboard-input' aria-label={literals.common.copy} onClick={handleCopyClientSecret}>
              <i className='fa-regular fa-clipboard' />
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseCreatedApiKeyModal}>
            {literals.common.close}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const printApiKeysTable = () => {
    if (apiKeys === null) {
      return null;
    }

    if (apiKeys.length === 0) {
      return (
        <div className='page_content'>
          <div className='no_elements'>{literals.thereIsNoApiKeys}</div>
        </div>
      );
    }

    const rows = apiKeys.map((apiKey) => (
      <tr key={apiKey.id} data-id={apiKey.id}>
        <td>{apiKey.name}</td>
        <td>{apiKey.api_key}</td>
        <td>{moment(apiKey.created_at).format('YYYY-MM-DD')}</td>
        <td className='right'>
          <button type='button' className='btn btn-primary btn-sm' data-id={apiKey.id} onClick={handleEditApiKey}>
            {literals.common.edit}
          </button>
          <Dropdown className='dropdown-menu-expand'>
            <Dropdown.Toggle as={DropdownMenuToggle} id={`dropdown-custom-components-${apiKey.id}`} />
            <Dropdown.Menu>
              <Dropdown.Item
                eventKey='1'
                onClick={handleDeleteApiKey}
                className='text-danger'
                data-id={apiKey.id}
              >
                {literals.common.delete}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    ));

    return (
      <table className='table_stl1 mt-xxl' data-cy='api-keys-list'>
        <thead>
          <tr>
            <th>{literals.common.name}</th>
            <th>{literals.apiKey}</th>
            <th>{literals.common.createdAt}</th>
            <th className='right'>{literals.common.options}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  };

  return (
    <Layout selectedSection='apiKeys'>
      {printErrorModal()?.modal}
      {printCreateApiKeyForm()}
      {printDeleteApiKeyModal()}
      {printCreatedApiKeyModal()}
      <div className='section'>
        <div className='section_header'>
          <h1>{literals.title}</h1>
          <div>
            <button type='button' className='btn btn-primary' onClick={handleCreateApiKey}>
              {literals.createApiKey}
            </button>
          </div>
        </div>
        <div className='mt-lg'>
          {literals.info1}
        </div>
        {printApiKeysTable()}
      </div>
    </Layout>
  );
}

export default ApiKeys;
