import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import useLiterals from 'hooks/useLiterals';
import { createDatatable, updateDatatable } from 'modules/datatables/actions';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';

function DatatableForm(props) {
  const {
    id: idProp, name: nameProp, description: descriptionProp, datastructureId: datastructureIdProp,
    organizations: organizationsProp,
    onSave, onClose,
  } = props;

  const [id] = useState(idProp || null);
  const [name, setName] = useState(nameProp || '');
  const [description, setDescription] = useState(descriptionProp || '');
  const [datatableOrganizations, setDatatableOrganizations] = useState(organizationsProp || []);
  const [datastructureId, setDatastructureId] = useState(datastructureIdProp || '');
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();
  const literals = useLiterals('datatables');

  const { organizations } = useSelector((state) => state.organizations);
  const { actualOrganization } = useSelector((state) => state.app);
  const { datastructures } = useSelector((state) => state.datastructures);

  const { showErrorModal, printErrorModal } = useErrorHandling();

  useEffect(() => {
    if (!id && actualOrganization) {
      setDatatableOrganizations(actualOrganization.id === 'personal' ? [] : [{ value: actualOrganization.id, label: actualOrganization.name }]);
    }
  }, [actualOrganization]);

  useEffect(() => {
    if (id && organizations && organizations.length > 0 && organizationsProp.length > 0) {
      const selectedOrganizations = organizations.filter((organization) => organizationsProp.includes(organization.id));
      setDatatableOrganizations(selectedOrganizations.map((organization) => ({ value: organization.id, label: organization.name })));
    }
  }, [id, organizations, organizationsProp]);

  const handleSaveDatatable = async () => {
    try {
      setIsSaving(true);
      const organizationsIds = datatableOrganizations.map((organization) => organization.value);
      const data = {
        name,
        description,
        datastructure_id: datastructureId,
        organizations: organizationsIds,
      };
      await dispatch(id ? updateDatatable(id, data) : createDatatable(data));
      onSave();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  const canSave = () => {
    return datastructureId && name.length > 0 && !isSaving;
  };

  const handleOnChangeName = (event) => {
    setName(event.target.value);
  };

  const handleOnChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleSelectOrganization = (selectedValues) => {
    setDatatableOrganizations(selectedValues);
  };

  const handleSelectDatastructure = (event) => {
    setDatastructureId(event.target.value);
  };

  return (
    <>
      {printErrorModal()?.modal}
      <Modal show size='lg' onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{id ? literals.editDatatable : literals.addDatatable}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className='input_title'>
              {literals.common.name}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeName} value={name} />
            </div>
            <div className='input_title'>
              {literals.common.description}
            </div>
            <div className='input_value'>
              <textarea className='form_input_stl' onChange={handleOnChangeDescription} value={description} />
            </div>
            <div className='input_title'>
              {literals.datastructure}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <select className='form_select_stl' onChange={handleSelectDatastructure} value={datastructureId}>
                <option value=''>{literals.selectDatastructure}</option>
                {datastructures.map((datastructure) => (
                  <option key={datastructure.id} value={datastructure.id}>{datastructure.name}</option>
                ))}
              </select>
            </div>
            <div className='input_title'>{literals.common.organizations}</div>
            <div className='input_value'>
              <Select
                isMulti
                options={organizations.map((organization) => ({ value: organization.id, label: organization.name }))}
                onChange={handleSelectOrganization}
                value={datatableOrganizations}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClose}>
            {literals.common.close}
          </Button>
          <Button
            variant='primary'
            onClick={handleSaveDatatable}
            disabled={!canSave()}
          >
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {literals.common.accept}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

DatatableForm.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  organizations: PropTypes.array,
  datastructureId: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

DatatableForm.defaultProps = {
  id: null,
  name: '',
  description: '',
  organizations: [],
  datastructureId: 0,
};

export default DatatableForm;
