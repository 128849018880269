import React from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
// import Logo from 'assets/img/logo_white.png';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import isEmail from 'validator/lib/isEmail';

function LoginForm(props) {
  const {
    email, password, error, onChangeEmail, onChangePassword, onSubmit,
    isLoading,
  } = props;

  const literals = useLiterals('login');

  const handleChangeEmail = (e) => {
    onChangeEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    onChangePassword(e.target.value);
  };

  const passwordHasMinimum8Characters = () => {
    return password.length >= 8;
  };

  const passwordHasNumbersAndLetters = () => {
    const regex = /[a-zA-Z]+.*[0-9]+|[0-9]+.*[a-zA-Z]+/;
    return regex.test(password);
  };

  const isValidPassword = () => {
    return passwordHasMinimum8Characters() && passwordHasNumbersAndLetters();
  };

  const canContinue = () => {
    return isEmail(email) && isValidPassword(password);
  };

  return (
    <div className='login-form'>
      <div className='login-bg'>
        {/* <img src={Logo} alt='logo' className='logo' /> */}
        <div className='welcome_text'>{literals.welcomeBack}</div>
      </div>
      <div className='llm-chatbot-form-content'>
        <div>
          <h1>{literals.login}</h1>
          <h2 className='mb-md'>{literals.welcomeBackDescription}</h2>
          <Form>
            <Form.Label className='mt-xl' htmlFor='email'>{literals.email}</Form.Label>
            <Form.Control
              type='email'
              id='inputPassword5'
              value={email}
              onChange={handleChangeEmail}
              data-cy='username'
            />
            <Form.Label className='mt-xl' htmlFor='inputPassword5'>{literals.password}</Form.Label>
            <Form.Control
              type='password'
              id='inputPassword5'
              value={password}
              onChange={handleChangePassword}
              data-cy='password'
            />
            <div className='text-align-right mt-sm mb-sm'>
              <a href='/forgot-password' className='text-explanation'>{literals.forgotPassword}</a>
            </div>
            <Button
              variant='primary'
              className='mt-sm mb-xxl'
              onClick={onSubmit}
              disabled={!canContinue()}
              data-cy='login-button'
            >
              {isLoading && (<i className='fa-solid fa-spinner fa-spin' />)}
              {literals.login}
            </Button>
            {error && (<div className='error'>{literals[error] || error}</div>)}
            <div className='mt-xxl'>
              {literals.newHere}
              {' '}
              <a href='/signup' className='font-semibold'>{literals.createAccount}</a>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

LoginForm.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LoginForm;
