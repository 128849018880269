import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import { getErrorMessage } from 'utils/errors';
import { loginInChatbotApi } from 'modules/apis/actions';
import { deleteMessage } from 'modules/chats/actions';
import useMessages from 'hooks/useMessages';

function ApiLoginBasicAuth(props) {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [error, setError] = useState('');

  const {
    dataAuth, chatbotId, conversationId, messageId,
  } = props;

  const literals = useLiterals('chat');
  const { getPreviousMessage, retryMessage } = useMessages(conversationId);

  const handleChangeUsername = (e) => {
    setUserName(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const canContinue = () => {
    return username && password;
  };

  const loginInApi = async () => {
    if (!isLoading) {
      setIsLoading(true);
      setError('');
      try {
        const prevMessage = getPreviousMessage(messageId);
        await loginInChatbotApi(chatbotId, conversationId, dataAuth.id, { username, password, message_id: messageId });
        deleteMessage(conversationId, messageId);
        retryMessage(chatbotId, prevMessage.id);
      } catch (err) {
        const errorMessage = getErrorMessage(err);
        setError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className='login-api-form'>
      {`${literals.signinApiBasicAuth1} `}
      <b>
        {dataAuth.name}
      </b>
      {` ${literals.signinApiBasicAuth2}`}
      <div className='input_value'>
        <input type='text' className='form_input_stl' onChange={handleChangeUsername} value={username} placeholder={dataAuth.auth_params.basic_username_fieldname} />
      </div>
      <div className='input_value'>
        <input type='password' className='form_input_stl' onChange={handleChangePassword} value={password} placeholder={dataAuth.auth_params.basic_password_fieldname} />
      </div>
      <div className='form_buttons'>
        <button type='button' className='btn btn-message-form btn-xs' disabled={!canContinue()} onClick={loginInApi}>
          {isLoading && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
          {literals.login}
        </button>
      </div>
      {error && <div className='error-in-message'>{error}</div>}
    </div>
  );
}

ApiLoginBasicAuth.propTypes = {
  dataAuth: PropTypes.object.isRequired,
  chatbotId: PropTypes.string.isRequired,
  conversationId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
};

export default ApiLoginBasicAuth;
