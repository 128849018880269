export const SET_DATASTRUCTURES = 'datastructures/set-datastructures';
export const CREATE_DATASTRUCTURE = 'datastructures/create-datastructure';
export const UPDATE_DATASTRUCTURE = 'datastructures/update-datastructure';
export const DELETE_DATASTRUCTURE = 'datastructures/delete-datastructure';

export const initialState = {
  datastructures: null,
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_DATASTRUCTURES:
      return {
        ...state,
        datastructures: payload,
      };
    case CREATE_DATASTRUCTURE: {
      const { datastructures } = state;
      const newDatastructures = datastructures ? [...datastructures] : [];
      newDatastructures.push(payload);
      return {
        ...state,
        datastructures: newDatastructures,
      };
    }
    case UPDATE_DATASTRUCTURE: {
      const datastructures = [...state.datastructures];

      const index = datastructures.findIndex((c) => c.id === payload.id);
      if (index !== -1) {
        datastructures[index] = payload;
      }

      return {
        ...state,
        datastructures,
      };
    }
    case DELETE_DATASTRUCTURE: {
      const datastructures = [...state.datastructures];
      const filteredDatastructures = datastructures.filter((datastructure) => datastructure.id !== payload);
      return {
        ...state,
        datastructures: filteredDatastructures,
      };
    }
    default:
      return state;
  }
};

export default reducer;
