import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { createChatbot } from 'modules/chatbots/actions';
import { createOrchestrator, updateOrchestrator, getOrchestrator } from 'modules/orchestrators/actions';
import { getMyOrganizations } from 'modules/organizations/actions';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import { ORCHESTRATOR_TYPES, ORCHESTRATOR_TYPE_AGENT } from 'constants/orchestrator';

const CACHE_DURATION_UNITS = [
  { label: 'minutes', value: 'MINUTES' },
  { label: 'hours', value: 'HOURS' },
  { label: 'days', value: 'DAYS' },
];

function CreateOrchestratorModalForm({
  showCreateForm,
  literals,
  onClose,
  orchestrator,
}) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [limit, setLimit] = useState(0);
  const [userDailyLimit, setUserDailyLimit] = useState(0);
  const [type, setType] = useState({ value: 'CONVERSATION', label: literals.conversational });
  const [hasCache, setHasCache] = useState({ value: 0, label: literals.common.no });
  const [cacheValue, setCacheValue] = useState(0);
  const [cacheUnit, setCacheUnit] = useState({ value: CACHE_DURATION_UNITS[0].value, label: literals.common[CACHE_DURATION_UNITS[0].label] });
  const [orchestratorOrganizations, setOrchestratorOrganizations] = useState([]);
  const { organizations } = useSelector((state) => state.organizations);
  const { actualOrganization } = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const { showErrorModal, printErrorModal } = useErrorHandling();

  useEffect(() => {
    if (orchestrator === null && actualOrganization) {
      setOrchestratorOrganizations(actualOrganization.id === 'personal' ? [] : [{ value: actualOrganization.id, label: actualOrganization.name }]);
    }
  }, [actualOrganization]);

  useEffect(() => {
    if (!organizations?.total) {
      dispatch(getMyOrganizations());
    }
  }, []);

  useEffect(() => {
    if (!orchestrator) {
      return;
    }
    setName(orchestrator.name);
    setDescription(orchestrator.description);
    setLimit(orchestrator.daily_limit);
    setCacheValue(orchestrator.cache_value || 0);

    const typeSelected = ORCHESTRATOR_TYPES.find((t) => t.value === orchestrator.type);
    if (typeSelected) {
      setType({ value: typeSelected.value, label: literals[typeSelected.label] });
    }

    if (orchestrator.cache_value && orchestrator.cache_value > 0) {
      setHasCache({ value: 1, label: literals.common.yes });
      const cacheUnitSelected = CACHE_DURATION_UNITS.find((u) => u.value === orchestrator.cache_unit);
      setCacheUnit({ value: cacheUnitSelected.value, label: literals.common[cacheUnitSelected.label] });
    }
    if (orchestrator.user_daily_limit) {
      setUserDailyLimit(orchestrator.user_daily_limit);
    }
  }, [orchestrator]);

  useEffect(() => {
    if (orchestrator && organizations && organizations.length > 0 && orchestrator.organizations.length > 0) {
      const selectedOrganizations = organizations.filter((organization) => orchestrator.organizations.includes(organization.id));
      setOrchestratorOrganizations(selectedOrganizations.map((organization) => ({ value: organization.id, label: organization.name })));
    }
  }, [orchestrator, organizations]);

  const selectSaveFunction = async () => {
    const organizationsIds = orchestratorOrganizations.map((organization) => organization.value);
    let finalCacheValue = 0;
    let finalCacheUnit = CACHE_DURATION_UNITS[0].value;

    if (type.value === ORCHESTRATOR_TYPE_AGENT && hasCache.value === 1) {
      finalCacheUnit = cacheUnit.value;
      finalCacheValue = parseInt(cacheValue, 10);
    }
    const values = {
      name,
      description,
      daily_limit: limit,
      user_daily_limit: userDailyLimit,
      organizations: organizationsIds,
      type: type.value,
      cache_unit: finalCacheUnit,
      cache_value: finalCacheValue,
    };
    try {
      if (orchestrator !== null) {
        return dispatch(updateOrchestrator(orchestrator.id, values));
      }

      const newOrchestrator = await dispatch(createOrchestrator(values));
      console.log('newOrchestrator', newOrchestrator);
      // Create new chatbot
      await dispatch(createChatbot(`${name} - Debug`, organizationsIds, newOrchestrator.id));
      return newOrchestrator;
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    }
    return false;
  };

  const onCloseModal = () => {
    setName('');
    setDescription('');
    setLimit(0);
    setUserDailyLimit(0);
    setType({ value: 'CONVERSATION', label: literals.conversational });
    setOrchestratorOrganizations([]);

    onClose();
  };

  const saveOrchestrator = () => {
    setIsSaving(true);
    selectSaveFunction()
      .then(() => {
        setIsSaving(false);
        onCloseModal();
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  const handleOnChangeName = (event) => {
    setName(event.target.value);
  };

  const handleOnChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleOnChangeLimit = (event) => {
    setLimit(event.target.value);
  };

  const handleOnChangeUserDailyLimit = (event) => {
    setUserDailyLimit(event.target.value);
  };

  const handleSelectOrganization = (selectedValues) => {
    setOrchestratorOrganizations(selectedValues);
  };

  const handleSelectType = (selectedType) => {
    setType(selectedType);
  };

  const canSave = () => {
    if (orchestrator !== null) {
      return name !== '';
    }
    return name !== '';
  };

  const handleChangeHasCache = (selectedType) => {
    setHasCache(selectedType);
  };

  const printCacheOptions = () => {
    if (type.value !== ORCHESTRATOR_TYPE_AGENT) {
      return null;
    }

    let cacheOptions = null;
    if (hasCache.value === 1) {
      cacheOptions = (
        <>
          <div className='input_title mt-2 mb-0'>{literals.cacheDuration}</div>
          <div className='flex gap-2 mb-0'>
            <div className='input_value'>
              <input type='number' className='form_input_stl' onChange={(e) => setCacheValue(e.target.value)} value={cacheValue} />
            </div>
            <div className='input_value flex-grow'>
              <Select
                options={CACHE_DURATION_UNITS.map((u) => ({ value: u.value, label: literals.common[u.label] }))}
                onChange={(e) => setCacheUnit(e)}
                value={cacheUnit}
              />
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className='input_title'>{literals.saveTheResultInCache}</div>
        <div className='input_value'>
          <Select
            options={[{ label: literals.common.yes, value: 1 }, { label: literals.common.no, value: 0 }]}
            onChange={handleChangeHasCache}
            value={hasCache}
          />
          {cacheOptions}
        </div>
      </>
    );
  };

  if (!showCreateForm) {
    return null;
  }

  return (
    <>
      {printErrorModal()?.modal}
      <Modal show onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{orchestrator ? literals.editOrchestrator : literals.createOrchestrator}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className='input_title'>
              {literals.common.name}
              <span className='required_field'>*</span>
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeName} value={name} />
            </div>
            <div className='input_title'>
              {literals.common.description}
            </div>
            <div className='input_value'>
              <input type='text' className='form_input_stl' onChange={handleOnChangeDescription} value={description} />
            </div>
            <div className='panel-header'>{literals.type}</div>
            <div className='input_value'>
              <Select
                options={ORCHESTRATOR_TYPES.map((t) => ({ value: t.value, label: literals[t.label] }))}
                onChange={handleSelectType}
                value={type}
                isDisabled={!!orchestrator}
              />
            </div>
            <div className='input_title'>
              {literals.dailyLimit}
            </div>
            <div className='input_value'>
              <input type='number' className='form_input_stl' onChange={handleOnChangeLimit} value={limit} />
            </div>
            <div className='input_title'>
              {literals.userDailyLimit}
            </div>
            <div className='input_value'>
              <input type='number' className='form_input_stl' onChange={handleOnChangeUserDailyLimit} value={userDailyLimit} />
            </div>
            {printCacheOptions()}
            <div className='panel-header'>{literals.organizations}</div>
            <div className='input_value'>
              <Select
                isMulti
                options={organizations.map((organization) => ({ value: organization.id, label: organization.name }))}
                onChange={handleSelectOrganization}
                value={orchestratorOrganizations}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onCloseModal}>
            {literals.common.close}
          </Button>
          <Button
            variant='primary'
            onClick={saveOrchestrator}
            disabled={!canSave()}
          >
            {isSaving && (<i className='fa-solid fa-spinner fa-spin' />)}
            {literals.common.accept}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

CreateOrchestratorModalForm.propTypes = {
  showCreateForm: PropTypes.bool.isRequired,
  literals: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  orchestrator: PropTypes.object,
};

CreateOrchestratorModalForm.defaultProps = {
  orchestrator: null,
};

export default CreateOrchestratorModalForm;
