import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

const URLS = {
  getChatbotQuestions: (chatbotId) => `chatbots/${chatbotId}/test_questions`,
  getAllQuestions: () => 'test_questions',
  createChatbotQuestion: () => 'test_questions',
  getTests: () => 'tests',
  createTest: () => 'tests',
  getTestDetails: (testId) => `tests/${testId}`,
  deleteTest: (testId) => `tests/${testId}`,
};

export default {
  async getChatbotQuestions(chatbotId) {
    return ApiFetchInstance.get(URLS.getChatbotQuestions(chatbotId));
  },
  async getAllQuestions() {
    return ApiFetchInstance.get(URLS.getAllQuestions());
  },
  async createChatbotQuestion(data) {
    return ApiFetchInstance.post(URLS.createChatbotQuestion(), data);
  },
  async getTests() {
    return ApiFetchInstance.get(URLS.getTests());
  },
  async createTest(data) {
    return ApiFetchInstance.post(URLS.createTest(), data);
  },
  async getTestDetails(testId) {
    return ApiFetchInstance.get(URLS.getTestDetails(testId));
  },
  async deleteTest(testId) {
    return ApiFetchInstance.delete(URLS.deleteTest(testId));
  },
};
