import {
  CLEAR_APIS, SET_APIS, ADD_API, UPDATE_API, DELETE_API,
  RESET_ENDPOINTS, SET_ENDPOINTS,
} from './index';
import Api from './repository';

export const clearChatbotApis = () => (dispatch) => {
  dispatch({ type: CLEAR_APIS });
};

export const getAPIs = (offset = 0, limit = 1000) => async (dispatch) => {
  const response = await Api.getAPIs(offset, limit);
  dispatch({ type: SET_APIS, payload: { apis: response.apis } });
  return response;
};

export const createApi = (data) => async (dispatch) => {
  const response = await Api.createApi(data);
  dispatch({ type: ADD_API, payload: { api: response } });
  return response;
};

export const updateApi = (apiId, data) => async (dispatch) => {
  const response = await Api.updateApi(apiId, data);
  dispatch({ type: UPDATE_API, payload: { api: response } });
  return response;
};

export const deleteApi = (apiId) => async (dispatch) => {
  const response = await Api.deleteApi(apiId);
  dispatch({ type: DELETE_API, payload: { apiId } });
  return response;
};

export const loadAndStoreApiEndpoints = (apiId) => async (dispatch) => {
  const response = await Api.getApiEndpoints(apiId);
  dispatch({ type: SET_ENDPOINTS, payload: { apiId, endpoints: response.endpoints } });
  return response;
};

export const clearApiEndpoints = () => (dispatch) => {
  dispatch({ type: RESET_ENDPOINTS });
};

export const getApiEndpoints = async (apiId) => {
  const response = await Api.getApiEndpoints(apiId);
  return response;
};

export const createApiEndpoint = async (apiId, data) => {
  const response = await Api.createApiEndpoint(apiId, data);
  return response;
};

export const updateApiEndpoint = async (apiId, endpointId, data) => {
  const response = await Api.updateApiEndpoint(apiId, endpointId, data);
  return response;
};

export const patchApiEndpoint = async (apiId, endpointId, data) => {
  const response = await Api.patchApiEndpoint(apiId, endpointId, data);
  return response;
};

export const deleteApiEndpoint = async (apiId, endpointId) => {
  const response = await Api.deleteApiEndpoint(apiId, endpointId);
  return response;
};

export const changeStatusApiEndpoints = async (apiId, active) => {
  const response = await Api.changeStatusApiEndpoints(apiId, active);
  return response;
};

export const loginInChatbotApi = async (chatbotId, conversationId, apiId, data) => {
  const response = await Api.loginInChatbotApi(chatbotId, conversationId, apiId, data);
  return response;
};

export const sendOtpInChatbotApi = async (chatbotId, conversationId, apiId, data) => {
  const response = await Api.sendOtpInChatbotApi(chatbotId, conversationId, apiId, data);
  return response;
};

export const verifyOtpInChatbotApi = async (chatbotId, conversationId, apiId, data) => {
  const response = await Api.verifyOtpInChatbotApi(chatbotId, conversationId, apiId, data);
  return response;
};

export const executeRequest = async (chatbotId, conversationId, messageId) => {
  const response = await Api.executeRequest(chatbotId, conversationId, messageId);
  return response;
};
