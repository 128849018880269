import React from 'react';
import PropTypes from 'prop-types';

function Form(props) {
  const { name, onChangeName } = props;

  const handleOnChangeName = (event) => {
    onChangeName(event.target.value);
  };

  return (
    <form>
      <div className='input_title'>
        Name
      </div>
      <div className='input_value'>
        <input type='text' className='form_input_stl' onChange={handleOnChangeName} value={name} />

      </div>
    </form>
  );
}

Form.propTypes = {
  name: PropTypes.string.isRequired,
  onChangeName: PropTypes.func.isRequired,
};

export default Form;
