export const getErrorMessage = (error) => {
  if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined') {
    if (typeof error.response.data.detail === 'string') {
      return error.response.data.detail;
    }

    if (typeof error.response.data.message === 'string') {
      return error.response.data.message;
    }

    if (typeof error.response.data.error === 'string') {
      return error.response.data.error;
    }
  }

  return error?.response?.data?.message ?? 'internalServerError';
};
