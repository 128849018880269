import { SET_TOOLS } from './index';
import Api from './repository';

export const getTools = (offset = 0, limit = 1000) => async (dispatch, getState) => {
  const { tools } = getState();
  if (tools.tools === null) {
    const response = await Api.getTools(offset, limit);
    dispatch({ type: SET_TOOLS, payload: response.tools });
  }
};
