import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useLiterals from 'hooks/useLiterals';
import Layout from 'components/Layout';
import Data from './Data';
import Questions from './Questions';
import QuestionForm from './QuestionForm';

const VIEW_DATA = 'view_data';
const VIEW_QUESTIONS = 'view_questions';

function Form(props) {
  const { form: originalForm, onBack } = props;
  const [form, setForm] = useState(originalForm ? { ...originalForm } : null);

  const handleRemoveQuestion = (updatedForm) => {
    setForm(updatedForm);
  };

  const [actualView, setActualView] = useState(VIEW_DATA);
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const [questionSelected, setQuestionSelected] = useState(null);

  const literals = useLiterals('forms');

  const handleSelectDataTab = () => {
    setActualView(VIEW_DATA);
  };

  const handleSelectQuestionsTab = () => {
    setActualView(VIEW_QUESTIONS);
  };

  const handleCreateQuestion = () => {
    setQuestionSelected(null);
    setShowQuestionForm(true);
  };

  const handleEditQuestion = (question) => {
    setQuestionSelected(question);
    setShowQuestionForm(true);
  };

  const closeQuestionForm = () => {
    setShowQuestionForm(false);
  };

  const handleNewQuestion = (question) => {
    const newForm = { ...form };
    if (!newForm.data) {
      newForm.data = {};
    }

    if (!newForm.data.questions) {
      newForm.data.questions = [];
    }

    if (questionSelected) {
      const index = newForm.data.questions.findIndex((q) => q.id === questionSelected.id);
      if (index >= 0) {
        newForm.data.questions[index] = question;
      }
    } else {
      newForm.data.questions.push(question);
    }

    setForm(newForm);
    setShowQuestionForm(false);
  };

  const printQuestionForm = () => {
    if (!showQuestionForm) {
      return null;
    }

    return (<QuestionForm question={questionSelected} onSubmit={handleNewQuestion} onClose={closeQuestionForm} />);
  };

  const printTabs = () => {
    if (!form) {
      return null;
    }

    return (
      <div className='secondary_tabs'>
        <div className={`tab ${actualView === VIEW_DATA ? 'selected' : ''}`} onClick={handleSelectDataTab}>{literals.data}</div>
        <div className={`tab ${actualView === VIEW_QUESTIONS ? 'selected' : ''}`} onClick={handleSelectQuestionsTab}>{literals.questions}</div>
      </div>
    );
  };

  const printDataView = () => {
    return (
      <Data form={form} onBack={onBack} />
    );
  };

  const printQuestionsView = () => {
    return (
      <Questions form={form} onEdit={handleEditQuestion} handleRemoveQuestion={handleRemoveQuestion} />
    );
  };

  const printActualView = () => {
    switch (actualView) {
      case VIEW_DATA:
        return printDataView();
      case VIEW_QUESTIONS:
        return printQuestionsView();
      default:
        return null;
    }
  };

  return (
    <Layout selectedSection='forms'>
      {printQuestionForm()}
      <div className='section'>
        <div className='section_header'>
          <h1>{literals.forms}</h1>
          <div>
            {actualView === VIEW_QUESTIONS && (
              <button type='button' className='btn btn-primary' onClick={handleCreateQuestion}>
                {literals.addQuestion}
              </button>
            )}
          </div>
        </div>
        <div className='mt-lg'>
          {printTabs()}
          {printActualView()}
        </div>
      </div>
    </Layout>
  );
}

Form.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  onBack: PropTypes.func.isRequired,
};

Form.defaultProps = {
  form: null,
};

export default Form;
