import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const DATA_CATEGORY = 'Data';

export default {
  MAKE_OBJECT: {
    categories: [DATA_CATEGORY],
    tag: 'makeObject',
    name: 'Create/Edit Object',
    tags: ['object', 'structure', 'dictionary', 'json', 'data', 'parse', 'convert', 'transform', 'make', 'create', 'edit', 'update', 'change', 'set', 'modify', 'make object', 'create object', 'edit object', 'update object', 'change object', 'set object', 'modify object'],
    icon: 'fa-solid fa-rectangle-list',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'object',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'object',
        custom: false,
        to: [],
      },
    ],
  },
  MERGE_OBJECTS: {
    categories: [DATA_CATEGORY],
    tag: 'mergeObjects',
    name: 'Merge objects',
    tags: ['object', 'structure', 'dictionary', 'json', 'data', 'parse', 'merge'],
    icon: 'fa-solid fa-rectangle-list',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'object 1',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'object 2',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'merged',
        custom: false,
        to: [],
      },
    ],
  },
  PROCESS_JSON: {
    categories: [DATA_CATEGORY],
    tag: 'processJson',
    name: 'Process JSON',
    tags: ['object', 'structure', 'dictionary', 'json', 'data', 'parse', 'convert', 'transform', 'extract', 'extractor', 'extracting'],
    icon: 'fa-solid fa-file-lines',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'text',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.OBJECT,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'object',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
    ],
  },
};
