import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import useLiterals from 'hooks/useLiterals';
import useErrorHandling from 'hooks/useErrorHandling';
import { getErrorMessage } from 'utils/errors';
import { v4 as uuidv4 } from 'uuid';
import Form from 'react-bootstrap/Form';
import { createApiEndpoint, updateApiEndpoint } from 'modules/apis/actions';

function FormEndpoint(props) {
  const {
    apiId, onClose, onSubmit, data,
  } = props;

  const [idEndpoint, setIdEndpoint] = useState(data?.id ?? null);
  const [summary, setSummary] = useState(data?.summary ?? '');
  const [description, setDescription] = useState(data?.description ?? '');
  const [active, setActive] = useState(data?.active ?? false);
  const [url, setUrl] = useState(data?.endpoint ?? '');
  const [method, setMethod] = useState(data?.method ?? 'get');
  const [params, setParams] = useState([]);
  const [requestBody, setRequestBody] = useState([]);
  const [requiresConfirmation, setRequiresConfirmation] = useState(data?.requires_confirmation ?? false);

  const [isSaving, setIsSaving] = useState(false);

  const { showErrorModal, printErrorModal } = useErrorHandling();
  const literals = useLiterals('addApi');

  useEffect(() => {
    if (data && data.parameters) {
      setParams(data.parameters.map((param) => ({ ...param, id: uuidv4() })));
    }
    if (data && data.request_body) {
      const bodyParams = data.request_body?.content ?? {};
      const newRequestBody = [];
      Object.keys(bodyParams).forEach((name) => {
        const type = bodyParams[name];
        newRequestBody.push({ name, type, id: uuidv4() });
      });
      setRequestBody(newRequestBody);
    }
  }, []);

  const handleOnChangeSummary = (e) => {
    setSummary(e.target.value);
  };

  const handleOnChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleOnChangeActive = () => {
    setActive(!active);
  };

  const handleOnChangeUrl = (e) => {
    setUrl(e.target.value);
  };

  const handleOnChangeMethod = (e) => {
    setMethod(e.target.value);
  };

  const handleOnChangeRequiresConfirmation = (e) => {
    setRequiresConfirmation(e.target.checked);
  };

  const handleAddParam = () => {
    const newParam = {
      in: 'get', name: '', type: 'string', description: '', required: false, id: uuidv4(),
    };
    setParams([...params, newParam]);
  };

  const handleAddRequestBodyParam = () => {
    const newParam = {
      name: '', type: 'string', id: uuidv4(),
    };

    setRequestBody([...requestBody, newParam]);
  };

  const canContinue = () => {
    if (!url.trim() || !summary.trim()) {
      return false;
    }

    // Check params
    let isOk = true;
    params.forEach((param) => {
      if (!param.name.trim()) {
        isOk = false;
      }
    });
    return isOk;
  };

  const handleSubmit = async () => {
    const dataToSend = {
      method,
      endpoint: url,
      summary,
      description,
      active,
      parameters: params,
      requires_confirmation: requiresConfirmation,
      request_body: {
      },
    };
    if (requestBody.length > 0) {
      const bodyParams = {};
      requestBody.forEach((param) => {
        if (param.name) {
          bodyParams[param.name] = param.type;
        }
      });

      dataToSend.request_body.content = bodyParams;
    }

    try {
      setIsSaving(true);
      if (!idEndpoint) {
        await createApiEndpoint(apiId, dataToSend);
      } else {
        await updateApiEndpoint(apiId, idEndpoint, dataToSend);
      }
      setIsSaving(false);
      onSubmit();
    } catch (err) {
      setIsSaving(false);
      const errorMessage = getErrorMessage(err);
      showErrorModal(errorMessage);
    }
  };

  const handleChangeMethodParam = (e) => {
    const { value } = e.target;
    const id = e.target.getAttribute('data-id');
    const newParams = params.map((param) => {
      if (param.id === id) {
        return { ...param, in: value };
      }
      return param;
    });
    setParams(newParams);
  };

  const handleChangeNameParam = (e) => {
    const { value } = e.target;
    const id = e.target.getAttribute('data-id');
    const newParams = params.map((param) => {
      if (param.id === id) {
        return { ...param, name: value };
      }
      return param;
    });
    setParams(newParams);
  };

  const handleChangeNameRequestBodyParam = (e) => {
    const { value } = e.target;
    const id = e.target.getAttribute('data-id');
    const newRequestBody = requestBody.map((param) => {
      if (param.id === id) {
        return { ...param, name: value };
      }
      return param;
    });
    setRequestBody(newRequestBody);
  };

  const handleChangeDescriptionParam = (e) => {
    const { value } = e.target;
    const id = e.target.getAttribute('data-id');
    const newParams = params.map((param) => {
      if (param.id === id) {
        return { ...param, description: value };
      }
      return param;
    });
    setParams(newParams);
  };

  const handleChangeTypeParam = (e) => {
    const { value } = e.target;
    const id = e.target.getAttribute('data-id');
    const newParams = params.map((param) => {
      if (param.id === id) {
        return { ...param, type: value };
      }
      return param;
    });
    setParams(newParams);
  };

  const handleChangeRequestBodyTypeParam = (e) => {
    const { value } = e.target;
    const id = e.target.getAttribute('data-id');
    const newRequestBody = requestBody.map((param) => {
      if (param.id === id) {
        return { ...param, type: value };
      }
      return param;
    });
    setRequestBody(newRequestBody);
  };

  const handleChangeRequiredParam = (e) => {
    const { checked } = e.target;
    const id = e.target.getAttribute('data-id');
    const newParams = params.map((param) => {
      if (param.id === id) {
        return { ...param, required: checked };
      }
      return param;
    });
    setParams(newParams);
  };

  const handleDeleteParam = (e) => {
    const id = e.target.getAttribute('data-id');
    const newParams = params.filter((param) => param.id !== id);
    setParams(newParams);
  };

  const handleDeleteRequestBodyParam = (e) => {
    const id = e.target.getAttribute('data-id');
    const newRequestBody = requestBody.filter((param) => param.id !== id);
    setRequestBody(newRequestBody);
  };

  const printParams = () => {
    return params.map((param) => {
      return (
        <div className='endpoint_param' key={param.id}>
          <div className='row'>
            <div className='col-4'>
              <div className='input_title'>
                {literals.common.name}
                <span className='required_field'>*</span>
              </div>
              <div className='input_value'>
                <input type='text' className='form_input_stl' data-id={param.id} onChange={handleChangeNameParam} value={param.name} />
              </div>
            </div>
            <div className='col-8'>
              <div className='input_title'>
                {literals.common.description}
              </div>
              <div className='input_value'>
                <input type='text' className='form_input_stl' data-id={param.id} onChange={handleChangeDescriptionParam} value={param.description} />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <div className='input_title'>
                {literals.in}
                <span className='required_field'>*</span>
              </div>
              <div className='input_value'>
                <select className='form_select_stl' data-id={param.id} onChange={handleChangeMethodParam} value={param.in}>
                  <option value='get'>GET</option>
                  <option value='post'>POST</option>
                  <option value='path'>PATH</option>
                  <option value='header'>HEADER</option>
                </select>
              </div>
            </div>
            <div className='col-4'>
              <div className='input_title'>
                {literals.type}
                <span className='required_field'>*</span>
              </div>
              <div className='input_value'>
                <select className='form_select_stl' data-id={param.id} onChange={handleChangeTypeParam} value={param.type}>
                  <option value='string'>String</option>
                  <option value='number'>Number</option>
                  <option value='boolean'>Boolean</option>
                  <option value='array'>Array</option>
                </select>
              </div>
            </div>
            <div className='col-3'>
              <div className='input_title'>
                {literals.required}
              </div>
              <div className='input_value mt-1'>
                <Form.Check
                  type='switch'
                  id='embed-switch'
                  data-id={param.id}
                  onChange={handleChangeRequiredParam}
                  checked={param.required}
                />
              </div>
            </div>
            <div className='col-1 right'>
              <i className='fa-solid fa-trash-can delete_icon' data-id={param.id} onClick={handleDeleteParam} />
            </div>
          </div>
        </div>
      );
    });
  };

  const printRequestBody = () => {
    return requestBody.map((param) => {
      return (
        <div className='endpoint_param' key={param.id}>
          <div className='row'>
            <div className='col-6'>
              <div className='input_title'>
                {literals.common.name}
                <span className='required_field'>*</span>
              </div>
              <div className='input_value'>
                <input type='text' className='form_input_stl' data-id={param.id} onChange={handleChangeNameRequestBodyParam} value={param.name} />
              </div>
            </div>
            <div className='col-5'>
              <div className='input_title'>
                {literals.type}
                <span className='required_field'>*</span>
              </div>
              <div className='input_value'>
                <select className='form_select_stl' data-id={param.id} onChange={handleChangeRequestBodyTypeParam} value={param.type}>
                  <option value='string'>String</option>
                  <option value='number'>Number</option>
                  <option value='boolean'>Boolean</option>
                  <option value='array'>Array</option>
                </select>
              </div>
            </div>
            <div className='col-1 right'>
              <i className='fa-solid fa-trash-can delete_icon' data-id={param.id} onClick={handleDeleteRequestBodyParam} />
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <Modal show onHide={onClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{literals.endpoint}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='endpoint-form'>
          <div className='panel'>
            <div className='panel-header'>{literals.endpointData}</div>
            <div className='panel-body'>
              <div className='row'>
                <div className='col-3'>
                  <div className='input_title'>
                    {literals.method}
                    <span className='required_field'>*</span>
                  </div>
                  <div className='input_value'>
                    <select className='form_select_stl' onChange={handleOnChangeMethod}>
                      <option value='get'>GET</option>
                      <option value='post'>POST</option>
                      <option value='put'>PUT</option>
                      <option value='patch'>PATCH</option>
                      <option value='delete'>DELETE</option>
                    </select>
                  </div>
                </div>
                <div className='col-7'>
                  <div className='input_title'>
                    {literals.urlWithoutUrlBase}
                    <span className='required_field'>*</span>
                  </div>
                  <div className='input_value'>
                    <input type='text' className='form_input_stl' onChange={handleOnChangeUrl} value={url} placeholder='/your_endpoint' />
                  </div>
                </div>
                <div className='col-2'>
                  <div className='input_title'>
                    {literals.status}
                  </div>
                  {active ? (
                    <div className='status active' onClick={handleOnChangeActive}>
                      {literals.active}
                    </div>
                  ) : (
                    <div className='status inactive' onClick={handleOnChangeActive}>
                      {literals.inactive}
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='input_title'>
                    {literals.summary}
                    <span className='required_field'>*</span>
                  </div>
                  <div className='input_value'>
                    <input type='text' className='form_input_stl' onChange={handleOnChangeSummary} value={summary} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='input_title'>
                    {literals.description}
                  </div>
                  <div className='input_value'>
                    <textarea className='form_input_stl' onChange={handleOnChangeDescription} value={description} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='input_title'>
                    <Form.Check
                      type='switch'
                      id='requires_confirmation-switch'
                      label={literals.requiresUserConfirmation}
                      onChange={handleOnChangeRequiresConfirmation}
                      checked={requiresConfirmation}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='panel'>
            <div className='panel-header'>{literals.params}</div>
            <div className='panel-body'>
              {printParams()}
              <div className='right'>
                <Button variant='primary' onClick={handleAddParam}>{literals.addParam}</Button>
              </div>
            </div>
          </div>
          <div className='panel'>
            <div className='panel-header'>{literals.requestBody}</div>
            <div className='panel-body'>
              {printRequestBody()}
              <div className='right'>
                <Button variant='primary' onClick={handleAddRequestBodyParam}>{literals.addParam}</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {literals.common.cancel}
        </Button>
        <Button
          variant='primary'
          onClick={handleSubmit}
          disabled={!canContinue()}
        >
          {isSaving && (<i className='fa-solid fa-spinner fa-spin mr5' />)}
          {literals.common.accept}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

FormEndpoint.propTypes = {
  onSubmit: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  data: propTypes.object,
  apiId: propTypes.string.isRequired,
};

FormEndpoint.defaultProps = {
  data: null,
};

export default FormEndpoint;
