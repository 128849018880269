import { TYPE_CONNECTIONS, TYPE_SET_CONNECTIONS } from '../orchestrator';

const ARRAY_CATEGORY = 'Arrays';

export default {
  ARRAY_CLEAR: {
    categories: [ARRAY_CATEGORY],
    tag: 'arrayClear',
    name: 'Clear array',
    tags: ['array', 'clear', 'delete', 'remove'],
    icon: 'fa-solid fa-trash-alt',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
    ],
  },
  ARRAY_APPEND: {
    categories: [ARRAY_CATEGORY],
    tag: 'arrayAppend',
    name: 'Append to array',
    tags: ['array', 'append', 'add', 'insert'],
    icon: 'fa-solid fa-plus-circle',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'element',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'index',
        custom: false,
        to: [],
      },
    ],
  },
  ARRAY_TO_STRING: {
    categories: [ARRAY_CATEGORY],
    tag: 'arrayToString',
    name: 'Array to string',
    tags: ['array', 'string', 'convert', 'transform'],
    icon: 'fa-solid fa-list-ul',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.STRING,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'string',
        custom: false,
        to: [],
      },
    ],
  },
  ARRAY_LENGTH: {
    categories: [ARRAY_CATEGORY],
    tag: 'arrayLength',
    name: 'Array length',
    tags: ['array', 'length', 'size', 'count', 'get array length', 'get length of array', 'get length array'],
    icon: 'fa-solid fa-list-ul',
    inputs: [
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'length',
        custom: false,
        to: [],
      },
    ],
  },
  ARRAY_MERGE: {
    categories: [ARRAY_CATEGORY],
    tag: 'arrayMerge',
    name: 'Array merge',
    tags: ['array', 'merge', 'extend', 'concat', 'combine'],
    icon: 'fa-solid fa-list-ul',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array1',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array2',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'merged',
        custom: false,
        to: [],
      },
    ],
  },
  ARRAY_INDEX_OF: {
    categories: [ARRAY_CATEGORY],
    tag: 'arrayIndexOf',
    name: 'Array index of',
    tags: ['array', 'index', 'find', 'search', 'position'],
    icon: 'fa-solid fa-list-ul',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'element',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'position',
        custom: false,
        to: [],
      },
    ],
  },
  ARRAY_INSERT: {
    categories: [ARRAY_CATEGORY],
    tag: 'arrayInsert',
    name: 'Insert into array',
    tags: ['array', 'insert', 'add', 'position'],
    icon: 'fa-solid fa-plus-circle',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'position',
        custom: false,
        default_value: 0,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'element',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        to: [],
      },
    ],
  },
  ARRAY_POP: {
    categories: [ARRAY_CATEGORY],
    tag: 'arrayPop',
    name: 'Pop from array',
    tags: ['array', 'pop', 'remove', 'delete', 'last'],
    icon: 'fa-solid fa-trash-alt',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'position',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'element',
        custom: false,
        to: [],
      },
    ],
  },
  ARRAY_REMOVE: {
    categories: [ARRAY_CATEGORY],
    tag: 'arrayRemove',
    name: 'Remove from array',
    tags: ['array', 'remove', 'delete'],
    icon: 'fa-solid fa-trash-alt',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'element',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        to: [],
      },
    ],
  },
  ARRAY_REVERSE: {
    categories: [ARRAY_CATEGORY],
    tag: 'arrayReverse',
    name: 'Reverse array',
    tags: ['array', 'reverse', 'invert', 'flip', 'array reverse'],
    icon: 'fa-solid fa-undo',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        to: [],
      },
    ],
  },
  ARRAY_SORT: {
    categories: [ARRAY_CATEGORY],
    tag: 'arraySort',
    name: 'Sort array',
    tags: ['array', 'sort', 'order', 'arrange', 'array sort'],
    icon: 'fa-solid fa-undo',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        to: [],
      },
    ],
  },
  ARRAY_GET_ELEMENT: {
    categories: [ARRAY_CATEGORY],
    tag: 'arrayGetElement',
    name: 'Get array element',
    tags: ['array', 'get', 'element', 'position', 'index', 'array get element', 'get element array'],
    icon: 'fa-solid fa-list-ul',
    inputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.ARRAY,
        name: 'array',
        custom: false,
        from: [],
      },
      {
        type: TYPE_CONNECTIONS.NUMBER,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'position',
        default_value: '0',
        custom: false,
        from: [],
      },
    ],
    outputs: [
      {
        type: TYPE_CONNECTIONS.EXEC,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: '',
        custom: false,
        to: [],
      },
      {
        type: TYPE_CONNECTIONS.ANY,
        type_set: TYPE_SET_CONNECTIONS.SINGLE,
        name: 'element',
        custom: false,
        to: [],
      },
    ],
  },
};
