export const SET_COMPANIES = 'elements/set-companies';
export const ADD_COMPANY = 'elements/add-company';
export const SET_DUE_DILIGENCES = 'elements/set-due-diligences';
export const ADD_DUE_DILIGENCE = 'elements/add-due-diligence';
export const SET_ELEMENTS = 'elements/set-elements';

export const initialState = {
  elements: null,
  companies: [],
  dueDiligences: [],
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_ELEMENTS: {
      return {
        ...state,
        elements: payload,
      };
    }
    case SET_COMPANIES: {
      return {
        ...state,
        companies: payload,
        dueDiligences: [],
      };
    }
    case ADD_COMPANY: {
      const { companies } = state;
      const newCompanies = structuredClone(companies);
      newCompanies.push(payload);
      return {
        ...state,
        companies: newCompanies,
      };
    }
    case SET_DUE_DILIGENCES:
      return {
        ...state,
        dueDiligences: payload,
      };
    case ADD_DUE_DILIGENCE: {
      const { dueDiligences } = state;
      const newDueDiligences = structuredClone(dueDiligences);
      newDueDiligences.push(payload);
      return {
        ...state,
        dueDiligences: newDueDiligences,
      };
    }
    default:
      return state;
  }
};

export default reducer;
